import React from "react";
import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ReactComponent as StatusActiveIcon } from "../../Icons/StatusActive.svg";
import { getChip } from "../../Utils";
import { StyledSecondaryTypography } from "../../Theme";
import { ReactComponent as StatusInactiveIcon } from "../../Icons/StatusInactive.svg";
import { ReactComponent as CheckedactiveIcon } from "../../Icons/CheckedActive.svg";
import moment from "moment";
import { useSelector } from "react-redux";


const ParcelStepper = ({ parcelStatus , fullData }) => {
  const { LanguageData } = useSelector((state) => state.Language);
  const collect = parcelStatus.find((value) => value.status_details.id === 1);
  const uncollect = parcelStatus.find((value) => value.status_details.id === 2);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E5E7EB",
          borderRadius: "8px",
          width: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Box sx={{ p: 3, borderBottom: "1px solid #E5E7EB" }}>
          <Typography
            sx={{ color: "#111927", fontSize: "14px", fontWeight: 500 }}
          >
       {LanguageData?.Status_Update || "Status Update"}     
          </Typography>
        </Box>
        <Stepper orientation="vertical" sx={{ px: 2, py: 3 }}>
          {collect && (
            <Step active>
              <StepLabel icon={<CheckedactiveIcon />}>
                {getChip(collect?.status_details?.name || "")}
              </StepLabel>
              <StepContent>
                <StyledSecondaryTypography>
                  {collect?.personal_details?.full_name || "--"}(Resident {fullData?.unitDetails?.unit_no}) has
                  collected the parcel.
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ marginTop: "6px" }}>
                  {moment.utc(collect?.updated_at).local().format("DD MMM YYYY • HH:mm")}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}

          <Step active>
            <StepLabel
              icon={
                uncollect?.status_id === 2  && !collect ? (
                  <StatusActiveIcon />
                ) : (
                  <StatusInactiveIcon />
                )
              }
            >
              {getChip(uncollect?.status_details?.name || "")}
            </StepLabel>
            <StepContent>
              <StyledSecondaryTypography>
                {uncollect?.personal_details?.full_name || "--"} ({fullData?.roleDetails?.title || '--'}) has marked the parcel
                as ready for collection
              </StyledSecondaryTypography>
              <StyledSecondaryTypography sx={{ marginTop: "6px" }}>
                {moment.utc(uncollect?.updated_at).local().format("DD MMM YYYY • HH:mm")}
              </StyledSecondaryTypography>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </>
  );
};

export default ParcelStepper;
