import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import { Box, Button, Grid, Divider } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addMyDetails,
  setNestedStep,
  setPropertyId,
  updatePropertyDetailsCompletedSteps,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useNavigate } from "react-router-dom";
import { changeUserData } from "../../../Utils";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const ReviewMyDetails = ({ datas, setDisplayScreen, billingemail }) => {
  const [postLoader, SetPostLoader] = useState(false);
  const navigate = useNavigate();
  let { workDetails, generalDetails, emergencyContact, personalDetails } =
    datas;
  const dispatch = useDispatch();
  const { addMyDetailsStatus, propertyId, propertyCompletedStep } = useSelector(
    (state) => state.PropertyDetails
  );

  useEffect(() => {
    if (addMyDetailsStatus != null) {
      if (addMyDetailsStatus.success === true) {
        if (changeUserData(5)) {
          dispatch(
            updatePropertyDetailsCompletedSteps({
              propertyId,
              completed_step: 5,
            })
          );
        } else if (!changeUserData(5)) {
          dispatch(setActiveStep(5));
          dispatch(setPropertySliceToNull());
          dispatch(setNestedStep(0));
        }
      }
    }
  }, [addMyDetailsStatus]);

  useEffect(() => {
    if (propertyCompletedStep != null) {
      if (propertyCompletedStep.success === true) {
        changeUserData(5, "change");
        dispatch(setActiveStep(5));
        dispatch(setPropertySliceToNull());
        dispatch(setNestedStep(0));
        SetPostLoader(false);
      }
    }
  }, [propertyCompletedStep]);

  const handleContinue = () => {
    SetPostLoader(true);
    // dispatch(addMyDetails(
    //     {
    //         first_name: generalDetails.firstName,
    //         last_name: generalDetails.lastName,
    //         middle_name: generalDetails.middleName,
    //         dob: moment(generalDetails.dob).format('YYYY-MM-DD'),
    //         gender: generalDetails.gender,
    //         nationality: generalDetails.nationality,
    //         identity_number: generalDetails.identityNumber,
    //         phone: generalDetails.phone,
    //         country_code: generalDetails.country_code,
    //         address: generalDetails.address,
    //         country: generalDetails.country,
    //         city: generalDetails.city,
    //         zipcode: generalDetails.postalCode,

    //         emergency_details: {
    //             first_name: emergencyContact.firstName,
    //             last_name: emergencyContact.lastName,
    //             email: emergencyContact.email,
    //             phone: emergencyContact.phone,
    //             country_code: emergencyContact.country_code,
    //             gender: emergencyContact.gender,
    //             nationality: emergencyContact.nationality,
    //             relationship: emergencyContact.relationship
    //         },

    //         access_details: {
    //             residency_type_id: workDetails.type,
    //             department_id: workDetails.department,
    //             role_id: workDetails.jobRole,
    //             level_id: workDetails.level,
    //             unit_id: workDetails.departmentLocation,
    //             access_start: moment(workDetails.accessStartDate).format('YYYY-MM-DD'),
    //             access_end: moment(workDetails.accessEndDate).format('YYYY-MM-DD')
    //         }
    //     }
    // ))
    const data = {
      first_name: personalDetails.firstName,
      last_name: personalDetails.lastName,
      country_code: personalDetails.country_code,
      phone: personalDetails.phone,
      billing_email: "",
    };

    {
      !billingemail &&
        Object.assign(data, { billing_email: personalDetails.billingemail });
    }
    dispatch(addMyDetails(data));
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <BackArrow
        onClick={() => {
          setDisplayScreen("myDetails");
          dispatch(setNestedStep(0));
        }}
        style={{ marginBottom: "26px", cursor: "pointer" }}
      />
      <PrimaryTypography>Administrator</PrimaryTypography>
      <StyledSecondaryTypography mb={3.2}>
        Information related platform’s admin account.
      </StyledSecondaryTypography>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #E5E7EB",
          mt: 2,
        }}
      >
        <Box sx={{ p: 2, borderBottom: "1px solid #E5E7EB" }}>
          <SecondaryTypography>Personal Details</SecondaryTypography>
        </Box>
        <Grid container sx={{ p: 2 }} rowSpacing={2.4} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
            <TypographyDefault>{personalDetails.firstName}</TypographyDefault>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
            <TypographyDefault>{personalDetails.lastName}</TypographyDefault>
          </Grid>
          <Divider />
          {/* <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Middle Name</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.middleName}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Date of Birth</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.dob}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.gender}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.nationality}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Identity Number / Passport Number</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.identityNumber}</TypographyDefault>
                    </Grid> */}
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>Email Address</StyledSecondaryTypography>
            <TypographyDefault>{personalDetails.email}</TypographyDefault>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>
              Contact Number
            </StyledSecondaryTypography>
            <TypographyDefault>
              {personalDetails.country_code} {personalDetails.phone}
            </TypographyDefault>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>
              Billing Email Address{" "}
            </StyledSecondaryTypography>
            <TypographyDefault>
              {billingemail
                ? personalDetails.email
                : personalDetails.billingemail}
            </TypographyDefault>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Address</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.address}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
                        <TypographyDefault>{generalDetails.postalCode}</TypographyDefault>
                    </Grid> */}
        </Grid>

        {/* <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #E5E7EB', mt: 2 }}>
                <Box sx={{ p: 2, borderBottom: '1px solid #E5E7EB' }}>
                    <SecondaryTypography>Emergency Contact</SecondaryTypography>
                </Box>
                <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.firstName}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.lastName}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Email Address</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.email}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Contact Number</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.contact}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.nationality}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.gender}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Relationship</StyledSecondaryTypography>
                        <TypographyDefault>{emergencyContact.relationship}</TypographyDefault>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #E5E7EB', mt: 2 }}>
                <Box sx={{ p: 2, borderBottom: '1px solid #E5E7EB' }}>
                    <SecondaryTypography>Work & Access Details</SecondaryTypography>
                </Box>
                <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Employment Type</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.type}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Department</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.department}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.jobRole}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Level</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.level}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Unit</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.unit}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Access Start Date</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.accessStartDate}</TypographyDefault>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSecondaryTypography>Access End Date</StyledSecondaryTypography>
                        <TypographyDefault>{workDetails.accessEndDate}</TypographyDefault>
                    </Grid>
                </Grid>
            </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "20px 16px",
            alignItems: "center",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          {/* <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <RefreshIcon style={{ marginRight: '5px' }} />
                        <StyledSecondaryTypography>Last Updated at {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
                    </Box> */}
          <Button
            onClick={handleContinue}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReviewMyDetails;
