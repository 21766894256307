import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, edit, get, post } from "../../AxiosInstance";
import axios from "axios";
import axiosInstance from "../../AxiosInstance";



const initialState = {
    addContactDetailsStatus: null,
    contactDetailsList: [],
    editContactDetailsId: null,
    editContactDetailsStatus: null,
    deleteContactDetailsStatus: null,

}


// ContactDetails

export const addContactDetails = createAsyncThunk('addContactDetails', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/${data.propertyId}/contactbook`, data.data);
        return response;
    } catch (err) {

        return rejectWithValue(err)
    }
})

export const getContactDetails = createAsyncThunk('getContactDetails', async (data, { rejectWithValue }) => {
    const { propertyId, id } = data
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/${id}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editContactDetails = createAsyncThunk('editContactDetails', async (data, { rejectWithValue }) => {
    let { datas, propertyId, id } = data
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/${id}`, datas);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteContactDetails = createAsyncThunk('deleteContactDetails', async (data, { rejectWithValue }) => {
    const { propertyId, id } = data
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/${id}`);
        console.log(response);
        return response;
    } catch (err) {
        console.log(rejectWithValue(err));
        return rejectWithValue(err)
    }
})

export const deleteMultipleContactDetails = createAsyncThunk('deleteMultipleContactDetails', async (datas, { rejectWithValue }) => {
    const { propertyId, ids } = datas
    console.log(ids);
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/multiple_delete`, { data: ids });
        console.log(response);
        return response;
    } catch (err) {
        console.log(rejectWithValue(err));
        return rejectWithValue(err)
    }
})



const ContactDetailsReducer = createSlice({
    name: 'ContactDetails',
    initialState,
    reducers: {
        setContactBookSliceToNull: (state) => {
            state.addContactDetailsStatus = null
            state.editContactDetailsStatus = null
            state.deleteContactDetailsStatus = null
            state.contactDetailsList = []
        },
    },
    extraReducers: (builders) => {
        builders.addCase(addContactDetails.fulfilled, (state, action) => {
            state.addContactDetailsStatus = action.payload.data
        })
        builders.addCase(addContactDetails.rejected, (state, action) => {
            state.addContactDetailsStatus = action.payload.response.data
        })
        builders.addCase(getContactDetails.fulfilled, (state, action) => {
            state.contactDetailsList = action.payload.data
            // state.editContactDetailsId = action.payload.data;
        })
        builders.addCase(getContactDetails.rejected, (state, action) => {
            state.contactDetailsList = []
        })
        builders.addCase(editContactDetails.fulfilled, (state, action) => {
            state.editContactDetailsStatus = action.payload.data
        })
        builders.addCase(editContactDetails.rejected, (state, action) => {
            state.editContactDetailsStatus = action.payload.response.data
        })
        builders.addCase(deleteContactDetails.fulfilled, (state, action) => {
            state.deleteContactDetailsStatus = action.payload.data

        })
        builders.addCase(deleteContactDetails.rejected, (state, action) => {
            state.deleteContactDetailsStatus = action.payload.response.data

        })
        builders.addCase(deleteMultipleContactDetails.fulfilled, (state, action) => {
            state.deleteContactDetailsStatus = action.payload.data

        })
        builders.addCase(deleteMultipleContactDetails.rejected, (state, action) => {
            state.deleteContactDetailsStatus = action.payload.response.data

        })
    }
})


export const { setContactBookSliceToNull } = ContactDetailsReducer.actions
export default ContactDetailsReducer.reducer