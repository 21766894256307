import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import { ReactComponent as PasswordVisible } from "../Icons/PasswordVisible.svg";
import { ReactComponent as PasswordInvisible } from "../Icons/PasswordInvisible.svg";
import { ReactComponent as SuccessTick } from "../Icons/SuccessTick.svg";
import { ReactComponent as AlertIcon } from "../Icons/ErrorAlert.svg";
import {
  StyledErrorTypography,
  StyledPrimaryTypography,
  StyledSecondaryTypography,
} from "../Theme";
import {
  Box,
  Button,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  updatePassword,
  setLoginSliceToNull,
} from "../Redux/Feature/Login/loginSlice";
import axiosInstance from "../Redux/AxiosInstance";
import { setActiveStep } from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { showToast } from "../CommonComponents/Toaster";

const CreatePassword = ({
  email,
  setDisplayScreen,
  setPasswords,
  setUserNameRole,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { updatePasswordStatus } = useSelector((state) => state.Login);
  let passwordRegex = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  const [password, setPassword] = useState({ new: "", confirm: "" });
  const [passwordType, setPasswordType] = useState({
    containerOneType: "password",
    containerTwoType: "password",
  });
  const [errorText, setErrorText] = useState({ new: "", confirm: "" });
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    if (updatePasswordStatus != null) {
      setLoadingBtn(false);
      if (updatePasswordStatus.success) {
        // localStorage.setItem("accessToken", updatePasswordStatus.data.token.access_token)
        axios
          .get(`${process.env.REACT_APP_API_URL}user_property`, {
            headers: {
              Authorization: `Bearer ${updatePasswordStatus.data.token.access_token}`,
            },
          })
          .then((data) => {
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(data.data.data.property_details)
            );

            // if (data.data.data.property_details[0].status === "draft") {
            // setActiveStep(data.data.data.property_details.completed_step == 0 ? data.data.data.property_details.completed_step : data.data.data.property_details.completed_step - 1)
            setPasswords(password.new);
            setUserNameRole(updatePasswordStatus.data?.user_details);
            setDisplayScreen("successPage");
            dispatch(setLoginSliceToNull());
            // }
            // else if (data.data.data.property_details[0].status !== "draft") {
            //   // setActiveStep(data.data.data.property_details.completed_step==0 ? data.data.data.property_details.completed_step : data.data.data.property_details.completed_step-1 )
            //   setDisplayScreen("login");
            //   dispatch(setLoginSliceToNull());
            // }
            // dispatch(login({ email, password: password.new }))
            // else{
            //     localStorage.setItem("accessToken",updatePasswordStatus.data.token.access_token)
            //     localStorage.setItem('propertyStatus',data.data.data.property_details)
            //     navigate('/overview')
            // }
          });
      } else {
        setErrorText({
          new: "",
          confirm: "Something went wrong! Please try again.",
        });
        // showToast("Something went wrong! Please try again.", "error");
      }
    }
  }, [updatePasswordStatus]);

  const handlePassword = (e) => {
    let { name, value } = e.target;
    setPassword({
      ...password,
      [name]: value,
    });
    setErrorText({ new: "", confirm: "" });
  };

  const handlePasswordType = (containerType, value) => {
    setPasswordType({
      ...passwordType,
      [containerType]: value,
    });
  };

  const validation = () => {
    let isError = false;
    if (password.new === "" && password.confirm === "") {
      setErrorText({
        new: "Error! No input detected.",
        confirm: "Error! No input detected.",
      });
      isError = true;
    } else if (password.new == "") {
      setErrorText({ new: "Error! No input detected.", confirm: "" });
      isError = true;
    } else if (password.confirm == "") {
      setErrorText({ new: "", confirm: "Error! No input detected." });
      isError = true;
    } else if (!passwordRegex.test(password.new)) {
      setErrorText({ new: "Error! Enter strong password", confirm: "" });
      isError = true;
    } else if (password.new != password.confirm) {
      setErrorText({
        new: "",
        confirm: "Error! Password does not match. Please re-enter.",
      });
      isError = true;
    }
    return isError;
  };

  const submitPassword = () => {
    let isError = validation();
    if (!isError) {
      setLoadingBtn(true);
      dispatch(updatePassword({ email, password: password.new }));
    }
  };
  return (
    <>
      <BackArrow
        onClick={() => setDisplayScreen("signup")}
        style={{ cursor: "pointer" }}
      />
      <StyledPrimaryTypography sx={{ mt: 1.5 }}>
        Create your password
      </StyledPrimaryTypography>
      <StyledSecondaryTypography sx={{ mt: 1, mb: 2 }}>
        Due to security reasons, your account password should contain the
        following:
      </StyledSecondaryTypography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {password.new.length >= 8 ? (
          <SuccessTick style={{ marginRight: "8px" }} />
        ) : (
          <span
            style={{
              backgroundColor: "#D2D6DB",
              borderRadius: "50%",
              padding: 3,
              marginRight: "8px",
              height: "15px",
              width: "15px",
              display: "block",
            }}
          ></span>
        )}
        <StyledSecondaryTypography>
          Minimum 8 characters
        </StyledSecondaryTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {passwordRegex.test(password.new) ? (
          <SuccessTick style={{ marginRight: "8px" }} />
        ) : (
          <span
            style={{
              backgroundColor: "#D2D6DB",
              borderRadius: "50%",
              padding: 3,
              marginRight: "8px",
              height: "15px",
              width: "15px",
              display: "block",
            }}
          ></span>
        )}
        <StyledSecondaryTypography sx={{ my: 1 }}>
          One uppercase, lowercase, number & symbol
        </StyledSecondaryTypography>
      </Box>
      <TextField
        name="new"
        value={password.new}
        onChange={handlePassword}
        placeholder="Enter new Password"
        type={passwordType.containerOneType}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton>
              {errorText.new != "" ? (
                <AlertIcon />
              ) : passwordType.containerOneType === "password" ? (
                <PasswordVisible
                  onClick={() => handlePasswordType("containerOneType", "text")}
                />
              ) : (
                <PasswordInvisible
                  onClick={() =>
                    handlePasswordType("containerOneType", "password")
                  }
                />
              )}{" "}
            </IconButton>
          ),
        }}
        sx={{
          mt: 3,
          mb: 2,
          "& .MuiInputBase-root": {
            color: errorText.new === "" ? "#111927" : "#912018",
          },
          backgroundColor: errorText.new == "" ? "#F9FAFB" : "#FEE4E2",
        }}
      />
      {errorText.new != "" && (
        <StyledErrorTypography>{errorText.new}</StyledErrorTypography>
      )}
      <TextField
        name="confirm"
        value={password.confirm}
        onChange={handlePassword}
        placeholder="Confirm Password"
        type={passwordType.containerTwoType}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton>
              {errorText.confirm != "" ? (
                <AlertIcon />
              ) : passwordType.containerTwoType === "password" ? (
                <PasswordVisible
                  onClick={() => handlePasswordType("containerTwoType", "text")}
                />
              ) : (
                <PasswordInvisible
                  onClick={() =>
                    handlePasswordType("containerTwoType", "password")
                  }
                />
              )}{" "}
            </IconButton>
          ),
        }}
        sx={{
          mt: 3,
          mb: 2,
          "& .MuiInputBase-root": {
            color: errorText.confirm === "" ? "#111927" : "#912018",
          },
          backgroundColor: errorText.confirm == "" ? "#F9FAFB" : "#FEE4E2",
        }}
      />
      {errorText.confirm != "" && (
        <StyledErrorTypography>{errorText.confirm}</StyledErrorTypography>
      )}
      <Button
        disabled={loadingBtn || !password.confirm || !password.new}
        onClick={submitPassword}
        sx={{ mt: 2 }}
        variant="contained"
        fullWidth
      >
        {loadingBtn ? <CircularProgress size={25} /> : "Continue"}
      </Button>
    </>
  );
};

export default CreatePassword;
