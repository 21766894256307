import React from "react";
import {
  Box,
  Select,
  MenuItem,
  SvgIcon,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import {
  TypoGraph,
  StyledSecondaryServiceTypography,
  StyledSecondaryTypography,
} from "../../Theme";
import { ReactComponent as ActiveIcon } from "../../Icons/ActiveGraph.svg";
import { ReactComponent as InactiveIcon } from "../../Icons/InactiveIcon.svg";
import { ReactComponent as OffBoardIcon } from "../../Icons/OffBoardIcon.svg";
import { ReactComponent as CancelIcon } from "../../Icons/FaultReportGraphCancel.svg";
import Chart from "react-apexcharts";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Circle } from "@mui/icons-material";

const FaultReportGraph = () => {
  const graphData = [1, 2, 2];
  const { FaultReportData, OverviewStatus } = useSelector(
    (state) => state.OverView
  );
  const { LanguageData } = useSelector((state) => state.Language);

  const FaultReportseries = [
    {
      name: "Submitted",
      data: [
        FaultReportData?.jan?.submitted || 0,
        FaultReportData?.feb?.submitted || 0,
        FaultReportData?.mar?.submitted || 0,
        FaultReportData?.apr?.submitted || 0,
        FaultReportData?.may?.submitted || 0,
        FaultReportData?.jun?.submitted || 0,
        FaultReportData?.jul?.submitted || 0,
        FaultReportData?.aug?.submitted || 0,
        FaultReportData?.sep?.submitted || 0,
        FaultReportData?.oct?.submitted || 0,
        FaultReportData?.nov?.submitted || 0,
        FaultReportData?.dec?.submitted || 0,
      ],
    },
    {
      name: "Investigating",
      data: [
        FaultReportData?.jan?.investigating || 0,
        FaultReportData?.feb?.investigating || 0,
        FaultReportData?.mar?.investigating || 0,
        FaultReportData?.apr?.investigating || 0,
        FaultReportData?.may?.investigating || 0,
        FaultReportData?.jun?.investigating || 0,
        FaultReportData?.jul?.investigating || 0,
        FaultReportData?.aug?.investigating || 0,
        FaultReportData?.sep?.investigating || 0,
        FaultReportData?.oct?.investigating || 0,
        FaultReportData?.nov?.investigating || 0,
        FaultReportData?.dec?.investigating || 0,
      ],
    },
    {
      name: "Completed",
      data: [
        FaultReportData?.jan?.completed || 0,
        FaultReportData?.feb?.completed || 0,
        FaultReportData?.mar?.completed || 0,
        FaultReportData?.apr?.completed || 0,
        FaultReportData?.may?.completed || 0,
        FaultReportData?.jun?.completed || 0,
        FaultReportData?.jul?.completed || 0,
        FaultReportData?.aug?.completed || 0,
        FaultReportData?.sep?.completed || 0,
        FaultReportData?.oct?.completed || 0,
        FaultReportData?.nov?.completed || 0,
        FaultReportData?.dec?.completed || 0,
      ],
    },
    {
      name: "Cancelled",
      data: [
        FaultReportData?.jan?.cancelled || 0,
        FaultReportData?.feb?.cancelled || 0,
        FaultReportData?.mar?.cancelled || 0,
        FaultReportData?.apr?.cancelled || 0,
        FaultReportData?.may?.cancelled || 0,
        FaultReportData?.jun?.cancelled || 0,
        FaultReportData?.jul?.cancelled || 0,
        FaultReportData?.aug?.cancelled || 0,
        FaultReportData?.sep?.cancelled || 0,
        FaultReportData?.oct?.cancelled || 0,
        FaultReportData?.nov?.cancelled || 0,
        FaultReportData?.dec?.cancelled || 0,
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
    },
    colors: ["#1687BD", "#FF7C32", "#9A66FF", "#00C4C3"],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 8,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#262D33",
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const total =
          series[0][dataPointIndex] +
          series[1][dataPointIndex] +
          series[2][dataPointIndex] +
          series[3][dataPointIndex];
        const active = series[0][dataPointIndex];
        const inactive = series[1][dataPointIndex];
        const offboard = series[2][dataPointIndex];
        const cancelled = series[3][dataPointIndex];
        const month = w.globals.labels[dataPointIndex];

        return `
            <div class="apexcharts-tooltip-custom">
              <div class="total-count-tip">
              <div>
              Total
              </div> 
              <div>
              ${total} 
              </div>
              </div>
              <div class="tool-tip-parent">
              <div><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#1687BD"/>
              </svg>
              Submitted
              </div>
              <div>
              ${active}
              </div>
            </div>
              <div class="tool-tip-parent">
              <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#FF7C32"/>
              </svg>
              Investigating
              </div> 
              <div>
               ${inactive}
               </div>
              </div>
              <div class="tool-tip-parent">
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#9A66FF"/>
              </svg>
              Completed
              </div>
              <div>${offboard}</div>
              </div>
              <div class="tool-tip-parent">
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 12 13" fill="none">
<rect x="0.000976562" y="0.494629" width="8" height="8" rx="2" fill="#00C4C3"/>
</svg>
Cancelled
              </div>
              <div>${cancelled}</div>
              </div>
              <div class="month-change-top">${month}${" "}${2023}</div>
            </div>
          `;
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
  };

  return (
    <>
      {
        <>
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                  {`${
                    LanguageData?.home_total_reports || "Total Reports"
                  }${" "}${FaultReportData?.total_count || 0} `}
                </StyledSecondaryServiceTypography>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <ActiveIcon style={{ marginRight: "10px" }} />
                  <TypoGraph>
                    {" "}
                    {LanguageData?.home_submit || "Submitted"}
                  </TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <InactiveIcon style={{ marginRight: "10px" }} />
                  <TypoGraph>
                    {" "}
                    {LanguageData?.home_invest || "Investigating"}
                  </TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <OffBoardIcon style={{ marginRight: "10px" }} />
                  <TypoGraph>
                    {" "}
                    {LanguageData?.home_complete || "Completed"}
                  </TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <CancelIcon style={{ marginRight: "10px" }} />
                  <TypoGraph>
                    {LanguageData?.home_cancel || "Cancelled"}
                  </TypoGraph>
                </Box>
              </Box>
            </Box>
          </Box>
          {FaultReportData.total_count ? (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Chart
                options={options}
                series={FaultReportseries}
                type="bar"
                height={400}
              />
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "0px, 0px, 8px, 8px",
                padding: "16px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "300px",
              }}
            >
              {OverviewStatus ? (
                <CircularProgress />
              ) : (
                <>
                  {" "}
                  <ExclamatoryIcon
                    style={{ marginBottom: "5px", marginTop: "60px" }}
                  />
                  <Typography
                    sx={{
                      color: "#9DA4AE",
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "470px",
                      my: 2,
                      textAlign: "center",
                      marginTop: "5px",
                      marginBottom: "100px",
                    }}
                    style={{ color: "#4D5761" }}
                  >
                    No statistics available for display
                  </Typography>
                </>
              )}
            </Box>
          )}
        </>
      }
    </>
  );
};

export default FaultReportGraph;
