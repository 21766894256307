import React, { useEffect, useState } from 'react'
import AddUserAndRole from './addUserAndRole'
import DepartmentPermissions from './departmentPermissions'
import PropertyInformationForm from './propertyInformationForm'
import AlertModal from "../../../CommonComponents/modal";
import { useDispatch, useSelector } from 'react-redux';
import { savePropertyDetailsAsDraftToNull } from '../../../Redux/Feature/Login/loginSlice';
import { saveAsDraftData } from '../../../Data';
import axiosInstance from '../../../Redux/AxiosInstance';
import { getUserAndRole } from '../../../Redux/Feature/PropertyDetails/propertyDetailsSlice';

const SystemDeteilsHandler = ({ ScrollToTop }) => {
    let initialState = { addUserAndRole: false, DepartmentPermissions: false, propertyInformationForm: false }
    let [displayScreen, setDisplayScreeen] = useState({ DepartmentPermissions: true, addUserAndRole: false, propertyInformationForm: false })
    const dispatch = useDispatch()
    const { savePropertyDetailsAsDraftStatus } = useSelector((state) => state.Login)
    const { UserAndRoleList, propertyId } = useSelector((state) => state.PropertyDetails)
    //default data
    const managementDefaultData = {
        role: "",
        info: []
    }
    const operationDefaultData = {
        role: "",
        info: []

    }
    const groundstaffDefaultData = {
        role: "",
        info: []

    }

    const [managementdefaultdata, setmanagementdefaultdata] = useState(managementDefaultData)
    const [operationdefaultdata, setoperationdefaultdata] = useState(operationDefaultData)
    const [groundstaffdefaultdata, setgroundstaffdefaultdata] = useState(groundstaffDefaultData)
    // console.log(operationdefaultdata);

    //User and Role Data
    const [managementUserData, setManagementUserData] = useState([])
    const [operationUserData, setOperationUserdata] = useState([])
    const [groundstaffUserData, setGroundstaffUserdata] = useState([])

    const [DataLength, setDataLength] = useState({ managementLth: '', operationLth: '', groundStaffLth: "" })


    const handleDisplayScreen = (value) => {
        setDisplayScreeen({
            ...initialState,
            [value]: true
        })
    }
    useEffect(() => {
        ScrollToTop()
    }, [displayScreen])


    const commonmodalData = {
        management: {
            label: "Management",
            description: 'Management department is the department which represents the highest level of authority within the Loby system, people tagged to management should be employees which requires statistics, have the right to onboard/ offboard all users, they are users who should not need to act on resident feedback or take action, only overall statistical data like monthly reports. '
        },
        operations: {
            label: 'Operations',
            description: 'Operations department represents the operations team users who require to filter and act on tasks, and assign them to further employees. Examples would include juristics adminstrators who will recieve feedback and calls from tenants, and assign employees like technicians or cleaners to act on it. '
        },
        groundstaff: {
            label: 'Ground Staff',
            description: "Ground Staff represents the team or users who like act on assigned tasks. This will include cleaning department, fixing and plumbing department and can include both supervisors and junior staff of cleaners, technicians, security who are in charge of acting upon feedback and ensuring residents safety & comfort. "
        },
        access: {
            label: 'Access',
            description: 'Access only allows the user to view the function on the system as well as complete assigned tasks, they will not be able to manage or edit the function itself.'
        },
        manage: {
            label: 'Manage',
            description: 'Manage is an advanced permission which allows user to edit the function like adding/ create, assigning users, deleting the function itself.'
        }
    }
    const getDefaultData = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}getDefaultPermissionDatas?department_type=NA`)
            const mdata = response.data.data.permission[0]
            const odata = response.data.data.permission[1]
            const gdata = response.data.data.permission[2]

            setmanagementdefaultdata((prev) => ({ ...prev, info: mdata.service, d_name: mdata.department_name, d_id: mdata.id }))
            setoperationdefaultdata((prev) => ({ ...prev, info: odata.service, d_name: odata.department_name, d_id: odata.id }))
            setgroundstaffdefaultdata((prev) => ({ ...prev, info: gdata.service, d_name: gdata.department_name, d_id: gdata.id }))

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDefaultData()
        dispatch(getUserAndRole(propertyId))

    }, [propertyId])

    useEffect(() => {
        if (UserAndRoleList != null) {
            setManagementUserData(UserAndRoleList?.data?.property_role[0].role)
            setOperationUserdata(UserAndRoleList?.data?.property_role[1].role)
            setGroundstaffUserdata(UserAndRoleList?.data?.property_role[2].role)

            setDataLength({ managementLth: UserAndRoleList?.data?.property_role[0]?.role?.length, operationLth: UserAndRoleList?.data?.property_role[1].role?.length, groundStaffLth: UserAndRoleList?.data?.property_role[2].role?.length })
        }

    }, [UserAndRoleList])

    //deletesingle role



    return (
        <>
            {savePropertyDetailsAsDraftStatus && <AlertModal closeFunction={() => dispatch(savePropertyDetailsAsDraftToNull())} modalDatas={saveAsDraftData} />}


            {displayScreen.DepartmentPermissions &&
                <DepartmentPermissions
                    Management={managementdefaultdata}
                    Operations={operationdefaultdata}
                    GroundStaff={groundstaffdefaultdata}
                    setDisplayScreen={handleDisplayScreen}
                    commonmodal={commonmodalData}
                />}
            {displayScreen.addUserAndRole &&
                <AddUserAndRole
                    managementdata={managementUserData}
                    setmanagementdata={setManagementUserData}
                    operationdata={operationUserData}
                    setoperationdata={setOperationUserdata}
                    groundstaffdata={groundstaffUserData}
                    setgroundstaffdata={setGroundstaffUserdata}
                    managementdefaultdata={managementdefaultdata}
                    operationdefaultdata={operationdefaultdata}
                    groundstaffdefaultdata={groundstaffdefaultdata}
                    setDisplayScreen={handleDisplayScreen}
                    commonmodal={commonmodalData}
                    DataLength={DataLength}
                    setDataLength={setDataLength}
                // Deletesinglerole={Deletesinglerole}
                />}
            {displayScreen.propertyInformationForm &&
                <PropertyInformationForm
                    urManagementdata={managementUserData}
                    urOperationdata={operationUserData}
                    urGroundstaffdata={groundstaffUserData}
                    setDisplayScreen={handleDisplayScreen}
                    commonmodal={commonmodalData}
                />}
        </>
    )
}

export default SystemDeteilsHandler
