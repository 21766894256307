import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { ReactComponent as FacilityStarsIcon } from "../../../Icons/FacilityStarsIcon.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import {
  PrimaryTypography,
  SecondaryTypographyDefault,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import axiosInstance from "../../../Redux/AxiosInstance";
import { ReactComponent as SuccessAlertIcon } from "../../../Icons/SuccessAlertProfile.svg";
import { ReactComponent as CloseIcon } from "../../../Icons/ProfileSettingsCloseIcon.svg";

export default function PropertyDocumentation() {
  const navigate = useNavigate();
  const [tabValue, settabValue] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [toastModal, setToastModal] = useState(false);
  const [alertText, SetAlertText] = useState("");
  const { UserManage } = useSelector((state) => state.Login);
  const [loading, setloading] = useState(true);

  const [GuidelinesData, setGuidelinesData] = useState({
    terms_condition: "",
    capacity: "",
    lift_size: "",
    id: "",
  });
  const [liftData, setliftData] = useState({
    width: "",
    height: "",
    depth: "",
    door_width: "",
  });

  useEffect(() => {
    const fromService = localStorage.getItem("fromService");
    if (fromService) {
      settabValue(1);
      localStorage.removeItem("fromService");
    }
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}faq/property/${propertyId}`)
      .then((res) => {
        setQuestionData(res.data.data.faq);
        setloading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error == "No data found.") {
          setQuestionData([]);
          setloading(false);
        }
      });

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/get_service_request_terms_condition`
      )
      .then(async (res) => {
        let result = await res.data.data?.service_request;

        if (result) {
          setGuidelinesData({
            terms_condition: result.terms_condition,
            capacity: result.max_load,
            lift_size: result.lift_size,
            id: result.id,
          });

          const inputString = result.lift_size;
          const numbersArray = inputString.split("x");

          const numericValues = numbersArray.map((numberString) =>
            parseFloat(numberString.replace(",", ""))
          );

          setliftData({
            capacity: result?.max_load,
            door_width: result?.door_width,
            width: numericValues[0],
            height: numericValues[1],
            depth: numericValues[2],
          });
          setloading(false);
        }
        // setQuestionData(res.data.data.faq);
      })
      .catch((err) => {
        if (err?.response?.data?.error == "No data found") {
          setloading(false);
        } else {
          setloading(true);
        }
      });
  }, []);

  const handleTabValue = (e, value) => {
    settabValue(value);
  };

  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };

  const handleEditNavigate = (id) => {
    navigate(`editPropertyDocumentation/${id}`);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {LanguageData?.Property_Documentations ||
                "Property Documentations"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.Property_Documentations_sub ||
                "Property documentations is your central source for FAQs and Service Request Guidelines."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 8px 8px"
          backgroundColor="#FFFFFF"
          width="100%"
        >
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab sx={{ width: "50%", maxWidth: "none" }} label="FAQs" />
              <Tab
                sx={{ width: "50%", maxWidth: "none", textTransform: "none" }}
                label="Service Request Guidelines"
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={tabValue} index={0}>
            {questionData?.length >= 1 ? (
              <>
                {questionData.map((itm, index) => (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="10px"
                      px="16px"
                      sx={{
                        height: "53px",
                        backgroundColor: "#FCFCFD",
                        borderBottom: "1px solid #E5E7EB",
                        borderTop: "1px solid #E5E7EB",
                      }}
                    >
                      <SecondaryTypography>
                        {LanguageData?.Question || "Question"} {index + 1}
                      </SecondaryTypography>
                      {UserManage?.settings && (
                        <Button
                          onClick={() => handleEditNavigate(itm.id)}
                          sx={{
                            width: "83px",
                            height: "36px",
                            padding: "8px 2px 8px 6px",
                            border: "1px solid #D2D6DB",
                            borderRadius: "8px",
                          }}
                        >
                          {" "}
                          {LanguageData?.Common_Edit || "Edit"}{" "}
                          <IconButton sx={{ paddingLeft: "10px" }}>
                            {" "}
                            <EditIcon width="14px" />
                          </IconButton>
                        </Button>
                      )}
                    </Stack>
                    <Grid container padding="12px 16px 20px 16px">
                      <Grid xs={12} sm={6} padding="10px 0px 10px 0px">
                        <StyledSecondaryTypography mb="5px">
                          {LanguageData?.Question || "Question"}{" "}
                        </StyledSecondaryTypography>
                        <TypographyDefault>{itm?.question}</TypographyDefault>
                      </Grid>
                      <Grid xs={12} sm={6} padding="10px 0px 10px 0px">
                        <StyledSecondaryTypography mb="5px">
                          {LanguageData?.Answer || "Answer"}
                        </StyledSecondaryTypography>
                        <TypographyDefault>{itm?.answer}</TypographyDefault>
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  width="100%"
                  height="80px"
                  borderTop=" 1px solid #F3F4F6"
                >
                  {UserManage?.settings && (
                    <Button
                      onClick={() => navigate("addPropertyDocumentation")}
                      sx={{
                        padding: "8px 14px 8px 14px",
                        height: "41px",
                        color: "#0E5E84",
                        border: "1px solid #E5E7EB",
                      }}
                    >
                      {LanguageData?.Add_More_FAQs || "Add More FAQs"}{" "}
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <Box sx={{ padding: "24px 16px 24px 16px" }}>
                <Grid
                  xs={12}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Stack
                    gap="12px"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    minHeight="200px"
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <FacilityStarsIcon />
                        <SecondaryTypographyDefault>
                          {LanguageData?.Frequently_Asked_Questions ||
                            "Frequently Asked Questions"}
                        </SecondaryTypographyDefault>
                        <TypographyDefault maxWidth="352px">
                          {" "}
                          {LanguageData?.Frequently_Asked_Questions_sub ||
                            "Add commonly asked questions and their corresponding answers to provide quick and helpful information to users."}
                        </TypographyDefault>
                        <Button
                          onClick={() => navigate("addPropertyDocumentation")}
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid #D2D6DB",
                          }}
                          disabled={!UserManage?.settings}
                        >
                          {LanguageData?.management_staff_add_button || "Add"}
                        </Button>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Box>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {Boolean(GuidelinesData.terms_condition) ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  px="16px"
                  sx={{
                    height: "53px",
                    backgroundColor: "#FCFCFD",
                    borderBottom: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                  }}
                >
                  <SecondaryTypography>
                    {" "}
                    {LanguageData?.Terms_And_Condition ||
                      "Terms & Conditions"}{" "}
                  </SecondaryTypography>
                  {UserManage?.settings && (
                    <Button
                      onClick={() => {
                        navigate("editServiceRequestGuidelines");
                      }}
                      sx={{
                        width: "83px",
                        height: "36px",
                        padding: "8px 2px 8px 6px",
                        border: "1px solid #D2D6DB",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {LanguageData?.Common_Edit || "Edit"}{" "}
                      <IconButton sx={{ paddingLeft: "10px" }}>
                        {" "}
                        <EditIcon width="14px" />
                      </IconButton>
                    </Button>
                  )}
                </Stack>
                <Grid container padding="12px 16px 20px 16px">
                  <Grid xs={12} padding="10px 0px 10px 0px">
                    <TypographyDefault>
                      {Boolean(GuidelinesData?.terms_condition) &&
                        parse(GuidelinesData?.terms_condition)}
                    </TypographyDefault>
                  </Grid>
                </Grid>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  px="16px"
                  sx={{
                    height: "53px",
                    backgroundColor: "#FCFCFD",
                    borderBottom: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                  }}
                >
                  <SecondaryTypography>
                    {LanguageData?.Cargo_Lift_Specification ||
                      "Cargo Lift Specification"}
                  </SecondaryTypography>
                  {UserManage?.settings && (
                    <Button
                      onClick={() => {
                        navigate("editServiceRequestGuidelines");
                      }}
                      sx={{
                        width: "83px",
                        height: "36px",
                        padding: "8px 2px 8px 6px",
                        border: "1px solid #D2D6DB",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {LanguageData?.Common_Edit || "Edit"}
                      <IconButton sx={{ paddingLeft: "10px" }}>
                        {" "}
                        <EditIcon width="14px" />
                      </IconButton>
                    </Button>
                  )}
                </Stack>
                <Grid container padding="12px 16px 20px 16px">
                  <Grid xs={12} sm={6} padding="10px 0px 10px 0px">
                    <StyledSecondaryTypography mb="5px">
                      {LanguageData?.Maximum_Load_Capacity ||
                        "Maximum Load/Capacity"}
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {GuidelinesData?.capacity + " kg"}
                    </TypographyDefault>
                  </Grid>
                  <Grid xs={12} sm={6} padding="10px 0px 10px 0px">
                    <StyledSecondaryTypography mb="5px">
                      {LanguageData?.Lift_Size || "Lift Size"}
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {/* {GuidelinesData?.lift_size} */}
                      {liftData?.width +
                        "mm (width) x " +
                        liftData?.height +
                        "mm (height) x " +
                        liftData?.depth +
                        "mm (depth) and Door width " +
                        liftData?.door_width +
                        "mm"}
                    </TypographyDefault>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Box sx={{ padding: "24px 16px 24px 16px" }}>
                <Grid
                  xs={12}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Stack
                    gap="12px"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    minHeight="200px"
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <FacilityStarsIcon />
                        <SecondaryTypographyDefault>
                          {LanguageData?.Security_Clearance ||
                            "Security Clearance and Cargo Lift usage"}
                        </SecondaryTypographyDefault>
                        <TypographyDefault maxWidth="352px">
                          {LanguageData?.terms || "1. Terms & Conditions"}{" "}
                          <br />{" "}
                          {LanguageData?.cargo ||
                            "2. Cargo Lift Specification (if applicable)"}
                        </TypographyDefault>
                        <Button
                          onClick={() =>
                            navigate("addServiceRequestGuidelines")
                          }
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid #D2D6DB",
                          }}
                        >
                          {LanguageData?.management_staff_add_button || "Add"}
                        </Button>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Box>
            )}
          </CustomTabPanel>
        </Box>
      </Box>
      {toastModal && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              padding: "10px 16px 10px 16px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
              marginTop: "30px",
              width: "370px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <SuccessAlertIcon />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#384250",
                  marginLeft: "10px",
                }}
              >
                {alertText}
              </Typography>
            </Box>
            <Box>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setToastModal(false)}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
