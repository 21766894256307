import React, { useEffect, useState } from "react";
import MyDetails from "./myDetails";
import ReviewMyDetails from "./reviewMyDetails";
import { getMyDetails } from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../../CommonComponents/modal";
import { saveAsDraftData } from "../../../Data";
import { savePropertyDetailsAsDraftToNull } from "../../../Redux/Feature/Login/loginSlice";

const AddMyDetailsHandler = () => {
    const initialState = { myDetails: false, review: false }
    const dispatch = useDispatch()
    const { myDetailsList } = useSelector((state) => state.PropertyDetails)
    const { savePropertyDetailsAsDraftStatus } = useSelector((state) => state.Login)
    const [displayScreen, setDisplayScreen] = useState({ myDetails: true, review: false })
    const [personalDetails, setPersonalDetails] = useState({ firstName: '', lastName: '', contact: '', email: '', billingemail: '', phone: '' })
    const [generalDetails, setGeneralDetails] = useState({ firstName: '', lastName: '', middleName: '', dob: '', gender: '', nationality: '', identityNumber: '', contact: '', email: '', address: '', country: '', city: '', postalCode: '' })
    const [emergencyContact, setEmergencyContact] = useState({ firstName: '', lastName: '', gender: '', nationality: '', contact: '', email: '', relationship: '' })
    const [workDetails, setWorkDetails] = useState({ type: '', department: '', jobRole: '', level: '', departmentLocation: '', accessStartDate: '', accessEndDate: '' })
    const [billingemail, setbillingemail] = useState(true)

    useEffect(() => {
        dispatch(getMyDetails())
    }, [])

    useEffect(() => {
        if (myDetailsList != null) {
            if (myDetailsList.success) {
                let data = myDetailsList.data.user_details
                let newEmergency = data.emergency_contacts
                let newWorkdetails = data.access_details

                const { email, first_name, last_name, phone, country_code, billing_email } = data
                // setPersonalDetails({ ...personalDetails, })
                if (Boolean(data.billing_email)) {
                    setPersonalDetails({ ...personalDetails, email: email, firstName: first_name, lastName: last_name != null ? last_name : '', contact: (country_code != null && phone != null) ? country_code + phone : '', billingemail: billing_email, phone: phone != null ? phone : '', country_code: country_code != null ? country_code : '' })
                    setbillingemail(false)
                } else {
                    setPersonalDetails({ ...personalDetails, email: email, firstName: first_name, lastName: last_name != null ? last_name : '', contact: (country_code != null && phone != null) ? country_code + phone : '', phone: phone != null ? phone : '', country_code: country_code != null ? country_code : '' })

                }
                // setGeneralDetails({
                //     ...generalDetails,
                //     firstName:data.first_name,
                //     lastName:data.last_name,
                //     middleName:data.middle_name,
                //     dob:new Date(data.dob),
                //     gender:data.gender,
                //     nationality:data.nationality,
                //     identityNumber:data.identity_number,
                //     contact:data.phone,
                //     email:data.email,
                //     address:data.address,
                //     country:data.country,
                //     city:data.city,
                //     postalCode:data.zipcode
                // })
                // setEmergencyContact({
                //     ...emergencyContact,
                //     firstName:newEmergency.first_name,
                //     lastName:newEmergency.last_name,
                //     nationality:newEmergency.nationality,
                //     gender:newEmergency.gender,
                //     contact:newEmergency.phone,
                //     email:newEmergency.email,
                //     relationship:newEmergency.relationship
                // })
                // setWorkDetails({
                //     ...workDetails,
                //     type:newWorkdetails.residency_type_id,
                //     department:newWorkdetails.department_id,
                //     jobRole:newWorkdetails.role_id,
                //     level:newWorkdetails.level_id,
                //    // departmentLocation:newWorkdetails.
                //    accessStartDate:new Date(newWorkdetails.access_start),
                //    accessEndDate:new Date(newWorkdetails.access_end)
                // })
            }
        }
    }, [myDetailsList])

    const handleDisplayScreen = (value) => {
        setDisplayScreen({
            ...initialState,
            [value]: true
        })
    }
    return (
        <>
            {savePropertyDetailsAsDraftStatus && <AlertModal closeFunction={() => dispatch(savePropertyDetailsAsDraftToNull())} modalDatas={saveAsDraftData} />}
            {displayScreen.myDetails && <MyDetails workDetails={workDetails} setWorkDetails={setWorkDetails} emergencyContact={emergencyContact} setEmergencyContact={setEmergencyContact} generalDetails={generalDetails} setGeneralDetails={setGeneralDetails} setDisplayScreen={handleDisplayScreen} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} billingemail={billingemail} setbillingemail={setbillingemail} />}
            {displayScreen.review && <ReviewMyDetails setDisplayScreen={handleDisplayScreen} datas={{ generalDetails, emergencyContact, workDetails, personalDetails }} billingemail={billingemail} />}
        </>
    )
}

export default AddMyDetailsHandler;