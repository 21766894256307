import React, { useState } from "react";
import AddBuilding from "./addBuilding";
import ReviewBuilding from './reviewBuilding'
// import ReviewLevel from "./reviewLevels";
import AlertModal from "../../../CommonComponents/modal";
import { logoutModalData } from "../../../Data";
import { savePropertyDetailsAsDraftToNull } from "../../../Redux/Feature/Login/loginSlice";
import { useDispatch, useSelector } from "react-redux";

const AddLevelHandler = ()=>{
    let initialState = {addBuilding:false,addFloor:false}
    const dispatch = useDispatch()
    const [displayScreen,setDisplayScreen] = useState({addBuilding:true,addFloor:false})
    const [buildingDetails,setBuildingDetails] = useState([])
    const [isDefaultAddress,setIsDefaultAddress] = useState(false)
    const [defaultAddress,setDefaultAddress] = useState('')
    const {savePropertyDetailsAsDraftStatus} = useSelector((state)=>state.Login)

    const handleDisplayScreen = (value)=>{
        setDisplayScreen({
            ...initialState,
            [value]:true
        })
    }
    return(
        <>
            {savePropertyDetailsAsDraftStatus && <AlertModal closeFunction={()=>dispatch(savePropertyDetailsAsDraftToNull())}  modalDatas={logoutModalData} />}
            {displayScreen.addBuilding && <AddBuilding setDisplayScreen={handleDisplayScreen} buildingDetails={buildingDetails} setBuildingDetails={setBuildingDetails} isDefaultAddress={isDefaultAddress} defaultAddress={defaultAddress}  setIsDefaultAddress={setIsDefaultAddress} setDefaultAddress={setDefaultAddress} /> }
            {displayScreen.addFloor && <ReviewBuilding setDisplayScreen={handleDisplayScreen} buildingDetails={buildingDetails} setBuildingDetails={setBuildingDetails} />}
            {/* {displayScreen.reviewLevel && <ReviewLevel  setDisplayScreen={handleDisplayScreen}  buildingDetails={buildingDetails}/> } */}
        </>
    )
}

export default AddLevelHandler;