import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "../Feature/Login/loginSlice";
import PropertyDetailsReducer from "../Feature/PropertyDetails/propertyDetailsSlice";
import ContactDetailsReducer from "../Feature/ContactBook/ContactBookSlice";
import FacilityReducer from "../Feature/Facility/FacilitySlice";
import OverViewDetailsReducer from "../Feature/OverView/OverviewSlice";
import LanguageConvertionReducer from "../Feature/Language/LanguageSlice";

const store = configureStore({
  reducer: {
    Login: LoginReducer,
    PropertyDetails: PropertyDetailsReducer,
    ContactBook: ContactDetailsReducer,
    Facility: FacilityReducer,
    OverView: OverViewDetailsReducer,
    Language: LanguageConvertionReducer,
  },
});

export default store;
