import React, { useEffect } from 'react'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { PrimaryTypography, StyledSecondaryTypography, TypographyDefault } from "../../../Theme";
import { Stack, Box, Button, Grid, Modal } from "@mui/material";
import { ReactComponent as BigcorrectIcon } from "../../../Icons/BigCorrectIcon.svg";
import { ReactComponent as SmallcorrectIcon } from "../../../Icons/smallcorrectIcon.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep, updatePropertyDetailsCompletedSteps, setpropertySetupLoader, setPropertySliceToNull, loadPropertyStatus } from '../../../Redux/Feature/PropertyDetails/propertyDetailsSlice';
import { changeUserData } from '../../../Utils';

const Review = () => {

    const { propertyId, propertyCompletedStep } = useSelector((state) => state.PropertyDetails)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (propertyCompletedStep != null) {
            if (propertyCompletedStep.success === true) {
                changeUserData(6, 'change')
                dispatch(setActiveStep(1))
                dispatch(setPropertySliceToNull())
                changeUserData(6, 'propertycompleted')
                navigate('/overview')
                dispatch(loadPropertyStatus())
            }
        }
    }, [propertyCompletedStep])


    const handleOpen = () => {
        dispatch(setpropertySetupLoader(true))
        dispatch(updatePropertyDetailsCompletedSteps({ propertyId, completed_step: 6 }))
        // changeUserData(6, 'chgstatus')

    }


    const edit = (id) => {
        dispatch(setActiveStep(id))
    }



    const data = ['Step 1 - General Details', 'Step 2 -  Building ', 'Step 3 - Common Area', 'Step 4 - Account & Permissions', 'Step 5 - Administrator']
    return (
        <Box py={3} px={20}>
            <Box mb={8}>
                <Stack gap='15px' direction='column' alignItems='center'>
                    <BigcorrectIcon />
                    <TypographyDefault sx={{ fontSize: '24px !important', fontWeight: '500 !important' }}>We have received the basic necessary information for dashboard kick off!</TypographyDefault>
                    <Stack direction='column' alignItems='center'>
                        <TypographyDefault sx={{ fontWeight: '600 !important' }}>Thank you for entering the requested details!</TypographyDefault>
                        <TypographyDefault sx={{ fontWeight: '600 !important' }}>  You have completed all the steps, and we are ready to prepare the Dashboard for ABC Condo! </TypographyDefault>
                        <TypographyDefault sx={{ fontWeight: '600 !important' }}> If all the information has been correctly entered, please click on the Create Condo Dashboard button below.</TypographyDefault>
                    </Stack>
                    <TypographyDefault sx={{ fontSize: '12px !important' }}>*You’re required to add more additional details after dashboard creation in order to fully utilise the LOBY system’s functions.*</TypographyDefault>
                </Stack>
            </Box>
            <Stack gap='24px'>
                {data.map((itm, index) => {
                    return (<>
                        < Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Stack gap='15px' direction='row' alignItems='center'>
                                <Box onClick={() => { edit(index) }} sx={{ border: "1px solid #D2D6DB", borderRadius: '8px', padding: '8px 8px 8px 8px', cursor: 'pointer' }}>  <EditIcon /></Box>
                                <PrimaryTypography>{itm}</PrimaryTypography>
                            </Stack>
                            <Stack>
                                <Stack direction='row' alignItems='center' gap='5px'>
                                    <PrimaryTypography>Filled</PrimaryTypography>
                                    <Box >
                                        <SmallcorrectIcon />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                    )
                })}
            </Stack>
            <Stack my={4} direction='row' alignItems='center' justifyContent='center'>
                <Button onClick={handleOpen} sx={{ padding: "10px 18px 10px 18px", backgroundColor: '#E7EFF3', color: '#0E5E84', fontWeight: 600, fontSize: '16px' }}>Create Condo Dashboard</Button>
            </Stack>


        </Box>
    )
}

export default Review;
