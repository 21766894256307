import {
  Button,
  InputAdornment,
  TextField,
  Stack,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as AlertIcon } from "../Icons/ErrorAlert.svg";
import {
  StyledPrimaryTypography,
  StyledSecondaryTypography,
  StyledErrorTypography,
  StyledTypography,
} from "../Theme";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import { ReactComponent as CheckboxIcon } from "../Icons/Checkbox.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  verifyEmail,
  setLoginSliceToNull,
} from "../Redux/Feature/Login/loginSlice";

const SignUp = ({
  email,
  setEmail,
  setDisplayScreen,
  setback,
  propertyDeatilsProp,
  back,
}) => {
  const dispatch = useDispatch();
  const { loginStatus, verifyEmailStatus } = useSelector(
    (state) => state.Login
  );
  const [errorText, setErrorText] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (verifyEmailStatus !== null) {
      setLoadingBtn(false);
      if (verifyEmailStatus.success === true) {
        setDisplayScreen("otp");
        dispatch(setLoginSliceToNull());
      } else {
        setErrorText(
          verifyEmailStatus.error || "Something went wrong!.Please try again"
        );
      }
    }
  }, [verifyEmailStatus]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSingUp();
    }
  };

  const handleEmail = (e) => {
    let { value } = e.target;
    setEmail(value);
    setErrorText("");
  };
  const handleSingUp = () => {
    let isError = validateEmail();
    if (!isError) {
      setLoadingBtn(true);
      dispatch(verifyEmail({ email }));
    }
  };

  const validateEmail = () => {
    let isError = false;
    if (email === "") {
      setErrorText("Error! No input detected.");
      isError = true;
    } else if (emailRegex.test(email) === false) {
      setErrorText("Enter valid email");
      isError = true;
    }
    return isError;
  };

  return (
    <>
      <BackArrow
        onClick={() => setDisplayScreen(back)}
        style={{ marginBottom: "30px", cursor: "pointer" }}
      />
      {propertyDeatilsProp.map((value) => (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "12px",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
            backgroundImage: `url(${process.env.REACT_APP_FILE_PATH}${value?.property_logo})`,
            backgroundSize: "cover",
          }}
        ></Stack>
      ))}

      <StyledPrimaryTypography sx={{
        paddingTop: '34px',
        lineHeight: '22px'
      }}>
        Sign Up to your account{" "}
      </StyledPrimaryTypography>
      <StyledSecondaryTypography mt={1.3}>
        The LOBY portal can only be accessed by Management and Operations staff.
      </StyledSecondaryTypography>
      <TextField
        placeholder="Filled the Email"
        value={email}
        onChange={handleEmail}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              {errorText !== "" && <AlertIcon />}
            </InputAdornment>
          ),
        }}
        sx={{
          mt: 3,
          "& .MuiInputBase-root": {
            color: errorText === "" ? "#111927" : "#912018",
          },
          backgroundColor: errorText === "" ? "#F9FAFB" : "#FEE4E2",
        }}
        onKeyPress={handleKeyPress}
      />
      {errorText != "" && (
        <StyledErrorTypography>{errorText}</StyledErrorTypography>
      )}
      <Button
        disabled={!email || loadingBtn}
        onClick={handleSingUp}
        fullWidth
        variant="contained"
        sx={{ mt: 2.6 }}
      >
        {loadingBtn ? <CircularProgress size={25} /> : "Sign Up"}
      </Button>
      {/* <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxHeight: "40px !important" }}
      >
        <StyledSecondaryTypography color="#9DA4AE !important">
          {" "}
          <Checkbox icon={<CheckboxIcon />} /> Remember for 30 days
        </StyledSecondaryTypography>
        <StyledTypography
          color="#4D5761 !important"
          onClick={() => {
            setDisplayScreen("recoverPassword");
          }}
        >
          Forgot password?
        </StyledTypography>
      </Stack> */}
    </>
  );
};

export default SignUp;
