import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Switch,
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { emailRegex } from "../../../Utils/index";
import { SnackbarSucces } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { userData, NameRegex, No_special_regex } from "../../../Utils";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import { showToast } from "../../../CommonComponents/Toaster";

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

const snakbardata = {
  main: "The staff’s details has been successfully updated.",
  sub: "You’re successfully Update the staff information.",
};

const EditStaffDetails = () => {
  const navigate = useNavigate();
  const { propertyId, userRole } = useSelector(
    (state) => state.PropertyDetails
  );
  const { userDetails } = useSelector((state) => state.Login);
  const { id } = useParams();
  const [getres, setgetres] = useState(true);
  const [blocks, setblocks] = useState({ editblock: true, reviewblock: false });
  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    nationality: "",
    inorpn: "",
    phone: "",
    contact: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
    fullName: "",
  });
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    contact: "",
    phone: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    employmenttype: "",
    department: "",
    jobrole: "",
    accessstartdate: "",
    accessenddate: "",
  });
  const [nationalityData, setnationalityData] = useState("");
  const [genderData, setgenderData] = useState([]);
  const [onboardedby, setonboardedby] = useState({
    name: "",
    email: "",
    department: "",
    jobrole: "",
  });
  const [errtext, seterrtext] = useState({
    PersonalDetailsData: {},
    emergencyContactData: {},
    WorkAccessData: {},
  });
  const [employeeTypeData, setemployeeTypeData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [postLoader, SetPostLoader] = useState(false);

  //edit api

  // {
  //   { Boolean(WorkAccessData.accessenddate) && WorkAccessData.employmenttype == 1 && (staffData.access_details.access_end = moment(WorkAccessData.accessenddate?.$d).format('YYYY-MM-DD')) }
  // }

  const editStaffData = async () => {
    const AccessStart = WorkAccessData.accessstartdate?.$d;
    const final = moment(AccessStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AccessEnd = WorkAccessData.accessenddate?.$d;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const staffData = {
      user_id: id,
      first_name: PersonalDetailsData.firstname, //required|max:20|min:1
      last_name: PersonalDetailsData.lastname, //required|max:20|min:1
      // middle_name: PersonalDetailsData.middlename, //max:20|min:1
      dob: moment(PersonalDetailsData.dob?.$d).format("YYYY-MM-DD"), //required|date|before_or_equal:today
      gender: PersonalDetailsData.gender, //required|int|in:1,2,3
      nationality: PersonalDetailsData.nationality, //required|max:20|min:1
      identity_number: PersonalDetailsData.inorpn, //required|max:25|min:1
      phone: PersonalDetailsData.phone, //required|min:10|max:20
      country_code: PersonalDetailsData.countrycode, //required
      address: PersonalDetailsData.address, //required|min:3|max:50
      country: PersonalDetailsData.country, //required|min:3|max:20
      city: PersonalDetailsData.city, //required|min:3|max:20
      zipcode: PersonalDetailsData.postalcode, //required|max:7|min:6
      email: PersonalDetailsData.email,
      emergency_details: {
        first_name: emergencyContactData.firstname, //required|max:20|min:1
        last_name: emergencyContactData.lastname, //max:20|min:1
        email: emergencyContactData.email, //email
        phone: emergencyContactData.phone, //required|min:10|max:20
        country_code: emergencyContactData.countrycode, //required
        gender: "1", //required|int|in:1,2,3
        nationality: "kjghv", //required|max:20|min:1
        relationship: emergencyContactData.relationship, //required|max:20|min:1
      },
      access_details: {
        residency_type_id: WorkAccessData.employmenttype, //required|exists:residency_type,id
        department_id: WorkAccessData.department, //exists:property_department,id
        role_id: WorkAccessData.jobrole, //exists:property_role,id
        join_date: moment.utc(new Date()).format("YYYY-MM-DD"), //date
        access_start: final1,
        //required|date
        access_end:
          WorkAccessData.accessenddate?.$d && WorkAccessData.employmenttype == 1
            ? final1End
            : null,
      },
    };
    {
      Boolean(PersonalDetailsData.middlename) &&
        Object.assign(staffData, {
          middle_name: PersonalDetailsData.middlename,
        });
    }
    SetPostLoader(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard_single`,
        staffData
      );
      showToast(response.data?.toast);
      navigate(`/staffs/reviewStaff/${response.data.data.staff.id}`);
      SetPostLoader(false);
    } catch (err) {
      showToast(
        err?.response?.data?.error || "Something went wrong! Please try again.",
        "error"
      );
      SetPostLoader(false);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      if (selectedCountryObject?.city) {
        setCityList(selectedCountryObject?.city);
      }
    }
  }, [selectedCountry, CountryList]);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/role`
        );
        const departmentdata = response.data.data.property_role;
        setDepartment(departmentdata);
      } catch (error) {
        console.log(error.message);
      }
    };
    getDepartment();

    //get api
    const getStaffData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}user_details?user_id=${id}`
        );
        // setstaffdata(response.data.data.user_details)
        const data = response.data.data.user_details;
        setPersonalDetailsData({
          firstname: data.first_name,
          lastname: data.last_name,
          middlename: data.middle_name,
          dob: Dayjs(data.dob),
          gender: data.gender,
          nationality: data.nationality,
          inorpn: data.identity_number,
          phone: data.phone,
          countrycode: data.country_code,
          contact: data.country_code + data.phone,
          email: data.email,
          address: data.address,
          country: data.country,
          city: data.city,
          postalcode: data.zipcode,
          fullName: data?.full_name,
        });
        setSelectedCountry(data.country);
        setemergencyContactData({
          firstname: data?.emergency_contacts.first_name,
          lastname: data?.emergency_contacts.last_name,
          // gender: data?.emergency_contacts.gender,
          // nationality: data?.emergency_contacts.nationality,
          countrycode: data?.emergency_contacts.country_code,
          contact:
            data?.emergency_contacts.country_code +
            data?.emergency_contacts.phone,
          phone: data?.emergency_contacts.phone,
          email: data?.emergency_contacts.email,
          relationship: data?.emergency_contacts.relationship,
        });
        setWorkAccessData({
          employmenttype: data.access_details.residency_type.id,
          department: data.access_details.department_id,
          jobrole: data.access_details.role_id,
          accessstartdate: Dayjs(data.access_details.access_start),
          accessenddate: Dayjs(data.access_details?.access_end),
          updated_at: data.access_details.updated_at,
        });
        setonboardedby({
          name: data?.access_details?.onboarded_user_details?.name,
          email: data?.access_details?.onboarded_user_details?.email,
          department:
            data?.access_details?.onboarded_user_details?.access_details
              ?.department_details?.department_name,
          jobrole:
            userRole == 2
              ? userDetails?.user_role_details?.role_name ||
                userData("role_details")?.title
              : data?.access_details?.onboarded_user_details?.access_details
                  ?.role_details?.title,
        });
        setgetres(false);
      } catch (err) {
        console.log(err);
      }
    };
    getStaffData();
  }, []);

  useEffect(() => {
    const getnationality = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=nationality`
        );
        const nationalityData = response.data.data.drop_down;
        var nationalitymenuItems = [];
        for (const key in nationalityData) {
          nationalitymenuItems.push(
            <MenuItem key={key} value={key}>
              {nationalityData[key]}
            </MenuItem>
          );
        }
        setnationalityData(nationalitymenuItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    getnationality();

    const getGenderData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=gender`
        );
        const genderData = response.data.data.drop_down;
        setgenderData(genderData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGenderData();
    const getResidentTypeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=residency_type_employee`
        );
        const employeeTypeData = response.data.data.drop_down;
        setemployeeTypeData(employeeTypeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getResidentTypeData();
  }, []);

  const DepartmentDropDown = () => {
    setWorkAccessData((prev) => {
      return { ...prev, jobrole: "" };
    });
  };

  const isValid = (curval) => {
    let isError = false;
    // Personaldetails
    for (const key in PersonalDetailsData) {
      if (key === "email" && PersonalDetailsData[key] !== "") {
        if (!emailRegex.test(PersonalDetailsData[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! Invalid Email Format",
              },
            };
          });
          isError = true;
        }
      }
      //  else if (key === "phone" && PersonalDetailsData[key].length < 10) {
      //   seterrtext((prev) => {
      //     return {
      //       ...prev,
      //       PersonalDetailsData: {
      //         ...prev.PersonalDetailsData,
      //         [key]: "Error! Contact number must be above 10 number ",
      //       },
      //     };
      //   });
      //   isError = true;
      // }
      else if (key === "phone") {
        if (
          PersonalDetailsData[key] === "" ||
          PersonalDetailsData[key] === null ||
          PersonalDetailsData[key].length < 6
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]:
                  PersonalDetailsData[key] === "" ||
                  PersonalDetailsData[key] === null
                    ? "Error! No Inputs detected"
                    : "Error! Phone should not below 6 numbers ",
              },
            };
          });
          isError = true;
        }
      } else if (key === "postalcode") {
        if (
          PersonalDetailsData[key] === "" ||
          PersonalDetailsData[key] === null
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        } else if (PersonalDetailsData[key].length < 5) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! Postal Code must be above 5 numbers ",
              },
            };
          });
          isError = true;
        }
      } else if (PersonalDetailsData[key] === "" && key !== "middlename") {
        seterrtext((prev) => {
          return {
            ...prev,
            PersonalDetailsData: {
              ...prev.PersonalDetailsData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }
    //emergencycontact
    for (const key in emergencyContactData) {
      if (key === "email" && emergencyContactData[key] !== "") {
        if (!emailRegex.test(emergencyContactData[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]: "Error! Invalid Email Format",
              },
            };
          });
          isError = true;
        }
      } else if (key === "phone") {
        if (
          emergencyContactData[key] === "" ||
          emergencyContactData[key] === null ||
          emergencyContactData[key].length < 6
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]:
                  emergencyContactData[key] === "" ||
                  emergencyContactData[key] === null
                    ? "Error! No Inputs detected"
                    : "Error! Phone should not below 6 numbers ",
              },
            };
          });
          isError = true;
        }
      }
      // else if (key === "phone" && emergencyContactData[key].length < 10) {
      //   seterrtext((prev) => {
      //     return {
      //       ...prev,
      //       emergencyContactData: {
      //         ...prev.emergencyContactData,
      //         [key]: "Error! Contact number must be above 10 number ",
      //       },
      //     };
      //   });
      //   isError = true;
      // }
      else if (emergencyContactData[key] === "" && key !== "lastname") {
        seterrtext((prev) => {
          return {
            ...prev,
            emergencyContactData: {
              ...prev.emergencyContactData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }
    for (const key in WorkAccessData) {
      if (key === "accessenddate" && WorkAccessData.employmenttype != "2") {
        if (
          WorkAccessData[key] === "" ||
          WorkAccessData[key] === null ||
          isNaN(WorkAccessData[key].$D)
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        } else if (
          !(
            WorkAccessData.accessstartdate.$d <= WorkAccessData.accessenddate.$d
          )
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! End date should be greater than start date",
              },
            };
          });
          isError = true;
        }
      } else if (
        (WorkAccessData[key] === "" || WorkAccessData[key] === null) &&
        key !== "accessenddate"
      ) {
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }
    return isError;
  };

  //personalDetails
  const Continue = () => {
    const Valid = isValid();
    if (!Valid) {
      editStaffData();
    }
  };

  const handlePersonalDetails = (e, name) => {
    if (name) {
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      if (name === "country" && value) {
        setCityList([]);
        const selectedCountry = value;
        const selectedCountryObject = CountryList.find(
          (city) => city.country === selectedCountry
        );
        setCityList(selectedCountryObject?.city);
        setPersonalDetailsData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    }
  };

  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      contact: value,
      countrycode: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    seterrtext((prev) => {
      return {
        ...prev,
        [statename]: {
          ...prev[statename],
          countrycode: "",
          phone: "",
        },
      };
    });
  };

  const handleEmergencyContact = (e) => {
    const { name, value } = e.target;
    setemergencyContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        emergencyContactData: {
          ...prev.emergencyContactData,
          [name]: "",
        },
      };
    });
  };

  const handleWorkAccess = (e, name) => {
    if (name) {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
      if (name === "employmenttype" && value == "2") {
        setWorkAccessData((prev) => {
          return {
            ...prev,
            accessenddate: "",
          };
        });
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              accessenddate: "",
            },
          };
        });
      }
    }
  };

  const Editblock = () => {
    return (
      <>
        <BackArrow
          onClick={() => navigate("/staffs")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={4}>
          <PrimaryTypography mb={1}>
            {PersonalDetailsData?.fullName}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            Last Updated:{" "}
            {WorkAccessData && WorkAccessData.updated_at
              ? moment
                  .utc(WorkAccessData.updated_at)
                  .local()
                  .format("DD MMM YYYY , HH:mm")
                  .toString()
              : "-"}
          </StyledSecondaryTypography>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Personal Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={PersonalDetailsData.firstname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name*</StyledTypography>
              <TextField
                name="lastname"
                value={PersonalDetailsData.lastname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Middle Name</StyledTypography>
              <TextField
                name="middlename"
                value={PersonalDetailsData.middlename}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.middlename}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Date of Birth*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="dob"
                value={PersonalDetailsData.dob}
                onChange={(value) => handlePersonalDetails(value, "dob")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.PersonalDetailsData.dob)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.dob
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.dob}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Gender*</StyledTypography>
              <Select
                name="gender"
                value={PersonalDetailsData.gender}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.gender
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.gender
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {genderData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.gender}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Nationality*</StyledTypography>
              <Select
                name="nationality"
                value={PersonalDetailsData.nationality}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.nationality
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.nationality
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {Boolean(nationalityData) && nationalityData}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.nationality}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>
                Identity Number / Passport Number*
              </StyledTypography>
              <TextField
                name="inorpn"
                value={PersonalDetailsData.inorpn}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !No_special_regex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 25 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.inorpn
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.inorpn
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.inorpn}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography sx={{ marginBottom: "10px" }}>
                Contact Number*
              </StyledTypography>
              <PhoneInput
                value={PersonalDetailsData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setPersonalDetailsData,
                    PersonalDetailsData,
                    "PersonalDetailsData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.PersonalDetailsData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.PersonalDetailsData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.phone}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={PersonalDetailsData.email}
                onChange={handlePersonalDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                disabled
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Address*</StyledTypography>
              <TextField
                name="address"
                placeholder="Enter the full address here"
                value={PersonalDetailsData.address}
                onChange={handlePersonalDetails}
                inputProps={{ maxLength: 50 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.address
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.address
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  height: "88px",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.address}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Country*</StyledTypography>
              <Select
                name="country"
                value={PersonalDetailsData.country}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.country
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.country
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.country}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>City*</StyledTypography>
              <Select
                name="city"
                value={PersonalDetailsData.city}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.city
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.city
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.city}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Postal Code*</StyledTypography>
              <TextField
                type="number"
                name="postalcode"
                value={PersonalDetailsData.postalcode}
                onChange={(e) => {
                  e.target.value.length <= 6 && handlePersonalDetails(e);
                }}
                // inputProps={{ maxLength: 6 }}
                onKeyDown={(evt) => {
                  ["e", "E", "-"].includes(evt.key) && evt.preventDefault();
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.postalcode
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.postalcode
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.postalcode}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={emergencyContactData.firstname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name</StyledTypography>
              <TextField
                name="lastname"
                value={emergencyContactData.lastname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={emergencyContactData.email}
                onChange={handleEmergencyContact}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Contact Number*</StyledTypography>
              <PhoneInput
                value={emergencyContactData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setemergencyContactData,
                    emergencyContactData,
                    "emergencyContactData"
                  )
                }
                inputProps={{ name: "phone", maxLength: "20" }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.emergencyContactData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.emergencyContactData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.phone}
              </ErrorTypography>
            </Grid>
            {/* <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Nationality*</StyledTypography>
                            <Select name="nationality" value={emergencyContactData.nationality}
                                onChange={(e) => handleEmergencyContact(e)}
                            >
                                {Boolean(nationalityData) && nationalityData}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.nationality}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Gender*</StyledTypography>
                            <Select name="gender" value={emergencyContactData.gender}
                                onChange={(e) => handleEmergencyContact(e)}
                            >
                                {genderData.map((itm) => {
                                    return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                                })}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.gender}</ErrorTypography>
                        </Grid> */}
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Relationship*</StyledTypography>
              <TextField
                name="relationship"
                value={emergencyContactData.relationship}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.relationship
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.relationship
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.relationship}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Work & Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Employment Type*</StyledTypography>
              <Select
                name="employmenttype"
                value={WorkAccessData.employmenttype}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.employmenttype
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.employmenttype
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {employeeTypeData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.employmenttype}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}></Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Department*</StyledTypography>
              <Select
                name="department"
                value={WorkAccessData.department}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.department
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.department
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {department.map((itm, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        DepartmentDropDown();
                      }}
                      key={index}
                      value={itm.id}
                    >
                      {itm.department_name}
                    </MenuItem>
                  );
                })}

                {/* <MenuItem value="1">rdsgvr</MenuItem> */}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.department}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Job Role*</StyledTypography>
              <Select
                name="jobrole"
                value={WorkAccessData.jobrole}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.jobrole
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.jobrole
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {department?.map((itm) => {
                  if (WorkAccessData.department == itm.id) {
                    return itm.role.map((sitm) => {
                      return <MenuItem value={sitm.id}>{sitm.title}</MenuItem>;
                    });
                  }
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.jobrole}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access Start Date*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="accessstartdate"
                value={WorkAccessData.accessstartdate}
                onChange={(value) => handleWorkAccess(value, "accessstartdate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(
                    errtext.WorkAccessData.accessstartdate
                  )
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessstartdate
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessstartdate}
              </ErrorTypography>
            </Grid>
            {WorkAccessData?.employmenttype === 1 && (
              <Grid xs={12} sm={5.8} mt={2}>
                <StyledTypography>Access End Date*</StyledTypography>
                <DatePicker
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  name="accessenddate"
                  value={WorkAccessData.accessenddate}
                  onChange={(value) => handleWorkAccess(value, "accessenddate")}
                  sx={{
                    ...DatePickerStyle,
                    backgroundColor: Boolean(
                      errtext.WorkAccessData.accessenddate
                    )
                      ? "#FEE4E2"
                      : "",

                    "& .MuiInputBase-root": {
                      color: errtext.WorkAccessData.accessenddate
                        ? "#912018"
                        : "#111927",
                    },
                  }}
                />
                <ErrorTypography>
                  {errtext.WorkAccessData.accessenddate}
                </ErrorTypography>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                Last Updated at{" "}
                {moment
                  .utc(WorkAccessData.updated_at)
                  .local()
                  .format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() => Continue()}
                variant="Standard"
                disabled={getres}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Reviewblock = () => {
    return (
      <>
        <BackArrow
          onClick={() => navigate("/staffs")}
          style={{ marginBottom: "16px" }}
        />
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>Kraisee Anurak </PrimaryTypography>
            <StyledSecondaryTypography>
              Last Updated:{" "}
              {moment(new Date()).format("DD MMM YYYY , HH:mm").toString()}{" "}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={() => {
              Continue("edit");
            }}
            sx={{
              backgroundColor: "#E7EFF3 !important",
              width: "134px",
              height: "36px",
              padding: "8px 6px 8px 2px",
            }}
          >
            {" "}
            <IconButton sx={{ paddingLeft: "10px" }}>
              {" "}
              <EditIcon width="14px" />
            </IconButton>{" "}
            Edit Details
          </Button>
        </Stack>
        {/* Active */}
        <Box
          my={4}
          backgroundColor="white"
          alignItems="center"
          padding="24px 16px 24px 16px"
          sx={{ borderRadius: "8px 8px 8px 8px " }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" mb={1}>
              <SecondaryTypography marginRight={2}>
                Staff is
              </SecondaryTypography>
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #ABEFC6",
                  backgroundColor: "#ECFDF3",
                  color: "#067647",
                }}
              >
                Active
              </Button>
            </Stack>
            <Switch
              checked={true}
              // onClick={active ? handleShowActiveModal : handleactive}
              name="loading"
              color="primary"
            />
          </Stack>
          <TypographyDefault>
            The staff is currently marked as an active user in the system.
          </TypographyDefault>
        </Box>
        {/* Inactive */}
        <Box
          my={4}
          backgroundColor="white"
          alignItems="center"
          padding="24px 16px 24px 16px"
          sx={{ borderRadius: "8px 8px 8px 8px " }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" mb={1}>
              <SecondaryTypography marginRight={2}>
                Staff is
              </SecondaryTypography>
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #FEE6A1",
                  backgroundColor: "#FFFAEB",
                  color: "#B54708",
                }}
              >
                Inactive
              </Button>
            </Stack>
            <Switch
              checked={false}
              // onClick={active ? handleShowActiveModal : handleactive}
              name="loading"
              color="primary"
            />
          </Stack>
          <TypographyDefault>
            The staff was marked as inactive for the period of 28 Jan 2023 - 22
            May 2023. (Status change by Germaine, Administrator, Operations) The
            staff will be active on 23 May 2023.
          </TypographyDefault>
        </Box>
        {/* offboard */}
        <Box
          my={4}
          backgroundColor="white"
          alignItems="center"
          padding="24px 16px 24px 16px"
          sx={{ borderRadius: "8px 8px 8px 8px " }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" mb={1}>
              <SecondaryTypography marginRight={2}>
                Staff is
              </SecondaryTypography>
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #E9D7FE",
                  backgroundColor: "#F9F5FF",
                  color: "#6941C6",
                }}
              >
                Offboard
              </Button>
            </Stack>
            <Switch
              checked={false}
              // onClick={active ? handleShowActiveModal : handleactive}
              name="loading"
              color="primary"
            />
          </Stack>
          <TypographyDefault>
            The staff is currently in offboarding as of 28 Jan 2023. (Status
            change by Germaine, Administrator, Operations) The staff will be
            permanently offboarded on 30 JAN 2023. .
          </TypographyDefault>
        </Box>

        {/* Personal Details */}
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <SecondaryTypography> Staff Basic Information</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Middle Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.middlename}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Date of Birth
              </StyledSecondaryTypography>
              <TypographyDefault>
                {moment(PersonalDetailsData.dob.$d)
                  .format("DD MMM YYYY ")
                  .toString()}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.gender == 1
                  ? "Male"
                  : PersonalDetailsData.gender == 2
                  ? "Female"
                  : "Other"}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.nationality}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Identity Number / Passport Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.inorpn}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.phone}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.email}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Address</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.address}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Country</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.country}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>City</StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.city}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.postalcode}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Emergency Contact */}
        <Box
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="0px"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.email}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.phone}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            {/* <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                            <TypographyDefault>{emergencyContactData.nationality}</TypographyDefault>
                        </Grid>
                        <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                            <TypographyDefault>{PersonalDetailsData.gender == 1 ? 'Male' : PersonalDetailsData.gender == 2 ? 'Female' : 'Other'}</TypographyDefault>
                        </Grid>
                        <Divider sx={{ borderTop: '1px solid #F3F4F6', width: '100%', marginTop: '10px' }} /> */}
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Relationship
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.relationship}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Work & Access Details */}
        <Box component="div" boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)">
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Work & Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Employment Type
              </StyledSecondaryTypography>
              <TypographyDefault>
                {WorkAccessData.employmenttype == 1 ? "Temporary" : "Permanent"}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              {department.map((itm, index) => {
                if (WorkAccessData.department == itm.id) {
                  return (
                    <TypographyDefault>{itm.department_name}</TypographyDefault>
                  );
                }
              })}
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              {department?.map((itm) => {
                if (WorkAccessData.department == itm.id) {
                  return itm.role.map((sitm) => {
                    return <TypographyDefault>{sitm.title}</TypographyDefault>;
                  });
                }
              })}
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access Start Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {moment(WorkAccessData.accessstartdate.$d)
                  .format("DD MMM YYYY ")
                  .toString()}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access End Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {moment(WorkAccessData.accessenddate.$d)
                  .format("DD MMM YYYY ")
                  .toString()}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Onboarded by */}
        <Box
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="0 0 8px 8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Onboarded by</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Name</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.name}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Email</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.email}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.department}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.jobrole}</TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        <SnackbarSucces data={snakbardata} />
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        {blocks.editblock && Editblock()}
        {/* {blocks.reviewblock && Reviewblock()} */}
      </Box>
    </>
  );
};

export default EditStaffDetails;
