// export const menus = [
//     {name:'Overview',options:false},
//     {name:'Management',options:true,values:[{sub_name:'Staffs'},{sub_name:'Residents'},{sub_name:'Visitors'}]},
//     {name:'Facilities',options:false},
//     {name:'Fault Reports',options:false},
//     {name:'Service Request',options:false},
//     {name:'Parcel Collection',options:false},
//     {name:'Contact Book',options:false},
//     {name:'Marketing',options:false},
//     {name:'Announcement',options:false},
//     {name:'Property',options:true,values:[{sub_name:'Documentations'},{sub_name:'Notifications'},{sub_name:'Settings'}]},
// ]

// import { useSelector } from "react-redux";
// const { LanguageData } = useSelector((state) => state.Language);

export const menus = [
  {
    id: 1,
    nav_bar_name: "Overview",
    isdrobdown: 0,
    uri_link: "overview",
    icon_name: "Overview",
  },
  {
    id: 2,
    nav_bar_name: "Management",
    isdrobdown: 1,
    uri_link: "management",
    icon_name: "Management",
    hasSub: 1,
    sub: [
      {
        id: 3,
        nav_bar_name: "Staffs",
        isdrobdown: 0,
        uri_link: "staffs",
        icon_name: "Staffs",
      },
      {
        id: 4,
        nav_bar_name: "Residents",
        isdrobdown: 0,
        uri_link: "residents",
        icon_name: "Residents",
      },
      {
        id: 5,
        nav_bar_name: "Visitors",
        isdrobdown: 0,
        uri_link: "visitors",
        icon_name: "Visitors",
      },
    ],
  },
  {
    id: 6,
    nav_bar_name: "Facilities",
    isdrobdown: 0,
    uri_link: "facilities",
    icon_name: "Facilities",
  },
  {
    id: 28,
    nav_bar_name: "Fault Reports",
    isdrobdown: 0,
    uri_link: "fault_reports",
    icon_name: "fault_reports",
  },
  {
    id: 29,
    nav_bar_name: "Service Request",
    isdrobdown: 0,
    uri_link: "service_request",
    icon_name: "service_request",
  },
  {
    id: 30,
    nav_bar_name: "Parcel Collection",
    isdrobdown: 0,
    uri_link: "parcel_collection",
    icon_name: "parcel_collection",
  },
  {
    id: 31,
    nav_bar_name: "Contact Book",
    isdrobdown: 0,
    uri_link: "contact_book",
    icon_name: "contact_book",
  },
  {
    id: 32,
    nav_bar_name: "Marketing",
    isdrobdown: 0,
    uri_link: "marketing",
    icon_name: "Marketing",
  },
  {
    id: 33,
    nav_bar_name: "Announcement",
    isdrobdown: 0,
    uri_link: "announcement",
    icon_name: "Announcement",
  },
  {
    id: 34,
    nav_bar_name: "Property",
    isdrobdown: 0,
    uri_link: "property",
    icon_name: "Property",
    hasSub: 1,
    sub: [
      {
        id: 35,
        nav_bar_name: "Documentations",
        isdrobdown: 0,
        uri_link: "documentations",
        icon_name: "documentations",
      },
      {
        id: 36,
        nav_bar_name: "Notifications",
        isdrobdown: 0,
        uri_link: "notifications",
        icon_name: "Notifications",
      },
      {
        id: 37,
        nav_bar_name: "Settings",
        isdrobdown: 0,
        uri_link: "settings",
        icon_name: "Settings",
      },
    ],
  },
];

export const property_details_stepper = [
  {
    label: "Step 1 • General",
    content: [
      {
        label: "Property Details",
        description:
          "Fill in the general details of the condo like condo name, address and description.",
      },
      {
        label: "Logo & Cover Image",
        description: "Upload of condo complex’s images and logo.",
      },
      {
        label: "Review",
        description: "",
      },
    ],
  },
  {
    label: "Step 2 • Property",
    content: [
      {
        label: "Create Building",
        description:
          "Uploading of the condo complex’s building, levels and unit information.",
      },
      {
        label: "Review",
        description: "",
      },
    ],
  },
  {
    label: "Step 3 • Common Area",
    content: [
      {
        label: "Add Common Area",
        description:
          "Identifying of the common areas within the building. This information will aid staff and residents.",
      },
      {
        label: "Review",
        description: "",
      },
    ],
  },
  {
    label: "Step 4 • Users",
    content: [
      {
        label: "Permissions",
        description: "Adding of departments and roles.",
      },
      {
        label: "Review",
        description: "Selecting the permission for the departments.",
      },
    ],
  },
  {
    label: "Step 5 • Administrator",
    content: [
      {
        label: "Administrator Details",
        description:
          "As the first user of the system, you will onboard your own details here.",
      },
      {
        label: "Review",
        description: "",
      },
    ],
  },
];

export const logoutModalData = {
  label: "Confirm to logout now?",
  descriptionOne:
    "All unsaved changes will be lost. Are you sure you want to log out? ",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

export const saveAsDraftData = {
  label: "Save As Draft",
  descriptionOne: "Please confirm that you wish to save as draft",
  descriptionTwo: "",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Save As Draft",
  },
};

export const contactListColumns = [
  "Checkbox",
  "Name",
  "Category",
  "Upload by",
  "Last Updated ",
  "Delete",
];
export const UsercontactListColumns = [
  "Name",
  "Category",
  "Upload by",
  "Last Updated ",
];

export const StaffAllTabColumn = [
  "Checkbox",
  "Name & Department",
  "Start Date",
  "Status",
  "Status Validity",
  "Email & Contact Number",
  "More",
];

export const UserStaffAllTabColumn = [
  "Name & Department",
  "Start Date",
  "Status",
  "Status Validity",
  "Email & Contact Number",
];

export const StaffActiveTabColumn = [
  "Checkbox",
  "Name & Department",
  "Start Date",
  "Status Validity",
  "Email & Contact Number",
  "More",
];

export const StaffInactiveTabColumn = [
  "Checkbox",
  "Name & Department",
  "Start Date",
  "Status Validity",
  "Email & Contact Number",
  "More",
];

export const StaffOffboardTabColumn = [
  "Name & Department",
  "Working Period",
  "Offboard Date",
];

export const ResidentColumn = [
  "Checkbox",
  "Name",
  "Unit",
  "Start Date",
  "Type",
  "Offboard Date",
  "Email & Contact Number",
  "More",
];

export const UserResidentColumn = [
  "Name",
  "Unit",
  "Start Date",
  "Type",
  "Offboard Date",
  "Email & Contact Number",
];

export const VisitorsAllTabColumn = [
  "Invitation No.",
  "Invitation / Self Registration Date",
  "Location",
  "Invited By",
  "Visitor(s)",
];

export const FaultReportColumn = [
  "Ticket-ID",
  "Title",
  "Status",
  "Last Updated",
  "Reported by",
  "Submission Date",
];

export const ServiceNavTab = [
  "All",
  "Cargo Lift Usage",
  "Security Clearance",
  "Ad Hoc",
];

export const ServiceTableHeader = [
  "Ticket-ID",
  "Title",
  "Status",
  "Requested by",
  "Date Requested",
];

export const ServiceDeclineConfirm = {
  label: "Confirm to decline the request",
  descriptionOne: "Please confirm if you wish to decline the request.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

export const FacilitiesBookTableHeader = [
  "Booking-ID",
  "Facility",
  "Location",
  "Booking by",
  "Date",
  "Time",
];

export const FacilityManageColumn = [
  "Facility",
  "Status",
  "Location",
  "Level",
  "Booking Type",
];

export const ParcelCollectionTableHeaders = [
  "Parcel Number ",
  "Status",
  "Parcel Owner",
  "Collected By",
  "Unit",
  "Added to System",
  "Received by",
];

export const MarketingTabColumn = [
  "Checkbox",
  "Title",
  "Type",
  "Publish Date",
  "Created by",
  "",
];

export const UserMarketingTabColumn = [
  "Title",
  "Type",
  "Publish Date",
  "Created by",
];

// export const AnnouncementTabColumn = [
//  LanguageData?.Announcement_tab_checkbox|| "Checkbox",
//  LanguageData?.Announcemt_tab_title || "Title",
//  LanguageData?.Announcemt_tab_type || "Type",
//  LanguageData?.Announcemt_tab_Publish || "Publish Date",
//  LanguageData?.Announcemt_tab_createdby || "Created by",
//   "",
// ];

// export const UserAnnouncementTabColumn = [
//   LanguageData?.Announcemt_tab_title || "Title",
//   LanguageData?.Announcemt_tab_type || "Type",
//   LanguageData?.Announcemt_tab_Publish || "Publish Date",
//   LanguageData?.Announcemt_tab_createdby || "Created by",
// ];

export const passwordCancelData = {
  label: "Confirm to cancel the update password",
  descriptionOne: "Please confirm that you wish to cancel the update password.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

export const saveAsDraftPropertySetupData = {
  label: "Quit set up process without saving?",
  descriptionOne:
    "Please confirm that you wish to quit the set up property process.",
  descriptionTwo:
    "If you quit the process now without saving, you will have to start the sign up process again. ",
  buttonOne: {
    color: "#0E5E84",
    backgroundColor: "#E7EFF3",
    text: "Quit",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    text: "Save As Draft",
  },
};

export const logoutModalDraftData = {
  label: "Logout",
  descriptionOne: "Please confirm that you wish to quit the set up process.  ",
  descriptionTwo:
    "If you quit the process now and log out, you will have to start sign up again. ",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Logout",
  },
};
