import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../styles/CustomTextEditor.css";

export default function App({ textEditorvalue, settextEditorvalue, label, textEditorErr, setcheckdescription }) {
    // const [value, setValue] = useState("");
    const reactQuillRef = useRef();


    const modules = {
        toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"], // superscript/subscript
        ],
    };

    const handleCount = (e) => {
        const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
        if (unprivilegedEditor?.getLength() > 10 && e.key !== 'Backspace')
            e.preventDefault();

    }

    const handleChange = (e) => {

        // const element = document.getElementById('myElement'); // Replace 'myElement' with the actual ID of your element
        const textWithoutTags = textEditorvalue.replace(/<[^>]+>/g, '');
        const onChangeEditorValue = e?.replace(/<[^>]+>/g, '')

        // console.log(textEditorvalue);
        if ((onChangeEditorValue.trim()).length > 10) {
            console.log(textWithoutTags);
            settextEditorvalue(textEditorvalue)
            setcheckdescription((textWithoutTags.trim()).length)
            return false

        }
        else {
            setcheckdescription((onChangeEditorValue.trim()).length)
            return true
        }
        // if (element) {
        //     if (element.innerText.length <= 10) {
        //         setcheckdescription((element.innerText.trim()).length)
        //         // return true
        //     }
        // }

    }


    return (
        <>
            <ReactQuill
                // theme="snow"
                // id="myElement"
                // ref={reactQuillRef}
                // onKeyDown={handleCount}
                className={textEditorErr && "error"}
                modules={modules}
                placeholder={label}
                // formats={formats}
                value={textEditorvalue}
                onChange={settextEditorvalue}
                maxLength={3000}
            />
        </>
    );
}
