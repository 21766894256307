import React, { lazy } from "react";
import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ReactComponent as StatusActiveIcon } from "../../Icons/StatusActive.svg";
import { getChip } from "../../Utils";
import { StyledSecondaryTypography } from "../../Theme";
import { ReactComponent as StatusInactiveIcon } from "../../Icons/StatusInactive.svg";
import moment from "moment";
import { useSelector } from "react-redux";

const ServiceRequestStepper = ({ serviceStatus }) => {
  const { LanguageData } = useSelector((state) => state.Language);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E5E7EB",
          borderRadius: "8px",
          width: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Box sx={{ p: 3, borderBottom: "1px solid #E5E7EB" }}>
          <Typography
            sx={{ color: "#111927", fontSize: "14px", fontWeight: 500 }}
          >
            {LanguageData?.Status_Update || "Status Update"}
          </Typography>
        </Box>
        <Stepper orientation="vertical" sx={{ px: 2, py: 3 }}>
          {serviceStatus.cancel && (
            <Step active>
              <StepLabel icon={<StatusInactiveIcon />}>
                {getChip("cancelled")}
              </StepLabel>
              <StepContent>
                <StyledSecondaryTypography>
                  {serviceStatus.cancel?.genral_comment}
                </StyledSecondaryTypography>{" "}
                <StyledSecondaryTypography sx={{ marginTop: "6px" }}>
                  {serviceStatus.cancel?.updated_at
                    ? moment
                        .utc(serviceStatus.cancel.updated_at)
                        .local()
                        .format("DD MMM YYYY • HH:mm")
                    : "-"}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}

          {serviceStatus.approve && (
            <Step active>
              <StepLabel icon={<StatusActiveIcon />}>
                {getChip("approved")}
              </StepLabel>
              <StepContent>
                <StyledSecondaryTypography>
                  {serviceStatus.approve?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ marginTop: "6px" }}>
                  {serviceStatus.approve?.updated_at
                    ? moment
                        .utc(serviceStatus.approve.updated_at)
                        .local()
                        .format("DD MMM YYYY • HH:mm")
                    : "-"}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}

          {serviceStatus.submit && (
            <Step active>
              <StepLabel
                icon={
                  serviceStatus.approve || serviceStatus.cancel ? (
                    <StatusInactiveIcon />
                  ) : (
                    <StatusActiveIcon />
                  )
                }
              >
                {getChip("submitted")}
              </StepLabel>
              <StepContent>
                <StyledSecondaryTypography>
                  {serviceStatus.submit?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ marginTop: "6px" }}>
                  {serviceStatus.submit?.updated_at
                    ? moment
                        .utc(serviceStatus.submit.updated_at)
                        .local()
                        .format("DD MMM YYYY • HH:mm")
                    : "-"}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}
        </Stepper>
      </Box>
    </>
  );
};

export default ServiceRequestStepper;
