import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import { PrimaryTypography, TypographyDefault } from "../Theme";
import { ReactComponent as Close } from "../Icons/BorderAlertClose.svg";
import { ReactComponent as MoreInfoIcon } from "../Icons/MoreInfo.svg";
import { ReactComponent as MoreInfoWOBIcon } from "../Icons/MoreInfoWOB.svg";

const SystemDetailsModal = ({ commonmodal }) => {
    const [showmodal, setshowmodal] = useState(false);

    return (
        <>
            {(commonmodal?.label === 'Access') || (commonmodal?.label === 'Manage') || (commonmodal?.label === 'This common area is a part of a building') ?
                <IconButton sx={{ '&:hover': { cursor: 'pointer' } ,padding:'0px'}}> <MoreInfoWOBIcon onClick={() => setshowmodal(!showmodal)} /></IconButton> :
                <Box bgcolor="#FFFFFF" onClick={() => setshowmodal(!showmodal)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "23px",
                        height: "23px",
                        border: '1px solid #E5E7EB',
                        borderRadius: '8px',
                        padding: "8px",
                        '&:hover': { cursor: 'pointer' }
                    }}>
                    <MoreInfoIcon />
                </Box>
            }
            <Modal open={showmodal}>
                <Box sx={{ position: 'absolute', border: 'none', width: '100%', height: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ backgroundColor: '#FFFFFF', width: '40%', borderRadius: '8px', }}>
                        <Box sx={{ p: 2, borderBottom: '2px solid #F3F4F6', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <PrimaryTypography>{commonmodal?.label}</PrimaryTypography>
                            <Close onClick={() => setshowmodal(!showmodal)} />
                        </Box>
                        <Box sx={{ px: 2, py: 3 }}>
                            <TypographyDefault sx={{ fontSize: "16px !important" }}>{commonmodal?.description}</TypographyDefault>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SystemDetailsModal;
