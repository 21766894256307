import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../Icons/ContactDetails.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Divider,
  Select,
  TextField,
  Typography,
  FormControl,
  IconButton,
} from "@mui/material";
import { addContactDetails } from "../../Redux/Feature/ContactBook/ContactBookSlice";
import { setContactBookSliceToNull } from "../../Redux/Feature/ContactBook/ContactBookSlice";
import { emailRegex } from "../../Utils";
import axiosInstance from "../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const CreateContactDetails = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [create, setcreate] = useState(true);
  const [preview, setPreview] = useState(false);
  const [contactdata, setcontactdata] = useState({
    category: "",
    title: "",
    contact_number: "",
    email: "",
  });
  const [errtext, seterrtext] = useState({
    category: "",
    title: "",
    contact_number: "",
    email: "",
  });
  const { addContactDetailsStatus } = useSelector((state) => state.ContactBook);
  let { propertyId } = useSelector((state) => state.PropertyDetails);
  const [response, setresponse] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [InvalidErr, setInvalidErr] = useState({
    contact_number: "",
    email: "",
  });
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/contactbookcategory`
      )
      .then((data) => {
        setCategoryData(data.data.data.contactbook_category);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (addContactDetailsStatus != null) {
      SetPostLoader(false);
      if (addContactDetailsStatus.success) {
        navigate("/contact-book");
        showToast(addContactDetailsStatus?.toast);
      } else {
        if (addContactDetailsStatus.error === "Validation Error") {
          let obj = addContactDetailsStatus.data.validation_error;
          let str = "";
          for (let key in obj) {
            str = str.concat(obj[key]);
          }
          setresponse(str);
        } else {
          setresponse(addContactDetailsStatus.error);
        }
      }
    }
  }, [addContactDetailsStatus]);

  const handlechange = (e) => {
    const { name, value } = e.target;

    setcontactdata((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
    setInvalidErr((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  const validate = () => {
    let isError = false;
    for (const key in contactdata) {
      if (contactdata[key] === "") {
        seterrtext((prev) => {
          return {
            ...prev,
            [key]: "Error! No Inputs detected",
          };
        });
        // }
        isError = true;
      } else if (
        key == "contact_number" &&
        (contactdata[key].length < 6 || contactdata[key].length > 20)
      ) {
        seterrtext((prev) => {
          return {
            ...prev,
            [key]: "Error! min and max value should between 6 - 20",
          };
        });
        setInvalidErr((prev) => ({
          ...prev,
          contact_number: "Error! min and max value should between 6 - 20",
        }));
        isError = true;
      } else if (key == "email" && !emailRegex.test(contactdata[key])) {
        seterrtext((prev) => {
          return {
            ...prev,
            [key]: "Error! Invalid email",
          };
        });
        setInvalidErr((prev) => ({ ...prev, email: "Error! Invalid email" }));
        isError = true;
      }
    }
    return isError;
  };

  const Continue = (e) => {
    e.preventDefault();
    const isError = validate();
    if (!isError) {
      setcreate(false);
      setPreview(true);
    }
  };

  const gobacktocreate = () => {
    setcreate(true);
    setPreview(false);
  };

  const createContact = () => {
    SetPostLoader(true);
    let data = new FormData();
    data.append("title", contactdata.title);
    data.append("category_id", contactdata.category.id);
    data.append("contact_number", contactdata.contact_number);
    data.append("email", contactdata.email);
    data.append("status", "1");
    dispatch(addContactDetails({ data, propertyId }));
  };

  const createf = () => {
    return (
      <>
        <Grid container gap="1.5%" sx={{ "&:hover": { cursor: "pointer" } }}>
          <Grid xs={5.9}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #0E4965",
                width: "100%",
                color: "#0E4965",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Contact_Details || "Contact Details"}{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={5.9} onClick={Continue}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #D2D6DB",
                width: "100%",
                color: "#9DA4AE",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Review_Information || "Review Information"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3} component="div" boxShadow={1} borderRadius="8px">
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Contact_Details || "Contact Details"}{" "}
            </SecondaryTypography>
          </Stack>
          <Box component="form" onSubmit={Continue}>
            <Grid
              container
              xs={12}
              gap="20px"
              sx={{
                backgroundColor: "white",
                padding: "30px 16px 30px 16px",
                border: "1px solid #E5E7EB",
                borderBottom: "0",
              }}
            >
              <Grid xs={12} md={5.77}>
                <StyledTypography>
                  {" "}
                  {LanguageData?.Category || "Category"}
                </StyledTypography>
                <FormControl fullWidth>
                  {/* <InputLabel id="emer">Acge</InputLabel> */}
                  <Select
                    name="category"
                    displayEmpty
                    // inputProps={{ 'aria-label': 'Without label' }}
                    value={contactdata.category.id}
                    sx={{
                      color: Boolean(errtext.category) ? "#912018" : "",
                      backgroundColor: Boolean(errtext.category)
                        ? "#FEE4E2"
                        : "",
                    }}
                    onChange={handlechange}
                    renderValue={
                      contactdata.category.id !== undefined
                        ? undefined
                        : () => "Select category"
                    }
                    // fullWidth
                  >
                    {categoryData?.map((value) => (
                      <MenuItem value={value}>{value.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <ErrorTypography>{errtext.category}</ErrorTypography> */}
              </Grid>
              <Grid xs={12} md={5.77}>
                <StyledTypography>
                  {LanguageData?.Name || "Name"}
                </StyledTypography>
                <TextField
                  name="title"
                  placeholder="Add contact name"
                  value={contactdata.title}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: Boolean(errtext.title) ? "#912018" : "",
                    },
                    backgroundColor: Boolean(errtext.title) ? "#FEE4E2" : "",
                  }}
                  onChange={handlechange}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                />
                {/* <ErrorTypography>{errtext.title}</ErrorTypography> */}
              </Grid>
              <Grid xs={12} md={5.77}>
                <StyledTypography>
                  {" "}
                  {LanguageData?.Contact_Number || "Contact Number"}
                </StyledTypography>
                <TextField
                  type="number"
                  name="contact_number"
                  value={contactdata.contact_number}
                  placeholder="Add contact number"
                  onChange={(e) => {
                    e.target.value.length <= 20 && handlechange(e);
                  }}
                  onKeyDown={(evt) => {
                    ["e", "E", "-"].includes(evt.key) && evt.preventDefault();
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: Boolean(errtext.contact_number) ? "#912018" : "",
                    },
                    backgroundColor: Boolean(errtext.contact_number)
                      ? "#FEE4E2"
                      : "",
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                />
                <ErrorTypography>{InvalidErr.contact_number}</ErrorTypography>
              </Grid>
              <Grid xs={12} md={5.77}>
                <StyledTypography>
                  {" "}
                  {LanguageData?.Email_Address || "Email Address"}
                </StyledTypography>
                <TextField
                  name="email"
                  value={contactdata.email}
                  placeholder="Add email address"
                  onChange={handlechange}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: Boolean(errtext.email) ? "#912018" : "",
                    },
                    backgroundColor: Boolean(errtext.email) ? "#FEE4E2" : "",
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 125 }}
                  fullWidth
                />
                <ErrorTypography>{InvalidErr.email}</ErrorTypography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Box
                bgcolor="#FFFFFF"
                sx={{
                  border: "1px solid  #E5E7EB",
                  borderTop: " 1px solid #F3F4F6",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Stack
                  direction="row"
                  py={2}
                  px={2}
                  justifyContent="end"
                  alignItems="center"
                  height="60px"
                >
                  {/* <Stack direction='row'>
                                        <RefreshIcon style={{ marginRight: '5px' }} />
                                        <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
                                    </Stack> */}
                  <Stack
                    direction="column"
                    justifyContent="end"
                    alignItems="end"
                  >
                    <Button type="submit" variant="Standard">
                      {LanguageData?.Common_Continue || "Continue"}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  const reviewf = () => {
    return (
      <>
        <Grid container gap="1.5%">
          <Grid xs={5.9} onClick={gobacktocreate}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #9DA4AE",
                width: "100%",
                color: "#9DA4AE",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Contact_Details || "Contact Details"}{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={5.9}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #0E4965",
                width: "100%",
                color: "#0E4965",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Review_Information || "Review Information"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3} component="div" boxShadow={1} borderRadius="8px">
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Contact_Details || "Contact Details"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 20px 16px",
              border: "1px solid #E5E7EB",
              borderBottom: "0",
            }}
          >
            <Grid xs={12} md={5.8} mb={1}>
              <StyledSecondaryTypography>
                {" "}
                {LanguageData?.Category || "Category"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {contactdata.category.name}{" "}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} md={5.8}>
              <StyledSecondaryTypography>
                {" "}
                {LanguageData?.Name || "Name"}
              </StyledSecondaryTypography>
              <TypographyDefault>{contactdata.title}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid xs={12} md={5.8} mt={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {contactdata.contact_number}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} md={5.8} mt={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Email_Address || "Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{contactdata.email || "-"} </TypographyDefault>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Box
              bgcolor="#FFFFFF"
              sx={{
                border: "1px solid  #E5E7EB",
                borderTop: " 1px solid #F3F4F6",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Stack
                direction="row"
                py={2}
                px={2}
                justifyContent="space-between"
                alignItems="center"
                height="60px"
              >
                <Stack direction="row">
                  <RefreshIcon style={{ marginRight: "5px" }} />
                  <StyledSecondaryTypography>
                    {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                    {moment(new Date()).format("hh:mm A")}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack direction="column" justifyContent="end" alignItems="end">
                  <Link>
                    <Button variant="Standard" onClick={createContact}>
                      {LanguageData?.Common_CreateNow || "Create Now"}
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <BackArrow
          onClick={() => navigate("/contact-book")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={5}>
          <PrimaryTypography mb={0.5}>
            {LanguageData?.Create_Contact || "Create Contact"}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Create_Contact_Sub ||
              " Create a contact detail that will be added and displayed on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {create && createf()}
        {preview && reviewf()}
      </Box>
    </>
  );
};

export default CreateContactDetails;
