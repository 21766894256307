import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Close } from "../../../../Icons/BorderAlertClose.svg";
import { ReactComponent as UploadIcon } from "../../../../Icons/UploadIcon.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
} from "../../../../Theme";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Chip,
  Container,
} from "@mui/material";
import { ReactComponent as EditBuilding } from "../../../../Icons/EditBuilding.svg";
import { ReactComponent as ExcelIcon } from "../../../../Icons/Excel.svg";
import Dropzone from "react-dropzone";
import axiosInstance from "../../../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import { showToast } from "../../../../CommonComponents/Toaster";
import { PostLoaderModal } from "../../../../CommonComponents/modal";

const ReviewProperty = () => {
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [postLoader, SetPostLoader] = useState(false);
  const [buildingData, setBuildingData] = useState([]);
  const [editModalName, setEditModalName] = useState(false);
  const [editData, setEditData] = useState({ id: "", building: "" });
  const [inputError, setInputError] = useState("");
  const [errId, setErrorId] = useState(null);
  const [xlsError, setXlsError] = useState("");
  const [levelTrigger, setLevelTrigger] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const { LanguageData } = useSelector((state) => state.Language);
  const fileInputRef = useRef(null);
  const [currentBuildingId, setCurrentBuildingId] = useState(null);

  const getBuilding = () => {
    SetPostLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building?pagination=false`
      )
      .then((res) => {
        setBuildingData(res?.data?.data?.property_buildings);
        SetPostLoader(false);
      })
      .catch((err) => {
        console.error(err);
        SetPostLoader(false);
        showToast("Something went wrong! Please try again later.");
      });
  };

  useEffect(() => {
    getBuilding();
  }, []);

  const handlePreviewXls = async (id) => {
    const buildingName = buildingData?.find((itm) => itm?.id === id);
    setPreviewData(buildingName);
    levelopen();
  };

  const levelopen = () => {
    setLevelTrigger(true);
  };

  const handleExistsEmpty = (id) => {
    setCurrentBuildingId(id);
    fileInputRef.current.click();
  };

  const handleEditBuildingName = (name, id) => {
    setEditData({ id: id, building: name });
    setEditModalName(true);
  };
  const handleEmptyReplace = (id) => {
    setCurrentBuildingId(id);
    fileInputRef.current.click();
  };

  const onDrop = (id, acceptedFiles) => {
    const buildingName = buildingData?.find((itm) => itm?.id === id);
    const updatedBuildingData = buildingData.map((building) => {
      if (building.id === id) {
        return {
          ...building,
          files: acceptedFiles,
        };
      }
      return building;
    });
    setBuildingData(updatedBuildingData);
    uploadFile(id, acceptedFiles, buildingName?.building_name);
  };

  const uploadFile = (id, files, name) => {
    SetPostLoader(true);
    const formData = new FormData();
    formData.append("building_array_list[0][building_name]", name);
    formData.append("building_array_list[0][id]", id);
    formData.append("building_array_list[0][file]", files[0]);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building/import`,
        formData
      )
      .then((res) => {
        getBuilding();
        SetPostLoader(false);
        showToast("XLS Template upload successfully");
      })
      .catch((err) => {
        SetPostLoader(false);
        setErrorId(id);
        const importErrors = err?.response?.data?.data?.import_validation_error;
        if (importErrors) {
          const errorMessages = Object.keys(importErrors).map((row, index) => {
            const rowData = importErrors[row];
            const formattedMessage = `Row ${index + 1}: ${rowData.join(" , ")}`;
            return formattedMessage;
          });
          setXlsError(errorMessages.join("\n"));
        } else {
          setXlsError("An error occurred during file upload.");
        }
      });
  };

  const handleEditConfirm = () => {
    if (!editData.building.trim()) {
      setInputError("Please Enter the building name");
    } else {
      SetPostLoader(true);
      const formData = new FormData();
      formData.append(
        "building_array_list[0][building_name]",
        editData.building
      );
      formData.append("building_array_list[0][id]", editData.id);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building/import`,
          formData
        )
        .then((res) => {
          getBuilding();
          SetPostLoader(false);
          setEditModalName(false);
        })
        .catch((err) => {
          const validationError =
            err.response?.data?.data?.validation_error[
              "building_array_list.0.building_name"
            ];
          SetPostLoader(false);
          if (validationError) {
            setInputError(validationError);
          } else {
            showToast("Something went wrong! Please try again later.", "error");
            setEditModalName(false);
          }
        });
    }
  };
  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      onDrop(currentBuildingId, files);
    }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {levelTrigger && (
        <Modal open={true}>
          <Container
            sx={{
              border: "none",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px 24px 16px 24px",
                  borderBottom: "1px solid #F3F4F6",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PrimaryTypography>
                  {previewData?.building_name}
                </PrimaryTypography>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={() => setLevelTrigger(false)}
                />
              </Box>

              <Box sx={{ padding: "30px 30px 30px 30px" }}>
                {previewData?.level?.map((data) => (
                  <Grid
                    container
                    sx={{
                      borderBottom: "1px solid #E5E7EB",
                      mt: 1,
                      py: 1,
                    }}
                  >
                    <Grid item xs={1}>
                      <SecondaryTypography>{data?.level}</SecondaryTypography>
                    </Grid>
                    <Grid item xs={11} sx={{ display: "flex" }}>
                      <StyledSecondaryTypography>
                        {" "}
                        {data?.area_type_id === 1
                          ? "Residential"
                          : data?.area_type_id === 2
                          ? "Non-residential"
                          : data?.area_type_id === 3
                          ? "Mixed"
                          : ""}{" "}
                        | {data?.total_unit}
                        {LanguageData?.Units || "Units"}
                      </StyledSecondaryTypography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} sx={{ my: 1 }}>
                      {data?.building_unit?.map((dataitm) => {
                        return (
                          <Chip
                            sx={{
                              borderRadius: "6px",
                              border: "1px solid #D0D5DD",
                              fontSize: "14px",
                              mr: 2,
                            }}
                            label={dataitm.unit_no}
                            variant="outlined"
                          ></Chip>
                        );
                      })}
                    </Grid>
                  </Grid>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    borderRadius: "0px 0px 8px 8px",
                    backgroundColor: "#FFFFFF",
                    paddingTop: "24px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setLevelTrigger(false)}
                    style={{
                      borderRadius: "8px",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#FFFFFF",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    {LanguageData?.Close || "Close"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Modal>
      )}
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
        }}
      >
        {buildingData?.map((value, index) => (
          <>
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #E5E7EB",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              {UserManage?.settings && (
                <EditBuilding
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={() =>
                    handleEditBuildingName(value?.building_name, value?.id)
                  }
                />
              )}

              <SecondaryTypography>
                {" "}
                {value?.building_name}{" "}
              </SecondaryTypography>
            </Box>
            {value?.files ? (
              <Box sx={{ padding: "30px 16px 30px 16px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Box>
                    <Stack direction="row">
                      <ExcelIcon style={{ marginRight: "12px" }} />
                      <Stack direction="column">
                        <StyledSecondaryTypography>
                          {value?.files[0]?.name}
                        </StyledSecondaryTypography>
                        <StyledSecondaryTypography>
                          {(value?.files[0]?.size / 1048576).toFixed(2)} MB
                        </StyledSecondaryTypography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0E5E84",
                      marginRight: "1rem",
                    }}
                    onClick={() => handleEmptyReplace(value?.id)}
                  >
                    {LanguageData?.Replace || "Replace"}
                  </Button>
                </Box>
                {value?.id === errId && (
                  <>
                    {xlsError.split("\n").map((errorMessage, index) => (
                      <ErrorTypography
                        style={{ marginTop: "1rem" }}
                        key={index}
                      >
                        {errorMessage}
                      </ErrorTypography>
                    ))}
                  </>
                )}
              </Box>
            ) : value?.import_file_path ? (
              <Box sx={{ padding: "30px 16px 30px 16px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Box>
                    <Stack direction="row">
                      <ExcelIcon style={{ marginRight: "12px" }} />
                      <Stack direction="column">
                        <StyledSecondaryTypography>
                          {value?.file?.name}
                        </StyledSecondaryTypography>
                        <StyledSecondaryTypography>
                          {(value?.file?.size).toFixed(2) + "KB"}
                        </StyledSecondaryTypography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box>
                    {UserManage?.settings && (
                      <Button
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D2D6DB",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#0E5E84",
                          marginRight: "1rem",
                        }}
                        onClick={() => handleExistsEmpty(value?.id)}
                      >
                        {LanguageData?.Replace || "Replace"}
                      </Button>
                    )}

                    <Button
                      sx={{
                        backgroundColor: "#E7EFF3",
                        color: "#0E5E84",
                        "&:hover": {
                          backgroundColor: "#CFDFE6",
                        },
                      }}
                      onClick={() => handlePreviewXls(value?.id)}
                    >
                      {LanguageData?.Announcement_Switchtab_Preview ||
                        "Preview"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </>
        ))}
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileInputChange}
          accept=".xlsx, .xls"
          hidden
        />
      </Box>
      {editModalName && (
        <Modal open={true}>
          <Box
            sx={{
              position: "absolute",
              border: "none",
              width: "100%",
              height: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "40%",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px 24px 16px 24px",
                  borderBottom: "1px solid #F3F4F6",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PrimaryTypography>
                  {LanguageData?.Edit_Building_Name || "Edit Building Name"}
                </PrimaryTypography>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditModalName(false);
                    setInputError("");
                  }}
                />
              </Box>
              <Box sx={{ padding: "30px 30px 30px 30px" }}>
                <Box>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      marginTop: "10px",
                      marginRight: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#111927",
                      }}
                    >
                      {LanguageData?.Building_Name || "Building Name"}
                    </Typography>
                    <TextField
                      sx={{
                        backgroundColor: inputError ? "#FEE4E2" : "#F3F4F6",
                        "& .MuiInputBase-root": {
                          color: inputError ? "#912018" : "#111927",
                        },
                      }}
                      value={editData.building}
                      onChange={(event) => {
                        setEditData({
                          ...editData,
                          building: event.target.value,
                        });
                        setInputError("");
                      }}
                      placeholder="Enter the building name"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ maxLength: 20 }}
                      fullWidth
                    />
                    <ErrorTypography>{inputError}</ErrorTypography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "0px 0px 8px 8px",
                    backgroundColor: "#FFFFFF",
                    paddingTop: "24px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setEditModalName(false);
                      setInputError("");
                    }}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0E5E84",
                    }}
                  >
                    {LanguageData?.Announcement_Cancel || "Cancel"}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "8px",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#FFFFFF",
                      cursor: "pointer",
                      border: "none",
                    }}
                    onClick={handleEditConfirm}
                  >
                    {LanguageData?.Common_Confirm || "Confirm"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ReviewProperty;
