import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/ContactDelete.svg";
import { ReactComponent as EditIcon } from "../../Icons/Edit.svg";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";

import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledSecondaryDefaultTypography,
  StyledTypography,
  TypographyDefault,
} from "../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  Typography,
  Skeleton,
  IconButton,
  Modal,
  Switch,
  Divider,
} from "@mui/material";
import AlertModal from "../../CommonComponents/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactDetails,
  deleteContactDetails,
  setContactBookSliceToNull,
} from "../../Redux/Feature/ContactBook/ContactBookSlice";
import { getChip } from "../../Utils";
import axiosInstance from "../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const ReviewContactDetails = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { contactDetailsList, deleteContactDetailsStatus } = useSelector(
    (state) => state.ContactBook
  );
  const { id } = useParams();
  const [contactdata, setcontactdata] = useState({
    category_id: "",
    title: "",
    contact_number: "",
    email: "",
  });
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [active, setactive] = useState(true);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [response, setresponse] = useState("");
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  let activeModalDatas = {
    label: "Contact Status",
    descriptionOne: `Please confirm that you wish to change the contact status from ${
      active ? "active to inactive" : "inactive to active"
    }.`,
    descriptionTwo: `You will be able to toggle it back to ${
      active ? "active" : "inactive"
    } when you wish to. If your intention is to permanently delete the contact, please choose to delete contact instead.`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Confirm Change",
    },
  };

  let deleteModalDatas = {
    label:
      LanguageData?.Contact_Single_Delete ||
      "Confirm to delete contact permanently",
    descriptionOne: LanguageData?.Delete_description
      ? `${contactdata?.title} ${LanguageData?.Delete_description} `
      : `${contactdata?.title} will be removed from contact book.`,

    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Announcement_DeleteNow || "Delete Now",
    },
  };

  useEffect(() => {
    if (deleteContactDetailsStatus != null) {
      if (deleteContactDetailsStatus.success) {
        SetPostLoader(false);
        navigate("/contact-book");
        showToast(deleteContactDetailsStatus.toast, "delete");
        dispatch(setContactBookSliceToNull());
      } else {
        setresponse(deleteContactDetailsStatus.error);
      }
    }
  }, [deleteContactDetailsStatus]);

  useEffect(() => {
    dispatch(getContactDetails({ propertyId, id }));
  }, []);

  useEffect(() => {
    if (contactDetailsList?.success) {
      let data = contactDetailsList.data.contact_book;
      setloading(false);
      setcontactdata(data);
      setactive(data.status[0]?.key === "active" ? 1 : 0);
    }
  }, [contactDetailsList]);

  const deleteContactDetail = () => {
    SetPostLoader(true);
    dispatch(deleteContactDetails({ propertyId, id }));
    // setshowDeleteModal(false)
  };

  const handleShowActiveModal = () => {
    setShowActiveModal(!showActiveModal);
  };

  const handleActiveInactive = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/${id}`
      )
      .then((data) => {
        handleShowActiveModal();
        dispatch(getContactDetails({ propertyId, id }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //DeleteModal

  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showActiveModal && (
        <AlertModal
          modalDatas={activeModalDatas}
          confirmFunction={handleActiveInactive}
          closeFunction={handleShowActiveModal}
        />
      )}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteContactDetail}
          closeFunction={handleShowDeleteModal}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6", height: "100%" }}>
        <Link to="/contact-book">
          <BackArrow style={{ marginBottom: "26px" }} />
        </Link>
        <Stack
          mb={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {loading ? <Skeleton /> : contactdata?.title}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                "Last Updated:" +
                moment
                  .utc(contactdata?.updated_at)
                  .local()
                  .format("DD MMM YYYY, HH:mm")
              )}
            </StyledSecondaryTypography>
          </Box>
          {UserManage?.["contact-book"] && !loading && (
            <DeleteIcon
              onClick={handleShowDeleteModal}
              style={{ cursor: "pointer" }}
            />
          )}
        </Stack>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={300} />
        ) : (
          <Box mt={3} component="div" boxShadow={1} borderRadius="8px">
            {/* <Box backgroundColor='white' alignItems='center' padding='24px 16px 24px 16px' sx={{ borderRadius: '8px 8px 0 0 ' }}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' alignItems='center' mb={2}>
                                <SecondaryTypography marginRight={2}>Contact is</SecondaryTypography>
                                {Array.isArray(contactdata.status) && getChip(`${contactdata?.status[0]?.key}`)}
                            </Stack>
                            {UserManage?.["contact-book"] &&
                                <Switch
                                    checked={active}
                                    onClick={handleShowActiveModal}
                                    name="loading"
                                    color="primary"
                                />
                            }
                        </Stack>
                        <TypographyDefault>The details of the contact are reflected
                            below. You may turn on/ off the active <br />
                            state by pressings the purple icon.
                            You may also edit or delete the contact details.</TypographyDefault>
                    </Box> */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderTop: "1px solid #E5E7EB",
                borderRadius: "8px 8px 0 0 ",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Contact_Details || "Contact Details"}{" "}
              </SecondaryTypography>
              {UserManage?.["contact-book"] && (
                <Button
                  onClick={() => navigate(`/contact-book/editContact/${id}`)}
                  sx={{
                    width: "83px",
                    height: "36px",
                    padding: "8px 6px 8px 14px",
                  }}
                  variant="outlined"
                >
                  {" "}
                  Edit{" "}
                  <IconButton sx={{ paddingLeft: "10px" }}>
                    {" "}
                    <EditIcon width="14px" />
                  </IconButton>
                </Button>
              )}
            </Stack>
            <Grid
              container
              xs={12}
              sx={{
                backgroundColor: "white",
                padding: "30px 16px 20px 16px",
                borderTop: "1px solid #E5E7EB",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Grid xs={12} sm={6}>
                <StyledSecondaryTypography>
                  {LanguageData?.Category || "Category"}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {Array.isArray(contactdata?.category) &&
                    contactdata?.category[0]?.name}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={6}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.Name || "Name"}
                </StyledSecondaryTypography>
                <TypographyDefault>{contactdata?.title}</TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid xs={12} sm={6} mt={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Contact_Number || "Contact Number"}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {contactdata?.contact_number}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={6} mt={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Email_Address || "Email Address"}
                </StyledSecondaryTypography>
                <TypographyDefault sx={{ wordBreak: "break-word" }}>
                  {contactdata?.email}
                </TypographyDefault>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      <Modal open={showDeleteModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Contact_Single_Delete ||
                  "Confirm to delete contact permanently"}
              </PrimaryTypography>
              <Close onClick={() => handleShowDeleteModal()} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              {/* <Stack direction="row" alignItems="center">
                                <SecondaryTypography mr={1} sx={{ color: '#B54708 !important' }}>231 bookings</SecondaryTypography>
                                <StyledSecondaryDefaultTypography sx={{ color: "#1F2A37 !important" }}> during the stated period will be cancelled.</StyledSecondaryDefaultTypography>
                            </Stack> */}
              <StyledSecondaryDefaultTypography
                sx={{ mt: 2, mb: 1, color: "#1F2A37 !important" }}
              >
                {LanguageData?.Delete_description
                  ? contactdata?.title + LanguageData?.Delete_description
                  : contactdata?.title + "will be removed from contact book."}
              </StyledSecondaryDefaultTypography>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => handleShowDeleteModal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  deleteContactDetail();
                }}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ReviewContactDetails;
