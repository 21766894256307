import React, { useEffect, useState } from "react";
import { StyledPrimaryTypography, StyledSecondaryTypography } from "../Theme";
import { Button,CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoginSliceToNull,
  login,
  setUserDetails,
} from "../Redux/Feature/Login/loginSlice";
import {
  setActiveStep,
  setPropertyId,
  setUserRole,
} from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { showToast } from "../CommonComponents/Toaster";


const SuccessPage = ({ setDisplayScreen, email, password }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginStatus } = useSelector((state) => state.Login);
  const [data, setData] = useState({ email: "", password: "" });
  const [errorText, setErrorText] = useState({ email: "", password: "" });
  const [responseError, setResponseError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    if (loginStatus != null) {
        setLoadingBtn(false);
      if (loginStatus.success) {
        if (loginStatus.data.user_details.user_role == 2) {
          if (loginStatus.data.property_status.status === "draft") {
            localStorage.clear();
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            // dispatch(setActiveStep(loginStatus.data.property_status.completed_step))
            // dispatch(setPropertyId(loginStatus.data.property_status.id))
            navigate("/propertyDetailsSetup");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          } else if (loginStatus.data.property_status.status != "draft") {
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          }
        } else if (loginStatus.data.user_details.user_role == 3) {
          if (loginStatus.data.user_property[0].status === "published") {
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.user_property[0])
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.user_property[0].id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          }
          // setResponseError('No Access on Web')
        }
      } else if (!loginStatus.success){
        setResponseError(loginStatus.error);
        showToast('Something went wrong! Please try again.' , 'error')
      }
    }
  }, [loginStatus]);

  const confirmToSetup = () => {
    setLoadingBtn(true);
    dispatch(login({ email: email, password: password }));
  };
  return (
    <>
      <StyledPrimaryTypography textAlign={"center"}>
        Loby Super Admin
      </StyledPrimaryTypography>
      <StyledSecondaryTypography sx={{ mt: 2, textAlign: "center" }}>
        Your account has now been successfully activated!
      </StyledSecondaryTypography>
      <StyledSecondaryTypography sx={{ mb: 2, textAlign: "center" }}>
        Please press the button below to go set up the dashboard for your condo.
      </StyledSecondaryTypography>
      <StyledSecondaryTypography textAlign={"center"}>
        You will have to spend around 10 minutes.
      </StyledSecondaryTypography>
      <Button
        disabled={loadingBtn}
        onClick={() => confirmToSetup()}
        sx={{ mt: 4 }}
        fullWidth
        variant="contained"
      >
        {loadingBtn ? <CircularProgress size={25} /> : "Set up Loby System"}
      </Button>
    </>
  );
};

export default SuccessPage;
