import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../Icons/MoreInfo.svg";
import { ReactComponent as CommonAreaIcon } from "../../../Icons/CommonArea.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as Arrow } from "../../../Icons/AccordionArrow.svg";
import { ReactComponent as ManagementIcon } from "../../../Icons/ManagementIcon.svg";
import { ReactComponent as OperationIcon } from "../../../Icons/Operation.svg";
import { ReactComponent as GroundStaffIcon } from "../../../Icons/GroundStaff.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as MoreInfoWOBIcon } from "../../../Icons/MoreInfoWOB.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SystemDetailsModal from "../../../CommonComponents/SystemDetailsModal";
import {
  addUserAndRole,
  setNestedStep,
  getUserAndRole,
  editUserAndRole,
  deleteUserAndRole,
  setPropertySliceToNull,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import AlertModal from "../../../CommonComponents/modal";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const AddUserAndRole = ({
  managementdata,
  setmanagementdata,
  operationdata,
  setoperationdata,
  groundstaffdata,
  setgroundstaffdata,
  managementdefaultdata,
  operationdefaultdata,
  groundstaffdefaultdata,
  setDisplayScreen,
  commonmodal,
  setDataLength,
  DataLength,
}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [add, setadd] = useState({
    management: false,
    operation: false,
    groundstaff: false,
  });
  const [errortext, seterrortext] = useState();
  const [data, setdata] = useState();
  const [contineerr, setcontinueerr] = useState(false);
  const [addrole, setaddrole] = useState({
    management: false,
    operation: false,
    groundstaff: false,
  });
  const [expand, setexpand] = useState(true);
  //get id of edited rights
  const [getId, setgetId] = useState([]);
  const [deletingIndex, setDeletingIndex] = useState();
  const {
    addUserAndRoleStatus,
    deleteUserAndRoleStatus,
    editUserAndRoleStatus,
    propertyId,
    getUserAndRoleStatus,
  } = useSelector((state) => state.PropertyDetails);
  const [editmanagementData, seteditmanagementdata] = useState(managementdata);
  const [editoperationdata, seteditoperationdata] = useState(operationdata);
  const [editgroundstaffdata, seteditgroundstaffdata] =
    useState(groundstaffdata);

  const [editManagementExpand, setEditManagementExpand] = useState([]);
  const [editOperationExpand, setEditOperationExpand] = useState([]);
  const [editGroundStaffExpand, setEditGroundStaffExpand] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);
  const [editedData, seteditedData] = useState({ index: "", state: "" });

  console.log(editedData);

  useEffect(() => {
    console.log(editManagementExpand);
  }, [editManagementExpand]);

  useEffect(() => {
    if (
      addUserAndRoleStatus?.success ||
      editUserAndRoleStatus?.success ||
      deleteUserAndRoleStatus?.success
    ) {
      dispatch(getUserAndRole(propertyId));
      setShowModal(false);
      setaddrole({ management: false, operation: false, groundstaff: false });
      dispatch(setPropertySliceToNull());
      SetPostLoader(false);
    }
    if (getUserAndRoleStatus != null) {
      if (getUserAndRoleStatus?.success) {
        console.log(getUserAndRoleStatus);
        setDataLength({
          managementLth:
            getUserAndRoleStatus?.data?.property_role[0]?.role?.length,
          operationLth:
            getUserAndRoleStatus?.data?.property_role[1].role?.length,
          groundStaffLth:
            getUserAndRoleStatus?.data?.property_role[2].role?.length,
        });

        dispatch(setPropertySliceToNull());
      }
    }
    // if (editUserAndRoleStatus?.success) {
    //     dispatch(getUserAndRole(propertyId))
    //     console.log(editedData);
    //     editedData?.state((prev) => {
    //         const data = prev.map((itm, sind) => {
    //             if (editedData?.index == sind) {
    //                 return { ...itm, expand: false }
    //             }
    //             return itm
    //         })
    //         return data
    //     })
    //     dispatch(setPropertySliceToNull())

    // }

    if (!addUserAndRoleStatus?.success) {
      console.log(addUserAndRoleStatus);
      seterrortext(addUserAndRoleStatus?.data?.validation_error?.title);
    }
  }, [
    addUserAndRoleStatus,
    editUserAndRoleStatus,
    deleteUserAndRoleStatus,
    getUserAndRoleStatus,
  ]);

  useEffect(() => {
    seteditmanagementdata(managementdata);
    seteditoperationdata(operationdata);
    seteditgroundstaffdata(groundstaffdata);

    const editManagementExpands = managementdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));
    const editOperationExpands = operationdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));
    const editGroundStaffExpands = groundstaffdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));

    setEditManagementExpand(editManagementExpands);
    setEditOperationExpand(editOperationExpands);
    setEditGroundStaffExpand(editGroundStaffExpands);
  }, [managementdata, operationdata, groundstaffdata]);

  let modalDatas = {
    label: "Are you sure you wish to delete the role?",
    descriptionOne: "Please confirm that you wish to delete the role.",
    descriptionTwo: "",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Delete Now",
    },
  };

  const commoninputblock = (
    data,
    SaveRole,
    handlechange,
    setaddrole,
    errortext,
    setitem,
    block,
    expand,
    handleexpand
  ) => {
    return (
      <Box
        sx={{
          borderRadius: "12px",
          marginBottom: "20px",
          border: "1px solid #E5E7EB",
        }}
        key={1}
      >
        <Accordion
          expanded={expand}
          onChange={() => {
            handleexpand(block);
          }}
          sx={{ background: "none" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SecondaryTypography>
              {data?.role?.length > 0 ? data?.role : "Create New Role"}
            </SecondaryTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} gap={3}>
              <Grid xs={12}>
                <StyledTypography>Roles*</StyledTypography>
                <TextField
                  placeholder="Please give a role name"
                  name="role"
                  value={data?.role}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{ position: "absolute", right: 0, top: "-24px" }}
                      >
                        {data?.role?.length}/50
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handlechange("role", e);
                  }}
                  fullWidth
                />
                <ErrorTypography>{errortext}</ErrorTypography>
              </Grid>
              <Grid xs={12}>
                <Stack direction="row">
                  <Grid xs={8}>
                    <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                      Functions
                    </TypographyDefault>
                  </Grid>
                  <Grid xs={4}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Stack direction="row" alignItems="center" gap="5px">
                        <TypographyDefault
                          ml={1}
                          sx={{ fontWeight: "600 !important" }}
                        >
                          Access
                        </TypographyDefault>
                        <SystemDetailsModal commonmodal={commonmodal.access} />
                      </Stack>
                      <Stack direction="row" alignItems="center" gap="5px">
                        <TypographyDefault
                          sx={{ fontWeight: "600 !important" }}
                        >
                          Manage{" "}
                        </TypographyDefault>
                        <SystemDetailsModal commonmodal={commonmodal.manage} />
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
              <Grid xs={12}>
                {data?.info?.map((itm, index) => {
                  return (
                    <Stack direction="row" alignItems="center">
                      <Grid xs={8}>
                        <Stack my={2} gap="5px">
                          <TypographyDefault>
                            {itm?.service_name}
                          </TypographyDefault>
                          <StyledSecondaryTypography
                            sx={{ fontSize: "12px !important" }}
                          >
                            {itm?.description}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Grid>

                      <Grid xs={4}>
                        <Stack
                          my={2}
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                          {/* <Checkbox checked={itm?.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => handlechange(index, e, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                    <Checkbox checked={itm?.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => handlechange(index, e, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                          {itm.rights.map((rtsitm, sindex) => {
                            return (
                              <Checkbox
                                checked={rtsitm?.enabled}
                                icon={
                                  <CheckboxIcon
                                    fill={
                                      rtsitm?.editable === true
                                        ? "none"
                                        : "#D2D6DB"
                                    }
                                  />
                                }
                                onChange={(e) =>
                                  handlechange(
                                    index,
                                    e,
                                    sindex,
                                    itm.id,
                                    rtsitm.id
                                  )
                                }
                                name="access"
                                disabled={
                                  rtsitm?.editable === true ? false : true
                                }
                              />
                            );
                          })}
                        </Stack>
                      </Grid>
                    </Stack>
                  );
                })}
              </Grid>
              <Grid xs={12}>
                <Stack direction="row" justifyContent="end" gap={2} my={2}>
                  <Button
                    style={{
                      padding: "10px 18px",
                      borderRadius: "8px",
                      backgroundColor: "#0E5E84",
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      SaveRole(setitem, block, data.d_id);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{
                      width: "85px",
                      height: "41px",
                      color: "#0E5E84",
                      backgroundColor: "#E7EFF3",
                      "&:hover": {
                        backgroundColor: "#CFDFE6",
                      },
                    }}
                    onClick={() => {
                      handleexpand(block);
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const handleexpand = (block) => {
    setaddrole((prev) => {
      return { ...prev, [block]: false };
    });
    seterrortext("");

    if (block === "management" && managementdata.length === 0) {
      setadd((prev) => {
        return { ...prev, management: false };
      });
    } else if (block === "operation" && operationdata.length === 0) {
      setadd((prev) => {
        return { ...prev, operation: false };
      });
    } else if (block === "groundstaff" && groundstaffdata.length === 0) {
      setadd((prev) => {
        return { ...prev, groundstaff: false };
      });
    }
  };

  useEffect(() => {
    if (add.management === true) {
      setdata(managementdefaultdata);
    }
    if (add.operation === true) {
      setdata(operationdefaultdata);
    }
    if (add.groundstaff === true) {
      setdata(groundstaffdefaultdata);
    }
  }, [add]);

  const handlechange = (ind, e, subind, service_id, rights_id) => {
    const { name, checked, value } = e.target;
    if (ind === "role") {
      seterrortext("");
    }
    setdata((prev) => {
      if (ind === "role") {
        return { ...prev, [name]: value };
      } else {
        const item = data.info.map((itm, index) => {
          if (ind === index) {
            const sitm = itm.rights.map((sitm, sindex) => {
              if (subind === sindex) {
                return { ...sitm, enabled: checked };
              }
              return sitm;
            });
            return { ...itm, rights: sitm };
          }
          return itm;
        });
        return { ...prev, info: item };
      }
    });
  };

  const SaveRole = (itm, block, id) => {
    if (data.role.length > 0) {
      SetPostLoader(true);
      itm((prev) => {
        return [...prev, data];
      });
      // setaddrole((prev) => ({ ...prev, [block]: false }))
      seterrortext("");

      var arr = [];
      const Dta = data.info.map((itm) => {
        const rights = itm.rights.map((sitm) => {
          if (sitm.enabled === true) {
            arr.push({ service_id: itm.id, rights_id: sitm.id });
          }
        });
      });

      const adddata = {
        title: data.role,
        description: "jgvu",
        department_id: id,
        access: arr,
      };
      dispatch(addUserAndRole({ propertyId, adddata }));
    } else {
      seterrortext("Error! No Inputs detected");
    }
    setcontinueerr(false);
  };
  // var editarr = []

  const editData = (
    index,
    title,
    e,
    subindex,
    setstate,
    state,
    rightsindex
  ) => {
    const { name, value, checked } = e.target;

    if (name === "title") {
      setstate((prev) => {
        const item = prev.map((itm, ind) => {
          if (index === ind) {
            return { ...itm, [name]: value };
          }
          return itm;
        });
        return item;
      });
      seterrortext("");
    } else {
      const data = state.map((itm, ind) => {
        if (index === ind) {
          const subdata = itm.service.map((sitm, sind) => {
            if (subindex === sind) {
              const ssitm = sitm.rights.map((ssitm, ssind) => {
                if (rightsindex === ssind) {
                  const editedIds = getId.find((itm) => {
                    return (
                      itm.service_id === sitm.id && itm.rights_id === ssitm.id
                    );
                  });
                  if (!Boolean(editedIds)) {
                    setgetId((prev) => [
                      ...prev,
                      { service_id: sitm.id, rights_id: ssitm.id },
                    ]);
                  } else if (Boolean(editedIds)) {
                    const d = getId.filter((itm) => {
                      return !(
                        itm.service_id === sitm.id && itm.rights_id === ssitm.id
                      );
                    });
                    setgetId(d);
                  }
                }
              });
            }
          });
        }
      });

      setstate((prev) => {
        const item = prev.map((itm, ind) => {
          if (index === ind) {
            const subitem = itm.service.map((subitm, subind) => {
              if (subind === subindex) {
                const ssitm = subitm.rights.map((ssitm, ssind) => {
                  if (rightsindex === ssind) {
                    return { ...ssitm, enabled: checked };
                  }
                  return ssitm;
                });
                return { ...subitm, rights: ssitm };
              }
              return subitm;
            });
            return { ...itm, service: subitem };
          }
          return itm;
        });
        return item;
      });
    }
  };

  const editSave = (index, state) => {
    const { title, department_id, id } = state[index];
    if (title.length > 0) {
      const data = {
        id: id,
        body: {
          title: title,
          description: "hgj",
          department_id: department_id,
          access: getId,
        },
      };
      dispatch(editUserAndRole({ propertyId, data }));
    } else {
      seterrortext("Error! No Inputs detected");
    }
  };

  useEffect(() => {
    if (managementdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, management: false };
      });
    }
    if (operationdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, operation: false };
      });
    }
    if (groundstaffdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, groundstaff: false };
      });
    }
  }, [managementdata, operationdata, groundstaffdata]);

  const addCommonblock = (value) => {
    if (value === "management") {
      setadd({ management: true, operation: false, groundstaff: false });
      setaddrole({ management: true, operation: false, groundstaff: false });
    } else if (value === "operation") {
      setadd({ management: false, operation: true, groundstaff: false });
      setaddrole({ management: false, operation: true, groundstaff: false });
    } else if (value === "groundstaff") {
      setadd({ management: false, operation: false, groundstaff: true });
      setaddrole({ management: false, operation: false, groundstaff: true });
    }
  };

  console.log(DataLength?.managementLth);

  const urcontinue = () => {
    if (
      DataLength?.managementLth > 0 &&
      DataLength?.operationLth > 0 &&
      DataLength?.groundStaffLth > 0
    ) {
      setDisplayScreen("propertyInformationForm");
      dispatch(setNestedStep(1));
    } else {
      setcontinueerr(true);
    }
  };
  // const handleShowModal = () => {
  //   setShowModal(!showModal);
  // };
  const Deletesinglerole = () => {
    SetPostLoader(true);
    dispatch(deleteUserAndRole({ propertyId, deletingIndex }));
  };

  const handleEditExpand = (e, expanded, index, state) => {
    state((prev) => {
      const data = prev.map((itm, sind) => {
        if (index == sind) {
          return { ...itm, expand: expanded };
        }
        return itm;
      });
      return data;
    });
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box>
        {showModal && (
          <AlertModal
            modalDatas={modalDatas}
            confirmFunction={Deletesinglerole}
            closeFunction={() => setShowModal(false)}
          />
        )}

        <BackArrow
          onClick={() => setDisplayScreen("DepartmentPermissions")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={4}>
          <PrimaryTypography mb="4px">Users</PrimaryTypography>
          <StyledSecondaryTypography>
            Add, edit and delete staff roles and permissions.
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="30px">
          <Grid xs={12} sx={{ borderRadius: "8px" }}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Management{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {managementdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.management} />
            </Stack>
            <Box
              component="div"
              padding="24px 10px 24px 10px"
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Box
                component="div"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "12px",
                }}
              >
                {!add?.management && !(managementdata?.length > 0) && (
                  <Box
                    gap="30px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #E5E7EB",
                      borderRadius: "12px",
                      padding: "24px 10px 24px 10px",
                    }}
                  >
                    <ManagementIcon />
                    <Box
                      maxWidth="728px"
                      gap="12px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                        Management
                      </TypographyDefault>
                      <TypographyDefault>
                        Management department is the department which represents
                        the highest level of authority within the Loby system,
                        people tagged to management should be employees which
                        requires statistics, have the right to onboard/ offboard
                        all users, they are users who should not need to act on
                        resident feedback or take action, only overall
                        statistical data like monthly reports.
                      </TypographyDefault>
                      <Button
                        variant="outlined"
                        sx={{ border: " 1px solid #D2D6DB" }}
                        onClick={() => addCommonblock("management")}
                      >
                        Add Role
                      </Button>
                    </Box>
                  </Box>
                )}
                {(add?.management || managementdata?.length > 0) && (
                  <Box
                    sx={{
                      borderRadius: "12px",
                      marginBottom: "20px",
                      border: "1px solid #E5E7EB",
                    }}
                  >
                    <Accordion sx={{ background: "none" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <SecondaryTypography>
                          Business Account Owner (Default)
                        </SecondaryTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container xs={12} gap={3}>
                          <Grid xs={12}>
                            <StyledTypography>Roles*</StyledTypography>
                            <TextField
                              disabled
                              value="Business Account Owner (Default)"
                              inputProps={{ maxLength: 30 }}
                              InputProps={{ disableUnderline: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid xs={12}>
                            <Stack direction="row">
                              <Grid xs={8}>
                                <TypographyDefault
                                  sx={{ fontWeight: "600 !important" }}
                                >
                                  Functions
                                </TypographyDefault>
                              </Grid>
                              <Grid xs={4}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap="5px"
                                  >
                                    <TypographyDefault
                                      ml={1}
                                      sx={{ fontWeight: "600 !important" }}
                                    >
                                      Access
                                    </TypographyDefault>
                                    <SystemDetailsModal
                                      commonmodal={commonmodal.access}
                                    />
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap="5px"
                                  >
                                    <TypographyDefault
                                      sx={{ fontWeight: "600 !important" }}
                                    >
                                      Manage{" "}
                                    </TypographyDefault>
                                    <SystemDetailsModal
                                      commonmodal={commonmodal.manage}
                                    />
                                  </Stack>
                                </Stack>
                              </Grid>
                            </Stack>
                          </Grid>
                          <Grid xs={12}>
                            {managementdefaultdata?.info.map((itm, index) => {
                              return (
                                <Stack
                                  key={index}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Grid xs={8}>
                                    <Stack my={2} gap="5px">
                                      <TypographyDefault>
                                        {itm.title}
                                      </TypographyDefault>
                                      <StyledSecondaryTypography
                                        sx={{ fontSize: "12px !important" }}
                                      >
                                        {itm.description}
                                      </StyledSecondaryTypography>
                                    </Stack>
                                  </Grid>
                                  <Grid xs={4}>
                                    <Stack
                                      my={2}
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Checkbox
                                        checked
                                        icon={<CheckboxIcon />}
                                        disabled
                                      />
                                      <Checkbox
                                        checked
                                        icon={<CheckboxIcon />}
                                        disabled
                                      />
                                    </Stack>
                                  </Grid>
                                </Stack>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
                {editmanagementData?.map((itm, index) => {
                  const { id, department_id } = itm;
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderRadius: "12px !important",
                        "&>*": { borderRadius: "12px !important" },
                        mb: 2,
                      }}
                    >
                      <Accordion
                        key={index}
                        defaultExpanded={false}
                        expanded={
                          Boolean(editManagementExpand[index]?.expand)
                            ? editManagementExpand[index]?.expand
                            : false
                        }
                        onChange={(e, expanded) =>
                          handleEditExpand(
                            e,
                            expanded,
                            index,
                            setEditManagementExpand
                          )
                        }
                        sx={{ background: "none" }}
                      >
                        <AccordionSummary
                          onClick={() => {
                            setgetId([]);
                            seteditmanagementdata(managementdata);
                            seterrortext("");
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>{itm.title}</SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <StyledTypography>Roles*</StyledTypography>
                              <TextField
                                name="title"
                                value={itm?.title}
                                onChange={(e) => {
                                  editData(
                                    index,
                                    "role",
                                    e,
                                    "",
                                    seteditmanagementdata,
                                    "",
                                    ""
                                  );
                                }}
                                inputProps={{ maxLength: 30 }}
                                InputProps={{
                                  disableUnderline: true,
                                  endAdornment: (
                                    <InputAdornment
                                      sx={{
                                        position: "absolute",
                                        right: 0,
                                        top: "-15px",
                                      }}
                                    >
                                      {itm?.title?.length}/50
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                              />
                            </Grid>
                            <ErrorTypography>{errortext}</ErrorTypography>
                            <Grid xs={12}>
                              <Stack direction="row">
                                <Grid xs={8}>
                                  <TypographyDefault
                                    sx={{ fontWeight: "600 !important" }}
                                  >
                                    Functions
                                  </TypographyDefault>
                                </Grid>
                                <Grid xs={4}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        ml={1}
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Access
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.access}
                                      />
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Manage{" "}
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.manage}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid xs={12}>
                              {itm?.service?.map((sitm, subindex) => {
                                return (
                                  <Stack
                                    key={subindex}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid xs={8}>
                                      <Stack my={2} gap="5px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                        <StyledSecondaryTypography
                                          sx={{ fontSize: "12px !important" }}
                                        >
                                          {sitm.description}
                                        </StyledSecondaryTypography>
                                      </Stack>
                                    </Grid>

                                    <Grid xs={4}>
                                      <Stack
                                        my={2}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                      >
                                        {sitm.rights.map((rtsitm, ssindex) => {
                                          return (
                                            <Checkbox
                                              checked={rtsitm?.enabled}
                                              icon={
                                                <CheckboxIcon
                                                  fill={
                                                    rtsitm?.editable === true
                                                      ? "none"
                                                      : "#D2D6DB"
                                                  }
                                                />
                                              }
                                              onChange={(e) =>
                                                editData(
                                                  index,
                                                  sitm.title,
                                                  e,
                                                  subindex,
                                                  seteditmanagementdata,
                                                  editmanagementData,
                                                  ssindex
                                                )
                                              }
                                              name="access"
                                              disabled={
                                                rtsitm?.editable === true
                                                  ? false
                                                  : true
                                              }
                                            />
                                          );
                                        })}
                                        {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, sitm.title, e, subindex, setmanagementdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                              <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, sitm.title, e, subindex, setmanagementdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                );
                              })}
                            </Grid>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                gap={2}
                                my={2}
                              >
                                <Button
                                  style={{
                                    padding: "10px 18px",
                                    borderRadius: "8px",
                                    backgroundColor: "#0E5E84",
                                    color: "#FFF",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    editSave(
                                      index,
                                      editmanagementData,
                                      editManagementExpand
                                    );
                                    seteditedData({
                                      index: index,
                                      state: setEditManagementExpand,
                                    });
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  sx={{
                                    width: "85px",
                                    height: "41px",
                                    color: "#0E5E84",
                                    backgroundColor: "#E7EFF3",
                                    "&:hover": {
                                      backgroundColor: "#CFDFE6",
                                    },
                                  }}
                                  onClick={() => {
                                    setDeletingIndex(id);
                                    setShowModal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
                {addrole?.management &&
                  commoninputblock(
                    data,
                    SaveRole,
                    handlechange,
                    setaddrole,
                    errortext,
                    setmanagementdata,
                    "management",
                    expand,
                    handleexpand
                  )}
                {managementdata?.length > 0 && !addrole.management && (
                  <Stack direction="row" justifyContent="center">
                    <Button
                      sx={{
                        border: "1px solid #D2D6DB",
                        width: "fit-content",
                        color: "#0E5E84",
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#F3F4F6",
                        },
                      }}
                      onClick={() => {
                        setaddrole({
                          management: true,
                          operation: false,
                          groundstaff: false,
                        });
                        setadd({
                          management: true,
                          operation: false,
                          groundstaff: false,
                        });
                      }}
                    >
                      Add More Role
                    </Button>
                  </Stack>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Operations{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {operationdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.operations} />
            </Stack>
            <Box
              component="div"
              padding="24px 10px 24px 10px"
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px ",
              }}
            >
              <Box
                component="div"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "12px",
                }}
              >
                {!add?.operation && !(operationdata?.length > 0) && (
                  <Box
                    gap="30px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #E5E7EB",
                      borderRadius: "12px",
                      padding: "24px 10px 24px 10px",
                    }}
                  >
                    <OperationIcon />
                    <Box
                      maxWidth="728px"
                      gap="12px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                        Operations
                      </TypographyDefault>
                      <TypographyDefault>
                        Operations department represents the operations team
                        users who require to filter and act on tasks, and assign
                        them to further employees. Examples would include
                        juristics adminstrators who will recieve feedback and
                        calls from tenants, and assign employees like
                        technicians or cleaners to act on it.
                      </TypographyDefault>
                      <Button
                        variant="outlined"
                        sx={{ border: " 1px solid #D2D6DB" }}
                        onClick={() => addCommonblock("operation")}
                      >
                        Add Role
                      </Button>
                    </Box>
                  </Box>
                )}
                {editoperationdata?.map((itm, index) => {
                  const { id, department_id } = itm;
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderRadius: "12px !important",
                        "&>*": { borderRadius: "12px !important" },
                        mb: 2,
                      }}
                    >
                      <Accordion
                        key={index}
                        defaultExpanded={false}
                        expanded={
                          Boolean(editOperationExpand[index]?.expand)
                            ? editOperationExpand[index]?.expand
                            : false
                        }
                        onChange={(e, expanded) =>
                          handleEditExpand(
                            e,
                            expanded,
                            index,
                            setEditOperationExpand
                          )
                        }
                        sx={{ background: "none" }}
                      >
                        <AccordionSummary
                          onClick={() => {
                            setgetId([]);
                            seteditoperationdata(operationdata);
                            seterrortext("");
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>
                            {itm?.title}
                          </SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <StyledTypography>Roles*</StyledTypography>
                              <TextField
                                name="title"
                                value={itm?.title}
                                onChange={(e) => {
                                  editData(
                                    index,
                                    "role",
                                    e,
                                    "",
                                    seteditoperationdata
                                  );
                                }}
                                inputProps={{ maxLength: 30 }}
                                InputProps={{
                                  disableUnderline: true,
                                  endAdornment: (
                                    <InputAdornment
                                      sx={{
                                        position: "absolute",
                                        right: 0,
                                        top: "-15px",
                                      }}
                                    >
                                      {itm?.title?.length}/50
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                              />
                              <ErrorTypography>{errortext}</ErrorTypography>
                            </Grid>
                            <Grid xs={12}>
                              <Stack direction="row">
                                <Grid xs={8}>
                                  <TypographyDefault
                                    sx={{ fontWeight: "600 !important" }}
                                  >
                                    Functions
                                  </TypographyDefault>
                                </Grid>
                                <Grid xs={4}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        ml={1}
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Access
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.access}
                                      />
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Manage{" "}
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.manage}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid xs={12}>
                              {itm?.service?.map((sitm, subindex) => {
                                return (
                                  <Stack
                                    key={subindex}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid xs={8}>
                                      <Stack my={2} gap="5px">
                                        <TypographyDefault>
                                          {sitm?.service_name}
                                        </TypographyDefault>
                                        <StyledSecondaryTypography
                                          sx={{ fontSize: "12px !important" }}
                                        >
                                          {sitm?.description}
                                        </StyledSecondaryTypography>
                                      </Stack>
                                    </Grid>
                                    <Grid xs={4}>
                                      <Stack
                                        my={2}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                      >
                                        {sitm.rights.map((rtsitm, ssindex) => {
                                          return (
                                            <Checkbox
                                              checked={rtsitm?.enabled}
                                              icon={
                                                <CheckboxIcon
                                                  fill={
                                                    rtsitm?.editable === true
                                                      ? "none"
                                                      : "#D2D6DB"
                                                  }
                                                />
                                              }
                                              onChange={(e) =>
                                                editData(
                                                  index,
                                                  sitm.title,
                                                  e,
                                                  subindex,
                                                  seteditoperationdata,
                                                  editoperationdata,
                                                  ssindex
                                                )
                                              }
                                              name="access"
                                              disabled={
                                                rtsitm?.editable === true
                                                  ? false
                                                  : true
                                              }
                                            />
                                          );
                                        })}
                                        {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setoperationdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                              <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setoperationdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                );
                              })}
                            </Grid>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                gap={2}
                                my={2}
                              >
                                <Button
                                  style={{
                                    padding: "10px 18px",
                                    borderRadius: "8px",
                                    backgroundColor: "#0E5E84",
                                    color: "#FFF",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    editSave(index, editoperationdata);
                                    seteditedData({
                                      index: index,
                                      state: setEditOperationExpand,
                                    });
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  sx={{
                                    width: "85px",
                                    height: "41px",
                                    color: "#0E5E84",
                                    backgroundColor: "#E7EFF3",
                                    "&:hover": {
                                      backgroundColor: "#CFDFE6",
                                    },
                                  }}
                                  onClick={() => {
                                    setDeletingIndex(id);
                                    setShowModal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
                {addrole?.operation &&
                  commoninputblock(
                    data,
                    SaveRole,
                    handlechange,
                    setaddrole,
                    errortext,
                    setoperationdata,
                    "operation",
                    expand,
                    handleexpand
                  )}
                {operationdata?.length > 0 && !addrole.operation && (
                  <Stack direction="row" justifyContent="center">
                    <Button
                      sx={{
                        border: "1px solid #D2D6DB",
                        width: "fit-content",
                        color: "#0E5E84",
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#F3F4F6",
                        },
                      }}
                      onClick={() => {
                        setaddrole({
                          management: false,
                          operation: true,
                          groundstaff: false,
                        });
                        setadd({
                          management: false,
                          operation: true,
                          groundstaff: false,
                        });
                      }}
                    >
                      Add More Role
                    </Button>
                  </Stack>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Ground Staff{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {groundstaffdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.groundstaff} />
            </Stack>
            <Box
              component="div"
              padding="24px 10px 24px 10px"
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Box
                component="div"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "12px",
                }}
              >
                {!add?.groundstaff && !(groundstaffdata?.length > 0) && (
                  <Box
                    gap="30px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #E5E7EB",
                      borderRadius: "12px",
                      padding: "24px 10px 24px 10px",
                    }}
                  >
                    <GroundStaffIcon />
                    <Box
                      maxWidth="728px"
                      gap="12px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                        Ground Staff
                      </TypographyDefault>
                      <TypographyDefault>
                        Ground Staff represents the team or users who like act
                        on assigned tasks. This will include cleaning
                        department, fixing and plumbing department and can
                        include both supervisors and junior staff of cleaners,
                        technicians, security who are in charge of acting upon
                        feedback and ensuring residents safety & comfort.
                      </TypographyDefault>
                      <Button
                        variant="outlined"
                        sx={{ border: " 1px solid #D2D6DB" }}
                        onClick={() => addCommonblock("groundstaff")}
                      >
                        Add Role
                      </Button>
                    </Box>
                  </Box>
                )}

                {editgroundstaffdata?.map((itm, index) => {
                  const { id, department_id } = itm;
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderRadius: "12px !important",
                        "&>*": { borderRadius: "12px !important" },
                        mb: 2,
                      }}
                    >
                      <Accordion
                        key={index}
                        defaultExpanded={false}
                        expanded={
                          Boolean(editGroundStaffExpand[index]?.expand)
                            ? editGroundStaffExpand[index]?.expand
                            : false
                        }
                        onChange={(e, expanded) =>
                          handleEditExpand(
                            e,
                            expanded,
                            index,
                            setEditGroundStaffExpand
                          )
                        }
                        sx={{ background: "none" }}
                      >
                        <AccordionSummary
                          onClick={() => {
                            setgetId([]);
                            seteditgroundstaffdata(groundstaffdata);
                            seterrortext("");
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>
                            {itm?.title}
                          </SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <StyledTypography>Roles*</StyledTypography>
                              <TextField
                                name="title"
                                value={itm?.title}
                                onChange={(e) => {
                                  editData(
                                    index,
                                    "role",
                                    e,
                                    "",
                                    seteditgroundstaffdata,
                                    editgroundstaffdata,
                                    ""
                                  );
                                }}
                                inputProps={{ maxLength: 30 }}
                                InputProps={{
                                  disableUnderline: true,
                                  endAdornment: (
                                    <InputAdornment
                                      sx={{
                                        position: "absolute",
                                        right: 0,
                                        top: "-15px",
                                      }}
                                    >
                                      {itm?.title?.length}/50
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                              />
                              <ErrorTypography>{errortext}</ErrorTypography>
                            </Grid>
                            <Grid xs={12}>
                              <Stack direction="row">
                                <Grid xs={8}>
                                  <TypographyDefault
                                    sx={{ fontWeight: "600 !important" }}
                                  >
                                    Functions
                                  </TypographyDefault>
                                </Grid>
                                <Grid xs={4}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        ml={1}
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Access
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.access}
                                      />
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        Manage{" "}
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.manage}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid xs={12}>
                              {itm?.service?.map((sitm, subindex) => {
                                return (
                                  <Stack
                                    key={subindex}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid xs={8}>
                                      <Stack my={2} gap="5px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                        <StyledSecondaryTypography
                                          sx={{ fontSize: "12px !important" }}
                                        >
                                          {sitm.description}
                                        </StyledSecondaryTypography>
                                      </Stack>
                                    </Grid>
                                    <Grid xs={4}>
                                      <Stack
                                        my={2}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                      >
                                        {sitm.rights.map((rtsitm, ssindex) => {
                                          return (
                                            <Checkbox
                                              checked={
                                                rtsitm?.enabled === true
                                                  ? true
                                                  : false
                                              }
                                              icon={
                                                <CheckboxIcon
                                                  fill={
                                                    rtsitm?.editable === true
                                                      ? "none"
                                                      : "#D2D6DB"
                                                  }
                                                />
                                              }
                                              onChange={(e) =>
                                                editData(
                                                  index,
                                                  sitm.title,
                                                  e,
                                                  subindex,
                                                  seteditgroundstaffdata,
                                                  editgroundstaffdata,
                                                  ssindex
                                                )
                                              }
                                              name="access"
                                              disabled={
                                                rtsitm?.editable === true
                                                  ? false
                                                  : true
                                              }
                                            />
                                          );
                                        })}
                                        {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setgroundstaffdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                              <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setgroundstaffdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                );
                              })}
                            </Grid>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                gap={2}
                                my={2}
                              >
                                <Button
                                  style={{
                                    padding: "10px 18px",
                                    borderRadius: "8px",
                                    backgroundColor: "#0E5E84",
                                    color: "#FFF",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    editSave(index, editgroundstaffdata);
                                    seteditedData({
                                      index: index,
                                      state: setEditGroundStaffExpand,
                                    });
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  sx={{
                                    width: "85px",
                                    height: "41px",
                                    color: "#0E5E84",
                                    backgroundColor: "#E7EFF3",
                                    "&:hover": {
                                      backgroundColor: "#CFDFE6",
                                    },
                                  }}
                                  onClick={() => {
                                    setDeletingIndex(id);
                                    setShowModal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}

                {addrole?.groundstaff &&
                  commoninputblock(
                    data,
                    SaveRole,
                    handlechange,
                    setaddrole,
                    errortext,
                    setgroundstaffdata,
                    "groundstaff",
                    expand,
                    handleexpand
                  )}

                {groundstaffdata?.length > 0 && !addrole.groundstaff && (
                  <Stack direction="row" justifyContent="center">
                    <Button
                      sx={{
                        border: "1px solid #D2D6DB",
                        width: "fit-content",
                        color: "#0E5E84",
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#F3F4F6",
                        },
                      }}
                      onClick={() => {
                        setaddrole({
                          management: false,
                          operation: false,
                          groundstaff: true,
                        });
                        setadd({
                          management: false,
                          operation: false,
                          groundstaff: true,
                        });
                      }}
                    >
                      Add More Role
                    </Button>
                  </Stack>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box bgcolor="#FFFFFF" sx={{ borderRadius: "8px" }}>
              <Stack
                direction="row"
                py={2}
                px={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row">
                  <RefreshIcon style={{ marginRight: "5px" }} />
                  <StyledSecondaryTypography>
                    Last Updated at {moment(new Date()).format("HH:mm A")}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack direction="column" justifyContent="end" alignItems="end">
                  <Button
                    sx={{
                      width: "fit-content",
                      backgroundColor: "#E7EFF3",
                      "&:hover": {
                        backgroundColor: "#CFDFE6",
                      },
                    }}
                    onClick={urcontinue}
                  >
                    Continue
                  </Button>
                  {contineerr && (
                    <ErrorTypography>
                      Min 1 role should be added in every department
                    </ErrorTypography>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddUserAndRole;
