import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PrimaryTypography,
  StyledTableRow,
  StyledTableCell,
  TypographyDefault,
  StyledSecondaryDefaultTypography,
} from "../../../Theme";
import { getChip } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Redux/AxiosInstance";
import { ReactComponent as MoreIcon } from "../../../Icons/ThreeDot.svg";
import { useSelector } from "react-redux";
import AlertModal from "../../../CommonComponents/modal";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { PostLoaderModal } from "../../../CommonComponents/modal";
import { showToast } from "../../../CommonComponents/Toaster";

const DeviceControl = () => {
  const navigate = useNavigate();
  const { UserManage } = useSelector((state) => state.Login);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [deviceData, setDeviceData] = useState([]);
  const DevcieTableHeader = [
    "Device ID",
    "Device Name",
    "Area",
    "Access",
    "Status",
  ];
  const [moreOption, setMoreOption] = useState(null);
  const moreOptionOpen = Boolean(moreOption);
  const [id, setId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);
  const [Tableloading, setTableloading] = useState(true);

  let deleteData = {
    label: "Confirm Delete Device ",
    descriptionOne: "Please confirm that you wish to delete the device.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  const handleNavigate = () => {
    navigate("create-device");
  };

  const handleClose = () => {
    setMoreOption(null);
    setDeleteModal(false);
  };

  const handleMoreOption = (e, id) => {
    setId(id);
    setMoreOption(e.currentTarget);
  };
  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}property/${propertyId}/device`)
      .then((res) => {
        setDeviceData(res.data?.data?.device_configuration);
        setTableloading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error == "No data found.") {
          setDeviceData([]);
          setTableloading(false);
        } else {
          setTableloading(true);
        }
      });
  }, [trigger]);

  const handleEdit = () => {
    if (id) {
      navigate(`edit-device/${id}`);
      setMoreOption(null);
    }
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    SetPostLoader(true);
    axiosInstance
      .delete(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/device/${id}`
      )
      .then((res) => {
        if (res.data?.success) {
          setDeleteModal(false);
          setTrigger(!trigger);
          setMoreOption(null);
          SetPostLoader(false);
          showToast(res.data?.message, "delete");
        }
      })
      .catch(() => {
        SetPostLoader(false);
        setDeleteModal(false);
        setMoreOption(null);
        showToast("Something went wrong. Please try again later", "error");
      });
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <PrimaryTypography>{LanguageData?.Device_List || "Device List"}</PrimaryTypography>
        {UserManage?.settings && (
          <Button   
          style={{
            // height:'40px',
            padding:"10px 16px",
            borderRadius :"8px",
            backgroundColor:'#0E5E84',
            color:'#FFF',
            fontSize:'14px',
            fontWeight:600
          }} onClick={handleNavigate}>
            {LanguageData?.Add_Device || "Add"}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          border: "1px solid #E5E7EB",
          borderRadius: "6px",
          backgroundColor: "#F9FAFB",
        }}
      >
        <TableContainer sx={{ borderRadius: "6px" }}>
          <Table>
            {deviceData.length > 0 && (
              <TableHead>
                <StyledTableRow>
                  {DevcieTableHeader.map((value, index) => (
                    <StyledTableCell key={index}>{value}</StyledTableCell>
                  ))}
                  {UserManage?.settings && <StyledTableCell></StyledTableCell>}
                </StyledTableRow>
              </TableHead>
            )}
            <TableBody>
              {deviceData.length > 0 ? (
                deviceData.map((request) => (
                  <StyledTableRow key={request?.id}>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.uuid || "--"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.device_name || "--"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.location_name || "--"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.access === 1
                          ? "In"
                          : request?.access === 2
                          ? "Out"
                          : "In & Out" || "--"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.status_id === 1
                          ? getChip("active")
                          : request?.status_id === 2
                          ? getChip("inactive")
                          : "-"}
                      </TypographyDefault>
                    </StyledTableCell>
                    {UserManage?.settings && (
                      <StyledTableCell>
                        <MoreIcon
                          onClick={(e) => handleMoreOption(e, request?.id)}
                        />
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#F9FAFB",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "50vh",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {Tableloading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <ExclamatoryIcon />
                      <Typography
                        sx={{
                          color: "#9DA4AE",
                          fontSize: "16px",
                          fontWeight: 600,
                          my: 2,
                        }}
                      >
                        {LanguageData?.No_Device_Here || "No Device Here"}
                      </Typography>
                      <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                        {LanguageData?.sub_head ||
                          "There are no device in the system yet."}{" "}
                      </StyledSecondaryDefaultTypography>
                      <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                        {LanguageData?.Sub_footer ||
                          "If you are authorised, you can add new device by clicking the top right add device button."}
                      </StyledSecondaryDefaultTypography>
                      {/* <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                        can add new device by clicking the top right add device
                        button.
                      </StyledSecondaryDefaultTypography> */}
                    </>
                  )}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Menu
        sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
        open={moreOptionOpen}
        anchorEl={moreOption}
        onClose={() => setMoreOption(null)}
      >
        <MenuItem onClick={handleEdit}>
          {" "}
          {LanguageData?.Common_Edit || "Edit"}{" "}
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          {" "}
          {LanguageData?.Delete || "Delete"}{" "}
        </MenuItem>
      </Menu>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={handleConfirmDelete}
          closeFunction={handleClose}
        />
      )}
    </>
  );
};

export default DeviceControl;
