import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";

const VisitorGraph = () => {
  const { VisitorData, OverviewStatus } = useSelector(
    (state) => state.OverView
  );
  console.log(OverviewStatus);
  const VisitorSeries = [
    {
      name: "Visitor",
      data: [
        VisitorData?.jan?.visitor_count || 0,
        VisitorData?.feb?.visitor_count || 0,
        VisitorData?.mar?.visitor_count || 0,
        VisitorData?.apr?.visitor_count || 0,
        VisitorData?.may?.visitor_count || 0,
        VisitorData?.jun?.visitor_count || 0,
        VisitorData?.jul?.visitor_count || 0,
        VisitorData?.aug?.visitor_count || 0,
        VisitorData?.sep?.visitor_count || 0,
        VisitorData?.oct?.visitor_count || 0,
        VisitorData?.nov?.visitor_count || 0,
        VisitorData?.dec?.visitor_count || 0,
      ],
    },
  ];
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
    },
    colors: ["#1687BD", "#FF7C32", "#9A66FF"],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 8,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#262D33",
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const totalVisitors = series[0][dataPointIndex];
        const month = w.globals.labels[dataPointIndex];

        return `
          <div class="apexcharts-tooltip-custom">
            <div class="total-count-tip">
              <div>
              Visitors
              </div> 
              <div>
                ${totalVisitors} 
              </div>
            </div>
            <div class="month-change-top">${month}${" "}${2023}</div>
          </div>
        `;
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
  };
  return (
    <>
      {VisitorData.total_count ? (
        <Chart
          options={options}
          series={VisitorSeries}
          type="bar"
          height={400}
        />
      ) : (
        <Box
          sx={{
            borderRadius: "0px, 0px, 8px, 8px",
            padding: "16px",
            backgroundColor: "#F9FAFB",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          {OverviewStatus ? (
            <CircularProgress />
          ) : (
            <>
              {" "}
              <ExclamatoryIcon
                style={{ marginBottom: "5px", marginTop: "60px" }}
              />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "470px",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "100px",
                }}
                style={{ color: "#4D5761" }}
              >
                No statistics available for display
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default VisitorGraph;
