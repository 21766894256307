import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { ReactComponent as DeleteNBIcon } from "../../../Icons/DeleteNB.svg";
import { ReactComponent as AlertCloseIcon } from "../../../Icons/AlertClose.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as BorderAlertCloseIcon } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as XlsIcon } from "../../../Icons/Xls.svg";
import { StyledTableCell, StyledTableRow, PrimaryTypography, SecondaryTypography, StyledSecondaryTypography, StyledTypography, TypographyDefault } from "../../../Theme";
import { LinearProgress, Stack, Box, Button, Grid, TableBody, TableHead, Table, TableContainer, TextField, Typography } from "@mui/material";
import AlertModal from "../../../CommonComponents/modal";

let deleteModalDatas = {
    label: 'Delete Onboarding',
    descriptionOne: 'Please confirm that you wish to delete onboarding staff. ',
    buttonOne: {
        variant: 'outlined',
        color: '#0E5E84',
        backgroundColor: '#FFFFFF',
        text: 'Cancel'
    },
    buttonTwo: {
        variant: 'contained',
        color: '#FFFFFF',
        backgroundColor: '#B42318',
        text: 'Delete Now'
    },
}
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{}}>
                <Typography variant="body2" color="#344054">{`${props.value}%`}</Typography>
            </Box>
        </Box>
    );
}

const AddBatchStaff = () => {

    const navigate = useNavigate()
    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [addblocks, setaddblocks] = useState({ Select_Employee_Size: true, Review_Submit: false })
    const [selectemp, setselectemp] = useState({ generate_file: true, upload_file: false })
    const [number, setnumber] = useState('')
    const [selectedfile, setselectedfile] = useState(null)
    const [uploadPercentage, setuploadPercentage] = useState(0);
    const [gen, setgen] = useState(false)
    const xlsinp = useRef()
    const num = Boolean(number.length >= 1)

    useEffect(() => {
        if (!num) {
            setgen(false)
        }
    }, [number])

    const UploadedData = [{
        name: 'Farhandor Eron Burik Easa',
        email: 'gehgfg@jhgv.com',
        department: 'Ground Staff',
        jobrole: "cleaner"
    }]

    const Continue = (val) => {
        if (gen) {
            switch (val) {
                case 'upload file':
                    setselectemp({ generate_file: false, upload_file: true })
                    break;
                case 'Select Employee Size':
                    setaddblocks({ Select_Employee_Size: true, Review_Submit: false })
                    break;
                case 'Review & Submit':
                    setaddblocks({ Select_Employee_Size: false, Review_Submit: true })
                    break;
                default:
                    break;
            }
        }
    }

    const deleteContactDetail = () => {
    }
    const handleShowDeleteModal = () => {
        setshowDeleteModal(!showDeleteModal)
    }
    const handlechange = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        if (file) {
            setselectedfile(file)
            let uploadedSize = 0;
            const interval = setInterval(() => {
                uploadedSize += 10
                setuploadPercentage(uploadedSize);
                if (uploadedSize >= 100) {
                    clearInterval(interval);
                }
            }, 500);
        }
        // if (file) {
        //     read(file)
        // }
    }

    // const read = () => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         const data = e.target.result;
    //         const workbook = XLSX.read(data, { type: 'binary' });
    //         const sheetName = workbook.SheetNames[0]; 
    //         const sheet = workbook.Sheets[sheetName];
    //         const jsonData = XLSX.utils.sheet_to_json(sheet);
    //         console.log(jsonData);
    //     };
    //     reader.readAsBinaryString(file)
    // }

    const handleDragOver = (e) => {
        e.preventDefault()
    }
    const handledrop = (e) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0];
        const filename = file.name.toLowerCase();
        if (filename.includes('.xlsx')) {

        }
    }

    const blocks = [{ status: addblocks.Select_Employee_Size, tle: 'Select Employee Size' }, { status: addblocks.Review_Submit, tle: 'Review & Submit' }]

    const selectemployee = () => {

        return (
            <>{selectemp.generate_file &&
                <Box mt={3} component='div' boxShadow='0 0 3px 0 rgba(0 ,0 ,0 ,0.1)' borderRadius='8px 8px 8px 8px ' border='1px solid #E5E7EB' >
                    <Stack direction='row' alignItems='center' gap='10px' px='16px' sx={{ height: '66px', backgroundColor: '#FCFCFD', borderBottom: '1px solid #E5E7EB', borderRadius: '8px 8px 0 0 ' }}>
                        <ContactDetailsIcon />
                        <SecondaryTypography>Generate File</SecondaryTypography>
                    </Stack>
                    <Grid container xs={12} gap='2.8%' sx={{ backgroundColor: 'white', padding: '30px 16px 30px 16px' }}>
                        {gen && <Grid xs={12} >
                            <TypographyDefault sx={{ fontWeight: '600 !important' }}>Download Template</TypographyDefault>
                            <TypographyDefault>Generated template for 100 persons ready for download. Should number of persons be incorrect,
                                key in number of persons to onboard and re-generate template again. </TypographyDefault>
                            <Stack my='20px' direction='row' alignItems='center' sx={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: "12px" }}>
                                <Stack direction='row' flex={1}>
                                    <XlsIcon />
                                    <Stack mx='12px'>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#344054' }}>Template.xls</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#475467' }}>16 MB</Typography>
                                    </Stack>
                                </Stack>
                                <Stack><DownloadIcon /></Stack>
                            </Stack>
                        </Grid>}
                        <Grid xs={12} sm={5.8}>
                            <StyledTypography>Number of person to onboard </StyledTypography>
                            <TextField
                                type='number'
                                name="number"
                                value={number}
                                onChange={(e) => setnumber(e.target.value)}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ disableUnderline: true }}
                                fullWidth
                            />
                            <StyledSecondaryTypography my={1} >100 Maximum</StyledSecondaryTypography>
                        </Grid>
                        <Grid xs={12} sm={5.8} display='flex' flexDirection='column' justifyContent='center'>
                            <Button onClick={() => setgen(true)} variant={num && 'contained'} sx={{ height: '44px', width: 'fit-Content', backgroundColor: !num && '#E5E7EB ', color: !num && 'white !important' }} disabled={!num} >{gen ? 'Re-Generate' : 'Generate'}</Button>
                        </Grid>
                    </Grid>
                    <Box bgcolor="#FFFFFF" sx={{ borderTop: ' 1px solid #F3F4F6', borderRadius: '0px 0px 8px 8px' }}>
                        <Stack direction="row" py={2} px={2} justifyContent="space-between" alignItems="center" height='60px'>
                            <Stack direction='row'>
                                <RefreshIcon style={{ marginRight: '5px' }} />
                                <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
                            </Stack>
                            <Stack direction="column" justifyContent='end' alignItems='end'>
                                <Button type='button' onClick={() => Continue('upload file')} sx={{ fontWeight: '600', width: "100px", color: '#0E5E84', backgroundColor: "#E7EFF3" }} >Continue</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>}
                {selectemp.upload_file &&
                    <Box mt={3} component='div' boxShadow='0 0 3px 0 rgba(0 ,0 ,0 ,0.1)' borderRadius='8px 8px 8px 8px ' border='1px solid #E5E7EB' >
                        <Stack direction='row' alignItems='center' gap='10px' px='16px' sx={{ height: '66px', backgroundColor: '#FCFCFD', borderBottom: '1px solid #E5E7EB', borderRadius: '8px 8px 0 0 ' }}>
                            <ContactDetailsIcon />
                            <SecondaryTypography>Upload File</SecondaryTypography>
                        </Stack>
                        {Boolean(!selectedfile) &&
                            <Grid container xs={12} gap='2.8%' sx={{ backgroundColor: 'white', padding: '16px' }}>
                                <Grid xs={12} >
                                    <Stack my='20px' direction='row' justifyContent='center' alignItems='center' sx={{ padding: '30px', border: '1px solid #EAECF0', borderRadius: "12px" }} onDrop={handledrop} onDragOver={handleDragOver}>
                                        <Stack flex={1} direction='column' alignItems='center'>
                                            <UploadIcon />
                                            <input type='file' accept='xls,.xlsx' onChange={handlechange} ref={xlsinp} hidden />
                                            <Stack my='5px' direction='row'><Typography onClick={() => { xlsinp.current.click() }} marginRight='5px' sx={{ fontSize: '14px !important', fontWeight: '600 !important', color: '#0E4965 !important' }}>Click to upload</Typography><StyledSecondaryTypography sx={{ color: '#4D5761' }}>or drag and drop</StyledSecondaryTypography></Stack>
                                            <StyledSecondaryTypography sx={{ color: '#4D5761' }}>CSV, EXL (max. 25 megabytes)</StyledSecondaryTypography>
                                        </Stack>
                                        <XlsIcon />
                                    </Stack>
                                </Grid>
                            </Grid>}
                        {Boolean(selectedfile) &&
                            <Grid container xs={12} gap='2.8%' sx={{ backgroundColor: 'white', padding: '16px' }}>
                                <Grid xs={12} >
                                    <Stack my='20px' direction='row' alignItems='center' sx={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: "12px" }}>
                                        <Stack direction='row' width='100%'>
                                            <XlsIcon />
                                            <Stack direction='column' flex={1}>
                                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                    <Stack mx='12px'>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#344054' }}>Template.xls</Typography>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#475467' }}>16 MB</Typography>
                                                    </Stack>
                                                    {(uploadPercentage === 100) ? <AlertCloseIcon /> : <DeleteNBIcon />}
                                                </Stack>
                                                {!(uploadPercentage === 100) &&
                                                    <Box sx={{ width: '100%' }}>
                                                        <LinearProgressWithLabel sx={{ height: '8px', borderRadius: '8px' }} value={uploadPercentage} />
                                                    </Box>}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                        <Box bgcolor="#FFFFFF" sx={{ borderTop: ' 1px solid #F3F4F6', borderRadius: '0px 0px 8px 8px' }}>
                            <Stack direction="row" py={2} px={2} justifyContent="space-between" alignItems="center" height='60px'>
                                <Stack direction='row'>
                                    <RefreshIcon style={{ marginRight: '5px' }} />
                                    <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
                                </Stack>
                                <Stack direction="column" justifyContent='end' alignItems='end'>
                                    <Button type='button' onClick={() => Continue('Review & Submit')} sx={{ fontWeight: '600', width: "100px", color: '#0E5E84', backgroundColor: "#E7EFF3" }}  >Continue</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                }
            </>
        )
    }

    const review = () => {
        return (
            <>
                <Box my={4} minHeight='100px' padding='20px 16px' backgroundColor='#FFFAEB' border='1px solid #FEE6A1'>
                    <StyledTypography mb='10px' sx={{ color: '#B54708 !important' }}>Onboarding Alert</StyledTypography>
                    <TypographyDefault sx={{ color: '#B54708 !important' }}>Please ensure that all data provided are accurate to prevent any delays in user access. Once tenant is onboarded, they will receive an onboarding email to download and install the LOBY App. Tenants will be able to use the app once the selected access start date has been reached.</TypographyDefault>
                </Box>
                <Box mt={3} component='div' boxShadow='0 0 3px 0 rgba(0 ,0 ,0 ,0.1)' borderRadius='8px 8px 8px 8px ' border='1px solid #E5E7EB' >
                    <Stack direction='row' alignItems='center' gap='10px' px='16px' sx={{ height: '66px', backgroundColor: '#FCFCFD', borderBottom: '1px solid #E5E7EB', borderRadius: '8px 8px 0 0 ' }}>
                        <ContactDetailsIcon />
                        <SecondaryTypography>Uploaded Details</SecondaryTypography>
                    </Stack>
                    <Grid xs={12} gap='2.8%' sx={{ backgroundColor: 'white' }}>
                        {/* <Grid xs={12} > */}
                        <Box sx={{ border: '1px solid #E5E7EB', backgroundColor: '#FFFFFF' }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>No.</StyledTableCell>
                                            <StyledTableCell>Name & Department</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Access Date</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {UploadedData.map((value, index) => {
                                            const { name, email, department, jobrole } = value;
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell height='55px'>{index + 1}</StyledTableCell>
                                                    <StyledTableCell height='55px'>{name}<br /><StyledSecondaryTypography sx={{ fontSize: '12px !important' }}>{department} - {jobrole}</StyledSecondaryTypography></StyledTableCell>
                                                    <StyledTableCell height='55px'>{email}</StyledTableCell>
                                                    <StyledTableCell height='55px'>{moment(new Date()).format('DD MMM YYYY').toString()}</StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Box bgcolor="#FFFFFF" sx={{ borderTop: ' 1px solid #F3F4F6', borderRadius: '0px 0px 8px 8px' }}>
                        <Stack direction="row" py={2} px={2} justifyContent="space-between" alignItems="center" height='60px'>
                            <Stack direction='row'>
                                <RefreshIcon style={{ marginRight: '5px' }} />
                                <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
                            </Stack>
                            <Stack direction="column" justifyContent='end' alignItems='end'>
                                <Button type='button' onClick={() => Continue('')} sx={{ fontWeight: '600', width: "100px", color: '#0E5E84', backgroundColor: "#E7EFF3" }}  >Continue</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            {showDeleteModal && <AlertModal modalDatas={deleteModalDatas} confirmFunction={deleteContactDetail} closeFunction={handleShowDeleteModal} />}
            <Box p={5} sx={{ backgroundColor: '#F3F4F6' }}>
                <Stack direction='row' justifyContent='space-between'>
                    <BackArrow onClick={() => navigate('/staffs')} style={{ marginBottom: '16px' }} />
                    <BorderAlertCloseIcon width='40' height='40' onClick={() => setshowDeleteModal(true)} />
                </Stack>
                <Box mb={5} >
                    <PrimaryTypography mb={1}>Staff Batch Onboarding</PrimaryTypography>
                    <StyledSecondaryTypography >
                        Batch onboard multiple staff by filling in employees details into generated excel template.    </StyledSecondaryTypography>
                </Box>
                <Grid container gap='1.5%' >
                    {blocks.map(itm => {
                        return (
                            <Grid xs={5.9} onClick={() => Continue(itm.tle)}>
                                <Box component='div' sx={{ borderTop: itm.status ? '3px solid #0E4965' : '3px solid #D2D6DB', width: '100%', color: itm.status ? '#0E4965' : '#D2D6DB' }}>
                                    <Typography sx={{ fontWeight: '500', fontSize: "12px", padding: '10px 0 0 0' }}>{itm.tle}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                {addblocks.Select_Employee_Size && selectemployee()}
                {addblocks.Review_Submit && review()}
            </Box >
        </>
    )
}

export default AddBatchStaff
