import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import ImageCourosel from "../../../CommonComponents/ImageCourosel";
import CustomTextEditor from "../../../CommonComponents/CustomTextEditor";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as FacilityStarsIcon } from "../../../Icons/FacilityStarsIcon.svg";
import { ReactComponent as SelectTimeIcon } from "../../../Icons/SelectTimeIcon.svg";
import { ReactComponent as DeleteSBIcon } from "../../../Icons/DeleteSBIcon.svg";
import { ReactComponent as JPGIcon } from "../../../Icons/Jpg.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../Icons/BackArrowMobileIcon.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  StyledSecondaryDefaultTypography,
  StyledPrimaryDefaultTypography,
  SecondaryTypographyDefault,
  SecondaryTypography,
  StyledSwitch,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Modal,
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tab,
  Tabs,
  FormGroup,
  Typography,
  IconButton,
  Divider,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { HomeMaxRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { editFacility } from "../../../Redux/Feature/Facility/FacilitySlice";
import { NestedMenuItem } from "mui-nested-menu";
import { showToast } from "../../../CommonComponents/Toaster";

let deleteModalDatas = {
  label: "Delete Onboarding",
  descriptionOne: "Please confirm that you wish to delete onboarding staff. ",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Delete Now",
  },
};

const endAdornmentStyles = {
  position: "absolute",
  right: 0,
  top: "-20px",
  fontSize: "13px !important",
  "&>*": { fontSize: "13px !important", fontWeight: "400", color: "#9DA4AE" },
};
const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

export default function EditFacilityBooking() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { editFacilityStatus } = useSelector((state) => state.Facility);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [EditFacilityStatusModal, setEditFacilityStatusModal] = useState(false);
  const [EditFacilitytatusData, setEditFacilitytatusData] = useState("");
  const [ScheduleDateTime, setScheduleDateTime] = useState(null);
  const [btndisable, setBtnDisable] = useState(true);
  const [FacilityManageList, setFacilityManageList] = useState([]);
  const [addblocks, setaddblocks] = useState({
    Details_Booking: true,
    Features_Images: false,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details_Booking, tle: "Details & Booking" },
    { status: addblocks.Features_Images, tle: "Features & Images" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];
  const [buildingData, setBuildingData] = useState([]);
  const [CommonAreaData, setCommonAreaData] = useState([]);
  const [commonAreaAnchor, setcommonAreaAnchor] = useState(null);
  const [commonAreaOpen, setCommonAreaOpen] = useState(false);
  const [commonAreaId, setcommonAreaId] = useState(null);
  const [timeSlot, setTimeSlot] = useState([]);
  const [UniqueFeaturesIcon, setUniqueFeaturesIcon] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);
  // Details_Booking

  const [errtextDetailsBookingData, seterrortextDetailsBookingData] = useState({
    facility_Details: {},
    opening_hours: {},
    Booking_type: {},
  });
  const [lastUpdate, setLastUpdate] = useState(null);
  const [DetailsBookingData, setDetailsBookingData] = useState({
    facility_Details: {
      title: "",
      description: "",
      location: "",
      level: "",
      max_people: "",
    },
    opening_hours: {
      mon: { from: "", to: "", status: true },
      tue: { from: "", to: "", status: true },
      wed: { from: "", to: "", status: true },
      thu: { from: "", to: "", status: true },
      fri: { from: "", to: "", status: true },
      sat: { from: "", to: "", status: true },
      sun: { from: "", to: "", status: true },
    },
    Booking_type: {
      type: "",
      slot: "",
    },
  });

  console.log(DetailsBookingData);
  useEffect(() => {
    if (editFacilityStatus != null) {
      if (editFacilityStatus.success == true) {
        navigate("/manage");
        showToast(editFacilityStatus?.toast);
      } else {
        showToast(
          editFacilityStatus?.error || "Something went wrong!Please try again.",
          "error"
        );
      }
    }
  }, [editFacilityStatus]);

  const handleChangeDetailsBooking = (e, obj, key) => {
    const { name, value, checked } = e.target;
    if (obj == "facility_Details") {
      if (name == "location") {
        setcommonAreaId(null);
      }
      setDetailsBookingData((prev) => {
        return {
          ...prev,
          [obj]: { ...prev[obj], [name]: value },
        };
      });
      seterrortextDetailsBookingData((prev) => {
        return {
          ...prev,
          facility_Details: { ...prev.facility_Details, [name]: false },
        };
      });
    } else if (obj == "opening_hours") {
      setDetailsBookingData((prev) => {
        return {
          ...prev,
          [obj]: {
            ...prev[obj],
            [key]: {
              ...prev[obj][key],
              [name]: value == "on" ? checked : value,
            },
          },
        };
      });
      if (name == "status" && checked == false) {
        setAllhoursCheck(false);
        setDetailsBookingData((prev) => {
          return {
            ...prev,
            [obj]: {
              ...prev[obj],
              [key]: { from: null, to: null, status: false },
            },
          };
        });

        seterrortextDetailsBookingData((prev) => {
          return {
            ...prev,
            [obj]: {
              ...prev[obj],
              [key]: { from: null, to: null, status: false },
            },
          };
        });
      } else {
        seterrortextDetailsBookingData((prev) => {
          return {
            ...prev,
            [obj]: {
              ...prev[obj],
              [key]: { ...prev[obj][key], [name]: false },
            },
          };
        });
      }
    } else if (obj == "Booking_type") {
      if (checked) {
        setDetailsBookingData((prev) => {
          return {
            ...prev,
            [obj]: { ...prev[obj], type: name === "single" ? 1 : 2 },
          };
        });
        seterrortextDetailsBookingData((prev) => {
          return {
            ...prev,
            Booking_type: { ...prev.Booking_type, type: false },
          };
        });
      } else {
        setDetailsBookingData((prev) => {
          return {
            ...prev,
            [obj]: { ...prev[obj], [name]: value },
          };
        });
        seterrortextDetailsBookingData((prev) => {
          return {
            ...prev,
            Booking_type: { ...prev.Booking_type, [name]: false },
          };
        });
      }
    }
  };

  //FeatureIMages
  const [uniqueFeatureBlock, setUniqueFeatureBlock] = useState(false);
  const [uniqueFeatures, setuniqueFeatures] = useState([]);
  const [editeduniqueFeatures, setediteduniqueFeatures] = useState([]);
  const [FacilityImages, setFacilityImages] = useState([]);
  const [errtextFeatureImages, seterrtextFeatureImages] = useState({
    uniqueFeatures: "",
    FacilityImages: "",
    texteditor: "",
  });
  const [errtextuniqueFeature, seterrtextuniqueFeature] = useState([]);
  const [textEditorvalue, settextEditorvalue] = useState("");
  const [checktexteditorvalue, setchecktexteditorvalue] = useState("");

  useEffect(() => {
    // setchecktexteditorvalue(parse(textEditorvalue)?.props?.children?.length)
    const descriptionLength = textEditorvalue?.replace(/<[^>]+>/g, "");
    setchecktexteditorvalue(descriptionLength.trim().length);
    seterrtextFeatureImages((prev) => ({ ...prev, texteditor: "" }));
  }, [textEditorvalue]);

  useEffect(() => {
    if (uniqueFeatures.length == 0) {
      setUniqueFeatureBlock(false);
    } else {
      seterrtextFeatureImages((prev) => ({ ...prev, uniqueFeatures: false }));
    }
  }, [uniqueFeatures]);

  const handleuniqueFeatures = (e, id, eid) => {
    const { name, value } = e.target;

    setuniqueFeatures((prev) => {
      const data = prev.map((itm, index) => {
        if (index === id) {
          return { ...itm, [name]: value };
        }
        return itm;
      });
      return data;
    });

    seterrtextuniqueFeature((prev) => {
      const data = prev.map((itm, index) => {
        if (index === id) {
          return { ...itm, [name]: false };
        }
        return itm;
      });
      return data;
    });

    if (Boolean(eid)) {
      const editdata = uniqueFeatures.find((itm) => itm?.id == eid);
      const checkeditdata = editeduniqueFeatures.find((itm) => itm?.id == eid);

      setediteduniqueFeatures((prev) => {
        if (prev.length == 0) {
          return [
            {
              id: eid,
              title: name == "title" ? value : editdata?.title,
              description:
                name == "description" ? value : editdata?.description,
              icon_id: name == "icon_id" ? value : editdata?.icon_id,
            },
          ];
        } else if (Boolean(!checkeditdata)) {
          return [
            ...prev,
            {
              id: eid,
              title: name == "title" ? value : editdata?.title,
              description:
                name == "description" ? value : editdata?.description,
              icon_id: name == "icon_id" ? value : editdata?.icon_id,
            },
          ];
        } else {
          const data = prev.map((itm, index) => {
            if (itm?.id == eid) {
              return {
                id: eid,
                title: name == "title" ? value : editdata?.title,
                description:
                  name == "description" ? value : editdata?.description,
                icon_id: name == "icon_id" ? value : editdata?.icon_id,
              };
            }
            return itm;
          });
          return data;
        }
      });
    }
  };

  const AddFeatureBtn = () => {
    setuniqueFeatures((prev) => [
      ...prev,
      { icon_id: "", title: "", description: "" },
    ]);
  };

  const DeleteUniqueFeatures = (id, delId) => {
    if (Boolean(delId)) {
      DeleteUniqueFeatureAPi(delId);
    }
    const Features = uniqueFeatures.filter((itm, index) => index != id);
    setuniqueFeatures(Features);
  };

  const inputImg = useRef();
  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (FacilityImages?.length < 5) {
        setFacilityImages((prev) => [
          ...prev,
          { file: file, src: URL.createObjectURL(file) },
        ]);
        seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: false }));
        e.target.value = null;
      }
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (FacilityImages?.length < 5) {
        setFacilityImages((prev) => [
          ...prev,
          { file: file, src: URL.createObjectURL(file) },
        ]);
        seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: false }));
      }
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const handleDeleteFacilityImage = (id, delId) => {
    if (delId) {
      DeleteImageAPi(delId);
    }
    const img = FacilityImages.filter((itm, index) => index != id);
    setFacilityImages(img);
  };

  //Reciew imformation

  const [tabValue, setTabValue] = useState(0);

  const handleTabValue = (e, value) => {
    setTabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{value == index && <div>{children}</div>}</>;
  };

  const ValidDetails_Booking = (val) => {
    let isError = false;
    if (val == "Details & Booking") {
      const facility_Details = DetailsBookingData.facility_Details;
      const Booking_type = DetailsBookingData.Booking_type;
      const opening_hours = DetailsBookingData.opening_hours;
      for (const key in facility_Details) {
        if (facility_Details[key] == "") {
          seterrortextDetailsBookingData((prev) => {
            return {
              ...prev,
              facility_Details: { ...prev.facility_Details, [key]: true },
            };
          });
          isError = true;
        }
      }
      for (const key in Booking_type) {
        if (Booking_type[key] == "") {
          seterrortextDetailsBookingData((prev) => {
            return {
              ...prev,
              Booking_type: { ...prev.Booking_type, [key]: true },
            };
          });
          isError = true;
        }
      }
      for (const key in opening_hours) {
        for (const keys in opening_hours[key]) {
          if (opening_hours[key]["status"] == true) {
            if (opening_hours[key][keys] == null) {
              seterrortextDetailsBookingData((prev) => {
                return {
                  ...prev,
                  opening_hours: {
                    ...prev.opening_hours,
                    [key]: { ...prev.opening_hours[key], [keys]: true },
                  },
                };
              });
              isError = true;
            }
          }
        }
      }
    } else if (val == "Features & Images") {
      if (FacilityImages.length < 1) {
        seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: true }));
        isError = true;
      }
      if (checktexteditorvalue == 0) {
        seterrtextFeatureImages((prev) => ({ ...prev, texteditor: true }));
        isError = true;
      }
      if (uniqueFeatures.length >= 1) {
        seterrtextuniqueFeature((prev) => {
          const data = uniqueFeatures.map((itm, index) => {
            var errs = { ...itm };
            for (const key in itm) {
              if (itm[key] == "") {
                isError = true;
                Object.assign(errs, { [key]: true });
              } else if (itm[key] != "") {
                Object.assign(errs, { [key]: false });
              }
            }
            return errs;
          });
          return data;
        });
      } else if (uniqueFeatures.length < 1) {
        seterrtextFeatureImages((prev) => ({ ...prev, uniqueFeatures: true }));
        isError = true;
      }
    }
    return isError;
  };

  const Continue = (nxtval, curval) => {
    let setval = curval;
    if (nxtval == "Features & Images") {
      setval = "Details & Booking";
    } else if (nxtval == "Review Information") {
      setval = "Features & Images";
    }

    if (!ValidDetails_Booking(setval)) {
      if (nxtval == "Details & Booking") {
        setaddblocks({
          Details_Booking: true,
          Features_Images: false,
          Review_Information: false,
        });
      } else if (nxtval == "Features & Images") {
        setaddblocks({
          Details_Booking: false,
          Features_Images: true,
          Review_Information: false,
        });
      } else if (nxtval == "Review Information") {
        setaddblocks({
          Details_Booking: false,
          Features_Images: false,
          Review_Information: true,
        });
      }
    }
  };

  const DropdownEmpty = (val) => {
    if (val === "building") {
      setDetailsBookingData((prev) => {
        return {
          ...prev,
          facility_Details: { ...prev["facility_Details"], level: "" },
        };
      });
    }
  };

  const ReviewOpeningHours = Object.values(DetailsBookingData.opening_hours);

  const Reiviewlevel = (id, idd) => {
    const data = buildingData.map((itm, index) => {
      if (id == itm.id) {
        return itm.level.map((sitm) => {
          if (idd == sitm.id) {
            return sitm.level;
          }
        });
      }
    });
    return data;
  };

  const ReviewBuilding = (id) => {
    const data = buildingData.map((itm, index) => {
      if (id == itm?.id) {
        return itm?.building_name;
      }
    });
    return data;
  };

  //POST API
  const EditFacility = async () => {
    const data = new FormData();

    const opening_hours = DetailsBookingData.opening_hours;
    const checkuniqueFeatures = uniqueFeatures.filter((itm) =>
      Boolean(!itm?.id)
    );
    const checkFacilityImages = FacilityImages.filter((itm) =>
      Boolean(!itm?.id)
    );

    const finaluniqueFeatures =
      checkuniqueFeatures.concat(editeduniqueFeatures);

    data.append("title", DetailsBookingData?.facility_Details?.title);
    data.append(
      "description",
      DetailsBookingData?.facility_Details?.description
    );
    data.append(
      "max_person_allowed",
      parseInt(DetailsBookingData?.facility_Details?.max_people, 10)
    );
    {
      Boolean(commonAreaId)
        ? data.append("common_area_id", commonAreaId)
        : data.append("level_id", DetailsBookingData?.facility_Details?.level);
    }
    data.append("booking_type_id", DetailsBookingData?.Booking_type?.type);
    data.append("consecutive_slots", DetailsBookingData?.Booking_type?.slot);
    for (const key in opening_hours) {
      if (opening_hours[key]["status"] == true) {
        data.append(`opening_hours[${key}][is_open]`, 1);
        data.append(
          `opening_hours[${key}][from_time]`,
          opening_hours[key]["from"]
        );
        data.append(`opening_hours[${key}][to_time]`, opening_hours[key]["to"]);
      }
    }
    checkFacilityImages.forEach((element, index) => {
      data.append("images[]", element?.file);
    });
    // checkuniqueFeatures.forEach((itm, index) => {
    //     data.append('unique_feature[2][title]', itm?.title)
    //     data.append('unique_feature[2][description]', itm?.description)
    //     data.append('unique_feature[2][custom_icon_id]', itm?.icon_id)
    // })
    finaluniqueFeatures.forEach((itm, index) => {
      data.append("unique_feature[" + index + "][title]", itm?.title);
      data.append(
        "unique_feature[" + index + "][description]",
        itm?.description
      );
      data.append(
        "unique_feature[" + index + "][custom_icon_id]",
        itm?.icon_id
      );
      Boolean(itm?.id) &&
        data.append("unique_feature[" + index + "][id]", itm?.id);
    });
    data.append("terms_conditions", textEditorvalue);

    {
      EditFacilitytatusData == 2 && data.append("update_type", 1);
    }
    {
      EditFacilitytatusData == 2 &&
        data.append(
          "schedule_date",
          moment(ScheduleDateTime).format("YYYY-MM-DD HH:mm:ss")
        );
    }

    dispatch(editFacility({ propertyId, id, data }));

    // try {
    //     const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`, data)
    //     navigate('/manage')
    // }
    // catch (err) {
    //     console.log("error:", err);
    // }
  };

  const getFacilityData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`
      );
      setBtnDisable(false);
      const data = response.data.data.facility;
      setFacilityManageList(data);
      setLastUpdate(data?.updated_at);
      setDetailsBookingData({
        facility_Details: {
          title: data?.title,
          description: data?.description,
          location: Boolean(data?.common_area_id)
            ? data?.common_area_id
            : data?.location_details?.building?.id,
          level: data?.location_details?.level?.id,
          max_people: data?.max_person_allowed,
        },
        opening_hours: {
          mon: {
            from: data?.open_days[0]?.from_time_id,
            to: data?.open_days[0]?.to_time_id,
            status: data?.open_days[0]?.is_open,
          },
          tue: {
            from: data?.open_days[1]?.from_time_id,
            to: data?.open_days[1]?.to_time_id,
            status: data?.open_days[1]?.is_open,
          },
          wed: {
            from: data?.open_days[2]?.from_time_id,
            to: data?.open_days[2]?.to_time_id,
            status: data?.open_days[2]?.is_open,
          },
          thu: {
            from: data?.open_days[3]?.from_time_id,
            to: data?.open_days[3]?.to_time_id,
            status: data?.open_days[3]?.is_open,
          },
          fri: {
            from: data?.open_days[4]?.from_time_id,
            to: data?.open_days[4]?.to_time_id,
            status: data?.open_days[4]?.is_open,
          },
          sat: {
            from: data?.open_days[5]?.from_time_id,
            to: data?.open_days[5]?.to_time_id,
            status: data?.open_days[5]?.is_open,
          },
          sun: {
            from: data?.open_days[6]?.from_time_id,
            to: data?.open_days[6]?.to_time_id,
            status: data?.open_days[6]?.is_open,
          },
        },
        Booking_type: {
          type: data?.booking_type_id,
          slot: data?.consecutive_slots,
        },
      });
      data?.images.forEach((itm) => {
        setFacilityImages((prev) => {
          const check = prev.find((sitm) => sitm?.file == itm?.module_name);
          if (!check) {
            return [
              ...prev,
              {
                id: itm?.id,
                file: itm?.module_name,
                src: `${process.env.REACT_APP_FILE_PATH}${itm?.path}`,
              },
            ];
          } else {
            return prev;
          }
        });
      });
      data?.unique_feature.forEach((itm) => {
        setuniqueFeatures((prev) => {
          const check = prev.find((sitm) => sitm?.id == itm?.id);
          if (!check) {
            return [
              ...prev,
              {
                id: itm?.id,
                icon_id: itm?.custom_icon_id,
                title: itm?.title,
                description: itm?.description,
              },
            ];
          } else {
            return prev;
          }
        });
      });

      // setFacilityImages(data?.images)
      // setuniqueFeatures(data?.unique_feature)
      Boolean(data?.terms_conditions) &&
        settextEditorvalue(data?.terms_conditions);
      Boolean(data?.common_area_id) && setcommonAreaId(data?.common_area_id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getIconsData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=unique_feature_icon`
        );
        const IconData = response.data.data.drop_down;
        setUniqueFeaturesIcon(IconData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getIconsData();

    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building?pagination=false`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();

    const getTimeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=slot_time`
        );
        const TimeData = response.data.data.drop_down;
        setTimeSlot(TimeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getTimeData();

    // const getCommonAreaData = async () => {
    //     try {
    //         const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea`);
    //         const CommonAreaData = response.data.data.common_areas
    //         setCommonAreaData(CommonAreaData);

    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // }
    // getCommonAreaData()
    getFacilityData();
  }, []);

  // DElete APi
  const DeleteUniqueFeatureAPi = async (unique_feature_id) => {
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}facility/unique_feature/${unique_feature_id}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteImageAPi = async (image_id) => {
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}delete_image/${image_id}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setEditFacilityStatusModal(false);
    setScheduleDateTime(null);
    setEditFacilitytatusData("");
  };

  function CommonAreaEnter(e) {
    setcommonAreaAnchor(e.currentTarget);
    setCommonAreaOpen(true);
  }
  function CommonAreaLeave() {
    setcommonAreaAnchor(null);
    setCommonAreaOpen(false);
  }

  //  check All hours

  const [allhoursCheck, setAllhoursCheck] = useState(false);
  const [allcheckid, setAllcheckId] = useState(null);
  const [errallcheck, seterrAllcheck] = useState(false);

  useEffect(() => {
    if (allhoursCheck) {
      const { from, to } = ReviewOpeningHours[allcheckid];
      setDetailsBookingData((prev) => ({
        ...prev,
        opening_hours: {
          mon: { from: from, to: to, status: true },
          tue: { from: from, to: to, status: true },
          wed: { from: from, to: to, status: true },
          thu: { from: from, to: to, status: true },
          fri: { from: from, to: to, status: true },
          sat: { from: from, to: to, status: true },
          sun: { from: from, to: to, status: true },
        },
      }));
      seterrAllcheck(false);
    }
    // else {
    //   setDetailsBookingData((prev) => ({
    //     ...prev,
    //     opening_hours: {
    //       mon: { from: null, to: null, status: true },
    //       tue: { from: null, to: null, status: true },
    //       wed: { from: null, to: null, status: true },
    //       thu: { from: null, to: null, status: true },
    //       fri: { from: null, to: null, status: true },
    //       sat: { from: null, to: null, status: true },
    //       sun: { from: null, to: null, status: true },
    //     },
    //   }));
    // }
  }, [allhoursCheck]);

  const CheckAllhours = (e) => {
    const { checked } = e.target;

    if (checked) {
      ReviewOpeningHours.map((itm, index) => {
        if (itm?.status == true && itm?.to != null && itm.from != null) {
          setAllhoursCheck(true);
          setAllcheckId(index);
          seterrAllcheck(false);

          return;
        } else {
          seterrAllcheck(true);
        }
      });
    } else {
      setAllhoursCheck(false);
      setAllcheckId(null);
    }
  };

  const Details_Booking = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Facility_Details || "Facility Details"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography>
                {" "}
                {LanguageData?.Announcement_tab_title || "Title"}{" "}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtextDetailsBookingData?.facility_Details?.title
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtextDetailsBookingData?.facility_Details
                    ?.title
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                name="title"
                placeholder="Enter facility title"
                value={DetailsBookingData?.facility_Details?.title}
                onChange={(e) =>
                  handleChangeDetailsBooking(e, "facility_Details")
                }
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment sx={endAdornmentStyles}>
                      {DetailsBookingData?.facility_Details?.title?.length}/50
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              {/* <ErrorTypography>{errtext.PersonalDetailsData.firstname}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} sm={12} mt={3.5}>
              <StyledTypography>
                {LanguageData?.Description || "Description"}{" "}
              </StyledTypography>
              <TextField
                name="description"
                placeholder="Enter a brief description of the facility."
                multiline
                value={DetailsBookingData?.facility_Details?.description}
                onChange={(e) =>
                  handleChangeDetailsBooking(e, "facility_Details")
                }
                inputProps={{ maxLength: 1000 }}
                sx={{
                  minHeight: "88px",
                  "& .MuiInputBase-root": {
                    color: errtextDetailsBookingData?.facility_Details
                      ?.description
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtextDetailsBookingData?.facility_Details
                    ?.description
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment sx={endAdornmentStyles}>
                      {
                        DetailsBookingData?.facility_Details?.description
                          ?.length
                      }
                      /1000
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              {/* <ErrorTypography>{errtext.PersonalDetailsData.address}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} sm={3.77} mt={3.5}>
              <StyledTypography>
                {" "}
                {LanguageData?.Location || "Location"}
              </StyledTypography>
              <Select
                name="location"
                value={DetailsBookingData?.facility_Details?.location}
                onChange={(e) =>
                  handleChangeDetailsBooking(e, "facility_Details")
                }
                sx={{
                  color: Boolean(DetailsBookingData?.facility_Details?.location)
                    ? ""
                    : errtextDetailsBookingData?.facility_Details?.location
                    ? "#912018"
                    : "#9DA4AE",
                  backgroundColor: errtextDetailsBookingData?.facility_Details
                    ?.location
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                displayEmpty
                renderValue={
                  DetailsBookingData?.facility_Details?.location !== ""
                    ? undefined
                    : () => "Select location"
                }
                open={commonAreaOpen}
                onOpen={(e) => CommonAreaEnter(e)}
                onClose={() => CommonAreaLeave()}
              >
                {buildingData.map((itm, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        DropdownEmpty("building");
                      }}
                      key={index}
                      value={itm.id}
                    >
                      {itm.building_name}
                    </MenuItem>
                  );
                })}
                {/* {CommonAreaData?.map((itm) => (
                                    <MenuItem
                                        value={itm?.id}
                                        sx={{
                                            display: "none"
                                        }}
                                    >
                                        {itm?.common_area_name}
                                    </MenuItem>
                                ))}
                                <NestedMenuItem label={"Common Area"} parentMenuOpen={commonAreaOpen} disabled>
                                    {CommonAreaData?.map((itm) => (
                                        <MenuItem
                                            data-value={itm?.id}
                                            onClick={(e) => {
                                                setDetailsBookingData((prev) => ({ ...prev, facility_Details: { ...prev['facility_Details'], location: e.currentTarget.dataset.value } }));
                                                setcommonAreaId(itm?.id);
                                                CommonAreaLeave()
                                                DropdownEmpty('building')
                                            }}
                                        >
                                            {itm?.common_area_name}
                                        </MenuItem>
                                    ))}
                                </NestedMenuItem> */}
              </Select>
              {/* <ErrorTypography>{errtext.PersonalDetailsData.country}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} sm={3.77} mt={3.5}>
              <StyledTypography>
                {LanguageData?.Common_level || "Level"}{" "}
              </StyledTypography>
              <Select
                name="level"
                value={DetailsBookingData?.facility_Details?.level}
                onChange={(e) =>
                  handleChangeDetailsBooking(e, "facility_Details")
                }
                sx={{
                  color: Boolean(DetailsBookingData?.facility_Details?.level)
                    ? ""
                    : errtextDetailsBookingData?.facility_Details?.level
                    ? "#912018"
                    : "#9DA4AE",
                  backgroundColor: errtextDetailsBookingData?.facility_Details
                    ?.level
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                displayEmpty
                renderValue={
                  DetailsBookingData?.facility_Details?.level !== ""
                    ? undefined
                    : () => "Select level"
                }
              >
                {Boolean(commonAreaId)
                  ? CommonAreaData.map((itm) => {
                      if (
                        DetailsBookingData?.facility_Details?.location == itm.id
                      ) {
                        return (
                          <MenuItem
                            onClick={() => {
                              DropdownEmpty("level");
                            }}
                            value={itm?.building_level?.id}
                          >
                            {itm?.building_level?.level}
                          </MenuItem>
                        );
                      }
                    })
                  : buildingData.map((itm, index) => {
                      if (
                        DetailsBookingData?.facility_Details?.location == itm.id
                      ) {
                        return itm.level.map((sitm) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                DropdownEmpty("level");
                              }}
                              value={sitm.id}
                            >
                              {sitm.level}
                            </MenuItem>
                          );
                        });
                      }
                    })}
              </Select>
              {/* <ErrorTypography>{errtext.PersonalDetailsData.country}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} sm={3.77} mt={3.5}>
              <StyledTypography>
                {" "}
                {LanguageData?.Maximum_Pepole_Allowed ||
                  "Maximum People Allowed"}{" "}
              </StyledTypography>
              <TextField
                type="number"
                name="max_people"
                placeholder="00"
                onWheel={(e) => e.target.blur()}
                value={DetailsBookingData?.facility_Details?.max_people}
                onChange={(e) =>
                  handleChangeDetailsBooking(e, "facility_Details")
                }
                inputProps={{ maxLength: 20 }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtextDetailsBookingData?.facility_Details
                      ?.max_people
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtextDetailsBookingData?.facility_Details
                    ?.max_people
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                InputProps={{ disableUnderline: true }}
              />
              {/* <ErrorTypography>{errtext.PersonalDetailsData.country}</ErrorTypography> */}
            </Grid>
          </Grid>

          {/* openninghours */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Opening_Hours || "Opening Hours"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} sm={5.8}>
              <Stack>
                <Stack height="30px" sx={{ position: "relative" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ position: "absolute", left: "-10px", bottom: "5px" }}
                  >
                    <Checkbox
                      checked={allhoursCheck}
                      onChange={(e) => CheckAllhours(e)}
                      icon={<CheckboxIcon />}
                    />
                    <styledTyphographyDefault>
                      {LanguageData?.Same_hours_everyday ||
                        "Same hours everyday"}
                    </styledTyphographyDefault>
                  </Stack>
                </Stack>
                <Stack direction="row" mt={2}>
                  <StyledTypography width="80px">
                    {LanguageData?.Mon || "Mon"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.mon?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "mon")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {" "}
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {" "}
                    {LanguageData?.Tue || "Tue"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.tue?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "tue")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {LanguageData?.Wed || "Wed"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.wed?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "wed")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {LanguageData?.Thu || "Thu"}{" "}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.thu?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "thu")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {LanguageData?.Fri || "Fri"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.fri?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "fri")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {LanguageData?.Sat || "Sat"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.sat?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sat")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
                <Stack direction="row" mt={5}>
                  <StyledTypography width="80px">
                    {LanguageData?.Sun || "Sun"}
                  </StyledTypography>
                  <StyledSwitch
                    name="status"
                    checked={DetailsBookingData?.opening_hours?.sun?.status}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sun")
                    }
                  />
                  <TypographyDefault ml={2}>
                    {LanguageData?.Open || "Open"}
                  </TypographyDefault>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <Stack>
                <styledTyphographyDefault>
                  {LanguageData?.Opening_Hours || "Opening Hours"}
                </styledTyphographyDefault>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.mon?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.mon?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.mon?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.mon?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "mon")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.mon?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.mon?.to}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.mon?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.mon?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.mon?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "mon")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.mon?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.tue?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.tue?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.tue?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.tue?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "tue")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.tue?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.tue?.to}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.tue?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.tue?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.tue?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "tue")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.tue?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.wed?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.wed?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.wed?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.wed?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "wed")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.wed?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.wed?.to}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.wed?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.wed?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.wed?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "wed")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.wed?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.thu?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.thu?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.thu?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.thu?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "thu")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.thu?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.thu?.to}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.thu?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.thu?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.thu?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "thu")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.thu?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.fri?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.fri?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.fri?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.fri?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "fri")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.fri?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.fri?.to}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.fri?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.fri?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.fri?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "fri")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.fri?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    IconComponent={() => <SelectTimeIcon />}
                    value={DetailsBookingData?.opening_hours?.sat?.from}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.sat?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.sat?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.sat?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sat")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.sat?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    value={DetailsBookingData?.opening_hours?.sat?.to}
                    IconComponent={() => <SelectTimeIcon />}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.sat?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.sat?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.sat?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sat")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.sat?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  mt={1.5}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    name="from"
                    value={DetailsBookingData?.opening_hours?.sun?.from}
                    IconComponent={() => <SelectTimeIcon />}
                    sx={{
                      color: Boolean(
                        DetailsBookingData?.opening_hours?.sun?.from
                      )
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.sun?.from
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.sun?.from
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sun")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.sun?.from != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                  <TypographyDefault mx={2}>
                    {LanguageData?.TO || "To"}
                  </TypographyDefault>
                  <Select
                    name="to"
                    value={DetailsBookingData?.opening_hours?.sun?.to}
                    IconComponent={() => <SelectTimeIcon />}
                    sx={{
                      color: Boolean(DetailsBookingData?.opening_hours?.sun?.to)
                        ? ""
                        : errtextDetailsBookingData?.opening_hours?.sun?.to
                        ? "#912018"
                        : "#9DA4AE",
                      backgroundColor: errtextDetailsBookingData?.opening_hours
                        ?.sun?.to
                        ? "#FEE4E2"
                        : " #F9FAFB",
                      paddingRight: "13px",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "opening_hours", "sun")
                    }
                    displayEmpty
                    renderValue={
                      DetailsBookingData?.opening_hours?.sun?.to != null
                        ? undefined
                        : () => "00:00"
                    }
                  >
                    {timeSlot?.map((itm) => (
                      <MenuItem value={itm?.id}>{itm?.time}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
            </Grid>
            {errallcheck && (
              <ErrorTypography>
                {LanguageData?.Select_Hour ||
                  "Please Select one of the Opening Hours"}
              </ErrorTypography>
            )}
          </Grid>

          {/* BookingTYpe */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Booking_type || "Booking Type"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={5.8}>
              <Stack
                p={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  DetailsBookingData?.Booking_type?.type == 1
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                backgroundColor={
                  DetailsBookingData?.Booking_type?.type == 1
                    ? "#EDF6FA"
                    : "#F9FAFB"
                }
                borderRadius="8px"
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Single || "Single"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Facility_allow ||
                      "Facility will only allow 1 resident to book per slot."}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="single"
                    checked={DetailsBookingData?.Booking_type?.type == 1}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "Booking_type")
                    }
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>

              {errtextDetailsBookingData?.Booking_type?.type && (
                <ErrorTypography mb={1}>
                  {LanguageData?.Select_book || "Select a booking type above"}
                </ErrorTypography>
              )}
            </Grid>

            <Grid xs={5.8}>
              <Stack
                p={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  DetailsBookingData?.Booking_type?.type == 2
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                sx={{
                  backgroundColor:
                    DetailsBookingData?.Booking_type?.type == 2
                      ? "#EDF6FA"
                      : "#F9FAFB",
                  borderRadius: "8px",
                }}
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Multiple || "Multiple"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Multiple_Des ||
                      " Facility will allow multiple residents to book per slot  based on maximum people allowed."}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="multiple"
                    checked={DetailsBookingData?.Booking_type?.type == 2}
                    onChange={(e) =>
                      handleChangeDetailsBooking(e, "Booking_type")
                    }
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>
                {LanguageData?.Slot_Consective ||
                  "Consecutive Slots (30 Min per Slot)"}
              </StyledTypography>
              <Select
                name="slot"
                value={DetailsBookingData?.Booking_type?.slot}
                onChange={(e) => handleChangeDetailsBooking(e, "Booking_type")}
                sx={{
                  color: Boolean(DetailsBookingData?.Booking_type?.slot)
                    ? ""
                    : errtextDetailsBookingData?.Booking_type?.slot
                    ? "#912018"
                    : "#9DA4AE",
                  backgroundColor: errtextDetailsBookingData?.Booking_type?.slot
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                displayEmpty
                renderValue={
                  DetailsBookingData?.Booking_type?.slot !== ""
                    ? undefined
                    : () => "Select slot"
                }
              >
                <MenuItem disabled value="">
                  {LanguageData?.Select_slot || "Select slot"}
                </MenuItem>
                <MenuItem value="1">
                  {" "}
                  {LanguageData?.One_Slot || "1 slot"}{" "}
                </MenuItem>
                <MenuItem value="2">
                  {LanguageData?.Two_Slot || "2 slot"}
                </MenuItem>
                <MenuItem value="3">
                  {LanguageData?.Three_Slot || "3 slot"}{" "}
                </MenuItem>
                <MenuItem value="4">
                  {LanguageData?.Four_Slot || "4 slot"}{" "}
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(lastUpdate).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                disabled={btndisable}
                onClick={() =>
                  Continue("Features & Images", "Details & Booking")
                }
                variant="Standard"
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Features_Images = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0 "
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Unique_Features || "Unique Features"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            {!uniqueFeatureBlock && uniqueFeatures?.length < 1 && (
              <Grid
                xs={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  padding: "24px 16px 24px 16px",
                  border: "1px solid #EAECF0",
                  borderRadius: "12px",
                }}
              >
                <Stack
                  gap="12px"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FacilityStarsIcon />
                  <SecondaryTypographyDefault>
                    {LanguageData?.Add_Unique ||
                      "Add Facility’s Unique Features"}
                  </SecondaryTypographyDefault>
                  <TypographyDefault>
                    {LanguageData?.Add_Unique_Des ||
                      "Add unique and important features of the facility to assist residents’ when making bookings."}
                  </TypographyDefault>
                  <Button
                    sx={{ borderRadius: "8px", border: "1px solid #D2D6DB" }}
                    onClick={() => {
                      setUniqueFeatureBlock(true);
                      AddFeatureBtn();
                    }}
                  >
                    {LanguageData?.management_staff_add_button || "Add"}
                  </Button>
                </Stack>
              </Grid>
            )}
            {uniqueFeatures?.map((itm, index) => {
              return (
                <Grid
                  mt={2}
                  xs={12}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SecondaryTypography>
                      {LanguageData?.Unique_Feature || "Unique Feature"}
                      {index + 1}
                    </SecondaryTypography>
                    <DeleteSBIcon
                      onClick={() => DeleteUniqueFeatures(index, itm?.id)}
                    />
                  </Stack>
                  <Grid xs={12} mt={4}>
                    <SecondaryTypographyDefault>
                      {LanguageData?.Choose_an_Icon || "Choose an Icon"}
                    </SecondaryTypographyDefault>
                    <Stack mt={2} columnGap={2} direction="row">
                      <FormControl>
                        <RadioGroup
                          sx={{ display: "flex", flexDirection: "row" }}
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={uniqueFeatures[index]?.icon_id}
                          name="icon_id"
                          onClick={(e) =>
                            handleuniqueFeatures(e, index, itm?.id)
                          }
                        >
                          {" "}
                          {UniqueFeaturesIcon?.map((sitm) => {
                            return sitm?.id == uniqueFeatures[index].icon_id ? (
                              <img
                                src={`${process.env.REACT_APP_FILE_PATH}${sitm?.icon_path}`}
                                width="25px"
                                height="24px"
                                style={{
                                  backgroundColor: "#E5E7EB",
                                  borderRadius: "8px",
                                  padding: "8px",
                                  margin: "3px 9px 0px 9px",
                                }}
                              />
                            ) : (
                              <FormControlLabel
                                value={sitm?.id}
                                control={
                                  <Radio
                                    icon={
                                      <img
                                        src={`${process.env.REACT_APP_FILE_PATH}${sitm?.icon_path}`}
                                        width="25px"
                                        height="24px"
                                      />
                                    }
                                  />
                                }
                                sx={{ mx: 1, mt: 0.4 }}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                    {errtextuniqueFeature[index]?.icon_id && (
                      <ErrorTypography>
                        {LanguageData?.Please_Select_the_icon ||
                          "Please Select the icon"}{" "}
                      </ErrorTypography>
                    )}
                  </Grid>
                  <Grid xs={12} mt={4}>
                    <SecondaryTypographyDefault>
                      {LanguageData?.Announcement_tab_title || "Title"}
                    </SecondaryTypographyDefault>
                    <TextField
                      name="title"
                      value={itm?.title}
                      onChange={(e) => handleuniqueFeatures(e, index, itm?.id)}
                      placeholder="Enter title"
                      inputProps={{ maxLength: 50 }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment sx={endAdornmentStyles}>
                            {itm?.title?.length}/50
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    {errtextuniqueFeature[index]?.title && (
                      <ErrorTypography>
                        {LanguageData?.Please_Enter_the_title ||
                          "Please Enter the title"}{" "}
                      </ErrorTypography>
                    )}
                  </Grid>
                  <Grid xs={12} mt={4}>
                    <SecondaryTypographyDefault>
                      {LanguageData?.Description || "Description"}
                    </SecondaryTypographyDefault>
                    <TextField
                      sx={{ minHeight: "100px" }}
                      name="description"
                      value={itm?.description}
                      onChange={(e) => handleuniqueFeatures(e, index, itm?.id)}
                      placeholder="Enter description of the unique feature."
                      inputProps={{ maxLength: 300 }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment sx={endAdornmentStyles}>
                            {itm?.description?.length}/300
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      multiline
                    />
                    {errtextuniqueFeature[index]?.description && (
                      <ErrorTypography>
                        {LanguageData?.Please_Enter_the_description ||
                          "Please Enter the description"}
                      </ErrorTypography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {(uniqueFeatureBlock || uniqueFeatures?.length >= 1) && (
              <Box display="flex" justifyContent="center" mt={3} width="100%">
                <Button
                  onClick={() => {
                    AddFeatureBtn();
                  }}
                  sx={{
                    padding: "8px 14px 8px 14px",
                    height: "41px",
                    color: "#0E5E84",
                    backgroundColor: "#E7EFF3",
                    "&:hover": {
                      backgroundColor: "#CFDFE6",
                      border: "none",
                    },
                  }}
                >
                  {LanguageData?.Add_New_Feature || "Add New Feature"}
                </Button>
              </Box>
            )}
            {errtextFeatureImages?.uniqueFeatures && (
              <ErrorTypography mt={1} width="100%" textAlign="center">
                {LanguageData?.Please_add_facilitys_unique_features ||
                  "Please add facility’s unique features"}
              </ErrorTypography>
            )}
          </Grid>

          {/* Facility Images */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Facility_Images || "Facility Images"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <Stack
                onDragOver={handleDragImage}
                onDrop={handleDropImage}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  padding: "24px 16px 24px 16px",
                  border: "1px solid #EAECF0",
                  borderRadius: "12px",
                }}
              >
                <Stack
                  flex={1}
                  direction="column"
                  alignItems="center"
                  textAlign="center"
                >
                  <UploadIcon onClick={handleImage} />
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.svg,.gif"
                    ref={inputImg}
                    onChange={handleInputImage}
                    hidden
                  />
                  <Stack onClick={handleImage} my="5px" direction="row">
                    <Typography
                      marginRight="5px"
                      sx={{
                        fontSize: "14px !important",
                        fontWeight: "600 !important",
                        color: "#0E4965 !important",
                      }}
                    >
                      {LanguageData?.Click || "Click to upload"}
                    </Typography>
                    <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                      {LanguageData?.Drag || "or drag and drop"}
                    </StyledSecondaryTypography>
                  </Stack>
                  <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                    {LanguageData?.File_type || "JPG, PNG (max. 25 megabytes)"}{" "}
                    <br />
                    {LanguageData?.Maximum_images || "Maximum 5 images"}
                  </StyledSecondaryTypography>
                </Stack>
              </Stack>
              {FacilityImages?.map((itm, index) => {
                // const { name, size } = itm?.file
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      borderRadius: "12px",
                      p: 2,
                      mt: 2,
                    }}
                  >
                    <Stack direction="row">
                      <JPGIcon style={{ marginRight: "12px" }} />
                      <Stack direction="column">
                        <StyledSecondaryTypography>
                          {Boolean(itm?.file?.name)
                            ? itm?.file?.name
                            : itm?.file}
                        </StyledSecondaryTypography>
                        <StyledSecondaryTypography>
                          {Boolean(itm?.file?.size)
                            ? (itm?.file?.size / 1048576).toFixed(2)
                            : "0.02"}{" "}
                          MB
                        </StyledSecondaryTypography>
                      </Stack>
                    </Stack>
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteFacilityImage(index, itm?.id);
                      }}
                      width={40}
                    />
                  </Box>
                );
              })}
            </Grid>
            {/* <ErrorTypography mt={1} width='100%' textAlign="center">Please upload facility images</ErrorTypography> */}
            {errtextFeatureImages?.FacilityImages && (
              <ErrorTypography mt={1} width="100%" textAlign="center">
                {LanguageData?.Please_upload_facility_images ||
                  "Please upload facility images"}
              </ErrorTypography>
            )}
          </Grid>

          {/* Terms & Conditions */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Terms_And_Condition || "Terms & Conditions"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} sx={{ position: "relative" }}>
              <CustomTextEditor
                textEditorvalue={textEditorvalue}
                settextEditorvalue={settextEditorvalue}
                label={"Enter the terms and conditions for the facility usage."}
                textEditorErr={errtextFeatureImages?.texteditor}
              />
              {/* <StyledSecondaryServiceTypography mt={1.5} sx={{ display: 'flex', justifyContent: 'end', fontSize: '12px !important', color: "#9DA4AE !important" }}></StyledSecondaryServiceTypography> */}
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(lastUpdate).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() =>
                  Continue("Review Information", "Features & Images")
                }
                variant="Standard"
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const ReviewInformation = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
          width="100%"
        >
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab sx={{ width: "50%", maxWidth: "none" }} label="Details" />
              <Tab sx={{ width: "50%", maxWidth: "none" }} label="Preview" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Facility_Details || "Facility Details"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "20px 16px 20px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.Announcement_tab_title || "Title"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {DetailsBookingData?.facility_Details?.title}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Description || "Description"}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {DetailsBookingData?.facility_Details?.description}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.Location || "Location"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {ReviewBuilding(
                    DetailsBookingData?.facility_Details?.location
                  )}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.Common_level || "Level"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {Reiviewlevel(
                    DetailsBookingData?.facility_Details?.location,
                    DetailsBookingData?.facility_Details?.level
                  )}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid xs={12} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Maximum_Pepole_Allowed ||
                    "Maximum People Allowed"}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {DetailsBookingData?.facility_Details?.max_people}{" "}
                  {LanguageData?.Pax || "Pax"}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* openninghours */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Opening_Hours || "Opening Hours"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8}>
                <Stack>
                  <Stack direction="row" alignItems="center">
                    <StyledSecondaryTypography>
                      {" "}
                      {LanguageData?.Day || "Day"}
                    </StyledSecondaryTypography>
                  </Stack>
                  <Stack rowGap={2.5} direction="column" mt={4}>
                    <TypographyDefault width="80px">
                      {LanguageData?.Monday || "Monday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Tuesday || "Tuesday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Wednesday || "Wednesday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Thursday || "Thursday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Friday || "Friday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Saturday || "Saturday"}
                    </TypographyDefault>
                    <TypographyDefault width="80px">
                      {LanguageData?.Sunday || "Sunday"}
                    </TypographyDefault>
                  </Stack>
                </Stack>
              </Grid>
              <Grid xs={12} sm={5.8}>
                <Stack>
                  <Stack direction="row" alignItems="center">
                    <StyledSecondaryTypography>
                      {LanguageData?.Opening_Hours || "Opening Hours"}
                    </StyledSecondaryTypography>
                  </Stack>
                  <Stack rowGap={2.5} direction="column" mt={4}>
                    {ReviewOpeningHours?.map((itm) => {
                      const time = (id) =>
                        timeSlot?.map((sitm) => {
                          if (sitm?.id == id) {
                            return sitm?.time;
                          }
                        });
                      return itm.status == true ? (
                        <TypographyDefault>
                          {time(itm?.from)} - {time(itm?.to)}
                        </TypographyDefault>
                      ) : (
                        <TypographyDefault>
                          {" "}
                          {LanguageData?.closed || "closed"}{" "}
                        </TypographyDefault>
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* BookingTYpe */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Booking_type || "Booking Type"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8}>
                <StyledSecondaryTypography>
                  {LanguageData?.Booking_type || "Booking Type"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={1}>
                  {DetailsBookingData?.Booking_type?.type == "1"
                    ? "single"
                    : "Multiple"}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8}>
                <StyledSecondaryTypography>
                  {LanguageData?.Consecutive_Slots || "Consecutive Slots"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={1}>
                  {DetailsBookingData?.Booking_type?.slot}{" "}
                  {LanguageData?.slot || "Slot"}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Unique features */}
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Unique_Features || "Unique Features"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              {uniqueFeatures?.map((itm, index) => {
                return (
                  <>
                    {index >= 1 && (
                      <Divider
                        sx={{
                          borderTop: "1px solid #F3F4F6",
                          width: "100%",
                          my: 3,
                        }}
                      />
                    )}
                    <Grid xs={12} sm={5.8}>
                      <StyledSecondaryTypography mb={1}>
                        {LanguageData?.Icon || "Icon"}
                      </StyledSecondaryTypography>
                      {UniqueFeaturesIcon.map((sitm) => {
                        if (sitm.id == itm.icon_id) {
                          return (
                            <img
                              src={`${process.env.REACT_APP_FILE_PATH}${sitm?.icon_path}`}
                              width="25px"
                              height="24px"
                            />
                          );
                        }
                      })}
                    </Grid>
                    <Grid xs={12} sm={5.8}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Announcement_tab_title || "Title"}
                      </StyledSecondaryTypography>
                      <TypographyDefault mt={0.5}>
                        {itm?.title}
                      </TypographyDefault>

                      <StyledSecondaryTypography mt={2}>
                        {LanguageData?.Description || "Description"}
                      </StyledSecondaryTypography>
                      <TypographyDefault mt={0.5}>
                        {itm?.description}
                      </TypographyDefault>
                    </Grid>
                  </>
                );
              })}
            </Grid>

            {/* FacilityIMages */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Facility_Images || " Facility Images"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  columnGap={3}
                  rowGap={2}
                  padding="20px 16px 20px 16px"
                >
                  {FacilityImages.map((itm) => (
                    <img
                      src={itm.src}
                      width="120px"
                      height="120px"
                      style={{ borderRadius: "10px" }}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>

            {/* Terms & Condition */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Terms_And_Condition || "Terms & Conditions"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <Stack
                  direction="column"
                  rowGap={1}
                  sx={{
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                    "& li": { padding: "5px !important" },
                  }}
                >
                  {parse(textEditorvalue)}
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Stack
              py={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#F9FAFB",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <StyledPrimaryDefaultTypography>
                {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
              </StyledPrimaryDefaultTypography>
              <StyledSecondaryTypography>
                {LanguageData?.Scroll_mobile ||
                  "Scroll on the mobile phone to review details"}
              </StyledSecondaryTypography>
            </Stack>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                my={6.5}
                sx={{
                  border: "1px solid #E5E7EB",
                  width: "375px",
                  height: "750px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                  <BackArrowMobileIcon />
                  <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                    {DetailsBookingData?.facility_Details?.title}
                  </Typography>
                  <StyledPrimaryDefaultTypography mt={2}>
                    {ReviewBuilding(
                      DetailsBookingData?.facility_Details?.location
                    )}
                    ,{" "}
                    {Reiviewlevel(
                      DetailsBookingData?.facility_Details?.location,
                      DetailsBookingData?.facility_Details?.level
                    )}
                  </StyledPrimaryDefaultTypography>
                  <StyledSecondaryTypography>
                    {DetailsBookingData?.facility_Details?.description}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <TypographyDefault
                    sx={{ fontSize: "12px !important", ml: 2, pb: 1 }}
                  >
                    {LanguageData?.Capcity || "Capacity:"}{" "}
                    {DetailsBookingData?.facility_Details?.max_people}{" "}
                    {LanguageData?.Pax || "Pax"}
                  </TypographyDefault>
                  <ImageCourosel images={FacilityImages} />
                  {/* <img src="https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=600" width="100%" /> */}
                </Stack>
                <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                  {uniqueFeatures?.map((itm) => {
                    return (
                      <Stack columnGap={2} direction="row">
                        {UniqueFeaturesIcon.map((sitm) => {
                          if (sitm.id == itm.icon_id) {
                            return (
                              <img
                                src={`${process.env.REACT_APP_FILE_PATH}${sitm?.icon_path}`}
                                width="25px"
                                height="24px"
                                style={{ paddingTop: "4px" }}
                              />
                            );
                          }
                        })}
                        <Stack gap="4px">
                          <SecondaryTypography>
                            {itm?.title}
                          </SecondaryTypography>
                          <StyledSecondaryTypography>
                            {itm?.description}{" "}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(lastUpdate).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => setEditFacilityStatusModal(true)}
                variant="Standard"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {/* {showDeleteModal && <AlertModal modalDatas={deleteModalDatas} confirmFunction={} closeFunction={} />} */}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => navigate("/manage")}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Box mb={5}>
          <PrimaryTypography mb={1}>
            {LanguageData?.Edit_Facility || "Edit Facility"}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Edit_Facility_Des ||
              " Edit or schedule edit details of the facility below. Once edit date is reached, it will be updated on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={3.87}
                onClick={() => Continue(itm.tle, "")}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {addblocks.Details_Booking && Details_Booking()}
        {addblocks.Features_Images && Features_Images()}
        {addblocks.Review_Information && ReviewInformation()}
      </Box>

      <Modal open={EditFacilityStatusModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: { xs: "70%", sm: "50", md: "40%", lg: "35%" },
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Facility_Status_Des || "Facility Status"}{" "}
              </PrimaryTypography>
              <Close onClick={() => closeModal()} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              {Boolean(EditFacilitytatusData) && (
                <>
                  <Stack direction="row" alignItems="center">
                    <StyledSecondaryDefaultTypography
                      sx={{ color: "#1F2A37 !important" }}
                    >
                      {" "}
                      Selected facility has {
                        FacilityManageList?.booking_count
                      }{" "}
                      bookings.
                    </StyledSecondaryDefaultTypography>
                    <SecondaryTypography
                      ml={1}
                      sx={{ color: "#B54708 !important" }}
                    >
                      {FacilityManageList?.booking_count} bookings
                    </SecondaryTypography>
                  </Stack>
                  <StyledSecondaryDefaultTypography
                    sx={{ mt: 1, color: "#1F2A37 !important" }}
                  >
                    {LanguageData?.Edit_Update ||
                      "Select when you would like the edit to be updated."}
                  </StyledSecondaryDefaultTypography>
                </>
              )}
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => setEditFacilitytatusData(1)}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  EditFacilitytatusData == "1"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                backgroundColor={
                  EditFacilitytatusData == "1" ? "#EDF6FA" : "#F9FAFB"
                }
                borderRadius="8px"
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Immediate || "Immediate"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Refelect_Immediate ||
                      "Changes will be reflected immediately."}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="single"
                    checked={EditFacilitytatusData == "1"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => {
                  setEditFacilitytatusData(2);
                  setScheduleDateTime(null);
                }}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  EditFacilitytatusData == "2"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                sx={{
                  backgroundColor:
                    EditFacilitytatusData == "2" ? "#EDF6FA" : "#F9FAFB",
                  borderRadius: "8px",
                }}
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Schedule || "Schedule"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Schedule_Des ||
                      "Changes will be reflected after the selected date."}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="multiple"
                    checked={EditFacilitytatusData == "2"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              {EditFacilitytatusData == "2" && (
                <DateTimePicker
                  onChange={(e) => setScheduleDateTime(new Date(e?.$d))}
                  format="DD MMM YYYY, HH:mm"
                  disablePast
                  slotProps={{
                    textField: { placeholder: "Select date and time" },
                  }}
                  renderValue={() => "kghb"}
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  sx={DatePickerStyle}
                />
              )}
            </Box>

            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => closeModal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                disabled={
                  EditFacilitytatusData == "1"
                    ? Boolean(!EditFacilitytatusData)
                    : Boolean(!ScheduleDateTime)
                }
                onClick={() => {
                  EditFacility();
                  SetPostLoader(true);
                }}
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
