import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as XlsIcon } from "../../../../Icons/Xls.svg";
import { ReactComponent as Close } from "../../../../Icons/BorderAlertClose.svg";

import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { Toaster } from "../../../../CommonComponents/modal";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  ErrorTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  Dialog,
  Modal,
  Stack,
  TextField,
  CircularProgress,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCommonAreaByProperty,
  getUserAndRole,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { changeUserData } from "../../../../Utils";

export default function ReviewUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId, UserAndRoleList } = useSelector(
    (state) => state.PropertyDetails
  );
  const { LanguageData } = useSelector((state) => state.Language);
  const { UserManage } = useSelector((state) => state.Login);
  const [loading, setloading] = useState(true);

  const [UserRoleData, setUserRoleData] = useState([]);

  useEffect(() => {
    if (UserAndRoleList?.length == 0) {
      setloading(true);
      dispatch(getUserAndRole(propertyId));
    }
  }, []);

  useEffect(() => {
    if (UserAndRoleList.success) {
      if (UserAndRoleList.data.property_role.length > 0) {
        setUserRoleData(UserAndRoleList.data.property_role);
        setloading(false);
      }
    }
  }, [UserAndRoleList]);

  return (
    <>
      {loading ? (
        <Stack
          minHeight="400px"
          direction="row"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          backgroundColor="#FFFFFF"
          mb={3}
          sx={{ border: "1px solid #E5E7EB", borderRadius: "0 0 8px 8px" }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        UserRoleData.map((itm, index) => {
          return (
            <Box
              key={index}
              mb={3.5}
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: index !== 0 ? "8px" : "0 0 8px 8px",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: "8px 8px 0 0 ",
                  borderBottom: "1px solid #E5E7EB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SecondaryTypography>
                  {itm?.department_name}
                </SecondaryTypography>
                {UserManage?.settings && (
                  <Button
                    onClick={() => {
                      navigate("EditUserRole");
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Box>
              <Stack direction="column" rowGap={1.3} padding="24px 10px">
                {itm?.id == 1 && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      border: "1px solid #E5E7EB",
                      borderRadius: "12px",
                      padding: "16px",
                    }}
                  >
                    <SecondaryTypography>
                      {LanguageData?.Business_Account ||
                        "Business Account Owner (Default)"}
                    </SecondaryTypography>
                  </Box>
                )}
                {itm?.role.map((sitm, sindex) => {
                  return (
                    <Box
                      key={sindex}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        border: "1px solid #E5E7EB",
                        borderRadius: "12px",
                        padding: "16px",
                      }}
                    >
                      <SecondaryTypography>{sitm?.title}</SecondaryTypography>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          );
        })
      )}
    </>
  );
}
