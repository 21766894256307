import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
  ErrorTypography,
} from "../../Theme";
import moment from "moment";
import { ReactComponent as ProfileEditIcon } from "../../Icons/ProfileEdit.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PasswordVisible } from "../../Icons/PasswordVisible.svg";
import { ReactComponent as PasswordInvisible } from "../../Icons/PasswordInvisible.svg";
import { ReactComponent as ProfileSyncIcon } from "../../Icons/ProfileSync.svg";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import OtpInput from "react18-input-otp";
import axiosInstance from "../../Redux/AxiosInstance";
import { ReactComponent as SuccessAlertIcon } from "../../Icons/SuccessAlertProfile.svg";
import { ReactComponent as CloseIcon } from "../../Icons/ProfileSettingsCloseIcon.svg";
import AlertModal from "../../CommonComponents/modal";
import { passwordCancelData } from "../../Data";
import { ReactComponent as ErrorIcon } from "../../Icons/InputErrorIcon.svg";
import { ReactComponent as SuccessIcon } from "../../Icons/InputSuccessIcon.svg";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordTrigger, setPasswordTrigger] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
  const [confirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState();
  const [isError, setIsError] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);

  const handleOtpChange = (value) => {
    setOtp(value);
    setIsError("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (forgotPassword) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          setShowResend(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, forgotPassword]);

  useEffect(() => {
    if (otp.length === 6) {
      SetPostLoader(true);
      const verifyData = {
        email: userProfileDetails.email,
        otp_code: otp,
        type: 2,
      };
      axiosInstance
        .post(`${process.env.REACT_APP_API_URL}verify_otp`, verifyData)
        .then((res) => {
          console.log(res);
          const verify = res.data?.success;
          if (verify) {
            setPasswordTrigger(true);
            setOtp("");
            setForgotPassword(false);
            setIsError("");
            setSeconds(60);
            setShowResend(false);
            SetPostLoader(false);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error == "Invalid Code") {
            SetPostLoader(false);
            setIsError("Error! Wrong OTP entered. Please re-enter.");
          } else {
            SetPostLoader(false);
            showToast("Something went wrong. Please try again later", "error");
          }
        });
    }
  }, [otp]);

  const handlePasswordContinue = () => {
    if (currentPassword.trim()) {
      SetPostLoader(true);
      const token = localStorage.getItem("accessToken");

      if (token) {
        const formData = { password: currentPassword };

        axiosInstance
          .post(`${process.env.REACT_APP_API_URL}confirmpassword`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const validation = response?.data;
            const status = response?.status;

            if (validation === 1 && status === 200) {
              setPasswordTrigger(true);
              setCurrentPassword("");
              setErrorText("");
              SetPostLoader(false);
            }
          })
          .catch((err) => {
            SetPostLoader(false);
            console.log(err);
            setErrorText("Incorrect Password. Please try again.");
          });
      }
    } else {
      setErrorText("Error! No Inputs detected");
    }
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleNewPasswordChange = (e) => {
    setErrorText("");
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    setNewPasswordError(!validatePassword(newPasswordValue));
    setNewPasswordSuccess(validatePassword(newPasswordValue));
    setErrorText("");
  };

  const handleConfirmPasswordChange = (e) => {
    setErrorText("");
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setConfirmPasswordError(!validatePassword(confirmPasswordValue));
    setConfirmPasswordSuccess(validatePassword(confirmPasswordValue));
  };

  const handlePasswordUpdate = () => {
    if (newPassword.trim() === confirmPassword.trim()) {
      SetPostLoader(true);
      const formData = {
        password: newPassword,
        password_confirmation: confirmPassword,
      };
      axiosInstance
        .post(`${process.env.REACT_APP_API_URL}auth_update_password`, formData)
        .then((res) => {
          const validate = res.data?.success;
          if (validate) {
            setPasswordTrigger(false);
            setErrorText("");
            setNewPassword("");
            setConfirmPassword("");
            setNewPasswordError(false);
            setConfirmPasswordError(false);
            setNewPasswordSuccess(false);
            setConfirmPasswordSuccess(false);
            SetPostLoader(false);
            showToast(res.data?.message);
          } else {
            setErrorText("");
            setNewPassword("");
            setConfirmPassword("");
          }
        })
        .catch(() => {
          SetPostLoader(false);
          showToast("Something went wrong. Please try again later", "error");
        });
    } else {
      setErrorText("Password do not match.");
    }
  };

  const handleClosePassword = () => {
    setCancelModal(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleConfirmCancelPassword = () => {
    setNewPassword("");
    setConfirmPassword("");
    setCancelModal(false);
    setPasswordTrigger(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setNewPasswordSuccess(false);
    setConfirmPasswordSuccess(false);
    setErrorText("");
  };

  useEffect(() => {
    SetPostLoader(true);
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}user_details`)
      .then((res) => {
        setUserProfileDetails(res.data.data?.user_details);
        SetPostLoader(false);
      })
      .catch(() => {
        SetPostLoader(false);
      });
  }, []);

  const handleForgotPassword = () => {
    setForgotPassword(true);
    setSeconds(60);
    const forgotPasswordData = { email: userProfileDetails.email };
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}forgot_password`,
      forgotPasswordData
    );
  };

  const resendOtp = () => {
    const forgotPasswordData = { email: userProfileDetails.email };
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}forgot_password`,
      forgotPasswordData
    );
    setSeconds(60);
    setShowResend(false);
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {cancelModal && (
        <AlertModal
          closeFunction={() => setCancelModal(false)}
          modalDatas={passwordCancelData}
          confirmFunction={handleConfirmCancelPassword}
        />
      )}
      {forgotPassword ? (
        <Box>
          <BackArrow
            style={{ cursor: "pointer" }}
            onClick={() => {
              setForgotPassword(false);
              setOtp("");
              setSeconds(60);
              setShowResend(false);
              setIsError("");
            }}
          />
          <Box sx={{ marginTop: "20px" }}>
            <PrimaryTypography>
              {" "}
              {userProfileDetails?.full_name}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              Last Updated:{" "}
              {moment
                .utc(userProfileDetails?.last_updated)
                .local()
                .format("DD MMM YYYY,HH:mm")}
            </StyledSecondaryTypography>
          </Box>
          <Box
            sx={{
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
              marginTop: "30px",
            }}
          >
            <SecondaryTypography>Forget Password</SecondaryTypography>
          </Box>
          <Box
            sx={{ padding: "20px 16px 20px 16px", backgroundColor: "#FFFFFF" }}
          >
            <SecondaryTypography>Please enter your OTP</SecondaryTypography>
            <TypographyDefault>
              An OTP has been sent to your email {userProfileDetails.email}
            </TypographyDefault>

            <OtpInput
              isInputNum={true}
              hasErrored={isError}
              errorStyle={{
                backgroundColor: "#FEE4E2",
                color: "#912018",
                fontSize: "30px",
                fontWeight: 500,
              }}
              numInputs={6}
              inputStyle={{
                border: "none",
                height: "60px",
                width: "55px",
                borderRadius: "7px",
                color: "#6C737F",
                fontSize: "30px",
                fontWeight: 500,
                backgroundColor: "#FCFCFD",
                margin: "30px 10px 0px 0px",
                marginBottom: !isError ? "30px" : "initial",
              }}
              value={otp}
              onChange={handleOtpChange}
            />
            {isError && <ErrorTypography>{isError}</ErrorTypography>}
            {!showResend && (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#0E4965",
                  marginTop: isError ? "20px" : "initail",
                }}
              >
                Resend OTP in {seconds} Sec
              </Typography>
            )}
            {showResend && (
              <Button
                onClick={resendOtp}
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                sx={{ marginTop: "20px" }}
              >
                Resend OTP
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            <PrimaryTypography>
              {userProfileDetails?.full_name}
            </PrimaryTypography>
            <StyledSecondaryServiceTypography>
              Last Updated:{" "}
              {moment
                .utc(userProfileDetails?.last_updated)
                .local()
                .format("DD MMM YYYY,HH:mm")}
            </StyledSecondaryServiceTypography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px 8px 0px 0px",
              marginTop: "20px",
            }}
          >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                sx={{
                  width: "50%",
                  maxWidth: "none",
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#6C737F",
                  borderBottom: "1px solid #E5E7EB",
                }}
                label="Profile"
              />
              <Tab
                sx={{
                  width: "50%",
                  maxWidth: "none",
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#6C737F",
                  borderBottom: "1px solid #E5E7EB",
                }}
                label="Password"
              />
            </Tabs>
            {tabValue === 0 && (
              <>
                {" "}
                <Box
                  sx={{
                    backgroundColor: "#FCFCFD",
                    borderBottom: "1px solid #E5E7EB",
                    height: "53px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 16px",
                  }}
                >
                  <SecondaryTypography>Personal Details</SecondaryTypography>
                  <Button
                    sx={{
                      color: "#0E5E84",
                      fontSize: "14px",
                      fontWeight: 600,
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      backgroundColor: "#FFFFFF",
                    }}
                    onClick={() => navigate("/profile-settings/edit-profile")}
                  >
                    Edit
                    <ProfileEditIcon style={{ marginLeft: "8px" }} />
                  </Button>
                </Box>
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      Contact Number
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {userProfileDetails?.phone}
                    </TypographyDefault>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      Address
                    </StyledSecondaryTypography>

                    <TypographyDefault style={{ width: "252px" }}>
                      {userProfileDetails?.address}
                    </TypographyDefault>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "12px 16px 20px 16px",
                    borderTop: "1px solid #F3F4F6",
                  }}
                >
                  <StyledSecondaryTypography>
                    Postal Code
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {" "}
                    {userProfileDetails?.zipcode}
                  </TypographyDefault>
                </Grid>
                <Box
                  sx={{
                    backgroundColor: "#FCFCFD",
                    borderBottom: "1px solid #E5E7EB",
                    borderTop: "1px solid #E5E7EB",
                    height: "53px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 16px",
                  }}
                >
                  <SecondaryTypography>Emergency Contact</SecondaryTypography>
                  <Button
                    sx={{
                      color: "#0E5E84",
                      fontSize: "14px",
                      fontWeight: 600,
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      backgroundColor: "#FFFFFF",
                    }}
                    onClick={() => navigate("/profile-settings/edit-profile")}
                  >
                    Edit
                    <ProfileEditIcon style={{ marginLeft: "8px" }} />
                  </Button>
                </Box>
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      First Name
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {userProfileDetails.emergency_contacts?.first_name}
                    </TypographyDefault>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      Last Name
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {userProfileDetails.emergency_contacts?.last_name}
                    </TypographyDefault>
                  </Grid>
                </Grid>
                <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                  <Grid sx={{ p: 2 }} container rowSpacing={3}>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        Email Address
                      </StyledSecondaryTypography>

                      <TypographyDefault>
                        {userProfileDetails.emergency_contacts?.email}
                      </TypographyDefault>
                    </Grid>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        Contact Number
                      </StyledSecondaryTypography>

                      <TypographyDefault>
                        {" "}
                        {userProfileDetails.emergency_contacts?.phone}
                      </TypographyDefault>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "12px 16px 20px 16px",
                    borderTop: "1px solid #F3F4F6",
                  }}
                >
                  <StyledSecondaryTypography>
                    Relationship
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {userProfileDetails.emergency_contacts?.relationship}
                  </TypographyDefault>
                </Grid>
              </>
            )}
          </Box>
          {tabValue === 1 && (
            <>
              {" "}
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Box sx={{ padding: "24px 16px 24px 16px" }}>
                  <SecondaryTypography>Update password</SecondaryTypography>
                  <TypographyDefault>
                    Keep your personal information protected by updating your
                    password.
                  </TypographyDefault>
                </Box>
                {newPasswordError || confirmPasswordError ? (
                  <Box sx={{ padding: "8px 16px 10px 16px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <ErrorIcon style={{ marginRight: "10px" }} />
                      <StyledSecondaryTypography>
                        Minimum 8 characters
                      </StyledSecondaryTypography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <ErrorIcon style={{ marginRight: "10px" }} />
                      <StyledSecondaryTypography>
                        One uppercase, lowercase, number & symbol
                      </StyledSecondaryTypography>
                    </Box>
                  </Box>
                ) : newPasswordSuccess || confirmPasswordSuccess ? (
                  <Box sx={{ padding: "8px 16px 10px 16px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <SuccessIcon style={{ marginRight: "10px" }} />
                      <StyledSecondaryTypography>
                        Minimum 8 characters
                      </StyledSecondaryTypography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <SuccessIcon style={{ marginRight: "10px" }} />
                      <StyledSecondaryTypography>
                        One uppercase, lowercase, number & symbol
                      </StyledSecondaryTypography>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}

                {passwordTrigger ? (
                  <Box
                    sx={{
                      padding: "20px 16px 20px 16px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <SecondaryTypography>New Password</SecondaryTypography>
                      <TextField
                        sx={{
                          width: "400px",
                          backgroundColor: errorText ? "#FEE4E2" : "initial",
                          "& .MuiInputBase-root": {
                            color: errorText ? "#912018" : "#111927",
                          },
                        }}
                        type={newPasswordType}
                        style={{
                          color: errorText ? "#912018" : "initial",
                        }}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        placeholder="Enter Password"
                        InputProps={{
                          autoComplete: "off",
                          disableUnderline: true,
                          endAdornment:
                            newPasswordType === "password" ? (
                              <PasswordInvisible
                                style={{ cursor: "pointer" }}
                                onClick={() => setNewPasswordType("text")}
                              />
                            ) : (
                              <PasswordVisible
                                style={{ cursor: "pointer" }}
                                onClick={() => setNewPasswordType("password")}
                              />
                            ),
                        }}
                      />
                    </Box>
                    <Box sx={{ marginRight: "80px" }}>
                      <SecondaryTypography>
                        Confirm New Password
                      </SecondaryTypography>
                      <TextField
                        sx={{
                          width: "400px",
                          backgroundColor: errorText ? "#FEE4E2" : "initial",
                          "& .MuiInputBase-root": {
                            color: errorText ? "#912018" : "#111927",
                          },
                        }}
                        type={confirmPasswordType}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Enter Password"
                        InputProps={{
                          autoComplete: "off",
                          disableUnderline: true,
                          endAdornment:
                            confirmPasswordType === "password" ? (
                              <PasswordInvisible
                                style={{ cursor: "pointer" }}
                                onClick={() => setConfirmPasswordType("text")}
                              />
                            ) : (
                              <PasswordVisible
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setConfirmPasswordType("password")
                                }
                              />
                            ),
                        }}
                      />
                      {errorText && (
                        <ErrorTypography>{errorText}</ErrorTypography>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ padding: "20px 16px 20px 16px" }}>
                    <SecondaryTypography>Current Password</SecondaryTypography>
                    <TextField
                      sx={{
                        width: "400px",
                        backgroundColor: errorText ? "#FEE4E2" : "initial",
                        "& .MuiInputBase-root": {
                          color: errorText ? "#912018" : "#111927",
                        },
                      }}
                      type={passwordType}
                      value={currentPassword}
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setErrorText("");
                      }}
                      placeholder="Enter Password"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment:
                          passwordType === "password" ? (
                            <PasswordInvisible
                              style={{ cursor: "pointer" }}
                              onClick={() => setPasswordType("text")}
                            />
                          ) : (
                            <PasswordVisible
                              style={{ cursor: "pointer" }}
                              onClick={() => setPasswordType("password")}
                            />
                          ),
                      }}
                    />
                    <ErrorTypography>{errorText}</ErrorTypography>
                  </Box>
                )}

                {passwordTrigger ? (
                  <></>
                ) : (
                  <Box
                    sx={{
                      padding: "0px 16px 16px 16px",
                      cursor: "pointer",
                      width: "100px",
                    }}
                    onClick={handleForgotPassword}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#9DA4AE",
                        textDecoration: "underline",
                      }}
                    >
                      Forget Password
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    borderRadius: "0px 0px 8px 8px",
                    borderTop: "1px solid #F3F4F6",
                    padding: "20px 16px 20px 16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ProfileSyncIcon />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#9DA4AE",
                        marginLeft: "3px",
                      }}
                    >
                      Last Updated at{" "}
                      {moment
                        .utc(userProfileDetails?.last_updated)
                        .local()
                        .format("HH:mm A")}
                    </Typography>
                  </Box>
                  {passwordTrigger ? (
                    <Box>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D2D6DB",
                          backgroundColor: "#FFFFFF",
                          color: "#0E5E84",
                          fontSize: "16px",
                          fontWeight: 600,
                          marginRight: "10px",
                        }}
                        onClick={handleClosePassword}
                      >
                        Cancel{" "}
                      </Button>
                      <Button
                        disabled={
                          !newPasswordSuccess && !confirmPasswordSuccess
                        }
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#E7EFF3",
                          color: "#0E5E84",
                          fontSize: "16px",
                          fontWeight: "600",
                          "&:hover": {
                            backgroundColor: "#CFDFE6",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#F9FAFB",
                            color: "#D2D6DB",
                          },
                        }}
                        onClick={handlePasswordUpdate}
                      >
                        Update
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      <Button
                        disabled={!currentPassword}
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#E7EFF3",
                          color: "#0E5E84",
                          fontSize: "16px",
                          fontWeight: "600",
                          "&:hover": {
                            backgroundColor: "#CFDFE6",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#F9FAFB",
                            color: "#D2D6DB",
                          },
                        }}
                        onClick={handlePasswordContinue}
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ProfileSettings;
