import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  PrimaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import { Stack, Box, Button, Grid } from "@mui/material";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../Icons/MoreInfo.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as CorrectIcon } from "../../../Icons/CorrectIcon.svg";
import { ReactComponent as DashboardIcon } from "../../../Icons/DashboardIcon.svg";
import { ReactComponent as MobileIcon } from "../../../Icons/MobileIcon.svg";
import { ReactComponent as MoreInfoWOBIcon } from "../../../Icons/MoreInfoWOB.svg";
import SystemDetailsModal from "../../../CommonComponents/SystemDetailsModal";
import { setActiveStep } from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";

const DepartmentPermissions = ({
  Management,
  Operations,
  GroundStaff,
  setDisplayScreen,
  commonmodal,
}) => {
  const dispatch = useDispatch();
  const blocks = [Management, Operations, GroundStaff];

  const backStep = () => {
    dispatch(setActiveStep(2));
  };
  return (
    <>
      <Box bgcolor="#F3F4F6">
        <BackArrow
          onClick={backStep}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={4}>
          <PrimaryTypography mb="4px">Users</PrimaryTypography>
          <StyledSecondaryTypography>
            Add, edit and delete staff roles and permissions.{" "}
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="30px">
          {blocks?.map((block, index) => {
            return (
              <Grid key={index} xs={12}>
                <Stack
                  sx={{
                    borderRadius: "8px 8px 0 0 ",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                  direction="row"
                  bgcolor="#FCFCFD"
                  alignItems="center"
                  padding="16px"
                >
                  <Stack
                    gap={3}
                    flexGrow={1}
                    direction="row"
                    alignItems="center"
                  >
                    <PrimaryTypography>{block?.d_name}</PrimaryTypography>
                    {block?.d_name !== "Ground staff" && (
                      <Stack direction="row" alignItems="center">
                        <DashboardIcon />{" "}
                        <TypographyDefault px={1}>Dashboard</TypographyDefault>
                      </Stack>
                    )}
                    <Stack direction="row" alignItems="center">
                      <MobileIcon />{" "}
                      <TypographyDefault px={1}>
                        Mobile Application
                      </TypographyDefault>
                    </Stack>
                  </Stack>
                  {block.d_name === "Management" && (
                    <SystemDetailsModal commonmodal={commonmodal.management} />
                  )}
                  {block.d_name === "Operations" && (
                    <SystemDetailsModal commonmodal={commonmodal.operations} />
                  )}
                  {block.d_name === "Ground staff" && (
                    <SystemDetailsModal commonmodal={commonmodal.groundstaff} />
                  )}
                </Stack>
                <Box
                  component="div"
                  padding="24px 18px 24px 18px"
                  sx={{
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "0 0 8px 8px",
                  }}
                >
                  <Grid container>
                    <Grid xs={12}>
                      <Stack direction="row">
                        <Grid xs={8}>
                          <TypographyDefault
                            sx={{ fontWeight: "600 !important" }}
                          >
                            Functions
                          </TypographyDefault>
                        </Grid>
                        <Grid xs={4}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            {block?.info[0]?.rights?.map((rts, ind) => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap="5px"
                                  key={ind}
                                >
                                  <TypographyDefault
                                    ml={1}
                                    sx={{ fontWeight: "600 !important" }}
                                  >
                                    {rts.name}
                                  </TypographyDefault>
                                  {rts.key === "access" && (
                                    <SystemDetailsModal
                                      commonmodal={commonmodal.access}
                                    />
                                  )}
                                  {rts.key === "manage" && (
                                    <SystemDetailsModal
                                      commonmodal={commonmodal.manage}
                                    />
                                  )}
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid xs={12}>
                      {block?.info?.map((itm, subindex) => {
                        return (
                          <Stack
                            key={subindex}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid xs={8}>
                              <Stack my={2} gap="5px">
                                <TypographyDefault
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {itm?.service_name}
                                </TypographyDefault>
                                <StyledSecondaryTypography
                                  sx={{ fontSize: "12px !important" }}
                                >
                                  {itm.description}
                                </StyledSecondaryTypography>
                              </Stack>
                            </Grid>
                            <Grid xs={4}>
                              <Stack
                                my={2}
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                              >
                                {itm.rights[0].editable === true ? (
                                  <CorrectIcon />
                                ) : (
                                  <CorrectIcon
                                    style={{
                                      stroke: "#E5E7EB",
                                      strokeWidth: "1.5",
                                    }}
                                  />
                                )}
                                {itm.rights[1].editable === true ? (
                                  <CorrectIcon />
                                ) : (
                                  <CorrectIcon
                                    style={{
                                      stroke: "#E5E7EB",
                                      strokeWidth: "1.5",
                                    }}
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </Stack>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            );
          })}
          <Grid xs={12}>
            <Box
              bgcolor="#FFFFFF"
              borderRadius="8px"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 16px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RefreshIcon style={{ marginRight: "5px" }} />
                <StyledSecondaryTypography>
                  Last Updated at {moment(new Date()).format("HH:mm A")}
                </StyledSecondaryTypography>
              </Box>
              <Button
                onClick={() => {
                  setDisplayScreen("addUserAndRole");
                }}
                sx={{
                  backgroundColor: "#E7EFF3",
                  "&:hover": {
                    backgroundColor: "#CFDFE6",
                  },
                }}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DepartmentPermissions;
