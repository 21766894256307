import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { Toaster } from "../../../../CommonComponents/modal";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  TextField,
  Typography,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDetails,
  setTabValue,
  getGeneralDetailsByProperty,
  editGeneralDetails,
  updatePropertyDetailsCompletedSteps,
  setPropertyId,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { changeUserData } from "../../../../Utils";

const ReviewGeneralDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {
    addGeneralDetailsStatus,
    generalDetailsList,
    editGeneralDetailsStatus,
    propertyId,
    propertyCompletedStep,
  } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const { UserManage } = useSelector((state) => state.Login);
  const [response, setResponse] = useState("");
  const [generalDetails, setGeneralDetails] = useState({
    title: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    email: "",
    contact: "",
    phone: "",
    country_code: "",
    details: "",
  });
  const [image, setImage] = useState({ cover: "", logo: "" });
  const [toaster, settoaster] = useState({ message: "", delStatus: false });
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (generalDetailsList?.length == 0) {
      setloading(true);
      dispatch(getGeneralDetailsByProperty(propertyId));
    }
  }, []);

  useEffect(() => {
    if (generalDetailsList.success) {
      let data = generalDetailsList.data.property;
      setGeneralDetails({
        ...generalDetails,
        title: data.title,
        address: data.address,
        country: data.country,
        city: data.city,
        zipcode: data.zipcode,
        email: data.email,
        contact: data.phone,
        phone: data.phone,
        country_code: data.country_code,
        details: data.description,
      });
      setImage({
        ...image,
        cover: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.cover_image}`,
        },
        logo: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.property_logo}`,
        },
      });
      // dispatch(setPropertySliceToNull())
      setloading(false);
    }
  }, [generalDetailsList]);

  // useEffect(() => {
  //     if (editGeneralDetailsStatus != null)
  //         if (editGeneralDetailsStatus.success) {
  //             settoaster({ message: editGeneralDetailsStatus.message, delStatus: false })
  //             dispatch(setPropertySliceToNull())

  //         }
  // }, [editGeneralDetailsStatus])

  return (
    <>
      <Box
        boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
        sx={{ backgroundColor: "#FFFFFF", borderRadius: "0 0 8px 8px" }}
      >
        {loading ? (
          <Stack
            minHeight="400px"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                p: 2,
                borderRadius: "8px 8px 0 0 ",
                borderBottom: "1px solid #E5E7EB",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.General_Property_Details ||
                  "General Property Details"}{" "}
              </SecondaryTypography>
              {UserManage?.settings && (
                <Button
                  onClick={() => {
                    navigate("editGeneralDetails");
                  }}
                  sx={{
                    width: "83px",
                    height: "36px",
                    padding: "8px 2px 8px 6px",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                  }}
                >
                  {" "}
                  {LanguageData?.Common_Edit || "Edit"}{" "}
                  <IconButton sx={{ paddingLeft: "10px" }}>
                    {" "}
                    <EditIcon width="14px" />
                  </IconButton>
                </Button>
              )}
            </Box>
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={12} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Property_Title || "Property Title"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.title}</TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Property_Address || "Property Address"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.address}</TypographyDefault>
              </Grid>
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Country || "Country"}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.country}</TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.City || "City"}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.city}</TypographyDefault>
              </Grid>
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Postal_Code || "Postal Code"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.zipcode}</TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Corporate_Email_Address ||
                    "Corporate Email Address"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.email}</TypographyDefault>
              </Grid>
              <Grid item xs={12} sm={6} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Main_Contact_Number || "Main Contact Number"}
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {generalDetails?.country_code} {generalDetails?.phone}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid item xs={12} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Details_property || "Details of the property"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault>{generalDetails?.details}</TypographyDefault>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                p: 2,
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Property_Logo_Image ||
                  "Property Logo & Cover Image"}
              </SecondaryTypography>
              {UserManage?.settings && (
                <Button
                  onClick={() => {
                    navigate("editGeneralDetails");
                  }}
                  sx={{
                    width: "83px",
                    height: "36px",
                    padding: "8px 2px 8px 6px",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                  }}
                >
                  {" "}
                  {LanguageData?.Common_Edit || "Edit"}{" "}
                  <IconButton sx={{ paddingLeft: "10px" }}>
                    {" "}
                    <EditIcon width="14px" />
                  </IconButton>
                </Button>
              )}
            </Box>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${image.cover.src})`,
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FFFFFF",
                    width: "380px",
                    borderRadius: "8px",
                    p: 4,
                    my: 15,
                  }}
                >
                  <Box
                    sx={{
                      height: "85px",
                      borderRadius: "8px ",
                      width: "85px",
                      padding: "10px ",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                      backgroundImage: `url(${image.logo.src})`,
                    }}
                  ></Box>
                  <PrimaryTypography sx={{ mt: 2 }}>
                    {LanguageData?.Log_account || "Log in to your account"}
                  </PrimaryTypography>
                  <StyledSecondaryTypography sx={{ mt: 1 }}>
                    {LanguageData?.Log_account_des ||
                      "The LOBY portal is for Juristics & Condo Admin access only."}
                  </StyledSecondaryTypography>
                  <TextField
                    disabled
                    sx={{ my: 2 }}
                    placeholder="Email Address"
                    inputProps={{ maxLength: 125 }}
                    InputProps={{ disableUnderline: true }}
                  />
                  <Button variant="contained" disabled>
                    {LanguageData?.Login || "Login"}
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      my: 2,
                    }}
                  >
                    <StyledSecondaryTypography textAlign={"center"}>
                      {" "}
                      {LanguageData?.Remember || "Remember for 30 days"}
                    </StyledSecondaryTypography>
                    <StyledTypography>
                      {LanguageData?.forget || "Forgot password?"}{" "}
                    </StyledTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {/* <Toaster Toaster={toaster} setToaster={settoaster} /> */}
    </>
  );
};

export default ReviewGeneralDetails;
