import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { Toaster } from "../../../../CommonComponents/modal";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  Stack,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDetails,
  getMyDetails,
  setTabValue,
  getGeneralDetailsByProperty,
  editGeneralDetails,
  updatePropertyDetailsCompletedSteps,
  setPropertyId,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { changeUserData } from "../../../../Utils";

export default function ReivewAdministrator() {
  const navigate = useNavigate();
  const initialState = { myDetails: false, review: false };
  const dispatch = useDispatch();
  const { myDetailsList } = useSelector((state) => state.PropertyDetails);
  const { savePropertyDetailsAsDraftStatus, UserManage } = useSelector(
    (state) => state.Login
  );
  const [displayScreen, setDisplayScreen] = useState({
    myDetails: true,
    review: false,
  });
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    billingemail: "",
    phone: "",
  });
  const [billingemail, setbillingemail] = useState(true);
  const [loading, setloading] = useState(false);
  const { LanguageData } = useSelector((state) => state.Language);

  useEffect(() => {
    if (myDetailsList?.length == 0) {
      setloading(true);
      dispatch(getMyDetails());
    }
  }, []);

  useEffect(() => {
    if (myDetailsList != null) {
      if (myDetailsList.success) {
        let data =
          myDetailsList?.data?.user_details || myDetailsList?.data?.my_details;
        let newEmergency = data.emergency_contacts;
        let newWorkdetails = data.access_details;

        const {
          email,
          first_name,
          last_name,
          phone,
          country_code,
          billing_email,
        } = data;
        // setPersonalDetails({ ...personalDetails, })
        if (Boolean(data.billing_email)) {
          setPersonalDetails({
            ...personalDetails,
            email: email,
            firstName: first_name,
            lastName: last_name,
            contact:
              country_code != null && phone != null ? country_code + phone : "",
            billingemail: billing_email,
            phone: phone != null ? phone : "",
            country_code: country_code != null ? country_code : "",
          });
          setbillingemail(false);
        } else {
          setPersonalDetails({
            ...personalDetails,
            email: email,
            firstName: first_name,
            lastName: last_name,
            contact:
              country_code != null && phone != null ? country_code + phone : "",
            phone: phone != null ? phone : "",
            country_code: country_code != null ? country_code : "",
          });
        }
        setloading(false);
      }
    }
  }, [myDetailsList]);

  return (
    <Box
      boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
      sx={{ backgroundColor: "#FFFFFF", borderRadius: "0 0 8px 8px" }}
    >
      {loading ? (
        <Stack
          minHeight="400px"
          direction="row"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          backgroundColor="#FFFFFF"
          mb={3}
          sx={{ border: "1px solid #E5E7EB", borderRadius: "0 0 8px 8px" }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box
            sx={{
              p: 2,
              borderRadius: "8px 8px 0 0 ",
              borderBottom: "1px solid #E5E7EB",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Administrator_Details ||
                "Administrator Details"}{" "}
            </SecondaryTypography>
            {UserManage?.settings && (
              <Button
                onClick={() => {
                  navigate("editAdministrator");
                }}
                sx={{
                  width: "83px",
                  height: "36px",
                  padding: "8px 2px 8px 6px",
                  border: "1px solid #D2D6DB",
                  borderRadius: "8px",
                }}
              >
                {" "}
                {LanguageData?.Common_Edit || "Edit"}{" "}
                <IconButton sx={{ paddingLeft: "10px" }}>
                  {" "}
                  <EditIcon width="14px" />
                </IconButton>
              </Button>
            )}
          </Box>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.First_Name_no || "First Name"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {personalDetails?.firstName}
              </TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Last_Name_no || "Last Name"}{" "}
              </StyledSecondaryTypography>
              <TypographyDefault>{personalDetails?.lastName}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Email_Address || "Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{personalDetails?.email}</TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {personalDetails?.country_code} {personalDetails?.phone}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Billing_Email_Address || "Billing Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {personalDetails?.billingemail || personalDetails?.email}
              </TypographyDefault>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
