import { Box, Modal, Stack } from "@mui/material";
import React from "react";
import { PrimaryTypography, TypographyDefault } from "../Theme";
import { ReactComponent as Close } from "../Icons/BorderAlertClose.svg";

const TypesInformationModal = ({ label, data, setFunction }) => {
  return (
    <Modal onClose={() => setFunction(false)} open={true}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ borderRadius: "8px", backgroundColor: "#FFF", width: "50%" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #F3F4F6",
              p: 2,
              alignItems: "center",
            }}
          >
            <PrimaryTypography>{label}</PrimaryTypography>
            <Close onClick={() => setFunction(false)} />
          </Box>
          <Stack sx={{ padding: "40px 24px 40px 24px" }} rowGap={4}>
            <Box>
              <PrimaryTypography>{data[0].title}</PrimaryTypography>
              <TypographyDefault sx={{ mt: 2 }}>
                {data[0].description}
              </TypographyDefault>
            </Box>
            <Box>
              <PrimaryTypography>{data[1].title}</PrimaryTypography>
              <TypographyDefault sx={{ mt: 2 }}>
                {data[1].description}
              </TypographyDefault>
            </Box>
            <Box>
              <PrimaryTypography>{data[2].title}</PrimaryTypography>
              <TypographyDefault sx={{ mt: 2 }}>
                {data[2].description}
              </TypographyDefault>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default TypesInformationModal;
