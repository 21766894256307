import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  PaginationItem,
  Pagination,
  Typography,
  OutlinedInput,
  Menu,
  MenuItem,
  Checkbox,
  colors,
  CircularProgress,
} from "@mui/material";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  StyledOutlinedInput,
  SecondaryTypography,
  StyledTableRow,
  StyledTableCell,
  TypographyDefault,
  StyledSecondaryTypography,
} from "../../Theme";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ParcelCollectionTableHeaders } from "../../Data/index";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../Icons/DisableDownload.svg";
import { getChip } from "../../Utils";
import axiosInstance from "../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ParcelCollection = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const [initialRender, setInitialRender] = useState(true);
  const [parcelData, SetParcelData] = useState([]);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setsortData] = useState("new");
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const filterOpen = Boolean(filterAnchorEl);
  const [checkedItems, setCheckedItems] = useState({
    Collected: false,
    Uncollected: false,
  });
  const [SearchData, setSearchData] = useState("");
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleTotalCount = (key) => {
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [key]: !prevItems[key],
    }));
  };

  const totalCheckCount = Object.values(checkedItems).filter(
    (isChecked) => isChecked
  ).length;

  const getAllParcellCollectionData = async () => {
    const data = SelectedFilter.join(",");
    setTableloading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/parcel_collection?page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${sortData}&search=${SearchData}`
      )
      .then((res) => {
        SetParcelData(res.data.data?.parcel_collection);
        setPaginationData(res?.data?.data?.pagination_details);
        setTableloading(false);
        // console.log(res.data.data?.parcel_collection);
      })
      .catch((err) => {
        console.error(err);
        SetParcelData([]);
        setTableloading(false);
      });
  };

  useEffect(() => {
    getAllParcellCollectionData();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllParcellCollectionData();
      }
    } else {
      setInitialRender(false);
    }
  }, [rowsPerPage, SelectedFilter, sortData, SearchData]);

  const handleSearch = async (data) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/parcel_collection?search=${data}`
      );
      SetParcelData(response?.data?.data?.parcel_collection);
      setPaginationData(response?.data?.data?.pagination_details);
      setRowsPerPage(response?.data?.data?.pagination_details?.per_page);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedFilter = (e, id) => {
    setCurrentPage("1");
    const Data = SelectedFilter?.find((itm) => itm == id);
    if (Data) {
      const Data = SelectedFilter?.filter((itm) => itm !== id);
      setSelectedFilter(Data);
    } else {
      setSelectedFilter((itm) => [...itm, id]);
    }
  };

  const handleReviewNavigate = (id) => {
    navigate(`/parcel-collection/${id}`);
  };

  const DownloadReport = async (type) => {
    const data = SelectedFilter.join(",");
    try {
      setLoading(true);
      setDownloadAnchorEl(false);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/parcel_collection/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${sortData}&search=${SearchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `ParcelCollection_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {" "}
            {LanguageData?.Parcel_Collection || "Parcel Collection"}
          </PrimaryTypography>
          <StyledSecondaryServiceTypography>
            {LanguageData?.Parcel_Collection_Def ||
              "Manage and track residents’ parcels on the LOBY system."}
          </StyledSecondaryServiceTypography>
        </Box>
        {UserManage?.["parcel-collection"] && (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={parcelData.length === 0 || loading}
            >
              {" "}
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {parcelData.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            height: "40px",
            overflowX: "scroll",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <StyledOutlinedInput
            sx={{ flexGrow: 1 }}
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            placeholder="Search"
            startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
          />
          <Box
            onClick={(e) => setSortAnchorEl(e.currentTarget)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "8px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <SortIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>
              {LanguageData?.sort_filter || "Sort"}{" "}
            </SecondaryTypography>
          </Box>
          <Box
            onClick={(e) => setFilterAnchorEl(e.currentTarget)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "8px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <FilterIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>
              {" "}
              {LanguageData?.filter_common || "Filters"}{" "}
            </SecondaryTypography>
          </Box>
        </Box>
      </Box>
      {parcelData.length > 0 ? (
        <>
          {" "}
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  {ParcelCollectionTableHeaders.map((value) => (
                    <StyledTableCell key={value}>{value}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {parcelData.map((request) => (
                  <StyledTableRow
                    key={request?.id}
                    onClick={() => handleReviewNavigate(request?.id)}
                  >
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.uuid || "-"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {" "}
                        {getChip(request.status?.name)}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.parcel_owner}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request.collected_by?.name || "-"}
                      </TypographyDefault>
                      <Typography
                        style={{ color: "#6C737F", fontSize: "14px" }}
                      >
                        {request.collected_by?.collected_date &&
                          moment
                            .utc(request.collected_by?.collected_date)
                            .local()
                            .format("DD MMM YYYY • HH:mm")}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <TypographyDefault>
                        {request.unit_details?.unit_no || "-"}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {moment
                          .utc(request?.created_at)
                          .local()
                          .format("DD MMM YYYY • HH:mm")}
                      </TypographyDefault>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.received_by}
                      </TypographyDefault>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderTopStyle: "none !important",
              border: "1px solid #E5E7EB",
              borderRadius: "0px 0px 8px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2.5,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
              >
                {LanguageData?.Common_Show || "Show :"}
              </Typography>
              {/* <OutlinedInput
                inputProps={{ min: 5, max: 30, step: 5 }}
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                type="number"
                sx={{
                  height: "40px",
                  width: "100px",
                  ml: 2,
                  borderRadius: "8px",
                }}
              /> */}
              <Box
                onClick={(e) => setshowAnchorEl(e.currentTarget)}
                sx={{
                  width: "95px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  ml: 2,
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <Typography
                  sx={{ fontSize: "15px" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {rowsPerPage} rows{" "}
                  <NumberInputIcon style={{ paddingLeft: "5px" }} />
                </Typography>
              </Box>
            </Box>
            <Pagination
              page={currentPage}
              size="medium"
              count={paginationData?.last_page}
              onChange={(e, value) => setCurrentPage(value)}
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: PreviousIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: "50vh",
            borderRadius: "0px, 0px, 8px, 8px",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F9FAFB",
          }}
        >
          {Tableloading ? (
            <CircularProgress />
          ) : (
            <>
              <ExclamatoryIcon style={{ marginBottom: "5px" }} />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "470px",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                }}
              >
                {LanguageData?.No_Data_Parcel || "No Parcels Here"}
              </Typography>
              <StyledSecondaryTypography
                color="#9DA4AE !important"
                textAlign="center"
                width="470px"
                height="88px"
                fontSize="16px"
                fontWeight="400px"
                lineHeight="22.4px"
              >
                {LanguageData?.No_Data_Parcel_def ||
                  "There are no parcels to display at the moment. Parcels will be displayed in the system once the condo administrators accept parcel and enter it in the mobile app."}
              </StyledSecondaryTypography>
            </>
          )}
        </Box>
      )}
      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("csv")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("pdf")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "new" ? "#eff3f5" : "" }}
          onClick={() => {
            setsortData("new");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Newest || "Newest"}
          </styledTyphographyDefault>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setsortData("old");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {" "}
            {LanguageData?.Oldest_Filter || "Oldest"}
          </styledTyphographyDefault>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setsortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Most_recently_updated || "Most recently updated"}
          </styledTyphographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ width: "312px", height: "459px" }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem
          onClick={(e) => {
            handleSelectedFilter(e, 1);
          }}
        >
          <Checkbox
            sx={{ width: "42px", height: "42px" }}
            checked={Boolean(SelectedFilter.find((item) => 1 == item))}
            // onClick={() => handleTotalCount("Collected")}
            icon={<CheckboxIcon />}
          />
          <Typography> {LanguageData?.Collected || "Collected"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSelectedFilter(e, 2);
          }}
        >
          <Checkbox
            sx={{ width: "42px", height: "42px" }}
            checked={Boolean(SelectedFilter.find((item) => 2 == item))}
            // onClick={() => handleTotalCount("Uncollected")}
            icon={<CheckboxIcon />}
          />
          <Typography>{LanguageData?.Uncollected || "Uncollected"}</Typography>
        </MenuItem>
        {/* <Box
          sx={{
            width: "70px",
            height: "20px",
            borderRadius: "8px",
            border: "1px solid #D2D6DB",
            padding: "10px 8px 10px 8px",
            backgroundColor: "#FFFFFF",
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            marginLeft: "45px",
          }}
        >
          <Typography
            sx={{
              height: "24px",
              width: "30px",
              fontSize: "16px",
              fontWeight: 500,
              color: "#111927",
            }}
          >
          {LanguageData?.filter_common || "Filter"}  
          </Typography>
          <Box
            sx={{
              width: "24px",
              height: "24px",
              backgroundColor: "#0E5E84",
              borderRadius: "50%",
              position: "absolute",
              marginLeft: "47px",
              color: "#FFFFFF",
            }}
          >
            {totalCheckCount > 0 && (
              <Typography sx={{ marginLeft: "7px" }}>
                {totalCheckCount}
              </Typography>
            )}
          </Box>
        </Box> */}
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ParcelCollection;
