import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { lazy } from "react";
import { ReactComponent as StatusActiveIcon } from "../../Icons/StatusActive.svg";
import { ReactComponent as StatusInactiveIcon } from "../../Icons/StatusInactive.svg";
import { ReactComponent as StatusCompleteIcon } from "../../Icons/StatusComplete.svg";
import { getChip } from "../../Utils";
import moment from "moment";
import { StyledSecondaryTypography } from "../../Theme";
import { useSelector } from "react-redux";

const StatusStepper = ({ status }) => {
  const { LanguageData } = useSelector((state) => state.Language);

  var submit = status.find((itm) => itm.status.key == "submitted");
  var invest = status.find((itm) => itm.status.key == "investigating");
  var completed = status.find((itm) => itm.status.key == "completed");
  var cancelled = status.find((itm) => itm.status.key == "cancelled");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E5E7EB",
          borderRadius: "8px",
          width: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Box sx={{ p: 3, borderBottom: "1px solid #E5E7EB" }}>
          <Typography
            sx={{ color: "#111927", fontSize: "14px", fontWeight: 500 }}
          >
            {LanguageData?.Status_Update || "Status Update"}
          </Typography>
        </Box>
        <Stepper orientation="vertical" sx={{ px: 2, py: 3 }}>
          {/* {status?.map((itm, index) => {
                        const { status, genral_comment, created_at } = itm
                        return (
                            < Step active >
                                <StepLabel icon={<StatusInactiveIcon />}>{getChip(status?.key)}</StepLabel>
                                <StepContent sx={{ px: 4, py: 1.5 }}>
                                    <StyledSecondaryTypography>{genral_comment}</StyledSecondaryTypography>
                                    <StyledSecondaryTypography sx={{ mt: 2 }}>{moment(created_at).format("DD MMM YYYY • HH:mm")}</StyledSecondaryTypography>
                                </StepContent>
                            </Step>
                        )
                    })} */}
          {Boolean(cancelled) && (
            <Step active>
              <StepLabel icon={<StatusInactiveIcon />}>
                {getChip("cancelled")}
              </StepLabel>
              <StepContent sx={{ px: 4, py: 1.5 }}>
                <StyledSecondaryTypography>
                  {cancelled?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ mt: 2 }}>
                  {moment
                    .utc(cancelled?.created_at)
                    .local()
                    .format("DD MMM YYYY • HH:mm")}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}
          {Boolean(completed) && (
            <Step active>
              <StepLabel icon={<StatusCompleteIcon />}>
                {getChip("completed")}
              </StepLabel>
              <StepContent sx={{ px: 4, py: 1.5 }}>
                <StyledSecondaryTypography>
                  {completed?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ mt: 2 }}>
                  {moment
                    .utc(completed?.created_at)
                    .local()
                    .format("DD MMM YYYY • HH:mm")}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}
          {Boolean(invest) && (
            <Step active>
              <StepLabel
                icon={
                  completed || cancelled ? (
                    <StatusInactiveIcon />
                  ) : (
                    <StatusActiveIcon />
                  )
                }
              >
                {getChip("investigating")}
              </StepLabel>
              <StepContent sx={{ px: 4, py: 1.5 }}>
                <StyledSecondaryTypography>
                  {invest?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ mt: 2 }}>
                  {moment
                    .utc(invest?.created_at)
                    .local()
                    .format("DD MMM YYYY • HH:mm")}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}
          {Boolean(submit) && (
            <Step active>
              <StepLabel
                icon={
                  invest || completed || cancelled ? (
                    <StatusInactiveIcon />
                  ) : (
                    <StatusActiveIcon />
                  )
                }
              >
                {getChip("submitted")}
              </StepLabel>
              <StepContent sx={{ px: 4, py: 1.5 }}>
                <StyledSecondaryTypography>
                  {submit?.genral_comment}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography sx={{ mt: 2 }}>
                  {moment
                    .utc(submit?.created_at)
                    .local()
                    .format("DD MMM YYYY • HH:mm")}
                </StyledSecondaryTypography>
              </StepContent>
            </Step>
          )}

          {/* {reversedItems?.map((itm) => {
                        const { created_at } = itm
                        const { full_name, unit_details, role_details } = itm.updated_user

                        return (
                            (itm?.status?.key == "cancelled")
                                ?
                                (< Step active >
                                    <StepLabel icon={<StatusInactiveIcon />}>{getChip('cancelled')}</StepLabel>
                                    <StepContent sx={{ px: 4, py: 1.5 }}>
                                        <StyledSecondaryTypography>{full_name} ({role_details.department_id} , {role_details.title}) has updated the case to cancelled and provided comments.</StyledSecondaryTypography>
                                        <StyledSecondaryTypography sx={{ mt: 2 }}>{moment(created_at).format("DD MMM YYYY . HH:mm")}</StyledSecondaryTypography>
                                    </StepContent>
                                </Step>)
                                :

                                (itm?.status?.key == "completed") ? (
                                    < Step active >
                                        <StepLabel icon={<StatusCompleteIcon />}>{getChip('completed')}</StepLabel>
                                        <StepContent sx={{ px: 4, py: 1.5 }}>
                                            <StyledSecondaryTypography>{full_name} ({role_details.department_id} , {role_details.title}) has updated the case to completed.</StyledSecondaryTypography>
                                            <StyledSecondaryTypography sx={{ mt: 2 }}>{moment(created_at).format("DD MMM YYYY . HH:mm")}</StyledSecondaryTypography>
                                        </StepContent>
                                    </Step>) :
                                    (itm?.status?.key == "investigating") ?
                                        (<Step active >
                                            <StepLabel icon={completed ? <StatusInactiveIcon /> : <StatusActiveIcon />}>{getChip('investigating')}</StepLabel>
                                            <StepContent sx={{ px: 4, py: 1.5 }}>
                                                <StyledSecondaryTypography>{full_name} ({role_details.department_id} , {role_details.title}) has updated the case to investigating.</StyledSecondaryTypography>
                                                <StyledSecondaryTypography sx={{ mt: 2 }}>{moment(created_at).format("DD MMM YYYY . HH:mm")}</StyledSecondaryTypography>
                                            </StepContent>
                                        </Step>) :
                                        (itm?.status?.key == 'submitted')
                                            ?
                                            (< Step active >
                                                <StepLabel icon={invest ? <StatusInactiveIcon /> : <StatusActiveIcon />}>{getChip('submitted')}</StepLabel>
                                                <StepContent sx={{ px: 4, py: 1.5 }}>
                                                    <StyledSecondaryTypography>{full_name} (Resident, {unit_details?.unit_no}) has submitted a fault report.`</StyledSecondaryTypography>
                                                    <StyledSecondaryTypography sx={{ mt: 2 }}>{moment(created_at).format("DD MMM YYYY . HH:mm")}</StyledSecondaryTypography>
                                                </StepContent>
                                            </Step>)
                                            : ""
                        )

                    })} */}
          {/* <Step active>
                        <StepLabel icon={<StatusActiveIcon />}>{getChip('active')}</StepLabel>
                        <StepContent sx={{px:4,py:1.5}}>
                            <StyledSecondaryTypography>wefwefgwefwefwe</StyledSecondaryTypography>
                            <StyledSecondaryTypography sx={{mt:2}}>23 May 2023</StyledSecondaryTypography>
                        </StepContent>
                    </Step>
                    <Step active>
                        <StepLabel icon={<StatusActiveIcon/>}>{getChip('active')}</StepLabel>
                        <StepContent>
                            webfkwhebvfh
                        </StepContent>
                    </Step> */}
        </Stepper>
      </Box>
    </>
  );
};

export default StatusStepper;
