import React, { useState, useEffect } from "react";
import AddCommonArea from "./addCommonArea";
import ReviewCommonArea from "./reviewCommonArea";
import AlertModal from "../../../CommonComponents/modal";
import { logoutModalData } from "../../../Data";
import { savePropertyDetailsAsDraftToNull } from "../../../Redux/Feature/Login/loginSlice";
import { getAllCommonAreaByProperty ,getAllBuildingByProperty} from '../../../Redux/Feature/PropertyDetails/propertyDetailsSlice'
import { useDispatch, useSelector } from "react-redux";

const CommonAreaHandler = () => {
    let initialState = { addCommonArea: false, reviewCommonArea: false }
    const dispatch = useDispatch()
    const { savePropertyDetailsAsDraftStatus } = useSelector((state) => state.Login)
    let { generalDetailsList, propertyId } = useSelector((state) => state.PropertyDetails)
    let [displayScreen, setDisplayScreeen] = useState({ addCommonArea: true, reviewCommonArea: false })
    const [commonArea, setCommonArea] = useState([])


    useEffect(() => {
        dispatch(getAllCommonAreaByProperty(propertyId))
        dispatch(getAllBuildingByProperty(propertyId))
    }, [propertyId])

    const handleDisplayScreen = (value) => {
        setDisplayScreeen({
            ...initialState,
            [value]: true
        })
    }
    return (
        <>
            {savePropertyDetailsAsDraftStatus && <AlertModal closeFunction={() => dispatch(savePropertyDetailsAsDraftToNull())} modalDatas={logoutModalData} />}
            {displayScreen.addCommonArea && <AddCommonArea commonArea={commonArea} setCommonArea={setCommonArea} setDisplayScreen={handleDisplayScreen} />}
            {displayScreen.reviewCommonArea && <ReviewCommonArea commonArea={commonArea} setDisplayScreen={handleDisplayScreen} />}
        </>
    )
}

export default CommonAreaHandler;