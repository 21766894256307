import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PrimaryTypography,
  StyledTableRow,
  StyledTableCell,
  TypographyDefault,
  StyledSecondaryTypography,
  StyledSecondaryDefaultTypography,
  StyledOutlinedInput,
  SecondaryTypography,
} from "../../../Theme";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as SearchIcon } from "../../../Icons/Search.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { PostLoaderModal } from "../../../CommonComponents/modal";
import { showToast } from "../../../CommonComponents/Toaster";
import { ReactComponent as Delete } from "../../../Icons/DeleteAdhoc.svg";

const Adhoc = () => {
  const navigate = useNavigate();
  const { UserManage } = useSelector((state) => state.Login);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [AdhocData, setAdhoceData] = useState([]);
  const AdhocTableHeader = ["Title"];
  const [id, setId] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);
  const [Tableloading, setTableloading] = useState(false);
  const [formName, setFormName] = useState("");
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [SearchData, setSearchData] = useState("");
  const [sortData, setSortData] = useState("ASC");
  console.log(sortData);
  console.log(SearchData);

  const handleNavigate = () => {
    navigate("add-adhoc");
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleEdit = (id) => {
    navigate(`edit-adhoc/${id}`);
  };

  const handleOpenPdf = (path) => {
    const filePath = `${process.env.REACT_APP_FILE_PATH}${path}`;
    const newTab = window.open(filePath, "_blank");
    newTab?.focus();
  };

  const handleDelete = (id, name) => {
    setFormName(name);
    setId(id);
    setDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    SetPostLoader(true);
    axiosInstance
      .delete(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/adhoc_form/${id}`
      )
      .then((res) => {
        if (res.data?.success) {
          setDeleteModal(false);
          setTrigger(!trigger);
          SetPostLoader(false);
          showToast(res.data?.message, "delete");
        }
      })
      .catch(() => {
        setDeleteModal(false);
        SetPostLoader(false);
        showToast("Something went wrong. Please try again later", "error");
      });
  };

  useEffect(() => {
    setTableloading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/adhoc?sort=${sortData}&column=title&search=${SearchData}`
      )
      .then((res) => {
        setAdhoceData(res.data?.data?.adhoc_service_request_forms);
        setTableloading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error == "No service request found") {
          setAdhoceData([]);
          setTableloading(false);
        } else {
          setTableloading(true);
        }
      });
  }, [trigger, SearchData, sortData]);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <PrimaryTypography>
            {LanguageData?.Miscellaneous_Form || "Miscellaneous Form"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Miscellaneous_Form_SUB ||
              "The downloadable miscellaneous forms displayed on the LOBY mobile application."}
          </StyledSecondaryTypography>
        </Box>

        {UserManage?.["service-request"] && (
          <Button   style={{
            // height:'40px',
            padding:"10px 16px",
            borderRadius :"8px",
            backgroundColor:'#0E5E84',
            color:'#FFF',
            fontSize:'14px',
            fontWeight:600
          }} onClick={handleNavigate}>
            {LanguageData?.management_staff_add_button || "Add"}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            height: "40px",
            minWidth: "580px",
          }}
        >
          <StyledOutlinedInput
            sx={{ flexGrow: 1 }}
            placeholder="Search"
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "8px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer"  , backgroundColor:'#F9FAFB'},
            }}
            onClick={(e) => setSortAnchorEl(e.currentTarget)}
          >
            <SortIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>
              {LanguageData?.sort_filter || "Sort"}{" "}
            </SecondaryTypography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #E5E7EB",
          backgroundColor: "#F9FAFB",
        }}
      >
        <TableContainer sx={{ borderRadius: "6px" }}>
          <Table>
            {AdhocData.length > 0 && (
              <TableHead>
                <StyledTableRow>
                  {AdhocTableHeader.map((value, index) => (
                    <StyledTableCell key={index}>{value}</StyledTableCell>
                  ))}
                  {UserManage?.["service-request"] && (
                    <StyledTableCell></StyledTableCell>
                  )}
                </StyledTableRow>
              </TableHead>
            )}
            <TableBody>
              {AdhocData.length > 0 ? (
                AdhocData.map((request) => (
                  <StyledTableRow key={request?.id}>
                    <StyledTableCell>
                      <TypographyDefault>
                        {request?.title || "--"}
                      </TypographyDefault>
                    </StyledTableCell>
                    {UserManage?.["service-request"] && (
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #D2D6DB",
                              backgroundColor: "#E7EFF3",
                              color: "#0E5E84",
                              fontWeight: 600,
                              height: "40px",
                              padding: "10px 16px",
                              marginRight: "15px",
                              "&:hover": {
                                backgroundColor: "#CFDFE6",
                              },
                            }}
                            onClick={() => handleOpenPdf(request?.path)}
                          >
                            {LanguageData?.Download || "Download"}{" "}
                          </Button>
                          <Button
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #D2D6DB",
                              marginRight: "15px",
                              color: "#0E5E84",
                              fontWeight: 600,
                              height: "40px",
                              padding: "10px 16px",
                            }}
                            variant="outlined"
                            onClick={() => handleEdit(request?.id)}
                          >
                            {LanguageData?.Common_Edit || "Edit"}{" "}
                          </Button>
                          <Delete
                            style={{ marginRight: "15px", cursor: "pointer" }}
                            onClick={() =>
                              handleDelete(request?.id, request?.title)
                            }
                          />
                        </Box>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "300px",
                    justifyContent: "center",
                  }}
                >
                  {Tableloading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <ExclamatoryIcon />
                      <Typography
                        sx={{
                          color: "#9DA4AE",
                          fontSize: "16px",
                          fontWeight: 600,
                          my: 2,
                        }}
                      >
                     {LanguageData?.No_Data_Adhoc || "No Miscellaneous Form Here"}
                      </Typography>
                      <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                     {LanguageData?.No_Data_Adhoc_Des ||"There are no miscellaneous form to display at the moment."}  
                      </StyledSecondaryDefaultTypography>
                      <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                     {LanguageData?.No_Data_Adhoc_Des_three ||"You may add form by clicking the top right Add button."}
                      </StyledSecondaryDefaultTypography>
                    </>
                  )}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {deleteModal && (
        <Modal open={true}>
          <Box
            sx={{
              position: "absolute",
              border: "none",
              width: "100%",
              height: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "40%",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px 24px 16px 24px",
                  borderBottom: "1px solid #F3F4F6",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PrimaryTypography>
               {LanguageData?.Delete_Adhoc || "Confirm to delete form permanently"}   
                </PrimaryTypography>
                <Close style={{ cursor: "pointer" }} onClick={handleClose} />
              </Box>
              <Box sx={{ padding: "30px 30px 30px 30px" }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#4D5761",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      <span
                        style={{
                          color: "#4D5761",
                          fontSize: "14px",
                          fontWeight: 800,
                        }}
                      >
                        {formName}
                      </span>{" "}
                  {LanguageData?.Delete_Sec || "will be removed from the form list."}    
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      marginTop: "10px",
                      marginRight: "30px",
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "0px 0px 8px 8px",
                    backgroundColor: "#FFFFFF",
                    paddingTop: "24px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0E5E84",
                    }}
                  >
                  {LanguageData?.Cancel || "Cancel"}  
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    style={{
                      borderRadius: "8px",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#FFFFFF",
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "#912018",
                    }}
                  >
                 {LanguageData?.Common_Confirm || "Confirm"}   
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setSortData("ASC");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Alpha_Asc || "Alphabetical Asc. (A - Z)"}
          </styledTyphographyDefault>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortData("DESC");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
           {LanguageData?.Alpha || "Alphabetical Dsc. (Z - A)"} 
          </styledTyphographyDefault>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Adhoc;
