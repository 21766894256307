import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PrimaryTypography, StyledSecondaryTypography } from "../../../Theme";
import { Stack, Box, Tab, Tabs } from "@mui/material";
import ReviewGeneralDetails from "./General/ReivewGeneralDetails";
import {
  setPropertySliceToNull,
  setTabValue,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import ReviewProperty from "./Property/ReviewProperty";
import ReviewCommonArea from "./CommonArea/ReviewCommonArea";
import ReviewUser from "./User/ReviewUser";
import ReivewAdministrator from "./Administrator/ReivewAdministrator";
import { showToast } from "../../../CommonComponents/Toaster";

export default function PropertySettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {
    TabValue,
    editGeneralDetailsStatus,
    editUserAndRoleStatus,
    addMyDetailsStatus,
    editCommonAreaStatus,
    addUserAndRoleStatus,
    addCommonAreaStatus,
  } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [tabValue, settabValue] = useState(TabValue);

  useEffect(() => {
    settabValue(TabValue);
  }, [TabValue]);

  const handleTabValue = (e, value) => {
    dispatch(setTabValue(value));
  };

  useEffect(() => {
    if (editGeneralDetailsStatus != null) {
      if (editGeneralDetailsStatus.success) {
        showToast(editGeneralDetailsStatus?.message);
        dispatch(setPropertySliceToNull());
      }
    }

    if (editUserAndRoleStatus != null) {
      if (editUserAndRoleStatus.success) {
        showToast(editUserAndRoleStatus?.message);
        dispatch(setPropertySliceToNull());
      }
    } else if (addUserAndRoleStatus != null) {
      if (addUserAndRoleStatus.success) {
        showToast(addUserAndRoleStatus?.message);
        dispatch(setPropertySliceToNull());
      }
    }

    if (addMyDetailsStatus != null) {
      if (addMyDetailsStatus.success) {
        showToast(addMyDetailsStatus.message);
        dispatch(setPropertySliceToNull());
      }
    }

    if (editCommonAreaStatus != null) {
      if (editCommonAreaStatus?.success) {
        showToast(editCommonAreaStatus?.message);
        dispatch(setPropertySliceToNull());
      }
    } else if (addCommonAreaStatus != null) {
      if (addCommonAreaStatus?.success) {
        showToast(addCommonAreaStatus?.message);
        dispatch(setPropertySliceToNull());
      }
    }
  }, [
    editGeneralDetailsStatus,
    editUserAndRoleStatus,
    addMyDetailsStatus,
    editCommonAreaStatus,
    addUserAndRoleStatus,
    addCommonAreaStatus,
  ]);

  return (
    <>
      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {" "}
              {LanguageData?.Property_Settings || "Property Settings"}{" "}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.Property_Des ||
                "Your property's information and vital building details."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>

        <Box mt={3} component="div" borderRadius="8px 8px 8px 8px" width="100%">
          <Box
            boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
            backgroundColor="#FFFFFF"
            sx={{
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab sx={{ width: "20%", maxWidth: "none" }} label="General" />
              <Tab sx={{ width: "20%", maxWidth: "none" }} label="Property" />
              <Tab
                sx={{ width: "20%", maxWidth: "none" }}
                label="Common Area"
              />
              <Tab sx={{ width: "20%", maxWidth: "none" }} label="User" />
              <Tab
                sx={{ width: "20%", maxWidth: "none" }}
                label="Administrator"
              />
            </Tabs>
          </Box>

          {tabValue == 0 && <ReviewGeneralDetails />}
          {tabValue == 1 && <ReviewProperty />}
          {tabValue == 2 && <ReviewCommonArea />}

          {tabValue == 3 && <ReviewUser />}
          {tabValue == 4 && <ReivewAdministrator />}
        </Box>
      </Box>
    </>
  );
}
