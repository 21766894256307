import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../CommonComponents/styles/Toaster.css";

export const showToast = (message, type) => {
  console.log(message, type);
  if (type == "delete") {
    return toast(message);
  } else if (type == "error") {
    return toast.error(message);
  } else {
    return toast.success(message);
  }
};

const Toaster = () => {
  return (
    <ToastContainer
      autoClose="2000"
      position="bottom-center"
      toastClassName="custom-toast"
      hideProgressBar={true}
    />
  );
};

export default Toaster;
