import {
  Box,
  Button,
  Checkbox,
  OutlinedInput,
  Pagination,
  PaginationItem,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../Icons/DisableDownload.svg";
import moment from "moment";
import { FaultReportColumn } from "../../Data";
import axiosInstance from "../../Redux/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { getChip } from "../../Utils";

const FaultReport = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const navigate = useNavigate();
  const [initialRender, setInitialRender] = useState(true);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [rowsPerPage, setRowsPerPage] = useState("20");
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [faultList, setFaultList] = useState([]);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [SelectedsortData, setSelectedsortData] = useState("new");
  const [searchData, setSearchData] = useState("");
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getAllFaultReport = async () => {
    setTableloading(true);
    setFaultList([]);
    const data = SelectedFilter.join(",");

    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${SelectedsortData}&search=${searchData}`
      )
      .then((data) => {
        setFaultList(data?.data?.data?.fault_report);
        setPaginationData(data?.data?.data?.pagination_details);
        setTableloading(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err?.response?.data?.error);
        if (err?.response?.data?.error == "No data found") {
          setFaultList([]);
        }
        setTableloading(false);
      });
  };

  useEffect(() => {
    getAllFaultReport();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllFaultReport();
      }
    } else {
      setInitialRender(false);
    }
  }, [rowsPerPage, SelectedFilter, SelectedsortData, searchData]);

  const getAllFilteredData = async (ids) => {
    const data = ids.join(",");

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}`
      );
      setFaultList(response?.data?.data?.fault_report);
      setPaginationData(response?.data?.data?.pagination_details);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllSortData = async (data) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}?sort=${data}`
      );
      setFaultList(response?.data?.data?.fault_report);
      setPaginationData(response?.data?.data?.pagination_details);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (data) => {
    // setSearchData(data)
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}?search=${data}`
      );
      setFaultList(response?.data?.data?.fault_report);
      setPaginationData(response?.data?.data?.pagination_details);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterDD = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDropDownDatas?type=fault_report_status`
      );
      setFilterData(response?.data?.data?.drop_down);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFilterDD();
  }, []);

  const handleNavigate = (id) => {
    navigate(`reviewFaultReport/${id} `);
  };

  const handleSelectedFilter = (e, id) => {
    setCurrentPage("1");
    const Data = SelectedFilter?.find((itm) => itm == id);
    if (Data) {
      const Data = SelectedFilter?.filter((itm) => itm !== id);
      setSelectedFilter(Data);
    } else {
      setSelectedFilter((itm) => [...itm, id]);
    }
  };

  const DownloadReport = async (type) => {
    const data = SelectedFilter.join(",");
    try {
      setLoading(true);
      setDownloadAnchorEl(false);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${SelectedsortData}&search=${searchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Fault_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {" "}
            {LanguageData?.Fault_report || "Fault Reports"}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Fault_report_sub ||
              "A table of all the fault reports submitted on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.["fault-reports"] && (
          <Button
            onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
            sx={{
              fontWeight: "600",
              width: "fit-content",
              color: "#0E5E84",
              backgroundColor: "#E7EFF3",
              padding: "10px 16px 10px 16px",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
              "&.Mui-disabled": {
                backgroundColor: "#F9FAFB",
                color: "#D2D6DB",
              },
            }}
            disabled={faultList.length === 0 || loading}
          >
            {loading ? (
              <Box sx={{ width: "40px", marginTop: "5px" }}>
                <CircularProgress size={15} />
              </Box>
            ) : (
              <>
                {faultList.length > 0 ? (
                  <DownloadIcon style={{marginRight:'8px' , padding:'3px'}} />
                ) : (
                  <DisabledDownloadIcon style={{marginRight:'8px' , padding:'3px'}} />
                )}
              </>
            )}
            {LanguageData?.home_download_report || "Download Report"}
          </Button>
        )}

        <Menu
          sx={{ m: 0.5 }}
          open={downloadOpen}
          anchorEl={downloadAnchorEl}
          onClose={() => setDownloadAnchorEl(null)}
        >
          <MenuItem>
            <TypographyDefault
              onClick={() => DownloadReport("csv")}
              py={0.5}
              sx={{
                fontWeight: "500 !important",
                color: "#1F2A37 !important",
                display: "flex",
              }}
            >
              <CsvIcon width={40} />
              {LanguageData?.Download_csv || "Download as CSV file"}
            </TypographyDefault>
          </MenuItem>
          <MenuItem>
            <TypographyDefault
              onClick={() => DownloadReport("pdf")}
              py={0.5}
              sx={{
                fontWeight: "500 !important",
                color: "#1F2A37 !important",
                display: "flex",
              }}
            >
              <PdfIcon width={40} />
              {LanguageData?.Download_pdf || "Download as PDF file"}
            </TypographyDefault>
          </MenuItem>
        </Menu>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            height: "40px",
            minWidth: "580px",
          }}
        >
          <StyledOutlinedInput
            sx={{ flexGrow: 1 }}
            placeholder="Search"
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
          />
          <Box
            onClick={(e) => {
              setSortAnchorEl(e.currentTarget);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "6px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer" , backgroundColor:'#F9FAFB' },
            }}
          >
            <SortIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>
              {LanguageData?.sort_filter || "Sort"}{" "}
            </SecondaryTypography>
          </Box>
          <Box
            onClick={(e) => {
              setFilterAnchorEl(e.currentTarget);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "6px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer" , backgroundColor:'#F9FAFB' },
            }}
          >
            <FilterIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>
              {" "}
              {LanguageData?.filter_common || "Filters"}{" "}
            </SecondaryTypography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #E5E7EB", borderRadius:"0px 0px 8px 8px"  }}>
        {faultList?.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {FaultReportColumn.map((value) => {
                      return (
                        <StyledTableCell>
                          {value === "Checkbox" ? (
                            <Checkbox size="small" icon={<CheckboxIcon />} />
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {faultList?.map((value, index) => {
                    return (
                      <StyledTableRow
                        sx={{ height: "70px" }}
                        key={index}
                        onClick={() => handleNavigate(value.id)}
                      >
                        <StyledTableCell>{value?.uuid}</StyledTableCell>
                        <StyledTableCell>{value?.title}</StyledTableCell>
                        <StyledTableCell>
                          {getChip(value?.status?.key)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment
                            .utc(value?.updated_at)
                            .local()
                            .format("DD MMM YYYY, HH:mm")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {value?.user_personal_details?.full_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment
                            .utc(value?.created_at)
                            .local()
                            .format("DD MMM YYYY, HH:mm")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                // border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show :"}
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage('1') }} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
            }}
          >
            {Tableloading ? (
              <CircularProgress />
            ) : (
              <>
                <ExclamatoryIcon />
                <Typography
                  sx={{
                    color: "#9DA4AE",
                    fontSize: "16px",
                    fontWeight: 600,
                    my: 2,
                  }}
                >
                  {LanguageData?.No_Fault || "No Fault Reports Here"}
                </Typography>
                <StyledSecondaryTypography>
                  {LanguageData?.No_Fault_Haeder ||
                    "There are no active or past fault reports at the moment."}{" "}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography>
                  {LanguageData?.No_Fault_Haeder_sub ||
                    " Residents are able to submit fault reports via the LOBY mobile app. Once submitted, you will see it here for processing."}
                </StyledSecondaryTypography>
              </>
            )}
          </Box>
        )}
      </Box>

      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: SelectedsortData == "new" ? "#eff3f5" : "" }}
          onClick={() => {
            setSelectedsortData("new");
            setSortAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.Newest || "Newest"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: SelectedsortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSelectedsortData("old");
            setSortAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.Oldest_Filter || "Oldest"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{
            backgroundColor: SelectedsortData == "recent" ? "#eff3f5" : "",
          }}
          onClick={() => {
            setSelectedsortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <Typography> {LanguageData?.Most_recently_updated||"Most recently updated"}  </Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        {filterData?.map((itm, index) => (
          <MenuItem
            onClick={(e) => {
              handleSelectedFilter(e, itm?.id);
            }}
          >
            <Checkbox
              sx={{ width: "42px", height: "42px" }}
              value={itm?.id}
              checked={Boolean(SelectedFilter.find((item) => itm?.id == item))}
              icon={<CheckboxIcon />}
            />
            <Typography sx={{ mr: 3 }}>{itm?.name}</Typography>
          </MenuItem>
        ))}

        {/* 
                <MenuItem>
                    <Checkbox icon={<CheckboxIcon />} />
                    <Typography>Investigating</Typography>
                </MenuItem>
                <MenuItem>
                    <Checkbox icon={<CheckboxIcon />} />
                    <Typography>Completed</Typography>
                </MenuItem>
                <MenuItem>
                    <Checkbox icon={<CheckboxIcon />} />
                    <Typography>Cancelled</Typography>
                </MenuItem> */}
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default FaultReport;
