import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { Box, Grid, Skeleton } from "@mui/material";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axiosInstance from "../../../Redux/AxiosInstance";

const ReviewBooking = () => {
  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const { id } = useParams();
  const [bookingDetails, setBookingDetails] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}facility_booking/property/${propertyId}/booking/${id}`
      )
      .then((response) => {
        setBookingDetails(response.data.data?.facility_booking);
        setloading(false);
      })
      .catch((err) => console.err(err));
  }, []);

  return (
    <>
      <BackArrow
        style={{
          cursor: "pointer",
          marginBottom: "30px",
        }}
        onClick={() => navigate("/booking")}
      />
      <Box>
        <PrimaryTypography>
          {loading ? (
            <Skeleton width={250} />
          ) : (
            "Facility Booking #" + bookingDetails?.uuid
          )}
        </PrimaryTypography>
        {loading ? (
          <Skeleton width={200} />
        ) : moment(bookingDetails?.created_at) ===
          moment(bookingDetails?.updated_at) ? (
          <StyledSecondaryServiceTypography>
            {LanguageData?.Common_Created_at || "Created At:"}
            {moment
              .utc(bookingDetails.created_at)
              .local()
              .format("DD MMM YYYY, HH:mm")}
          </StyledSecondaryServiceTypography>
        ) : (
          <StyledSecondaryServiceTypography>
            {LanguageData?.Common_Last || "Last Updated:"}
            {moment
              .utc(bookingDetails.updated_at)
              .local()
              .format("DD MMM YYYY, HH:mm")}
          </StyledSecondaryServiceTypography>
        )}
      </Box>
      {loading ? (
        <Skeleton variant="rounded" height={400} width="100%" sx={{ mt: 3 }} />
      ) : (
        <Box>
          <Box
            sx={{
              borderRadius: "8px 8px 0 0",
              borderTop: "1px solid #E5E7EB",
              borderBottom: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
              marginTop: "20px",
            }}
          >
            <SecondaryTypography>
              {LanguageData?.Booking_Details || "Booking Details"}{" "}
            </SecondaryTypography>
          </Box>
          <Box sx={{ backgroundColor: "#FFFFFF" }}>
            <Box sx={{ padding: "12px 16px 20px 16px" }}>
              <Grid container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Facility || "Facility"}
                  </StyledSecondaryTypography>
                  {bookingDetails?.facility_details?.location_details
                    ?.is_part_of_building === 0 ? (
                    <TypographyDefault>
                      {bookingDetails?.facility_details?.title || "--"}
                    </TypographyDefault>
                  ) : (
                    <TypographyDefault>
                      {bookingDetails?.facility_details?.title}•{" "}
                      {
                        bookingDetails?.facility_details?.location_details
                          ?.building?.building_name
                      }
                      •{" "}
                      {bookingDetails?.facility_details?.location_details?.level
                        ?.level || "--"}
                    </TypographyDefault>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Date || "Date"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.booking_date
                      ? moment
                          .utc(bookingDetails?.booking_date)
                          .local()
                          .format("dddd, D MMMM YYYY")
                      : "--"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                padding: "12px 16px 20px 16px",
                borderTop: "1px solid #F3F4F6",
              }}
            >
              <Grid container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Booking_Id || "Booking ID"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.uuid || "--"}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Time || "Time"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.display_time || "--"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              borderTop: "1px solid #E5E7EB",
              borderBottom: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
            }}
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Resident_Details || "Resident Details"}
            </SecondaryTypography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <Box
              sx={{
                padding: "12px 16px 20px 16px",
              }}
            >
              <Grid container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Submitted_By || "Submitted By"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.user_details?.full_name || "--"}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Email_Address || "Email Address"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.user_details?.email || "--"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                padding: "12px 16px 20px 16px",
                borderTop: "1px solid #F3F4F6",
              }}
            >
              <Grid container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {" "}
                    {LanguageData?.Mobile || "Mobile"}{" "}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.user_details?.phone || "--"}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Unit || "Unit"}{" "}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {bookingDetails?.user_details?.unit_details?.unit_no ||
                      "--"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ReviewBooking;
