import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
  LanguageData: {},
  LanguageDataStatus: null,
};

export const getLanguageDatas = createAsyncThunk(
  "getLanguageDatas",
  async (data, { rejectWithValue }) => {
    const { languageId } = data;
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}${languageId}/getContent?device_type=web`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const LanguageConvertionReducer = createSlice({
  name: "LanguageConversion",
  initialState,
  reducers: {
    setLanguageToNull: (state) => {
      state.LanguageData = {};
      state.LanguageDataStatus = null;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getLanguageDatas.pending, (state) => {
      state.LanguageDataStatus = null;
    });
    builders.addCase(getLanguageDatas.fulfilled, (state, action) => {
      state.LanguageData = action.payload.data;
      state.LanguageDataStatus = true;
    });
    builders.addCase(getLanguageDatas.rejected, (state) => {
      state.LanguageData = {};
      state.LanguageDataStatus = null;
    });
  },
});

export const { setLanguageToNull } = LanguageConvertionReducer.actions;
export default LanguageConvertionReducer.reducer;
