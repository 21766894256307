import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as StepperActive } from "../../Icons/StepperActive.svg";
import { ReactComponent as StepperInActive } from "../../Icons/StepperInactive.svg";
import { ReactComponent as StepperSuccess } from "../../Icons/StepperSuccess.svg";
import { ReactComponent as NestedStepperActive } from "../../Icons/NestedStepperActive.svg";
import { ReactComponent as NestedStepperInactive } from "../../Icons/NestedStepperInactive.svg";

import { property_details_stepper } from "../../Data";
import {
  StepperDisabledPrimaryTypography,
  StepperDisabledSecondaryTypography,
  StepperDisabledTypogreaphy,
  StepperPrimaryTypography,
  StepperSecondaryTypography,
  StepperTypogreaphy,
} from "../../Theme";
import GeneralDetailsHandler from "./General Details";
import AddLevelHandler from "./Buildings";
import CommonAreaHandler from "./Common Area";
import AddMyDetailsHandler from "./My Details";
import SystemDeteilsHandler from "./System Details";
import Review from "./Review";
import { useSelector } from "react-redux";

const PropertyDetails = () => {
  const { activeStep, nestedStep } = useSelector(
    (state) => state.PropertyDetails
  );
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [activeSteps, setActiveSteps] = useState(activeStep);
  const scrollRef = useRef();

  useEffect(() => {
    setActiveSteps(activeStep);
  }, [activeStep]);

  useEffect(() => {
    const handleWindowResize = () => {
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const ScrollToTop = () => {
    scrollRef.current.scrollTop = 0;
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: `${innerHeight - 64}px`,
        paddingTop: "64px",
      }}
    >
      {activeSteps !== 5 && (
        <Box
          sx={{
            overflowY: "scroll",
            width: "380px",
            "&::-webkit-scrollbar": { width: "1.5px" },
            "&::-webkit-scrollbar-track": {
              boxShadow: " inset 0 0 1.5px #D3D3D3",
            },
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              p: 2,
              boxSizing: "border-box",
              backgroundColor: "#0E4965",
              color: "#FFFFFF",
            }}
          >
            {property_details_stepper.map((value, index) => {
              return (
                <Step active>
                  <StepLabel
                    sx={{ paddingLeft: "3px" }}
                    icon={
                      activeStep === index ? (
                        <StepperActive />
                      ) : activeStep < index ? (
                        <StepperInActive />
                      ) : (
                        <StepperSuccess />
                      )
                    }
                  >
                    {activeStep < index ? (
                      <StepperDisabledPrimaryTypography
                        sx={{ paddingLeft: "6px" }}
                      >
                        {value.label}
                      </StepperDisabledPrimaryTypography>
                    ) : (
                      <StepperPrimaryTypography sx={{ paddingLeft: "6px" }}>
                        {value.label}
                      </StepperPrimaryTypography>
                    )}
                  </StepLabel>
                  {value.content.map((item, sindex) => {
                    const isActiveStep =
                      activeStep > index ||
                      (activeStep === index && nestedStep > sindex);
                    return (
                      <Step key={sindex}>
                        <StepLabel
                          sx={{ padding: "0 0 0 5.5px" }}
                          StepIconComponent={
                            isActiveStep
                              ? NestedStepperActive
                              : NestedStepperInactive
                          }
                        >
                          {isActiveStep ? (
                            <StepperTypogreaphy sx={{ paddingLeft: "20px" }}>
                              {item.label}
                            </StepperTypogreaphy>
                          ) : (
                            <StepperDisabledTypogreaphy
                              sx={{ paddingLeft: "20px" }}
                            >
                              {item.label}
                            </StepperDisabledTypogreaphy>
                          )}
                        </StepLabel>
                      </Step>
                    );
                  })}
                  {/* <StepContent>
                  {value.content.map((item) => {
                    return (
                      <Box sx={{ my: 1.5, ml: 1 }}>
                        {activeStep < index ?
                          <>
                            <StepperDisabledSecondaryTypography>{item.label}</StepperDisabledSecondaryTypography>
                            <StepperDisabledTypogreaphy>{item.description}</StepperDisabledTypogreaphy>
                          </> :
                          <>
                            <StepperSecondaryTypography>{item.label}</StepperSecondaryTypography>
                            <StepperTypogreaphy>{item.description}</StepperTypogreaphy>
                          </>
                        }

                      </Box>
                    )
                  })}
                </StepContent> */}
                </Step>
              );
            })}
          </Stepper>
        </Box>
      )}
      <Box
        ref={scrollRef}
        sx={{
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          "::-webkit-scrollbar": { display: "none" },
          width: "100%",
          boxSizing: "border-box",
          p: 4,
          backgroundColor: "#F3F4F6",
        }}
      >
        {activeSteps === 0 && <GeneralDetailsHandler />}
        {activeSteps === 1 && <AddLevelHandler />}
        {activeSteps === 2 && <CommonAreaHandler />}
        {activeSteps === 3 && (
          <SystemDeteilsHandler ScrollToTop={ScrollToTop} />
        )}
        {activeSteps === 4 && <AddMyDetailsHandler />}
        {activeSteps === 5 && <Review />}
      </Box>
    </Box>
  );
};

export default PropertyDetails;
