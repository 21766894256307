import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageCarousel = ({ images }) => {

    console.log(images);
    return (
        <Carousel
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={3500}
            showStatus={false}
            showArrows={false}
        >
            {images?.map((itm) => (<div>
                <img src={itm?.src} height="220px" width="100%" style={{objectFit:'cover'}}/>
            </div>))}
        </Carousel>
    );
};

export default ImageCarousel;