import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import {
  ErrorTypography,
  StyledPrimaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
} from "../Theme";
import OtpInput from "react18-input-otp";
import { verifyOtp, verifyEmail } from "../Redux/Feature/Login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { PostLoaderModal } from "../CommonComponents/modal";
import { Typography } from "@mui/material";
import { showToast } from "../CommonComponents/Toaster";

const Otp = ({ email, setDisplayScreen }) => {
  const dispatch = useDispatch();
  const { verifyOtpStatus } = useSelector((state) => state.Login);
  console.log(verifyOtpStatus);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [isError, setIsError] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (otp.length === 6) {
      setLoader(true);
      dispatch(verifyOtp({ email, otp_code: otp, type: 2 }));
    }
  }, [otp]);

  useEffect(() => {
    if (verifyOtpStatus != null) {
      if (verifyOtpStatus.success) {
        setLoader(false);
        setDisplayScreen("createPassword");
      } else if (
        verifyOtpStatus.error === "Invalid Code" &&
        !verifyOtpStatus.success
      ) {
        setLoader(false);
        setIsError(true);
      } else {
        setLoader(false);
        showToast("Something went wrong! Please Try again.", "error");
      }
    }
  }, [verifyOtpStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setShowResend(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleOtp = (value) => {
    setOtp(value);
    setIsError(false);
  };

  const resendOtp = () => {
    setShowResend(false);
    dispatch(verifyEmail({ email }));
    setSeconds(60);
  };
  return (
    <>
      {loader && <PostLoaderModal />}
      <BackArrow
        onClick={() => {
          setDisplayScreen("signup");
        }}
        style={{ marginBottom: "16px" }}
      />
      <StyledPrimaryTypography>Please enter your OTP</StyledPrimaryTypography>
      <StyledSecondaryTypography>
        An OTP has been sent to your email {email}
      </StyledSecondaryTypography>
      <OtpInput
        isInputNum={true}
        hasErrored={isError}
        errorStyle={{
          backgroundColor: "#FEE4E2",
          color: "#912018",
          fontSize: "30px",
          fontWeight: 500,
        }}
        value={otp}
        numInputs={6}
        onChange={handleOtp}
        inputStyle={{
          border: "none",
          height: "60px",
          width: "100%",
          borderRadius: "7px",
          color: "#6C737F",
          fontSize: "30px",
          backgroundColor: "#FCFCFD",
          marginTop: "30px",
          marginBottom: !isError ? "30px" : "initial",
          marginRight: "10px",
          fontWeight: 500,
        }}
      />
      {isError && (
        <ErrorTypography>
          Error! Wrong OTP entered. Please re-enter.
        </ErrorTypography>
      )}
      {!showResend && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#0E4965",
            marginTop: isError ? "20px" : "initail",
            textAlign:'center'
          }}
        >
          Resend OTP in {seconds} Sec
        </Typography>
      )}
      {showResend && (
        <Button
          style={{ marginTop: isError ? "20px" : "initail" }}
          onClick={resendOtp}
          fullWidth
          variant="contained"
        >
          Resend OTP
        </Button>
      )}
    </>
  );
};

export default Otp;
