import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyD7Vc8I_SYhENUGnIi_XdwS2gmkvkAyXnU",
    authDomain: "loby-90ed9.firebaseapp.com",
    projectId: "loby-90ed9",
    storageBucket: "loby-90ed9.appspot.com",
    messagingSenderId: "441294005853",
    appId: "1:441294005853:web:8d692c938e5369cfbe0fb4",
    measurementId: "G-03EKRTPNER"
};

export const app = initializeApp(firebaseConfig);

// export const auth = getAuth(firebaseApp);
export const messaging = getMessaging(app);

// export default firebaseApp;

