import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Checkbox,
  Stack,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { emailRegex } from "../../../Utils";
import {
  setActiveStep,
  setNestedStep,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";

const MyDetails = ({
  workDetails,
  setWorkDetails,
  emergencyContact,
  setEmergencyContact,
  generalDetails,
  setGeneralDetails,
  personalDetails,
  setPersonalDetails,
  setDisplayScreen,
  billingemail,
  setbillingemail,
}) => {
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState({
    generalDetails: {},
    workDetails: {},
    emergencyContact: {},
    personalDetails: {},
  });

  console.log(personalDetails);
  console.log(errorText);
  const handleGeneralDetails = (e) => {
    // console.log(name);
    // if (name) {
    //     setPersonalDetails({
    //         ...personalDetails,
    //         [name]: e.$d
    //     })
    //     setErrorText({
    //         ...errorText,
    //         personalDetails: {
    //             ...errorText.personalDetails,
    //             [name]: ''
    //         }
    //     })
    // } else {
    let { name, value } = e.target;
    setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });
    setErrorText((prev) => {
      return {
        ...prev,
        personalDetails: {
          ...prev.personalDetails,
          [name]: "",
        },
      };
    });
    // }
  };
  useEffect(() => {
    if (billingemail === true) {
      setErrorText((prev) => {
        return {
          ...prev,
          personalDetails: {
            ...prev.personalDetails,
            billingemail: "",
          },
        };
      });
    }
  }, [billingemail]);
  // const handleEmergencyContact = (e) => {
  //     let { name, value } = e.target
  //     setEmergencyContact({
  //         ...emergencyContact,
  //         [name]: value
  //     })
  //     setErrorText({
  //         ...errorText,
  //         emergencyContact: {
  //             ...errorText.emergencyContact,
  //             [name]: ''
  //         }
  //     })
  // }

  // const handleWorkDetails = (e, name) => {
  //     if (name) {
  //         setWorkDetails({
  //             ...workDetails,
  //             [name]: e.$d
  //         })
  //         setErrorText({
  //             ...errorText,
  //             workDetails: {
  //                 ...errorText.workDetails,
  //                 [name]: ''
  //             }
  //         })
  //     } else {
  //         let { name, value } = e.target
  //         setWorkDetails({
  //             ...workDetails,
  //             [name]: value
  //         })
  //         setErrorText({
  //             ...errorText,
  //             workDetails: {
  //                 ...errorText.workDetails,
  //                 [name]: ''
  //             }
  //         })
  //     }
  // }

  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      contact: value,
      country_code: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    setErrorText({
      ...errorText,
      [statename]: {
        ...errorText[statename],
        contact: "",
        country_code: "",
        phone: "",
      },
    });
  };

  console.log(personalDetails);
  const validate = () => {
    let isError = [];

    //generalDetails validation
    for (let key in personalDetails) {
      if (key === "email" && personalDetails[key] != "") {
        if (!emailRegex.test(personalDetails[key])) {
          setErrorText((prev) => {
            return {
              ...prev,
              personalDetails: {
                ...prev.personalDetails,
                [key]: "Enter valid email",
              },
            };
          });
          isError.push(true);
        }
      } else if (key === "phone" && personalDetails[key] != "") {
        if (personalDetails[key].length < 6) {
          setErrorText((prev) => {
            return {
              ...prev,
              personalDetails: {
                ...prev.personalDetails,
                [key]: "value should be greater than 6",
              },
            };
          });
          isError.push(true);
        }
      } else {
        if (billingemail === true) {
          if (
            (personalDetails[key] === "" || personalDetails[key] == null) &&
            key !== "billingemail"
          ) {
            setErrorText((prev) => {
              return {
                ...prev,
                personalDetails: {
                  ...prev.personalDetails,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError.push(true);
          }
        } else {
          if (
            key === "billingemail" &&
            (personalDetails[key] === "" || personalDetails[key] == null)
          ) {
            if (!emailRegex.test(personalDetails[key])) {
              setErrorText((prev) => {
                return {
                  ...prev,
                  personalDetails: {
                    ...prev.personalDetails,
                    [key]: "Enter valid email",
                  },
                };
              });
              isError.push(true);
            }
          } else if (
            personalDetails[key] === "" ||
            personalDetails[key] == null
          ) {
            setErrorText((prev) => {
              return {
                ...prev,
                personalDetails: {
                  ...prev.personalDetails,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError.push(true);
          }
        }
      }
    }

    //workDetails validation
    // for (let workKey in workDetails) {
    //     if (workDetails[workKey] === '') {
    //         setErrorText((prev) => {
    //             return {
    //                 ...prev,
    //                 workDetails: {
    //                     ...prev.workDetails,
    //                     [workKey]: 'Error! No Inputs detected'
    //                 }
    //             }
    //         })
    //         isError.push(true)
    //     }
    // }

    // //emergencyContact validation
    // for (let emergencyKey in emergencyContact) {
    //     if (emergencyKey === 'email' && emergencyContact[emergencyKey] != '') {
    //         if (!emailRegex.test(emergencyContact[emergencyKey])) {
    //             setErrorText((prev) => {
    //                 return {
    //                     ...prev,
    //                     emergencyContact: {
    //                         ...prev.emergencyContact,
    //                         [emergencyKey]: 'Enter valid email'
    //                     }
    //                 }
    //             })
    //             isError.push(true)
    //         }
    //     } else {
    //         if (emergencyContact[emergencyKey] === '') {
    //             setErrorText((prev) => {
    //                 return {
    //                     ...prev,
    //                     emergencyContact: {
    //                         ...prev.emergencyContact,
    //                         [emergencyKey]: 'Error! No Inputs detected'
    //                     }
    //                 }
    //             })
    //             isError.push(true)
    //         }
    //     }

    // }

    return isError.includes(true);
  };

  const handleContinue = () => {
    let isError = validate();
    if (!isError) {
      setDisplayScreen("review");
      dispatch(setNestedStep(1));
    }
  };
  const backStep = () => {
    dispatch(setActiveStep(3));
  };

  return (
    <>
      <BackArrow
        onClick={backStep}
        style={{ marginBottom: "26px", cursor: "pointer" }}
      />
      <PrimaryTypography>Administrator</PrimaryTypography>
      <StyledSecondaryTypography mb={3.2}>
        Information related platform’s admin account.
      </StyledSecondaryTypography>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #E5E7EB",
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #E5E7EB",
            backgroundColor: "#FCFCFD",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <SecondaryTypography>Administrator Details</SecondaryTypography>
        </Box>
        <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTypography>First Name</StyledTypography>
            <TextField
              name="firstName"
              placeholder="Enter first name"
              value={personalDetails?.firstName}
              onChange={handleGeneralDetails}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 20 }}
            />
            <ErrorTypography>
              {errorText.personalDetails.firstName}
            </ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTypography>Last Name</StyledTypography>
            <TextField
              name="lastName"
              placeholder="Enter last name"
              value={personalDetails?.lastName}
              onChange={handleGeneralDetails}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 20 }}
            />
            <ErrorTypography>
              {errorText.personalDetails.lastName}
            </ErrorTypography>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
                            <StyledTypography>Middle Name</StyledTypography>
                            <TextField name="middleName" value={generalDetails?.middleName} onChange={handleGeneralDetails} InputProps={{disableUnderline:true}} />
                            <ErrorTypography>{errorText.generalDetails.middleName}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTypography>Date of Birth*</StyledTypography>
                            <DatePicker disableFuture format="DD/MM/YYYY" slots={{openPickerIcon:()=><DatePickerIcon/>}} name="dob" value={generalDetails?.dob} onChange={(value)=>handleGeneralDetails(value,'dob')} sx={DatePickerStyle} />
                            <ErrorTypography>{errorText.generalDetails.dob}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTypography>Gender*</StyledTypography>
                            <Select name="gender" value={generalDetails?.gender} onChange={(e)=>handleGeneralDetails(e)}>
                                <MenuItem value='sss'>rdsgvr</MenuItem>
                            </Select>
                            <ErrorTypography>{errorText.generalDetails.gender}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTypography>Nationality*</StyledTypography>
                            <Select name="nationality" value={generalDetails?.nationality} onChange={(e)=>handleGeneralDetails(e)}>
                                <MenuItem value='rdsgvr'>rdsgvr</MenuItem>
                            </Select>
                            <ErrorTypography>{errorText.generalDetails.nationality}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTypography>Identity Number / Passport Number*</StyledTypography>
                            <TextField name="identityNumber" value={generalDetails?.identityNumber} onChange={(e)=>handleGeneralDetails(e)} InputProps={{disableUnderline:true}} />
                            <ErrorTypography>{errorText.generalDetails.identityNumber}</ErrorTypography>
                        </Grid> */}
          <Grid item xs={12} sm={6}>
            <StyledTypography>Email Address</StyledTypography>
            <TextField
              name="email"
              value={personalDetails?.email}
              onChange={handleGeneralDetails}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 125 }}
              disabled
            />
            <ErrorTypography>{errorText.personalDetails.email}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTypography>Contact Number</StyledTypography>
            <PhoneInput
              value={personalDetails?.contact}
              onChange={(value, country, e, formatValue) =>
                handleMobileInput(
                  value,
                  country,
                  setPersonalDetails,
                  personalDetails,
                  "personalDetails"
                )
              }
              inputProps={{ name: "contact" }}
              country={"th"}
              buttonStyle={{ border: "none" }}
              inputStyle={{
                backgroundColor: "#F9FAFB",
                border: "none",
                height: "46px",
                width: "100%",
                borderRadius: "8px",
              }}
              countryCodeEditable={false}
              placeholder="Add Contact Number"
            />
            <ErrorTypography>{errorText.personalDetails.phone}</ErrorTypography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row">
              <Checkbox
                checked={billingemail}
                onClick={() => {
                  setbillingemail(!billingemail);
                }}
                icon={<CheckboxIcon />}
                onChange={handleGeneralDetails}
              />
              <Stack direction="column">
                <TypographyDefault sx={{ fontWeight: "500 !important" }}>
                  Billing Email Address is the same as above
                </TypographyDefault>
                <TypographyDefault sx={{ fontSize: "12px !important" }}>
                  If checked, the billing details will be sent to above email
                  address
                </TypographyDefault>
              </Stack>
            </Stack>
          </Grid>
          {!billingemail && (
            <Grid item xs={12} sm={6}>
              <StyledTypography>Billing Email Address</StyledTypography>
              <TextField
                name="billingemail"
                value={personalDetails?.billingemail}
                onChange={handleGeneralDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
              />
              <ErrorTypography>
                {errorText.personalDetails.billingemail}
              </ErrorTypography>
            </Grid>
          )}
          {/* <Grid item xs={12}>
                            <StyledTypography>Address*</StyledTypography>
                            <TextField name="address" value={generalDetails?.address} onChange={handleGeneralDetails} InputProps={{disableUnderline:true}} />
                            <ErrorTypography>{errorText.generalDetails.address}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledTypography>Country*</StyledTypography>
                            <Select name="country" value={generalDetails?.country} onChange={(e)=>handleGeneralDetails(e)}>
                                <MenuItem value="rhfe">rdsgvr</MenuItem>
                            </Select>
                            <ErrorTypography>{errorText.generalDetails.country}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledTypography>City*</StyledTypography>
                            <Select name="city" value={generalDetails?.city} onChange={(e)=>handleGeneralDetails(e)}>
                                <MenuItem value="ejru">rdsgvr</MenuItem>
                            </Select>
                            <ErrorTypography>{errorText.generalDetails.city}</ErrorTypography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledTypography>Postal Code*</StyledTypography>
                            <TextField name="postalCode" value={generalDetails?.postalCode} onChange={handleGeneralDetails} InputProps={{disableUnderline:true}} />
                            <ErrorTypography>{errorText.generalDetails.postalCode}</ErrorTypography>
                        </Grid> */}
        </Grid>

        {/* <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #E5E7EB', mt: 2 }}>
                <Box sx={{ p: 2, borderBottom: '1px solid #E5E7EB' }}>
                    <SecondaryTypography>Emergency Contact</SecondaryTypography>
                </Box>
                <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>First Name*</StyledTypography>
                        <TextField name="firstName" value={emergencyContact?.firstName} onChange={handleEmergencyContact} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography>{errorText.emergencyContact.firstName}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Last Name*</StyledTypography>
                        <TextField name="lastName" value={emergencyContact?.lastName} onChange={handleEmergencyContact} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography>{errorText.emergencyContact.lastName}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Email Address*</StyledTypography>
                        <TextField name="email" value={emergencyContact?.email} onChange={handleEmergencyContact} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography>{errorText.emergencyContact.email}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Contact Number*</StyledTypography>
                        <PhoneInput value={emergencyContact?.contact} onChange={(value, country, e, formatValue) => handleMobileInput(value, country, setEmergencyContact, emergencyContact)} inputProps={{ name: 'contact' }} country={'th'} buttonStyle={{ border: 'none' }} inputStyle={{ backgroundColor: "#F9FAFB", border: 'none', height: '46px', width: '100%', borderRadius: '8px' }} countryCodeEditable={false} placeholder="Add Contact Number" />
                        <ErrorTypography>{errorText.emergencyContact.contact}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledTypography>Nationality*</StyledTypography>
                        <Select name="nationality" value={emergencyContact?.nationality} onChange={(e) => handleEmergencyContact(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.emergencyContact.nationality}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledTypography>Gender*</StyledTypography>
                        <Select name="gender" value={emergencyContact?.gender} onChange={(e) => handleEmergencyContact(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.emergencyContact.gender}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledTypography>Relationship*</StyledTypography>
                        <TextField name="relationship" value={emergencyContact?.relationship} onChange={handleEmergencyContact} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography>{errorText.emergencyContact.relationship}</ErrorTypography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #E5E7EB', mt: 2 }}>
                <Box sx={{ p: 2, borderBottom: '1px solid #E5E7EB' }}>
                    <SecondaryTypography>Work & Access Details</SecondaryTypography>
                </Box>
                <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>
                        <StyledTypography>Employment Type</StyledTypography>
                        <Select name="type" value={workDetails?.type} onChange={(e) => handleWorkDetails(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.workDetails.type}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Department</StyledTypography>
                        <Select name="department" value={workDetails?.department} onChange={(e) => handleWorkDetails(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.workDetails.department}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Job Role</StyledTypography>
                        <Select name="jobRole" value={workDetails?.jobRole} onChange={(e) => handleWorkDetails(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.workDetails.jobRole}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Level*</StyledTypography>
                        <Select name="level" value={workDetails?.level} onChange={(e) => handleWorkDetails(e)}>
                            <MenuItem value="1">rdsgvr</MenuItem>
                        </Select>
                        <ErrorTypography>{errorText.workDetails.level}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Department Location Number</StyledTypography>
                        <TextField name="departmentLocation" value={workDetails?.departmentLocation} onChange={handleWorkDetails} InputProps={{ disableUnderline: true }} />
                        <ErrorTypography>{errorText.workDetails.departmentLocation}</ErrorTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Access Start Date</StyledTypography>
                        <DatePicker format="DD/MM/YYYY" slots={{ openPickerIcon: () => <DatePickerIcon /> }} name="accessStartDate" value={workDetails?.accessStartDate} onChange={(value) => handleWorkDetails(value, 'accessStartDate')} sx={DatePickerStyle} />
                        <ErrorTypography>{errorText.workDetails.accessStartDate}</ErrorTypography>
                    </Grid><Grid item xs={12} sm={6}>
                        <StyledTypography>Access End Date</StyledTypography>
                        <DatePicker format="DD/MM/YYYY" slots={{ openPickerIcon: () => <DatePickerIcon /> }} name="accessEndDate" value={workDetails?.accessEndDate} sx={DatePickerStyle} onChange={(value) => handleWorkDetails(value, 'accessEndDate')} />
                        <ErrorTypography>{errorText.workDetails.accessEndDate}</ErrorTypography>
                    </Grid>
                </Grid>
            </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 16px",
            alignItems: "center",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshIcon style={{ marginRight: "5px" }} />
            <StyledSecondaryTypography>
              Last Updated at {moment(new Date()).format("HH:mm A")}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={handleContinue}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MyDetails;
