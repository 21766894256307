import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, edit, get, post } from "../../AxiosInstance";
import axios from "axios";
import { getPropertyStatus } from "../../../Utils"
import { useDispatch } from "react-redux";
import axiosInstance from "../../AxiosInstance";
import { json } from "react-router-dom";

const initialState = {
    addGeneralDetailsStatus: null,
    generalDetailsList: [],
    editGeneralDetailsStatus: null,
    addBuildingDetailsStatus: null,
    editBuildingNameStatus: null,
    buildingList: [],
    deleteOneBuildingStatus: null,
    addLevelStatus: null,
    deleteLevelStatus: null,
    addCommonAreaStatus: null,
    editCommonAreaStatus: null,
    commonAreaList: [],
    deleteOneCommonAreaStatus: null,
    addMyDetailsStatus: null,
    myDetailsList: [],
    propertyCompletedStep: null,
    propertyId: JSON.parse(localStorage.getItem('propertyStatus')) != null ? JSON.parse(localStorage.getItem('propertyStatus')).id : null,
    userRole: JSON.parse(localStorage.getItem('userDetails')) != null ? JSON.parse(localStorage.getItem('userDetails')).user_role : null,
    PropertyStatusDetails: JSON.parse(localStorage.getItem('propertyStatus')) != null ? JSON.parse(localStorage.getItem('propertyStatus')) : null,
    propertySetupLoader: false,
    addUserAndRoleStatus: null,
    UserAndRoleList: [],
    getUserAndRoleStatus: null,
    editUserAndRoleStatus: null,
    deleteUserAndRoleStatus: null,
    activeStep: JSON.parse(localStorage.getItem('propertyStatus')) != null ? JSON.parse(localStorage.getItem('propertyStatus')).completed_step : null,
    nestedStep: 0,
    TabValue: 0,
}


//load propertyStatus

export const loadPropertyStatus = createAsyncThunk('loadPropertyStatus', async (_, { dispatch }) => {
    try {
        const data = getPropertyStatus();
        if (data) {
            dispatch(SetPropertyStatusDetails(data));
            dispatch(setPropertyId(data.id));
            dispatch(setActiveStep(data.completed_step));
        }
    } catch (err) {
        console.error('Error loading data from localStorage:', err);
    }
})


//generalDetails
export const addGeneralDetails = createAsyncThunk('generalDetails', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getGeneralDetailsByProperty = createAsyncThunk('getGeneralDetailsByProperty', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${data}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editGeneralDetails = createAsyncThunk('editGeneralDetails', async (data, { rejectWithValue }) => {
    let { propertyId, datas } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/${propertyId}`, datas);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

//building
export const addBuilding = createAsyncThunk('addBuildingDetails', async (data, { rejectWithValue }) => {
    let { propertyId, ...others } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/${propertyId}/building`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editBuilding = createAsyncThunk('editBuildingDetails', async (data, { rejectWithValue }) => {
    let { propertyId, ...others } = data
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/building`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editBuildingName = createAsyncThunk('editBuildingName', async (data, { rejectWithValue }) => {
    let { propertyId, buildingId, ...others } = data
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/building/${buildingId}`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAllBuildingByProperty = createAsyncThunk('getAllBuildingByProperty', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${data}/building?pagination=false`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteOneBuilding = createAsyncThunk('deleteOneBuilding', async (data, { rejectWithValue }) => {
    let { buildingId, propertyId } = data
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/building/${buildingId}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const addLevel = createAsyncThunk('addLevel', async (data, { rejectWithValue }) => {
    let { buildingId, ...others } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/building/${buildingId}/level`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteLevel = createAsyncThunk('deleteLevel', async (data, { rejectWithValue }) => {
    let { propertyId, levelId, buildingId } = data
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/building/${buildingId}/level/${levelId}/force_delete`,);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

//common area
export const addCommonArea = createAsyncThunk('addCommonArea', async (data, { rejectWithValue }) => {
    let { propertyId, ...others } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAllCommonAreaByProperty = createAsyncThunk('getAllCommonAreaByProperty', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${data}/commonarea`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editCommonAreaApi = createAsyncThunk('editCommonAreaApi', async (datas, { rejectWithValue }) => {
    // const { propertyId, id, common_area_name, description, is_part_of_building, building_id, level_id } = datas
    const { propertyId, id, ...others } = datas

    try {
        // const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/role/${data.id}?`, data.body)
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea/${id}`, others)

        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteOneCommonArea = createAsyncThunk('deleteOneCommonArea', async (data, { rejectWithValue }) => {
    let { propertyId, id } = data
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea/${id}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

//my details
export const addMyDetails = createAsyncThunk('addMyDetails', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}my_details`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const getMyDetails = createAsyncThunk('getMyDetails', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}user_details`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

//common
export const updatePropertyDetailsCompletedSteps = createAsyncThunk('updatePropertyDetailsCompletedSteps', async (data, { rejectWithValue }) => {
    let { propertyId, ...others } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property_step/${propertyId}`, others);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

//users and role
export const addUserAndRole = createAsyncThunk('addUserAndRole', async (data, { rejectWithValue }) => {
    const { propertyId, adddata } = data
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}property/${propertyId}/role`, adddata)
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getUserAndRole = createAsyncThunk('getUserAndRole', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${data}/role`)
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const editUserAndRole = createAsyncThunk('editUserAndRole', async (datas, { rejectWithValue }) => {
    const { propertyId, data } = datas
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}property/${propertyId}/role/${data.id}?`, data.body)
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const deleteUserAndRole = createAsyncThunk('deleteUserAndRole', async (data, { rejectWithValue }) => {
    let { propertyId, deletingIndex } = data
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/role/${deletingIndex}`)
        return response

    } catch (error) {
        console.log(error);
    }
})


const PropertyDetailsReducer = createSlice({
    name: 'propertyDetails',
    initialState,
    reducers: {
        setPropertySliceToNull: (state) => {
            state.addBuildingDetailsStatus = null
            state.addMyDetailsStatus = null
            state.propertyCompletedStep = null
            state.editGeneralDetailsStatus = null
            state.addCommonAreaStatus = null
            state.editCommonAreaStatus = null
            state.addUserAndRoleStatus = null
            state.editUserAndRoleStatus = null
            state.getUserAndRoleStatus = null
            state.deleteUserAndRoleStatus = null
        },
        SetPropertySliceDataToNull: (state, action) => {
            state.UserAndRoleList = []
            state.myDetailsList = []
            state.commonAreaList = []
            state.buildingList = []
            state.generalDetailsList = []
        },
        setPropertyId: (state, action) => {
            state.propertyId = action.payload
        },
        SetPropertyStatusDetails: (state, action) => {
            state.PropertyStatusDetails = action.payload
        },
        setpropertySetupLoader: (state, action) => {
            state.propertySetupLoader = action?.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload
        },
        setNestedStep: (state, action) => {
            state.nestedStep = action.payload
        },
        setTabValue: (state, action) => {
            state.TabValue = action.payload
        },
        SetgeneralDetailsList: (state, action) => {
            state.generalDetailsList = action?.payload
        },
        SetbuildingList: (state, action) => {
            state.buildingList = action?.payload
        },
        SetcommonAreaList: (state, action) => {
            state.commonAreaList = action?.payload
        },
        SetUserAndRoleList: (state, action) => {
            state.UserAndRoleList = action?.payload
        },
        SetmyDetailsList: (state, action) => {
            state.myDetailsList = action?.payload
        },
    },
    extraReducers: (builders) => {
        //generaldetails
        builders.addCase(addGeneralDetails.fulfilled, (state, action) => {
            state.addGeneralDetailsStatus = action.payload?.data
        })
        builders.addCase(addGeneralDetails.rejected, (state, action) => {
            state.addGeneralDetailsStatus = action.payload.response?.data
        })
        builders.addCase(getGeneralDetailsByProperty.fulfilled, (state, action) => {
            state.generalDetailsList = action.payload?.data
        })
        builders.addCase(getGeneralDetailsByProperty.rejected, (state, action) => {
            state.generalDetailsList = []
        })
        builders.addCase(editGeneralDetails.fulfilled, (state, action) => {
            state.editGeneralDetailsStatus = action.payload?.data
        })
        builders.addCase(editGeneralDetails.rejected, (state, action) => {
            state.editGeneralDetailsStatus = []
        })
        //building 
        builders.addCase(addBuilding.fulfilled, (state, action) => {
            state.addBuildingDetailsStatus = action.payload?.data
        })
        builders.addCase(addBuilding.rejected, (state, action) => {
            state.addBuildingDetailsStatus = action.payload.response?.data
        })
        builders.addCase(editBuildingName.fulfilled, (state, action) => {
            state.editBuildingNameStatus = action.payload?.data
        })
        builders.addCase(editBuildingName.rejected, (state, action) => {
            state.editBuildingNameStatus = action.payload.response?.data
        })
        builders.addCase(getAllBuildingByProperty.fulfilled, (state, action) => {
            state.buildingList = action.payload?.data
        })
        builders.addCase(getAllBuildingByProperty.rejected, (state, action) => {
            state.buildingList = []
        })
        builders.addCase(deleteOneBuilding.fulfilled, (state, action) => {
            state.deleteOneBuildingStatus = action.payload?.data
        })
        builders.addCase(deleteOneBuilding.rejected, (state, action) => {
            state.deleteOneBuildingStatus = action.payload.response?.data
        })
        builders.addCase(addLevel.fulfilled, (state, action) => {
            state.addLevelStatus = action.payload?.data
        })
        builders.addCase(addLevel.rejected, (state, action) => {
            state.addLevelStatus = action.payload.response?.data
        })
        builders.addCase(deleteLevel.fulfilled, (state, action) => {
            state.deleteLevelStatus = action.payload?.data
        })
        builders.addCase(deleteLevel.rejected, (state, action) => {
            state.deleteLevelStatus = action.payload.response?.data
        })
        //common area
        builders.addCase(addCommonArea.fulfilled, (state, action) => {
            state.addCommonAreaStatus = action.payload?.data
        })
        builders.addCase(addCommonArea.rejected, (state, action) => {
            state.addCommonAreaStatus = action.payload.response?.data
        })
        builders.addCase(editCommonAreaApi.fulfilled, (state, action) => {
            state.editCommonAreaStatus = action.payload?.data
        })
        builders.addCase(editCommonAreaApi.rejected, (state, action) => {
            state.editCommonAreaStatus = action.payload.response?.data
        })
        builders.addCase(getAllCommonAreaByProperty.fulfilled, (state, action) => {
            state.commonAreaList = action.payload?.data
        })
        builders.addCase(getAllCommonAreaByProperty.rejected, (state, action) => {
            state.commonAreaList = []
        })
        builders.addCase(deleteOneCommonArea.fulfilled, (state, action) => {
            state.deleteOneCommonAreaStatus = action.payload?.data
        })
        builders.addCase(deleteOneCommonArea.rejected, (state, action) => {
            state.deleteOneCommonAreaStatus = action.payload.response?.data
        })
        //mydetails
        builders.addCase(addMyDetails.fulfilled, (state, action) => {
            state.addMyDetailsStatus = action.payload?.data
        })
        builders.addCase(addMyDetails.rejected, (state, action) => {
            state.addMyDetailsStatus = action.payload.response?.data
        })
        builders.addCase(getMyDetails.fulfilled, (state, action) => {
            state.myDetailsList = action.payload?.data
        })
        builders.addCase(getMyDetails.rejected, (state, action) => {
            state.myDetailsList = []
        })
        //common
        builders.addCase(updatePropertyDetailsCompletedSteps.fulfilled, (state, action) => {
            state.propertyCompletedStep = action.payload?.data
        })
        builders.addCase(updatePropertyDetailsCompletedSteps.rejected, (state, action) => {
            state.myDetailsList = null
        })
        //UserAndRole
        builders.addCase(addUserAndRole.fulfilled, (state, action) => {
            state.addUserAndRoleStatus = action.payload?.data
        })
        builders.addCase(addUserAndRole.rejected, (state, action) => {
            state.addUserAndRoleStatus = action.payload?.response?.data
        })
        builders.addCase(getUserAndRole.fulfilled, (state, action) => {
            state.UserAndRoleList = action.payload?.data
            state.getUserAndRoleStatus = action.payload?.data
        })
        builders.addCase(editUserAndRole.fulfilled, (state, action) => {
            state.editUserAndRoleStatus = action.payload?.data
        })
        builders.addCase(deleteUserAndRole.fulfilled, (state, action) => {
            state.deleteUserAndRoleStatus = action.payload?.data
        })
    }
})

export const { setPropertySliceToNull, setPropertyId, setActiveStep, setTabValue, setNestedStep, SetPropertyStatusDetails, SetPropertySliceDataToNull, setpropertySetupLoader, setUserRole, SetgeneralDetailsList, SetbuildingList, SetcommonAreaList, SetUserAndRoleList, SetmyDetailsList } = PropertyDetailsReducer.actions

export default PropertyDetailsReducer.reducer