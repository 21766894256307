import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export default function NotFound() {
  const { UserAccess, UserManage } = useSelector((state) => state.Login);
  console.log(UserAccess, UserManage);

  if (!isEmptyObject(UserAccess) && !isEmptyObject(UserManage)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography sx={{ color: "red", fontSize: "24px", fontWeight: "bold" }}>
          404
        </Typography>
        <Typography
          sx={{ color: "red", fontSize: "24px", fontWeight: "bold", mt: 2 }}
        >
          Page Not Found
        </Typography>
      </Box>
    );
  } else {
    return <></>;
  }
}

export function NetworkErr() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography sx={{ color: "red", fontSize: "24px", fontWeight: "bold" }}>
        500
      </Typography>
      <Typography
        sx={{
          color: "red",
          fontSize: "24px",
          fontWeight: "bold",
          mt: 1,
          mb: 2,
        }}
      >
        Check your connection and try again
      </Typography>
      <Button
        sx={{ textDecoration: "underline", fontSize: "18px" }}
        onClick={() => navigate("/contact-book")}
      >
        Retry
      </Button>
    </Box>
  );
}
