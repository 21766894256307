import React, { useState } from 'react'
import { makeStyles } from "@mui/styles";
import { DatePickerStyle, ErrorTypography, PrimaryTypography, SecondaryTypography, StyledOutlinedInput, StyledSecondaryDefaultTypography, StyledSecondaryTypography, StyledTableCell, StyledTableRow, StyledTypography, TypographyDefault } from '../Theme'
import moment from 'moment';
import { Alert, Box, Button, Checkbox, Menu, MenuItem, Modal, Grid, OutlinedInput, Pagination, PaginationItem, Select, Snackbar, SnackbarContent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material'
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from 'dayjs';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white ",
        "& .MuiDateRangeCalendar-root ": { color: "red !important", },
        // "& .css-968uz8-MuiStack-root-MuiMultiInputDateRangeField-root": {
        //   border: "2px solid red"
        // },
        "&.MuiDateRangeCalendar-root > div ": {

            height: "470px !important",

        },
        "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
            color: "white !important",
            zIndex: "-100000 !important",

        }
        // "& .css-jef1b6-MuiDateRangeCalendar-container": { color: "red !important" },
    }
}));

export default function CustomDateRangePicker({ openDateRangePicker, setopenDateRangePicker, datetimerangedata, setdatetimerangedata, setdateSubmit, maxDate }) {

    const [errText, setErrText] = useState({ start: '', end: '' })

    const handleApply = () => {
        if (!Boolean(datetimerangedata[0]) || !Boolean(datetimerangedata[1])) {
            if (!Boolean(datetimerangedata[0])) {
                setErrText((prev) => ({ ...prev, start: true }))
            }
            if (!Boolean(datetimerangedata[1])) {
                setErrText((prev) => ({ ...prev, end: true }))
            }
        }
        else {
            setErrText({ start: '', end: '' })
            setopenDateRangePicker(false)
            setdateSubmit(true)
        }
    }



    return (
        <>
            <Box
                sx={{ backgroundColor: "#F9FAFB", position: "absolute", top: "10px" }}
            >
                <DateRangePicker
                    classes={useStyles()}
                    sx={{
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            display: "none !important"
                        },
                        "& .css-8wfwvd-MuiTypography-root-MuiMultiInputDateRangeField-separator": {
                            display: "none"
                        },
                        "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
                            width: "fit-Content"
                        }
                    }}
                    // value={datetimerangedata}
                    localeText={{ start: "", end: "" }}
                    open={openDateRangePicker}
                    onChange={(e) => { setdatetimerangedata(e); setErrText({ start: '', end: '' }) }}
                    maxDate={Boolean(maxDate) ? dayjs(maxDate) : null}
                />

                <Box
                    sx={{
                        position: "absolute",
                        zIndex: "1301",
                        // left: "5%",
                        transform: "translate3d(10px, 10px, 0px)",
                        top: "400px",
                        // width: '600px',
                        width: '118%',
                        padding: "20px 15px 20px 15px",
                        borderTop: "2px solid #F2F4F7",
                        backgroundColor: "white",
                        // height: "234px",
                        display: openDateRangePicker ? '' : 'none',
                        transition: 'opacity 2s ease-in',
                        transition: 'opacity 2s ease-out',
                        opacity: openDateRangePicker ? 1 : 0,
                        webkitanimation: "fadeIn 2.5s",
                        animation: "fadeIn 2.5s"
                        // display: openDateRangePicker ? '' : 'none'
                    }}
                >
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Stack direction='row' justifyContent='start' alignItems='center'>
                            <StyledSecondaryDefaultTypography mr={1} sx={{ color: errText?.start ? '#912018 !important' : '#111927 !important', backgroundColor: errText?.start ? '#FEE4E2 !important' : '', width: "120px", height: '25px', padding: "10px 14px 10px 14px", border: errText?.start ? '1px solid #FEE4E2' : '1px solid #D0D5DD', borderRadius: "8px" }}>{Boolean(datetimerangedata[0]) ? moment(datetimerangedata[0]?.$d).format('MMM D, YYYY') : 'MMM D, YYYY'}</StyledSecondaryDefaultTypography>
                            -
                            <StyledSecondaryDefaultTypography m={1} sx={{ color: errText?.end ? '#912018 !important' : '#111927 !important', backgroundColor: errText?.end ? '#FEE4E2 !important' : '', width: "120px", height: '25px', padding: "10px 14px 10px 14px", border: errText?.end ? '1px solid #FEE4E2' : '1px solid #D0D5DD', borderRadius: "8px" }}>{Boolean(datetimerangedata[1]) ? moment(datetimerangedata[1]?.$d).format('MMM D, YYYY') : 'MMM D, YYYY'}</StyledSecondaryDefaultTypography>

                        </Stack>

                        {/* <Stack direction='row' justifyContent='end'> */}
                        <Stack direction='row' columnGap={2} >
                            <Button onClick={() => { setopenDateRangePicker(false); setdatetimerangedata([]); setdateSubmit(false) }} variant="outlined">Cancel</Button>
                            <Button onClick={() => handleApply()} variant='contained'>Apply</Button>
                        </Stack>
                        {/* </Stack> */}
                    </Stack>
                </Box>
            </Box>


        </>
    )
}
