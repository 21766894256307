import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { StyledSecondaryTypography } from "../../Theme";
import Chart from "react-apexcharts";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const StaffGraph = () => {
  const { StaffData, OverviewStatus } = useSelector((state) => state.OverView);
  const Staffseries = [
    {
      name: "Active",
      data: [
        StaffData?.jan?.active || 0,
        StaffData?.feb?.active || 0,
        StaffData?.mar?.active || 0,
        StaffData?.apr?.active || 0,
        StaffData?.may?.active || 0,
        StaffData?.jun?.active || 0,
        StaffData?.jul?.active || 0,
        StaffData?.aug?.active || 0,
        StaffData?.sep?.active || 0,
        StaffData?.oct?.active || 0,
        StaffData?.nov?.active || 0,
        StaffData?.dec?.active || 0,
      ],
    },
    {
      name: "Inactive",
      data: [
        StaffData?.jan?.active || 0,
        StaffData?.feb?.inactive || 0,
        StaffData?.mar?.inactive || 0,
        StaffData?.apr?.inactive || 0,
        StaffData?.may?.inactive || 0,
        StaffData?.jun?.inactive || 0,
        StaffData?.jul?.inactive || 0,
        StaffData?.aug?.inactive || 0,
        StaffData?.sep?.inactive || 0,
        StaffData?.oct?.inactive || 0,
        StaffData?.nov?.inactive || 0,
        StaffData?.dec?.inactive || 0,
      ],
    },
    {
      name: "Offboard",
      data: [
        StaffData?.jan?.offboard || 0,
        StaffData?.feb?.offboard || 0,
        StaffData?.mar?.offboard || 0,
        StaffData?.apr?.offboard || 0,
        StaffData?.may?.offboard || 0,
        StaffData?.jun?.offboard || 0,
        StaffData?.jul?.offboard || 0,
        StaffData?.aug?.offboard || 0,
        StaffData?.sep?.offboard || 0,
        StaffData?.oct?.offboard || 0,
        StaffData?.nov?.offboard || 0,
        StaffData?.dec?.offboard || 0,
      ],
    },
  ];
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
    },
    colors: ["#1687BD", "#FF7C32", "#9A66FF"],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 8,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#262D33",
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const total =
          series[0][dataPointIndex] +
          series[1][dataPointIndex] +
          series[2][dataPointIndex];
        const active = series[0][dataPointIndex];
        const inactive = series[1][dataPointIndex];
        const offboard = series[2][dataPointIndex];
        const month = w.globals.labels[dataPointIndex];

        return `
            <div class="apexcharts-tooltip-custom">
              <div class="total-count-tip">
              <div>
              Total
              </div> 
              <div>
              ${total} 
              </div>
              </div>
              <div class="tool-tip-parent">
              <div><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#1687BD"/>
              </svg>
              Active 
              </div>
              <div>
              ${active}
              </div>
            </div>
              <div class="tool-tip-parent">
              <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#FF7C32"/>
              </svg>
              Inactive
              </div> 
              <div>
               ${inactive}  
               </div>
              </div>
              <div class="tool-tip-parent">
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <rect x="0.490234" y="0.305664" width="8" height="8" rx="2" fill="#9A66FF"/>
              </svg>
              Offboard
              </div>
              <div>${offboard}</div>
              </div>
              <div class="month-change-top">${month}${" "}${2023}</div>
            </div>
          `;
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
  };
  return (
    <>
      {StaffData.total_count ? (
        <Chart options={options} series={Staffseries} type="bar" height={400} />
      ) : (
        <Box
          sx={{
            borderRadius: "0px, 0px, 8px, 8px",
            padding: "16px",
            backgroundColor: "#F9FAFB",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          {OverviewStatus ? (
            <CircularProgress />
          ) : (
            <>
              {" "}
              <ExclamatoryIcon
                style={{ marginBottom: "5px", marginTop: "60px" }}
              />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "100%",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                }}
                style={{ color: "#4D5761" }}
              >
                No statistics available for display
              </Typography>
              <StyledSecondaryTypography
                color="#9DA4AE !important"
                textAlign="center"
                width="400px"
                height="88px"
                fontSize="16px"
                fontWeight="400px"
                lineHeight="22.4px"
                marginBottom="60px"
              >
                To get started, you can{" "}
                <Link to="/staffs" style={{ color: "#0E4965" }}>
                  <b>click here </b>
                </Link>
                to add staffs via the management page.
              </StyledSecondaryTypography>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default StaffGraph;
