import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as XlsIcon } from "../../../../Icons/Xls.svg";
import { ReactComponent as Close } from "../../../../Icons/BorderAlertClose.svg";

import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { Toaster } from "../../../../CommonComponents/modal";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  ErrorTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  Dialog,
  Modal,
  Stack,
  TextField,
  CircularProgress,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommonAreaByProperty } from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { changeUserData } from "../../../../Utils";

export default function ReviewCommonArea() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId, commonAreaList } = useSelector(
    (state) => state.PropertyDetails
  );
  const { LanguageData } = useSelector((state) => state.Language);
  const { UserManage } = useSelector((state) => state.Login);
  const [CommonAreaData, setCommonAreaData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (commonAreaList?.length == 0) {
      setloading(true);
      dispatch(getAllCommonAreaByProperty(propertyId));
    }
  }, []);

  useEffect(() => {
    if (commonAreaList?.success) {
      if (commonAreaList?.data?.common_areas?.length > 0) {
        let newCommonArea = commonAreaList?.data.common_areas?.map((value) => {
          return {
            fromApi: true,
            name: value.common_area_name,
            description: value.description,
            isPartOfBuilding: value.is_part_of_building,
            building: value.building_id,
            building_name: value?.buildings?.building_name,
            level: value.level_id,
            level_name: value?.building_level?.level,
            commonAreaId: value.id,
          };
        });
        setCommonAreaData([...newCommonArea]);
        setloading(false);
      }
    }
  }, [commonAreaList]);

  return (
    <>
      <Box
        boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
        sx={{ backgroundColor: "#FFFFFF", borderRadius: "0 0 8px 8px" }}
      >
        {loading ? (
          <Stack
            minHeight="400px"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                p: 2,
                borderRadius: "8px 8px 0 0 ",
                borderBottom: "1px solid #E5E7EB",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Property_Common_Area ||
                  "Property Common Area"}{" "}
              </SecondaryTypography>
              {UserManage?.settings && (
                <Button
                  onClick={() => {
                    navigate("editCommonArea");
                  }}
                  sx={{
                    width: "83px",
                    height: "36px",
                    padding: "8px 2px 8px 6px",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                  }}
                >
                  {" "}
                  {LanguageData?.Common_Edit || "Edit"}{" "}
                  <IconButton sx={{ paddingLeft: "10px" }}>
                    {" "}
                    <EditIcon width="14px" />
                  </IconButton>
                </Button>
              )}
            </Box>
            <Stack direction="column" rowGap={1.3} padding="24px 10px">
              {CommonAreaData.map((itm, index) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      border: "1px solid #E5E7EB",
                      borderRadius: "12px",
                      padding: "16px",
                    }}
                  >
                    <SecondaryTypography>{itm?.name}</SecondaryTypography>
                    {itm.isPartOfBuilding == 1 && (
                      <SecondaryTypography
                        sx={{ fontWeight: "400 !important" }}
                      >
                        {itm?.building_name} • {itm?.level_name}
                      </SecondaryTypography>
                    )}
                  </Box>
                );
              })}
            </Stack>
          </>
        )}
      </Box>
    </>
  );
}
