import React, { useEffect, useState } from "react";
import PropertySuperAdmin from "./PropertySuperAdmin";
import LandingPageNew from "../LandingPageNew";
import { useSelector } from "react-redux";
import PropertyDetails from "../Pages/PropertyDetails";
import Juristic_Management from "./Juristic_Management";
import { getAccess, getManage } from "../Utils";


const Routing = ({ }) => {
    const [hasToken, setHasToken] = useState(false)
    const { loginStatus } = useSelector((state) => state.Login)
    const { userRole } = useSelector((state) => state.PropertyDetails)

    useEffect(() => {
        if (localStorage.getItem('accessToken') != null) {
            setHasToken(true)
        }
    }, [loginStatus])



    return (
        <>
            {!hasToken ? <LandingPageNew /> :
                <>
                    {(userRole === 2) && <PropertySuperAdmin />}
                    {(userRole === 3) && <Juristic_Management />}
                </>
            }
        </>
    )
}

export default Routing;