import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  PrimaryTypography,
  StyledTypography,
  StyledSecondaryTypography,
  ErrorTypography,
  TypoGraph 
} from "../../../Theme";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as PDFIcon } from "../../../Icons/PdfBigIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import axiosInstance from "../../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../../CommonComponents/modal";
import { showToast } from "../../../CommonComponents/Toaster";

const AddAdhoc = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    setImageError(false);
    const file = acceptedFiles[0];
    setUploadedFile(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleSubmit = () => {
    if (title.trim() === "") {
      setTitleError(true);
    }
    if (!uploadedFile) {
      setImageError(true);
    }
    if (title.trim() !== "" && uploadedFile) {
      SetPostLoader(true);
      const data = new FormData();
      data.append("title", title);

      // data.append("description", "Description Check");

      data.append("path", uploadedFile);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/adhoc_form`,
          data,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          if (res.data?.success) {
            SetPostLoader(false);
            showToast(res.data?.message);
            navigate("/adhoc-forms");
          }
        })
        .catch(() => {
          SetPostLoader(false);
          showToast("Something went wrong. Please try again later", "error");
        });
    }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ padding: "0 20px", maxWidth: "728px", margin: "0 auto" }}>
        <Box>
          <BackArrow
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/adhoc-forms")}
          />
          <PrimaryTypography sx={{ marginTop: "20px", marginBottom: "20px" }}>
            {LanguageData?.Add_Form || "Add Form"}{" "}
          </PrimaryTypography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            marginTop: "10px",
          }}
        >
          <Box sx={{ padding: "15px 20px 15px 20px" }}>
           <Box display={"flex"} justifyContent={"space-between"}>
            <StyledTypography>
              {" "}
              {LanguageData?.Title_star || "Title*"}{" "}
            </StyledTypography>
            <TypoGraph  sx={{color:"red"}}>
                 {title.length}/20
            </TypoGraph>
            </Box>
            <TextField
              name="title"
              inputProps={{ maxLength: 20 }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              placeholder="Enter form name"
              sx={{
                "& .MuiInputBase-root": {
                  color: titleError ? "#912018" : "#111927",
                },
                backgroundColor: titleError ? "#FEE4E2" : " #F9FAFB",
              }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
            />
          </Box>

          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "15px 20px 15px 20px" }}
          >
            <Box sx={{ paddingBottom: "10px" }}>
              <StyledTypography>
                {LanguageData?.Upload_Form || "Upload File*"}{" "}
              </StyledTypography>
            </Box>
            <Grid xs={12}>
              {!uploadedFile && (
                <Stack
                  {...getRootProps()}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: imageError
                      ? "1px solid #912018"
                      : "1px solid #EAECF0",
                    borderRadius: "12px",
                    backgroundColor: imageError ? "#FEE4E2" : "#F9FAFB",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <Stack
                    flex={1}
                    direction="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    {imageError && (
                      <ErrorTypography mb={2}>
                        {LanguageData?.Please_upload || " Please upload File"}
                      </ErrorTypography>
                    )}

                    <UploadIcon />
                    <Stack my="5px" direction="row">
                      <Typography
                        marginRight="5px"
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "600 !important",
                          color: "#0E4965 !important",
                        }}
                      >
                        {LanguageData?.Click || "Click to upload"}
                      </Typography>
                      <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                        {LanguageData?.Drag || "or drag and drop"}
                      </StyledSecondaryTypography>
                    </Stack>
                    <StyledSecondaryTypography
                      sx={{ color: "#4D5761", fontSize: "12px !important" }}
                    >
                      {LanguageData?.Description_pdf ||
                        "PDF (max. 20 megabytes)"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Stack>
              )}

              {uploadedFile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Stack direction="row">
                    <PDFIcon style={{ marginRight: "12px" }} />
                    <Stack direction="column">
                      <StyledSecondaryTypography>
                        {uploadedFile.name}
                      </StyledSecondaryTypography>
                      <StyledSecondaryTypography>
                        {`${(uploadedFile.size / 1024 / 1024).toFixed(2)} MB`}
                      </StyledSecondaryTypography>
                    </Stack>
                  </Stack>
                  <DeleteIcon
                    onClick={() => setUploadedFile(null)}
                    cursor={"pointer"}
                    width={40}
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Button
              // variant="contained"
              height="40px"
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                padding: "20px",
                backgroundColor: "#E7EFF3",
                color: "#0E5E84",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
              onClick={handleSubmit}
            >
              {LanguageData?.Submit || "Submit"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddAdhoc;
