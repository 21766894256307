import axios from "axios";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const token =
      localStorage.getItem("accessToken") != null
        ? localStorage.getItem("accessToken")
        : null;
    request.headers.Authorization = `Bearer ${token}`;
    request.headers.device_type = 1;
    request.headers.device_token =
      localStorage.getItem("Notify_token") != null
        ? JSON.parse(localStorage.getItem("Notify_token"))
        : null;
    request.headers.timezone = timeZone;

    return request;
  },

  (err) => {
    Promise.reject(err);
  }
);

export default axiosInstance;
