import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { ReactComponent as LobbyText } from "../../../../Icons/Lobbytext.svg";
import { ReactComponent as CameraIcon } from "../../../../Icons/Camera.svg";
import { ReactComponent as DeleteIcon } from "../../../../Icons/DeleteRedIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../Icons/DownloadIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../../../Icons/Checkbox.svg";

import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledErrorTypography,
  StyledSecondaryTypography,
  TypographyDefault,
  StyledTypography,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Divider,
  Menu,
  TextField,
  Checkbox,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDetails,
  addMyDetails,
  getMyDetails,
  SetmyDetailsList,
  setTabValue,
  getGeneralDetailsByProperty,
  editGeneralDetails,
  updatePropertyDetailsCompletedSteps,
  setPropertyId,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { emailRegex } from "../../../../Utils";
import { PostLoaderModal } from "../../../../CommonComponents/modal";
import { showToast } from "../../../../CommonComponents/Toaster";

const EditAdministrator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LanguageData } = useSelector((state) => state.Language);
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];

  let { myDetailsList, addMyDetailsStatus, TabValue } = useSelector(
    (state) => state.PropertyDetails
  );

  const [errorText, setErrorText] = useState({
    generalDetails: {},
    workDetails: {},
    emergencyContact: {},
    personalDetails: {},
  });

  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    billingemail: "",
    phone: "",
  });
  const { savePropertyDetailsAsDraftStatus } = useSelector(
    (state) => state.Login
  );
  const [displayScreen, setDisplayScreen] = useState({
    myDetails: true,
    review: false,
  });
  const [billingemail, setbillingemail] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    if (myDetailsList?.length == 0) {
      dispatch(getMyDetails());
    }
  }, []);

  useEffect(() => {
    if (addMyDetailsStatus != null) {
      if (addMyDetailsStatus.success) {
        navigate("/settings");
        dispatch(setTabValue(4));
        // showToast(addMyDetailsStatus?.toast);
        dispatch(SetmyDetailsList(addMyDetailsStatus));
      }
    }
  }, [addMyDetailsStatus]);

  useEffect(() => {
    if (myDetailsList != null) {
      if (myDetailsList.success) {
        let data =
          myDetailsList?.data?.user_details || myDetailsList?.data?.my_details;
        let newEmergency = data?.emergency_contacts;
        let newWorkdetails = data?.access_details;

        const {
          email,
          first_name,
          last_name,
          phone,
          country_code,
          billing_email,
        } = data;
        // setPersonalDetails({ ...personalDetails, })
        if (Boolean(data.billing_email)) {
          setPersonalDetails({
            ...personalDetails,
            email: email,
            firstName: first_name,
            lastName: last_name,
            contact:
              country_code != null && phone != null ? country_code + phone : "",
            billingemail: billing_email,
            phone: phone != null ? phone : "",
            country_code: country_code != null ? country_code : "",
          });
          setbillingemail(false);
        } else {
          setPersonalDetails({
            ...personalDetails,
            email: email,
            firstName: first_name,
            lastName: last_name,
            contact:
              country_code != null && phone != null ? country_code + phone : "",
            phone: phone != null ? phone : "",
            country_code: country_code != null ? country_code : "",
          });
        }
        SetPostLoader(false);
      }
    }
  }, [myDetailsList]);

  const handleGeneralDetails = (e, name) => {
    if (name) {
      setPersonalDetails({
        ...personalDetails,
        [name]: e.$d,
      });
      setErrorText({
        ...errorText,
        personalDetails: {
          ...errorText.personalDetails,
          [name]: "",
        },
      });
    } else {
      let { name, value } = e.target;
      setPersonalDetails({
        ...personalDetails,
        [name]: value,
      });
      setErrorText((prev) => {
        return {
          ...prev,
          personalDetails: {
            ...prev.personalDetails,
            [name]: "",
          },
        };
      });
    }
  };
  useEffect(() => {
    if (billingemail === true) {
      setErrorText((prev) => {
        return {
          ...prev,
          personalDetails: {
            ...prev.personalDetails,
            billingemail: "",
          },
        };
      });
    }
  }, [billingemail]);

  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      contact: value,
      country_code: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    setErrorText({
      ...errorText,
      [statename]: {
        ...errorText[statename],
        contact: "",
        country_code: "",
        phone: "",
      },
    });
  };

  const validate = () => {
    let isError = [];

    //generalDetails validation
    for (let key in personalDetails) {
      if (key === "email" && personalDetails[key] != "") {
        if (!emailRegex.test(personalDetails[key])) {
          setErrorText((prev) => {
            return {
              ...prev,
              personalDetails: {
                ...prev.personalDetails,
                [key]: "Enter valid email",
              },
            };
          });
          isError.push(true);
        }
      }
      // else if (key === 'phone' && personalDetails[key] != '') {
      //     if (personalDetails[key].length < 10) {
      //         setErrorText((prev) => {
      //             return {
      //                 ...prev,
      //                 personalDetails: {
      //                     ...prev.personalDetails,
      //                     [key]: 'value should be greater than 10'
      //                 }
      //             }
      //         })
      //         isError.push(true)
      //     }
      // }
      else {
        if (billingemail === true) {
          if (personalDetails[key] === "" && key !== "billingemail") {
            setErrorText((prev) => {
              return {
                ...prev,
                personalDetails: {
                  ...prev.personalDetails,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError.push(true);
          }
        } else {
          if (key === "billingemail" && personalDetails[key] != "") {
            if (!emailRegex.test(personalDetails[key])) {
              setErrorText((prev) => {
                return {
                  ...prev,
                  personalDetails: {
                    ...prev.personalDetails,
                    [key]: "Enter valid email",
                  },
                };
              });
              isError.push(true);
            }
          } else if (personalDetails[key] === "") {
            setErrorText((prev) => {
              return {
                ...prev,
                personalDetails: {
                  ...prev.personalDetails,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError.push(true);
          }
        }
      }
    }

    return isError.includes(true);
  };

  const Continue = (nxtval) => {
    let isError = validate();
    if (!isError) {
      if (nxtval == "Review Information") {
        setaddblocks({ Details: false, Review_Information: true });
      } else if (nxtval == "Details") {
        setaddblocks({ Details: true, Review_Information: false });
      }
    }
  };

  const handleContinue = () => {
    SetPostLoader(true);
    const data = {
      first_name: personalDetails.firstName,
      last_name: personalDetails.lastName,
      country_code: personalDetails.country_code,
      phone: personalDetails.phone,
      billing_email: "",
    };

    {
      !billingemail &&
        Object.assign(data, { billing_email: personalDetails.billingemail });
    }
    dispatch(addMyDetails(data));
  };

  const Details = () => {
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px ",
            border: "1px solid #E5E7EB",
            mt: 2,
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FCFCFD",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <SecondaryTypography>
              {LanguageData?.Administrator_Details || "Administrator Details"}{" "}
            </SecondaryTypography>
          </Box>
          <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledTypography>
                {" "}
                {LanguageData?.First_Name || "First Name*"}
              </StyledTypography>
              <TextField
                name="firstName"
                value={personalDetails?.firstName}
                onChange={handleGeneralDetails}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errorText.personalDetails.firstName
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: Boolean(errorText.personalDetails.firstName)
                    ? "#FEE4E2"
                    : "",
                }}
              />
              <ErrorTypography>
                {errorText.personalDetails.firstName}
              </ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>
                {LanguageData?.Last_Name || "Last Name*"}
              </StyledTypography>
              <TextField
                name="lastName"
                value={personalDetails?.lastName}
                onChange={handleGeneralDetails}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errorText.personalDetails.lastName
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: Boolean(errorText.personalDetails.lastName)
                    ? "#FEE4E2"
                    : "",
                }}
              />
              <ErrorTypography>
                {errorText.personalDetails.lastName}
              </ErrorTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTypography>
                {LanguageData?.email_important || "Email Address*"}{" "}
              </StyledTypography>
              <TextField
                name="email"
                value={personalDetails?.email}
                onChange={handleGeneralDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                disabled
              />
              <ErrorTypography>
                {errorText.personalDetails.email}
              </ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>
                {" "}
                {LanguageData?.Contact_Number_impo || "Contact Number*"}{" "}
              </StyledTypography>
              <PhoneInput
                value={personalDetails?.contact}
                onChange={(value, country, e, formatValue) =>
                  handleMobileInput(
                    value,
                    country,
                    setPersonalDetails,
                    personalDetails,
                    "personalDetails"
                  )
                }
                inputProps={{ name: "contact" }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errorText.personalDetails.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errorText.personalDetails.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
                placeholder="Add Contact Number"
              />
              <ErrorTypography>
                {errorText.personalDetails.phone}
              </ErrorTypography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <Checkbox
                  checked={billingemail}
                  onClick={() => {
                    setbillingemail(!billingemail);
                  }}
                  icon={<CheckboxIcon />}
                  onChange={handleGeneralDetails}
                />
                <Stack direction="column">
                  <TypographyDefault sx={{ fontWeight: "500 !important" }}>
                    {LanguageData?.Billing_des ||
                      "Billing Email Address is the same as above"}
                  </TypographyDefault>
                  <TypographyDefault sx={{ fontSize: "12px !important" }}>
                    {LanguageData?.Billing_des_sub ||
                      "If checked, the billing details will be sent to above email address"}
                  </TypographyDefault>
                </Stack>
              </Stack>
            </Grid>
            {!billingemail && (
              <Grid item xs={12} sm={6}>
                <StyledTypography>
                  {" "}
                  {LanguageData?.Billing_Email_Address_impo ||
                    "Billing Email Address*"}{" "}
                </StyledTypography>
                <TextField
                  name="billingemail"
                  value={personalDetails?.billingemail}
                  onChange={handleGeneralDetails}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 125 }}
                />
                <ErrorTypography>
                  {errorText.personalDetails.billingemail}
                </ErrorTypography>
              </Grid>
            )}
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 16px",
              borderTop: "1px solid #F3F4F6",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at "}{" "}
                {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Box>
            <Button
              onClick={() => Continue("Review Information")}
              sx={{
                backgroundColor: "#E7EFF3",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
            >
              {LanguageData?.Common_Continue || "Continue"}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px" }}>
          <Box
            sx={{
              p: 2,
              borderRadius: "8px 8px 0 0 ",
              borderBottom: "1px solid #E5E7EB",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Administrator_Details ||
                "Administrator Details"}{" "}
            </SecondaryTypography>
          </Box>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.First_Name_no || "First Name"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {personalDetails?.firstName}
              </TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {" "}
                {LanguageData?.Last_Name_no || "Last Name"}{" "}
              </StyledSecondaryTypography>
              <TypographyDefault>{personalDetails?.lastName}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Email_Address || "Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{personalDetails?.email}</TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {personalDetails?.country_code} {personalDetails?.phone}
              </TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Billing_Email_Address || "Billing Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {billingemail
                  ? personalDetails.email
                  : personalDetails.billingemail}
              </TypographyDefault>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 16px",
              mb: 5,
              borderTop: "1px solid #F3F4F6",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Box>
            <Button
              onClick={() => handleContinue()}
              sx={{
                backgroundColor: "#E7EFF3",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
            >
              {LanguageData?.Common_Confirm || "Confirm"}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/settings");
            }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={0.5}>
              {" "}
              {LanguageData?.Administrator || "Administrator"}{" "}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.Edit_details_administrator ||
                "Edit details of the administrator."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%" mb={5}>
          {blocks.map((itm) => {
            return (
              <Grid xs={5.91} onClick={() => Continue(itm.tle, "")}>
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Details && Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
};

export default EditAdministrator;
