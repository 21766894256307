import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDetails,
  setNestedStep,
  editGeneralDetails,
  updatePropertyDetailsCompletedSteps,
  setPropertyId,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { changeUserData } from "../../../Utils";
import moment from "moment";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const ReviewGeneralDetails = ({ setDisplayScreen, generalDetails, image }) => {
  const dispatch = useDispatch();
  let {
    addGeneralDetailsStatus,
    editGeneralDetailsStatus,
    propertyId,
    propertyCompletedStep,
  } = useSelector((state) => state.PropertyDetails);
  const [response, setResponse] = useState("");
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    if (addGeneralDetailsStatus != null) {
      if (addGeneralDetailsStatus.success === true) {
        dispatch(setPropertyId(addGeneralDetailsStatus.data.property.id));
        setResponse(addGeneralDetailsStatus.message);
        dispatch(
          updatePropertyDetailsCompletedSteps({ propertyId, completed_step: 1 })
        );
        SetPostLoader(false);
      } else {
        if (addGeneralDetailsStatus.error === "Validation Error") {
          let obj = addGeneralDetailsStatus.data.validation_error;
          let str = "";
          for (let key in obj) {
            str = str.concat(obj[key]);
          }
          setResponse(str);
        } else {
          setResponse(addGeneralDetailsStatus.error);
        }
      }
    }
  }, [addGeneralDetailsStatus]);

  useEffect(() => {
    if (propertyCompletedStep != null) {
      if (propertyCompletedStep.success === true) {
        changeUserData(1, "change");
        dispatch(setActiveStep(1));
        dispatch(setPropertySliceToNull());
        dispatch(setNestedStep(0));
      }
    }
  }, [propertyCompletedStep]);

  useEffect(() => {
    if (editGeneralDetailsStatus != null) {
      if (editGeneralDetailsStatus.success === true) {
        dispatch(setPropertyId(editGeneralDetailsStatus.data.property.id));
        setResponse(editGeneralDetailsStatus.message);
        if (changeUserData(1)) {
          dispatch(
            updatePropertyDetailsCompletedSteps({
              propertyId,
              completed_step: 1,
            })
          );
        } else if (!changeUserData(1)) {
          dispatch(setActiveStep(1));
          dispatch(setPropertySliceToNull());
          dispatch(setNestedStep(0));
        }
      } else {
        if (editGeneralDetailsStatus.error === "Validation Error") {
          let obj = editGeneralDetailsStatus.data.validation_error;
          let str = "";
          for (let key in obj) {
            str = str.concat(obj[key]);
          }
          setResponse(str);
        } else {
          setResponse(editGeneralDetailsStatus.error);
        }
      }
    }
  }, [editGeneralDetailsStatus]);

  const handleContinue = () => {
    SetPostLoader(true);
    let data = new FormData();
    data.append("title", generalDetails.title);
    data.append("email", generalDetails.email);
    data.append("phone", generalDetails.phone);
    data.append("country_code", generalDetails.country_code);
    data.append("address", generalDetails.address);
    data.append("country", generalDetails.country);
    data.append("city", generalDetails.city);
    data.append("zipcode", generalDetails.zipcode);
    data.append("description", generalDetails.details);
    {
      Boolean(image.logo.file) && data.append("property_logo", image.logo.file);
    }
    {
      Boolean(image.cover.file) && data.append("cover_image", image.cover.file);
    }
    dispatch(editGeneralDetails({ datas: data, propertyId }));

    // if(propertyId==null){
    //    dispatch(addGeneralDetails(data))
    // }
    // else{

    // }
  };
  return (
    <>
      {postLoader && <PostLoaderModal />}
      <BackArrow
        onClick={() => {
          setDisplayScreen("coverAndLogo");
          dispatch(setNestedStep(1));
        }}
        style={{ marginBottom: "30px", cursor: "pointer" }}
      />
      <PrimaryTypography mb={0.5}>General</PrimaryTypography>
      <StyledSecondaryTypography mb="30px">
        Information related to property development and building.
      </StyledSecondaryTypography>
      <Box borderRadius="8px" sx={{ backgroundColor: "#FFFFFF" }}>
        <Box
          sx={{
            mt: 2,
            p: 2,
            borderRadius: "8px 8px 0 0 ",
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <SecondaryTypography>Property Details</SecondaryTypography>
        </Box>
        <Grid container rowSpacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <StyledSecondaryTypography>
              Property Title
            </StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.title}</TypographyDefault>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>
              Property Address
            </StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.address}</TypographyDefault>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>Country</StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.country}</TypographyDefault>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>City</StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.city}</TypographyDefault>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.zipcode}</TypographyDefault>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>
              Corporate Email Address
            </StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.email}</TypographyDefault>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledSecondaryTypography>
              Main Contact Number
            </StyledSecondaryTypography>
            <TypographyDefault>
              {generalDetails?.country_code} {generalDetails?.phone}
            </TypographyDefault>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <StyledSecondaryTypography>
              Details of the property
            </StyledSecondaryTypography>
            <TypographyDefault>{generalDetails?.details}</TypographyDefault>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, p: 2, borderBottom: "1px solid #E5E7EB" }}>
          <SecondaryTypography>Logo & Cover Image</SecondaryTypography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${image.cover.src})`,
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                width: "380px",
                borderRadius: "8px",
                p: 4,
                my: 15,
              }}
            >
              <Box
                sx={{
                  height: "85px",
                  borderRadius: "8px ",
                  width: "85px",
                  padding: "10px ",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                  backgroundImage: `url(${image.logo.src})`,
                }}
              ></Box>
              <PrimaryTypography sx={{ mt: 2 }}>
                Log in to your account
              </PrimaryTypography>
              <StyledSecondaryTypography sx={{ mt: 1 }}>
                The LOBY portal is for Juristics & Condo Admin access only.
              </StyledSecondaryTypography>
              <TextField
                disabled
                sx={{ my: 2 }}
                placeholder="Email Address"
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
              />
              <Button variant="contained" disabled>
                Login
              </Button>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
              >
                <StyledSecondaryTypography textAlign={"center"}>
                  {" "}
                  Remember for 30 days
                </StyledSecondaryTypography>
                <StyledTypography>Forgot password?</StyledTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography sx={{ fontSize: "16px" }} textAlign={"center"}>
          {response}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            mb: 5,
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshIcon style={{ marginRight: "5px" }} />
            <StyledSecondaryTypography>
              Last Updated at{" "}
              {moment.utc(generalDetails?.updateDate).local().format("HH:mm A")}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={handleContinue}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReviewGeneralDetails;
