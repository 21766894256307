import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CustomTextEditor from "../../../../CommonComponents/CustomTextEditor";
import parse from "html-react-parser";
import ImageCourosel from "../../../../CommonComponents/ImageCourosel";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as DeleteSBIcon } from "../../../../Icons/DeleteSBIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as CrosSIcon } from "../../../../Icons/TermsIcon.svg";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  SecondaryTypographyDefault,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../../Theme";
import { Stack, Box, Button, Grid, TextField, Typography } from "@mui/material";
import AlertModal, {
  PostLoaderModal,
} from "../../../../CommonComponents/modal";
import axiosInstance from "../../../../Redux/AxiosInstance";
import { showToast } from "../../../../CommonComponents/Toaster";

let leaveModalDatas = {
  label: "Are you sure you want to leave this section?",
  descriptionOne:
    "You have unfinished content to be filled. Filled information on this page will be lost if you leave the section.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Leave",
  },
};

let deleteTermsConditions = {
  label: "Confirm to delete the Terms & Conditions ",
  descriptionOne:
    "Please confirm that you wish to delete the Terms & Conditions  of Service Request.Upon deletion, the policy will disappear from both dashboard and mobile app.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

let deleteCargoLiftData = {
  label: "Confirm to delete the Cargo Lift Specification",
  descriptionOne:
    "Please confirm that you wish to delete the Cargo Lift Specification. Upon deletion, the policy will disappear from both dashboard and mobile app.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

export default function AddGuidelines() {
  const navigate = useNavigate();
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];
  const [CargoLiftData, setCargoLiftData] = useState({
    capacity: "",
    width: "",
    height: "",
    depth: "",
    door_width: "",
  });
  const [terms_conditions, setTerms_conditions] = useState("");
  const [terms_conditions_htmlContent, setterms_conditions_HtmlContent] =
    useState("");
  const [checkterms_conditions, setcheckterms_conditions] = useState("");
  const [errText, setErrText] = useState({
    terms_conditions: false,
    capacity: false,
    width: false,
    height: false,
    depth: false,
    door_width: false,
  });
  const [leavemodal, setLeavemodal] = useState(false);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);

  const [deleteTermsConditionmodal, setdeleteTermsConditionmodal] =
    useState(false);
  const [deleteCargoLift, setdeleteCargoLift] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    // setcheckterms_conditions(parse(terms_conditions)?.props?.children?.length)
    const terms_conditionLength = terms_conditions?.replace(/<[^>]+>/g, "");
    setcheckterms_conditions(terms_conditionLength.trim().length);
    setErrText((prev) => ({ ...prev, terms_conditions: false }));
  }, [terms_conditions]);

  const handleCargoLiftDtata = (e, id) => {
    const { name, value } = e.target;
    setCargoLiftData((prev) => ({ ...prev, [name]: value }));
    setErrText((prev) => ({ ...prev, [name]: false }));
  };

  const deleteGuidelines = () => {
    setCargoLiftData({
      capacity: "",
      width: "",
      height: "",
      depth: "",
      door_width: "",
    });
    setdeleteCargoLift(false);
  };

  useEffect(() => {
    if (isCargoDataEmpty()) {
      for (const key in CargoLiftData) {
        setErrText((prev) => ({ ...prev, [key]: false }));
      }
    }
  }, [CargoLiftData]);

  const isCargoDataEmpty = () => {
    let val = true;
    for (const key in CargoLiftData) {
      if (CargoLiftData[key] != "") {
        val = false;
      }
    }
    return val;
  };

  const valid = () => {
    let isValid = true;
    if (checkterms_conditions == 0) {
      isValid = false;
      setErrText((prev) => ({ ...prev, terms_conditions: true }));
    }
    if (!isCargoDataEmpty()) {
      for (const key in CargoLiftData) {
        if (CargoLiftData[key] == "") {
          setErrText((prev) => ({ ...prev, [key]: true }));
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const Continue = (nxtval) => {
    if (valid()) {
      if (nxtval == "Review Information") {
        setaddblocks({ Details: false, Review_Information: true });
      } else if (nxtval == "Details") {
        setaddblocks({ Details: true, Review_Information: false });
      }
    }
  };

  const closeModal = () => {
    setLeavemodal(false);
    setdeleteTermsConditionmodal(false);
    setdeleteCargoLift(false);
  };

  const handleCreateTerms = () => {
    let data = new FormData();
    const size =
      CargoLiftData?.width +
      "mm (width) x " +
      CargoLiftData?.height +
      " (height) x " +
      CargoLiftData?.depth +
      " (depth) and Door width " +
      CargoLiftData?.door_width;
    data.append("terms_condition", terms_conditions);
    if (CargoLiftData.capacity) {
      data.append("max_load", CargoLiftData.capacity);
      data.append("lift_size", size);
      data.append("door_width", CargoLiftData.door_width);
    }

    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/service_request_terms_condition`,
        data
      )
      .then((res) => {
        if (res.data?.success) {
          localStorage.setItem("fromService", true);
          showToast(res?.data?.toast);
          navigate("/documentations");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Details = () => {
    return (
      <>
        <Box
          backgroundColor="#FFFFFF"
          mb={4}
          sx={{ borderRadius: "8px", border: "1px solid #E5E7EB" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            borderBottom="1px solid #E5E7EB"
            height="53px"
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Terms_And_Condition || "Terms & Conditions"}
            </SecondaryTypography>
          </Stack>
          <Grid container padding="12px 16px 20px 16px">
            <Grid xs={12}>
              <Box mt={2} sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={terms_conditions}
                  settextEditorvalue={setTerms_conditions}
                  label={
                    "Enter the terms and conditions for the service request."
                  }
                  textEditorErr={errText?.terms_conditions}
                />
              </Box>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            borderBottom="1px solid #E5E7EB"
            height="53px"
          >
            <SecondaryTypography>
              {LanguageData?.Cargo_Lift_Specification ||
                "Cargo Lift Specification"}{" "}
            </SecondaryTypography>
          </Stack>
          <Box padding="24px 10px 24px 10px">
            <Grid
              container
              gap={2}
              padding="12px 16px 20px 16px"
              border="1px solid #E5E7EB"
              borderRadius="12px"
            >
              <Grid xs={12} sm={4.5}>
                <SecondaryTypographyDefault>
                  {LanguageData?.Maximum_Load_Capacity_unit ||
                    "Maximum Load / Capacity (kg)"}{" "}
                </SecondaryTypographyDefault>
                <TextField
                  placeholder="Enter maximum load"
                  InputProps={{ disableUnderline: true }}
                  value={CargoLiftData?.capacity}
                  name="capacity"
                  onChange={(e) => {
                    handleCargoLiftDtata(e);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: errText?.capacity ? "#912018" : "#111927",
                    },
                    backgroundColor: errText?.capacity ? "#FEE4E2" : "",
                  }}
                />
              </Grid>
              <Grid xs={12} sm={5}>
                <SecondaryTypographyDefault>
                  {LanguageData?.Lift_Size_unit || "Lift Size (mm)"}
                </SecondaryTypographyDefault>
                <Stack direction="row" alignItems="center" gap={2}>
                  <TextField
                    placeholder="Width"
                    InputProps={{ disableUnderline: true }}
                    value={CargoLiftData?.width}
                    name="width"
                    onChange={(e) => {
                      handleCargoLiftDtata(e);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        color: errText?.width ? "#912018" : "#111927",
                      },
                      backgroundColor: errText?.width ? "#FEE4E2" : "",
                    }}
                  />
                  <CrosSIcon style={{ marginTop: "10px" }} />
                  <TextField
                    placeholder="Height"
                    InputProps={{ disableUnderline: true }}
                    value={CargoLiftData?.height}
                    name="height"
                    onChange={(e) => {
                      handleCargoLiftDtata(e);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        color: errText?.height ? "#912018" : "#111927",
                      },
                      backgroundColor: errText?.height ? "#FEE4E2" : "",
                    }}
                  />
                  <CrosSIcon style={{ marginTop: "10px" }} />
                  <TextField
                    placeholder="Depth"
                    InputProps={{ disableUnderline: true }}
                    value={CargoLiftData?.depth}
                    name="depth"
                    onChange={(e) => {
                      handleCargoLiftDtata(e);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        color: errText?.depth ? "#912018" : "#111927",
                      },
                      backgroundColor: errText?.depth ? "#FEE4E2" : "",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid xs={12} sm={2.1}>
                <SecondaryTypographyDefault>
                  {LanguageData?.door_width || "Door Width (mm)"}
                </SecondaryTypographyDefault>
                <TextField
                  placeholder="Width"
                  InputProps={{ disableUnderline: true }}
                  value={CargoLiftData?.door_width}
                  name="door_width"
                  onChange={(e) => {
                    handleCargoLiftDtata(e);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: errText?.door_width ? "#912018" : "#111927",
                    },
                    backgroundColor: errText?.door_width ? "#FEE4E2" : "",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          mt={5}
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                Last Updated at {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => Continue("Review Information")}
                sx={{
                  padding: "8px 14px 8px 14px",
                  height: "41px",
                  color: "#0E5E84",
                  backgroundColor: "#E7EFF3 !important",
                }}
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box
          backgroundColor="#FFFFFF"
          sx={{ borderRadius: "8px", border: "1px solid #E5E7EB" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            borderBottom="1px solid #E5E7EB"
            height="53px"
          >
            <SecondaryTypography>
              {LanguageData?.Terms_And_Condition || "Terms & Conditions"}
            </SecondaryTypography>
          </Stack>
          <Grid container padding="12px 16px 20px 16px">
            <Grid xs={12}>
              <TypographyDefault
                mt={0.5}
                sx={{
                  wordWrap: "break-word",
                  "&>p": {
                    margin: "0px !important",
                    padding: "0px !important",
                  },
                }}
              >
                {parse(terms_conditions)}
              </TypographyDefault>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            borderBottom="1px solid #E5E7EB"
            borderTop="1px solid #E5E7EB"
            height="53px"
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.Cargo_Lift_Specification ||
                "Cargo Lift Specification"}
            </SecondaryTypography>
            <DeleteSBIcon
              onClick={() => {
                setdeleteCargoLift(true);
              }}
            />
          </Stack>
          <Grid container padding="12px 16px 20px 16px">
            <Grid xs={12} sm={5.8}>
              <StyledSecondaryServiceTypography>
                {LanguageData?.Maximum_Load_Capacity ||
                  "Maximum Load/ Capacity"}
              </StyledSecondaryServiceTypography>
              <TypographyDefault>
                {isCargoDataEmpty() ? "-" : CargoLiftData?.capacity + "kg"}{" "}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledSecondaryServiceTypography>
                {LanguageData?.Lift_Size || "Lift_Size"}
              </StyledSecondaryServiceTypography>
              <TypographyDefault>
                {isCargoDataEmpty()
                  ? "-"
                  : CargoLiftData?.width +
                    "mm (width) x " +
                    CargoLiftData?.height +
                    "mm (height) x " +
                    CargoLiftData?.depth +
                    "mm (depth) and Door width " +
                    CargoLiftData?.door_width +
                    "mm"}
              </TypographyDefault>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            px={2}
            justifyContent="end"
            alignItems="center"
            height="81px"
            borderTop="1px solid #E5E7EB"
            borderRadius="0 0 8px 8px "
          >
            {/* <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                Last Updated at {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack> */}
            <Button
              sx={{
                padding: "8px 14px 8px 14px",
                height: "41px",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3 !important",
              }}
              onClick={() => {
                handleCreateTerms();
                SetPostLoader(true);
              }}
            >
              {LanguageData?.Common_CreateNow || "Create Now"}
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {leavemodal && (
        <AlertModal
          modalDatas={leaveModalDatas}
          confirmFunction={() => navigate("/documentations")}
          closeFunction={closeModal}
        />
      )}
      {deleteCargoLift && (
        <AlertModal
          modalDatas={deleteCargoLiftData}
          confirmFunction={deleteGuidelines}
          closeFunction={closeModal}
        />
      )}

      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              setLeavemodal(true);
            }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={0.5}>
              {LanguageData?.Service_Request_Guidelines ||
                "Service Request Guidelines"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.Service_Request_Guidelines_des ||
                "Form Guidelines encompass the terms and conditions and cargo lift specifications for the Service Request"}
            </StyledSecondaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%" mb={5}>
          {blocks.map((itm) => {
            return (
              <Grid xs={5.91} onClick={() => Continue(itm.tle, "")}>
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Details && Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
}
