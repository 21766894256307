import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import { ReactComponent as AlertIcon } from "../Icons/ErrorAlert.svg";
import {
  StyledErrorTypography,
  StyledPrimaryTypography,
  StyledSecondaryTypography,
} from "../Theme";
import {
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyEmail,
  setLoginSliceToNull,
} from "../Redux/Feature/Login/loginSlice";

const ForgotPassword = ({ email, setEmail, setDisplayScreen, back }) => {
  const dispatch = useDispatch();
  const { loginStatus, verifyEmailStatus } = useSelector(
    (state) => state.Login
  );
  const [errorText, setErrorText] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (verifyEmailStatus !== null) {
      setLoadingBtn(false);
      if (verifyEmailStatus.success === true) {
        setDisplayScreen("otp");
        dispatch(setLoginSliceToNull());
      } else {
        setErrorText(
          verifyEmailStatus.error || "Something went wrong! Please try again."
        );
      }
    }
  }, [verifyEmailStatus]);

  const handleEmail = (e) => {
    let { value } = e.target;
    setEmail(value);
    setErrorText("");
  };
  const handleSingUp = () => {
    let isError = validateEmail();
    if (!isError) {
      setLoadingBtn(true);
      dispatch(verifyEmail({ email: email }));
    }
  };

  const validateEmail = () => {
    let isError = false;
    if (email === "") {
      setErrorText("Error! No input detected.");
      isError = true;
    } else if (emailRegex.test(email) === false) {
      setErrorText("Enter valid email.");
      isError = true;
    }
    return isError;
  };

  return (
    <>
      <BackArrow onClick={() => setDisplayScreen(back)} />
      <StyledPrimaryTypography sx={{ mt: 2 }}>
        Recover Password
      </StyledPrimaryTypography>
      <StyledSecondaryTypography>
        Please enter your email address below.
      </StyledSecondaryTypography>
      <TextField
        value={email}
        onChange={handleEmail}
        placeholder="Email Address"
        inputProps={{ maxLength: 125 }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              {errorText !== "" && <AlertIcon />}
            </InputAdornment>
          ),
        }}
        sx={{
          mt: 3,
          mb: 2,
          "& .MuiInputBase-root": {
            color: errorText === "" ? "#111927" : "#912018",
          },
          backgroundColor: errorText === "" ? "#F9FAFB" : "#FEE4E2",
        }}
      />
      {errorText != "" && (
        <StyledErrorTypography>{errorText}</StyledErrorTypography>
      )}
      <Button
        disabled={!email || loadingBtn}
        onClick={handleSingUp}
        fullWidth
        variant="contained"
      >
        {loadingBtn ? <CircularProgress size={25} /> : "Email OTP"}
      </Button>
    </>
  );
};

export default ForgotPassword;
