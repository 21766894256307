import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import dayjs from "dayjs";
import CustomTextEditor from "../../../CommonComponents/CustomTextEditor";
import { CustomDateRangePicker } from "../../../CommonComponents/CustomDateRange";
import CustomDateTimeRange from "../../../CommonComponents/CustomDateTimeRange";
import {
  DatePickerStyle,
  ErrorTypography,
  StyledPrimaryDefaultTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryServiceTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Alert,
  Box,
  Divider,
  Tabs,
  Tab,
  Button,
  Checkbox,
  Menu,
  TextField,
  MenuItem,
  Modal,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  IconButton,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import ImageCourosel from "../../../CommonComponents/ImageCourosel";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as FacilityStarsIcon } from "../../../Icons/FacilityStarsIcon.svg";
import { ReactComponent as SelectTimeIcon } from "../../../Icons/SelectTimeIcon.svg";
import { ReactComponent as DeleteSBIcon } from "../../../Icons/DeleteSBIcon.svg";
import { ReactComponent as LocationIcon } from "../../../Icons/LocationIcon.svg";
import { ReactComponent as TelphoneIcon } from "../../../Icons/TelphoneIcon.svg";
import { ReactComponent as GlobeIcon } from "../../../Icons/GlobeIcon.svg";

import { ReactComponent as JPGIcon } from "../../../Icons/Jpg.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../Icons/BackArrowMobileIcon.svg";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { NestedMenuItem } from "mui-nested-menu";
import { DatePicker } from "@mui/x-date-pickers";
import { showToast } from "../../../CommonComponents/Toaster";

const endAdornmentStyles = {
  position: "absolute",
  right: 0,
  top: "-20px",
  fontSize: "12px !important",
  "&>*": { fontSize: "12px !important", fontWeight: "400", color: "#9DA4AE" },
};

export default function EditPromotion() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { Marketing } = LanguageData;
  const navigate = useNavigate();
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);

  const [addblocks, setaddblocks] = useState({
    Event_Details: true,
    Review_Information: false,
  });
  const blocks = [
    {
      status: addblocks.Event_Details,
      tle: Marketing?.Event_Details || "Event Details",
    },
    {
      status: addblocks.Review_Information,
      tle: Marketing?.Review_Information || "Review Information",
    },
  ];
  const ref = useRef();
  const [tabValue, settabValue] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setlocation] = useState({
    location_name: "",
    address: "",
    map: "",
    contact: "",
    country_code: "",
    phone: "",
    website: " ",
  });
  const [ismapChecked, setIsMapChecked] = useState(false);
  const [image, setImage] = useState([{ file: "", src: "" }]);
  const [terms_conditions, setTerms_conditions] = useState("");
  const [updated, setUpdated] = useState(null);
  const [date, setDate] = useState({
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
  });
  console.log(date);
  const [errText, setErrtext] = useState({
    title: false,
    description: false,
    duration: false,
    image: false,
    startDate: false,
    endDate: false,
    location_name: false,
  });

  const [checkdescription, setcheckdescription] = useState("");
  const [checkterms_conditions, setcheckterms_conditions] = useState("");
  const [DateTimeRangeStatus, setDateTimeRangeStatus] = useState(true);
  // DateRange

  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const [time, setTime] = useState({ start: "", end: "" });
  const [isDatafilled, setIsDataFilled] = useState(false);
  // const [datetimerangedata, setdatetimerangedata] = useState([])
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    // setcheckdescription(parse(description)?.props?.children?.length)
    const descriptionLength = description?.replace(/<[^>]+>/g, "");
    setcheckdescription(descriptionLength.trim().length);
    setErrtext((prev) => ({ ...prev, description: false }));
  }, [description]);

  useEffect(() => {
    // setcheckterms_conditions(parse(terms_conditions)?.props?.children?.length)
    const terms_conditionLength = terms_conditions?.replace(/<[^>]+>/g, "");
    setcheckterms_conditions(terms_conditionLength.trim().length);
    setErrtext((prev) => ({ ...prev, terms_conditions: false }));
  }, [terms_conditions]);

  const inputImg = useRef();
  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage([{ file: file, src: URL.createObjectURL(file) }]);
      setErrtext((prev) => ({ ...prev, image: false }));
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (image?.length < 5) {
        setImage([{ file: file, src: URL.createObjectURL(file) }]);
        setErrtext((prev) => ({ ...prev, image: false }));
        e.target.value = null;
        // seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: false }))
      }
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const valid = () => {
    let isError = false;
    if (title == "") {
      setErrtext((prev) => ({ ...prev, title: true }));
      isError = true;
    }
    if (checkdescription == 0) {
      setErrtext((prev) => ({ ...prev, description: true }));
      isError = true;
    }
    if (!DateTimeRangeStatus) {
      setErrtext((prev) => ({ ...prev, duration: true }));
      isError = true;
    }
    if (image?.length == 0) {
      setErrtext((prev) => ({ ...prev, image: true }));
      isError = true;
    }
    if (location?.location_name == "") {
      setErrtext((prev) => ({ ...prev, location_name: true }));
      isError = true;
    }
    if (date?.startDate == null) {
      setErrtext((prev) => ({ ...prev, startDate: true }));
      isError = true;
    }
    if (date?.endDate == null) {
      setErrtext((prev) => ({ ...prev, endDate: true }));
      isError = true;
    }
    return isError;
  };

  const Continue = (nxtval) => {
    if (!valid()) {
      if (nxtval == (Marketing?.Event_Details || "Event Details")) {
        setaddblocks({ Event_Details: true, Review_Information: false });
        setIsDataFilled(true);
      } else if (
        nxtval == (Marketing?.Review_Information || "Review Information")
      ) {
        setaddblocks({ Event_Details: false, Review_Information: true });
        setIsDataFilled(true);
      }
    } else {
      setIsDataFilled(false);
    }
  };

  const handleTabValue = (e, value) => {
    settabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };

  const handleMobileInput = (value, country) => {
    setlocation((prev) => ({
      ...prev,
      contact: value,
      country_code: `+ ${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    }));
    setErrtext((prev) => {
      return {
        ...prev,
        country_code: false,
        phone: false,
      };
    });
  };

  const getPromotionData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/${id}`
      );
      const data = response?.data?.data?.marketing;
      setUpdated(response?.data?.data?.marketing?.updated_at);
      setTitle(data?.title);
      setDescription(Boolean(data?.description) ? data?.description : "");
      setlocation(data?.common_area?.common_area_name);
      setImage([
        {
          file: data?.images?.name,
          src: `${process.env.REACT_APP_FILE_PATH}${data?.images?.path}`,
        },
      ]);
      setTerms_conditions(
        Boolean(data?.terms_condition) ? data?.terms_condition : ""
      );
      setDate({
        startDate: dayjs(data?.start_date),
        endDate: dayjs(data?.end_date),
      });
      const startDate = moment.utc(data?.duration_start_date);
      const startTime = data?.duration_from_time;
      const combinedDateTime =
        startDate && startTime
          ? moment(startDate).add({
              hours: startTime.slice(0, 2),
              minutes: startTime.slice(3, 5),
              seconds: startTime.slice(6, 8),
            })
          : null;
      const local = moment.utc(combinedDateTime).local().format("DD MMM YYYY");
      const StartTime = moment.utc(combinedDateTime).local().format("HH:mm:ss");
      const EndDate = moment.utc(data?.duration_end_date);
      const EndTime = data?.duration_end_time;
      const combinedDateTimeEnd =
        EndDate && EndTime
          ? moment(EndDate).add({
              hours: EndTime.slice(0, 2),
              minutes: EndTime.slice(3, 5),
              seconds: EndTime.slice(6, 8),
            })
          : null;
      const local1 = moment
        .utc(combinedDateTimeEnd)
        .local()
        .format("DD MMM YYYY");
      const EndTimeDuration = moment
        .utc(combinedDateTimeEnd)
        .local()
        .format("HH:mm:ss");
      if (local && local1) {
        setdatetimerangedata([dayjs(local), dayjs(local1)]);
        setTime({
          start: StartTime,
          end: EndTimeDuration,
        });
      }
      setlocation({
        location_name: data?.location_name,
        address: data?.address,
        map: data?.map_link,
        contact: `( ${data?.country_code}) ${data?.phone} `,
        country_code: data?.country_code,
        phone: data?.phone,
        website: data?.website,
      });
      {
        Boolean(data?.map_link)
          ? setIsMapChecked(true)
          : setIsMapChecked(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPromotionData();
  }, []);

  const handleConfirm = async () => {
    const checkStartDate =
      moment(date?.startDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const StartDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkEndDate =
      moment(date?.endDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const EndDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AnnouncemntStart = date?.startDate?.$d;
    const final = moment(AnnouncemntStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AnnouncemntEnd = date?.endDate?.$d;
    const finalEnd = moment(AnnouncemntEnd).hour(0).minute(0).second(0);
    const finalEnd1 = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const dateStartRange = moment(datetimerangedata[0]?.$d).format(
      "YYYY-MM-DD"
    );
    const StartTime = time?.start;
    const combinedDateTime = new Date(
      new Date(dateStartRange).setHours(
        StartTime.slice(0, 2),
        StartTime.slice(3, 5),
        StartTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTC = moment.utc(combinedDateTime);
    const dateEndRange = moment(datetimerangedata[1]?.$d).format("YYYY-MM-DD");
    const EndTime = time?.end;
    const combinedDateTimeEnd = new Date(
      new Date(dateEndRange).setHours(
        EndTime.slice(0, 2),
        EndTime.slice(3, 5),
        EndTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTCEnd = moment.utc(combinedDateTimeEnd);
    const data = new FormData();
    data.append("marketing_type_id", 2);
    // data.append('status_id', 1)
    // data.append('common_area_id', location)
    {
      Boolean(location?.address) && data.append("address", location?.address);
    }
    data.append("phone", location?.phone);
    data.append("country_code", location?.country_code);
    // data.append('email', est @yopmail.com)
    data.append("title", title);
    // data.append('subtext', testsubtext)
    data.append("description", description);
    data.append("terms_condition", terms_conditions);
    {
      Boolean(image[0]?.file?.name) &&
        data.append("image_path[]", image[0].file);
    }
    if (datetimerangedata[0] && datetimerangedata[1]) {
      data.append(
        "duration_start_date",
        moment(combinedDateTimeUTC).format("YYYY-MM-DD")
      );
      data.append(
        "duration_end_date",
        moment(combinedDateTimeUTCEnd).format("YYYY-MM-DD")
      );
      data.append(
        "duration_from_time",
        moment(combinedDateTimeUTC).format("HH:mm:ss")
      );
      data.append(
        "duration_end_time",
        moment(combinedDateTimeUTCEnd).format("HH:mm:ss")
      );
    }
    data.append("location_name", location?.location_name);
    {
      ismapChecked && data.append("map_link", location?.map);
    }
    data.append("website", location?.website);
    data.append("start_date", checkStartDate ? StartDateToday : final1);
    data.append("end_date", checkEndDate ? EndDateToday : finalEnd1);

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/${id}`,
        data
      );
      navigate("/Marketing");
      SetPostLoader(false);
      showToast(response?.data?.toast);
    } catch (error) {
      console.log(error);
    }
  };

  const Event_Details = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {Marketing?.Promotions_Details || "Promotions Details"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography>
                {LanguageData?.Title || "Title"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errText?.title ? "#912018" : "#111927",
                  },
                  backgroundColor: errText?.title ? "#FEE4E2" : " #F9FAFB",
                }}
                name="title"
                placeholder={LanguageData?.Title_Placeholder || "Enter title"}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrtext((prev) => ({ ...prev, title: false }));
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment sx={endAdornmentStyles}>
                      {title?.length}/50
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid xs={12} mt={3}>
              <StyledTypography>
                {LanguageData?.Description || "Description"}
              </StyledTypography>
              <Box mt={2} sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={description}
                  settextEditorvalue={setDescription}
                  label={"Write description"}
                  textEditorErr={errText?.description}
                />
              </Box>
              <StyledSecondaryServiceTypography
                mt={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "12px !important",
                  color: "#9DA4AE !important",
                }}
              >
                {checkdescription}/3000
              </StyledSecondaryServiceTypography>
            </Grid>
          </Grid>

          {/* Duration */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Duration || "Duration"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography mb={1.4}>
                {LanguageData?.Date_Time_Range || "Date & Time Range"}
              </StyledTypography>

              <CustomDateTimeRange
                datetimerangedata={datetimerangedata}
                setdatetimerangedata={setdatetimerangedata}
                time={time}
                setTime={setTime}
                isStatus={DateTimeRangeStatus}
                setIsStatus={setDateTimeRangeStatus}
                errDateRange={errText?.duration}
                seterrDateRange={setErrtext}
              />
            </Grid>
          </Grid>

          {/* Location */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Location || "Location"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="30px"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography>
                {LanguageData?.Location_Name || "Location Name"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errText?.location_name ? "#912018" : "#111927",
                  },
                  backgroundColor: errText?.location_name
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                name="location"
                placeholder={
                  LanguageData?.Location_Name_Placeholder ||
                  "Enter location name"
                }
                value={location?.location_name}
                onChange={(e) => {
                  setlocation((prev) => ({
                    ...prev,
                    location_name: e.target.value,
                  }));
                  setErrtext((prev) => ({ ...prev, location_name: false }));
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <StyledTypography>
                {LanguageData?.Address || "Address"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": { color: "#111927" },
                  backgroundColor: " #F9FAFB",
                }}
                name="address"
                placeholder={
                  LanguageData?.Address_Placeholder || "Enter address"
                }
                value={location?.address}
                onChange={(e) => {
                  setlocation((prev) => ({ ...prev, address: e.target.value }));
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              <Stack
                my={1.5}
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <Checkbox
                  checked={ismapChecked}
                  onChange={(e) => {
                    setIsMapChecked(e.target.checked);
                  }}
                  icon={<CheckboxIcon />}
                  sx={{ height: "40px", width: "42px" }}
                />
                <styledTyphographyDefault>
                  {LanguageData?.Include_map_link ||
                    " Include google or apple map link"}
                </styledTyphographyDefault>
              </Stack>
              {ismapChecked && (
                <TextField
                  name="map"
                  value={location?.map}
                  onChange={(e) => {
                    setlocation((prev) => ({ ...prev, map: e.target.value }));
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                />
              )}
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography sx={{ marginBottom: "10px" }}>
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledTypography>
              <PhoneInput
                placeholder="Enter contact number"
                value={location?.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(value, country)
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: "#F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                }}
                countryCodeEditable={false}
                renderValue={false ? undefined : () => "Select location"}
              />
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>
                {" "}
                {LanguageData?.Website || "Website"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": { color: "#111927" },
                  backgroundColor: " #F9FAFB",
                }}
                name="website"
                placeholder={
                  LanguageData?.Website_Placeholder ||
                  "Enter website link if any"
                }
                value={location?.website}
                onChange={(e) => {
                  setlocation((prev) => ({ ...prev, website: e.target.value }));
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Image */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Image || "Image"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              {image.map(
                (value) =>
                  !value?.file && (
                    <Stack
                      onDragOver={handleDragImage}
                      onDrop={handleDropImage}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "24px 16px 24px 16px",
                        border: errText.image
                          ? "1px solid #912018"
                          : "1px solid #EAECF0",
                        borderRadius: "12px",
                        backgroundColor: errText.image ? "#FEE4E2" : "",
                      }}
                    >
                      <Stack
                        flex={1}
                        direction="column"
                        alignItems="center"
                        textAlign="center"
                      >
                        {errText?.image && (
                          <ErrorTypography
                            mb={1.5}
                            sx={{ marginTop: "0px !important" }}
                          >
                            {LanguageData?.Promotion_Image_ErrText ||
                              "Please upload promotion image"}
                          </ErrorTypography>
                        )}
                        <UploadIcon onClick={handleImage} />
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png,.svg,.gif"
                          ref={inputImg}
                          onChange={handleInputImage}
                          hidden
                        />
                        <Stack onClick={handleImage} my="5px" direction="row">
                          <Typography
                            marginRight="5px"
                            sx={{
                              fontSize: "14px !important",
                              fontWeight: "600 !important",
                              color: "#0E4965 !important",
                            }}
                          >
                            {LanguageData?.Click || " Click to upload"}
                          </Typography>
                          <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                            {LanguageData?.Drag || "or drag and drop"}
                          </StyledSecondaryTypography>
                        </Stack>
                        <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                          {Marketing?.Image_Types ||
                            "Types: JEPG, PNG (1MB max file size) (Max 1 file)"}
                        </StyledSecondaryTypography>
                        <StyledSecondaryServiceTypography
                          mt={1.5}
                          sx={{ fontSize: "12px !important" }}
                        >
                          {Marketing?.Image_Dimension ||
                            "Dimension: 375px (w) x 211px (h)"}
                        </StyledSecondaryServiceTypography>
                      </Stack>
                    </Stack>
                  )
              )}

              {image?.map((itm, index) => {
                if (itm.file) {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #EAECF0",
                        borderRadius: "12px",
                        p: 2,
                        mt: 2,
                      }}
                    >
                      <Stack direction="row">
                        <JPGIcon style={{ marginRight: "12px" }} />
                        <Stack direction="column">
                          <StyledSecondaryTypography>
                            {Boolean(itm?.file?.name)
                              ? itm?.file?.name
                              : itm?.file}
                          </StyledSecondaryTypography>
                          <StyledSecondaryTypography>
                            {Boolean(itm?.file?.size)
                              ? (itm?.file?.size / 1048576).toFixed(2)
                              : "0.02"}{" "}
                            MB
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                      <DeleteIcon
                        onClick={() => {
                          setImage([
                            {
                              file: "",
                              src: "",
                            },
                          ]);
                        }}
                        width={40}
                        cursor={"pointer"}
                      />
                    </Box>
                  );
                } else {
                  return <></>;
                }
              })}
            </Grid>
          </Grid>

          {/* Terms & Conditions */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Terms_Conditions || "Terms & Conditions"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} mt={1.5}>
              <Box sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={terms_conditions}
                  settextEditorvalue={setTerms_conditions}
                  label={"Enter the terms and conditions for the event."}
                  textEditorErr={errText?.terms_conditions}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Publish Date */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Publish_Date || "Publish Date"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>
                {LanguageData?.Start_Date || "Start Date"}
              </StyledTypography>
              <DatePicker
                disablePast
                value={date?.startDate}
                slotProps={{
                  textField: {
                    placeholder:
                      LanguageData?.Start_date_Placeholder ||
                      "Select start date",
                  },
                }}
                onChange={(e) => {
                  setDate((prev) => ({ endDate: null, startDate: e }));
                  setErrtext((prev) => ({ ...prev, startDate: false }));
                }}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => (
                    <DatePickerIcon
                      stroke={errText?.startDate ? "#912018" : ""}
                    />
                  ),
                }}
                sx={{
                  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                    color: errText?.startDate ? "#912018 !important" : "",
                  },
                  backgroundColor: errText?.startDate ? "#FEE4E2" : "",
                  paddingRight: "20px",
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                    { borderBottom: "none" },
                }}
              />
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>
                {LanguageData?.End_date || "End Date"}
              </StyledTypography>
              <DatePicker
                minDate={date?.startDate}
                value={date?.endDate}
                slotProps={{
                  textField: {
                    placeholder:
                      LanguageData?.End_date_Placeholder || "Select end date",
                  },
                }}
                onChange={(e) => {
                  setDate((prev) => ({ ...prev, endDate: e }));
                  setErrtext((prev) => ({ ...prev, endDate: false }));
                }}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => (
                    <DatePickerIcon
                      stroke={errText?.endDate ? "#912018" : ""}
                    />
                  ),
                }}
                sx={{
                  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                    color: errText?.endDate ? "#912018 !important" : "",
                  },
                  backgroundColor: errText?.endDate ? "#FEE4E2" : "",
                  paddingRight: "20px",
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                    { borderBottom: "none" },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || " Last Updated at"}{" "}
                {moment.utc(updated).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() =>
                  Continue(
                    Marketing?.Review_Information || "Review Information"
                  )
                }
                variant="Standard"
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
          width="100%"
        >
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={LanguageData?.Details || "Details"}
              />
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={LanguageData?.Preview || "Preview"}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Post_Settings || "Post Settings"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Start_Date || "Start Date"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.startDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.End_date || "End Date"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.endDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Event Details  */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {Marketing?.Promotions_Details || "Promotions Details"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Title || "Title"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>{title}</TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {Marketing?.Promotion_Duration || "Promotion Duration"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")},{" "}
                  {time?.start} -{" "}
                  {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")},{" "}
                  {time?.end}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                  borderColor: "rgb(0 0 0 / 4%) !important",
                }}
              />
              <Grid xs={12} my={2}>
                <StyledSecondaryTypography mb={0.5}>
                  {LanguageData?.Descripton || "Description"}
                </StyledSecondaryTypography>
                <TypographyDefault
                  mt={0.5}
                  sx={{
                    wordWrap: "break-word",
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                  }}
                >
                  {parse(description)}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Location */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Location || "Location"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Location || "Location"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {location?.location_name || "-"}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Address || "Address"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {location?.address || "-"}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                  borderColor: "rgb(0 0 0 / 4%) !important",
                }}
              />
              <Grid xs={12} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Include_map_link ||
                    " Include google or apple map link"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {location?.map || "-"}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                  borderColor: "rgb(0 0 0 / 4%) !important",
                }}
              />
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Contact_Number || "Contact Number"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {Boolean(location?.phone)
                    ? `( ${location?.country_code})  ${location?.phone}`
                    : "-"}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Website || "Website"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {location?.website || "-"}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* IMages */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Image || "Image"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <Stack padding="20px 16px 20px 16px">
                  <img
                    src={image[0]?.src}
                    width="120px"
                    height="120px"
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* Terms & Condition */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Terms_Conditions || "Terms & Conditions"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "20px 24px 30px 24px" }}
            >
              <Grid xs={12}>
                <Stack
                  direction="column"
                  sx={{
                    wordWrap: "break-word",
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                    "& li": { padding: "5px !important" },
                  }}
                >
                  <TypographyDefault>
                    {Boolean(terms_conditions) ? parse(terms_conditions) : "-"}
                  </TypographyDefault>
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Stack
              py={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#F9FAFB",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <StyledPrimaryDefaultTypography>
                {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
              </StyledPrimaryDefaultTypography>
              <StyledSecondaryTypography>
                {LanguageData?.Scroll_mobile ||
                  "Scroll on the mobile phone to review details"}
              </StyledSecondaryTypography>
            </Stack>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                my={6.5}
                sx={{
                  width: "375px",
                  height: "750px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                  <BackArrowMobileIcon />
                  <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                    {title}
                  </Typography>
                </Stack>
                <img
                  src={image[0]?.src}
                  width="100%"
                  height="211px"
                  style={{ objectFit: "cover" }}
                />
                <Box padding="24px 16px 24px 16px">
                  {/* <ImageCourosel images={FacilityImages} /> */}
                  {/* <img src="https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=600" width="100%" /> */}
                  <SecondaryTypography>
                    {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")} -{" "}
                    {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")}{" "}
                  </SecondaryTypography>
                  <StyledSecondaryDefaultTypography>
                    {time?.start} - {time?.end}
                  </StyledSecondaryDefaultTypography>
                </Box>
                <Box height="10px" backgroundColor="#F9FAFB">
                  {" "}
                </Box>
                <Stack
                  direction="column"
                  gap={2.5}
                  sx={{ padding: "24px 16px 24px 16px" }}
                >
                  <SecondaryTypography>
                    {location?.location_name || "Location Name"}
                  </SecondaryTypography>
                  {Boolean(location?.address) && (
                    <Stack direction="row" alignItem="start">
                      <Box pt={0.4}>
                        <LocationIcon width="20px" />
                      </Box>
                      <TypographyDefault pl={2} paddingTop="-10px">
                        {location?.address}
                      </TypographyDefault>
                    </Stack>
                  )}
                  {Boolean(location?.phone) && (
                    <Stack direction="row" alignItem="start">
                      <TelphoneIcon />
                      <TypographyDefault pl={2}>
                        ({location?.country_code}) {location?.phone}
                      </TypographyDefault>
                    </Stack>
                  )}
                  {Boolean(location?.map) && (
                    <Stack direction="row" alignItem="start">
                      <GlobeIcon />
                      <TypographyDefault pl={2}>
                        {location?.website}
                      </TypographyDefault>
                    </Stack>
                  )}
                </Stack>
                <Box height="10px" backgroundColor="#F9FAFB">
                  {" "}
                </Box>
                <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                  <Stack gap="4px">
                    <SecondaryTypography>
                      {LanguageData?.Details || "Details"}
                    </SecondaryTypography>
                    <TypographyDefault
                      sx={{
                        "&>p": {
                          margin: "0px !important",
                          padding: "0px !important",
                        },
                        "& li": { padding: "5px !important" },
                      }}
                    >
                      {parse(description)}{" "}
                    </TypographyDefault>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || " Last Updated at"}{" "}
                {moment.utc(updated).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => {
                  handleConfirm();
                  SetPostLoader(true);
                }}
                variant="Standard"
              >
                {LanguageData?.Confirm || "Confirm"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/Marketing");
            }}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {Marketing?.Edit_Promotion_Title || "Edit Promotion"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {Marketing?.Edit_Promotion_SubTitle ||
                "Edit details of the promotion below. Once edit date is reached, it will be updated on the LOBY system."}
            </StyledSecondaryTypography>
          </Box>
          {/* <Button disabled={!isDatafilled} sx={{ fontWeight: '600', height: "40px", width: "fit-content", color: '#0E5E84', backgroundColor: "#E7EFF3", padding: '10px 16px 10px 16px' }}  >Save Draft</Button> */}
        </Stack>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={5.91}
                onClick={() => Continue(itm.tle, "")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Event_Details && Event_Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
}
