import React, { useEffect , useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../Icons/MoreInfo.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { PrimaryTypography, SecondaryTypography, StyledSecondaryTypography } from "../../../Theme";
import { Box, Button, Typography, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setNestedStep, updatePropertyDetailsCompletedSteps, setPropertySliceToNull } from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import SystemDetailsModal from '../../../CommonComponents/SystemDetailsModal';
import { changeUserData } from '../../../Utils'
import moment from "moment";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const ReviewCommonArea = ({ commonArea, setDisplayScreen }) => {
    let singleObject = commonArea[0]
    const [postLoader, SetPostLoader] = useState(false);
    const dispatch = useDispatch()
    let { propertyId, propertyCompletedStep } = useSelector((state) => state.PropertyDetails)

    const handleContinue = () => {
        SetPostLoader(true)
        if (changeUserData(3)) {
            dispatch(updatePropertyDetailsCompletedSteps({ propertyId, completed_step: 3 }))
        }
        else if (!changeUserData(3)) {
            dispatch(setActiveStep(3))
            dispatch(setPropertySliceToNull())
            dispatch(setNestedStep(0))

        }
    }

    useEffect(() => {
        if (propertyCompletedStep != null) {
            if (propertyCompletedStep.success === true) {
                changeUserData(3, 'change')
                dispatch(setActiveStep(3))
                dispatch(setPropertySliceToNull())
                dispatch(setNestedStep(0))

            }
        }
    }, [propertyCompletedStep])

    const commonmodal = {
        commonarea: {
            label: "Common Area Explanation",
            description: 'Property Common Area refers to areas within the condo complex that are not housing any residents and will be listed in common area, and selectable when residents submit fault reports. '
        },

    }
    return (
        <> 
        {postLoader && <PostLoaderModal />}
            <BackArrow onClick={() => { setDisplayScreen('addCommonArea'); dispatch(setNestedStep(0)) }} style={{ marginBottom: '25px', cursor: 'pointer' }} />
            <PrimaryTypography mb='4px'>Common Area</PrimaryTypography>
            <StyledSecondaryTypography mb='30px' sx={{ width: '90%' }}>Setup of building common areas, such as gyms, pools and etc.</StyledSecondaryTypography>

            <Box sx={{ borderRadius: '8px', backgroundColor: '#FFFFFF', mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderRadius: '8px 8px 0 0 ', borderBottom: '1px solid #E5E7EB' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SecondaryTypography>Common Area <span style={{ color: '#9DA4AE', paddingLeft: '7px' }}> |</span></SecondaryTypography>
                        <StyledSecondaryTypography sx={{ ml: 1.3 }}>{commonArea.length} Common Areas</StyledSecondaryTypography>
                    </Box>
                    <SystemDetailsModal commonmodal={commonmodal.commonarea} />
                </Box>
                <Box sx={{ p: 2 }}>
                    {commonArea.map((value, index) => {
                        return (
                            <>
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <SecondaryTypography>{value.name} {value.isPartOfBuilding == 1 && <span style={{ color: '#9DA4AE', paddingLeft: '7px' }}> |</span>}</SecondaryTypography>
                                        {value.isPartOfBuilding == 1 && <SecondaryTypography ml={1.4} sx={{ fontWeight: '400 !important' }}>{value?.building_name} • {value?.level_name}</SecondaryTypography>}

                                    </Box>
                                    <StyledSecondaryTypography>{value.description}</StyledSecondaryTypography>
                                </Box>
                                {commonArea.length > index + 1 && <Divider />}
                            </>
                        )

                    })}
                </Box>
                <Box sx={{ display: "flex", justifyContent: 'space-between', p: 2, mb: 5, borderTop: '1px solid #F3F4F6' }}>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <RefreshIcon style={{ marginRight: '5px' }} />
                        <StyledSecondaryTypography>Last Updated at {moment.utc(singleObject?.updated_at || new Date()).local().format("HH:mm A")}</StyledSecondaryTypography>
                    </Box>
                    <Button onClick={handleContinue} sx={{ backgroundColor: '#E7EFF3' ,   "&:hover": {
                  backgroundColor: "#CFDFE6",
                },}}>Continue</Button>
                </Box>
            </Box>
        </>
    )
}

export default ReviewCommonArea;