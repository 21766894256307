import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

let initialState = {
    createFacilityStatus: null,
    editFacilityStatus: null,
    deleteFacilityStatus: null
}


export const createFacility = createAsyncThunk('createFacility', async (datas, { rejectWithValue }) => {
    const { propertyId, data } = datas
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}`, data)
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const editFacility = createAsyncThunk('editFacility', async (datas, { rejectWithValue }) => {
    const { propertyId, id, data } = datas
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`, data)
        return response;

    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteFacility = createAsyncThunk('deleteFacility', async (datas, { rejectWithValue }) => {
    const { propertyId, id } = datas
    try {
        const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`)
        return response

    } catch (err) {
        return rejectWithValue(err)
    }
})




const FacilityReducer = createSlice({
    name: 'Facility',
    initialState,
    reducers: {
        setfacilitySliceToNull: (state) => {
            state.createFacilityStatus = null
            state.editFacilityStatus = null
            state.deleteFacilityStatus = null
        },
    },
    extraReducers: (builders) => {
        builders.addCase(createFacility.fulfilled, (state, action) => {
            state.createFacilityStatus = action.payload.data
        })
        builders.addCase(createFacility.rejected, (state, action) => {
            state.createFacilityStatus = action.payload.response.data
        })
        builders.addCase(editFacility.fulfilled, (state, action) => {
            state.editFacilityStatus = action.payload.data
        })
        builders.addCase(editFacility.rejected, (state, action) => {
            state.editFacilityStatus = action.payload.response.data
        })
        builders.addCase(deleteFacility.fulfilled, (state, action) => {
            state.deleteFacilityStatus = action.payload.data
        })
        builders.addCase(deleteFacility.rejected, (state, action) => {
            state.deleteFacilityStatus = action.payload.response.data
        })
    }

})

export const { setfacilitySliceToNull } = FacilityReducer.actions
export default FacilityReducer.reducer