import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import dayjs from "dayjs";
import {
  DatePickerStyle,
  ErrorTypography,
  StyledPrimaryDefaultTypography,
  StyledSwitch,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryServiceTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Alert,
  Box,
  Divider,
  Tabs,
  Tab,
  Chip,
  Button,
  Skeleton,
  IconButton,
  Checkbox,
  Menu,
  TextField,
  MenuItem,
  Modal,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import ImageCourosel from "../../../CommonComponents/ImageCourosel";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as DeleteBIcon } from "../../../Icons/DeleteBIcon.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../Icons/BackArrowMobileIcon.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { getChip } from "../../../Utils/index";
import { showToast } from "../../../CommonComponents/Toaster";

let deleteModalDatas = {
  label: "Confirm to delete the event",
  descriptionOne:
    "Please confirm that you wish to delete the event. Upon deletion, the event will disappear from both dashboard and mobile app.",
  descriptionTwo:
    "If your intention is to hide the post temporarily to users, please turn the toggle to inactive instead.",

  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm",
  },
};

export default function ReviewEvent() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { Marketing } = LanguageData;
  const navigate = useNavigate();
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setlocation] = useState("");
  const [image, setImage] = useState([{ src: "" }]);
  const [terms_conditions, setTerms_conditions] = useState("");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [time, setTime] = useState({ start: "", end: "" });
  const [datetimerangedata, setdatetimerangedata] = useState({
    start: null,
    end: null,
  });
  const [tabValue, settabValue] = useState(0);
  const [ConfirmchangeStatus, setConfirmChangeStatus] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [DuplicateModal, setDuplicateModal] = useState(false);
  const [DuplicateName, setDuplicateName] = useState("");
  const [duplicateErr, setduplicateErr] = useState(false);
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);

  const handleTabValue = (e, value) => {
    settabValue(value);
  };

  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };

  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  const handleDuplicateConfirm = async () => {
    SetPostLoader(true);
    if (DuplicateName == "") {
      setduplicateErr(true);
    } else {
      const formData = new FormData();
      formData.append("title", DuplicateName);

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing_duplicate_draft/${id}`
        );
        showToast(response?.data?.toast);
        navigate("/Marketing");
        SetPostLoader(false);
      } catch (error) {
        console.log(error);
        setduplicateErr(true);
      }
    }
  };

  const handleCloseDupModal = () => {
    setduplicateErr(false);
    setDuplicateName(title);
    setDuplicateModal(false);
  };

  const handleConfirmDelete = async () => {
    SetPostLoader(true);
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/${id}`
      );
      navigate("/Marketing");
      SetPostLoader(false);
      showToast(response?.data?.toast, "delete");
    } catch (error) {
      console.log(error);
    }
  };

  const getEventData = async () => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/${id}`
      );
      const data = response?.data?.data?.marketing;
      const startDate = moment.utc(data?.duration_start_date);
      const startTime = data?.duration_from_time;
      const combinedDateTime =
        startDate && startTime
          ? moment(startDate).add({
              hours: startTime.slice(0, 2),
              minutes: startTime.slice(3, 5),
              seconds: startTime.slice(6, 8),
            })
          : null;
      const local = moment
        .utc(combinedDateTime)
        .local()
        .format("DD MMM YYYY,HH:mm");
      const EndDate = moment.utc(data?.duration_end_date);
      const EndTime = data?.duration_end_time;
      const combinedDateTimeEnd =
        EndDate && EndTime
          ? moment(EndDate).add({
              hours: EndTime.slice(0, 2),
              minutes: EndTime.slice(3, 5),
              seconds: EndTime.slice(6, 8),
            })
          : null;
      const local1 = moment
        .utc(combinedDateTimeEnd)
        .local()
        .format("DD MMM YYYY,HH:mm");

      setTitle(data?.title);
      setDescription(data?.description);
      setlocation(data?.common_area?.common_area_name);
      setImage([
        { src: `${process.env.REACT_APP_FILE_PATH}${data?.images?.path}` },
      ]);
      setTerms_conditions(data?.terms_condition);
      setDate({ startDate: data?.start_date, endDate: data?.end_date });
      // setTime({ start: data?.duration_from_time, end: data?.duration_end_time })
      setdatetimerangedata({
        start: local == "Invalid date" ? null : local1,
        end: local1 == "Invalid date" ? null : local1,
      });
      setStatus(data?.status);
      setDuplicateName(data?.title);
      setCreatedAt(data?.created_at);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("khgyjghvbn,bn");

  useEffect(() => {
    getEventData();
  }, []);

  const changeStatus = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing_status_update/${id}`
      );
      getEventData();
      setConfirmChangeStatus(false);
      SetPostLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(datetimerangedata?.end);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={handleConfirmDelete}
          closeFunction={handleShowDeleteModal}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/Marketing");
            }}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
        </Stack>
        <Stack mb={5} direction="row" justifyContent="space-between">
          <Box>
            <PrimaryTypography mb={1}>
              {loading ? (
                <Skeleton />
              ) : (
                Marketing?.Event_Details || "Event Details"
              )}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {loading ? (
                <Skeleton width={200} />
              ) : createdAt ? (
                LanguageData?.Created_on ||
                "Created on:" +
                  " " +
                  moment.utc(createdAt).local().format("DD MMM YYYY, HH:mm")
              ) : (
                "-"
              )}
            </StyledSecondaryTypography>
          </Box>
          {UserManage?.marketing &&
            !loading &&
            (status?.id !== 4 ? (
              <DeleteBIcon
                onClick={() => {
                  setshowDeleteModal(true);
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Button
                onClick={() => setDuplicateModal(true)}
                variant="Standard"
              >
                {LanguageData?.Duplicate_As_Draft || "Duplicate as draft"}
              </Button>
            ))}
        </Stack>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={400} />
        ) : (
          <Box
            mt={3}
            component="div"
            boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
            borderRadius="8px 8px 8px 8px"
            width="100%"
          >
            <Box
              sx={{
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px 8px 0 0 ",
              }}
            >
              <Tabs
                sx={{ width: "100%" }}
                value={tabValue}
                onChange={handleTabValue}
              >
                <Tab
                  sx={{ width: "50%", maxWidth: "none" }}
                  label={LanguageData?.Details || "Details"}
                />
                <Tab
                  sx={{ width: "50%", maxWidth: "none" }}
                  label={LanguageData?.Preview || "Preview"}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      {LanguageData?.Event_is || "Event is"}{" "}
                    </SecondaryTypography>
                    {getChip(status?.key)}
                  </Stack>
                  {UserManage?.marketing &&
                    (status?.id == 1 || status?.id == 2) && (
                      <StyledSwitch
                        checked={status?.id == 1 ? true : false}
                        onClick={() => {
                          setConfirmChangeStatus(true);
                        }}
                        name="loading"
                        color="primary"
                      />
                    )}
                </Stack>
                {UserManage?.marketing &&
                  (status?.id == 1 || status?.id == 2) && (
                    <TypographyDefault>
                      {LanguageData?.Status_toggle_Des ||
                        "Please click on the toggle button on the right to change the status."}
                    </TypographyDefault>
                  )}
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {LanguageData?.Publish_Date || "Publish Date"}
                </SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.To_Post_On || "To Post On"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {Boolean(date?.startDate)
                      ? moment
                          .utc(date?.startDate)
                          .local()
                          .format("DD MMM YYYY")
                      : "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.End_Post || "End Post"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {Boolean(date?.endDate)
                      ? moment.utc(date?.endDate).local().format("DD MMM YYYY")
                      : "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* Event Details  */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {Marketing?.Event_Details || "Event Details"}
                </SecondaryTypography>
                {UserManage?.marketing && status?.id !== 4 && (
                  <Button
                    onClick={() => {
                      navigate(`/Marketing/editEvent/${id}`);
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Title || "Title"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>{title}</TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Event_Duration || "Event Duration"}
                  </StyledSecondaryTypography>
                  {datetimerangedata?.start && datetimerangedata?.end && (
                    <TypographyDefault mt={0.5}>
                      {datetimerangedata?.start +
                        " " +
                        "-" +
                        " " +
                        datetimerangedata?.end}
                    </TypographyDefault>
                  )}
                  {!datetimerangedata?.start && !datetimerangedata?.end && (
                    <TypographyDefault mt={0.5}>{"-"}</TypographyDefault>
                  )}
                  {datetimerangedata?.start && !datetimerangedata?.end && (
                    <TypographyDefault mt={0.5}>
                      {datetimerangedata?.start}
                    </TypographyDefault>
                  )}
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} my={2}>
                  <StyledSecondaryTypography mb={0.5}>
                    {LanguageData?.Description || "Description"}
                  </StyledSecondaryTypography>
                  <TypographyDefault
                    mt={0.5}
                    sx={{
                      wordWrap: "break-word",
                      "&>p": {
                        margin: "0px !important",
                        padding: "0px !important",
                      },
                    }}
                  >
                    {Boolean(description) ? parse(description) : "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* Location */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Location || "Location"}
                </SecondaryTypography>
                {UserManage?.marketing && status?.id !== 4 && (
                  <Button
                    onClick={() => {
                      navigate(`/Marketing/editEvent/${id}`);
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Location || "Location"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>{location}</TypographyDefault>
                </Grid>
              </Grid>

              {/* IMages */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Image || "Image"}
                </SecondaryTypography>
                {UserManage?.marketing && status?.id !== 4 && (
                  <Button
                    onClick={() => {
                      navigate(`/Marketing/editEvent/${id}`);
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12}>
                  <Stack padding="20px 16px 20px 16px">
                    <img
                      src={image[0]?.src}
                      width="120px"
                      height="120px"
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              {/* Terms & Condition */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Terms_Conditions || "Terms & Conditions"}
                </SecondaryTypography>
                {UserManage?.marketing && status?.id !== 4 && (
                  <Button
                    onClick={() => {
                      navigate(`/Marketing/editEvent/${id}`);
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "20px 24px 30px 24px",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Grid xs={12}>
                  <Stack
                    direction="column"
                    sx={{
                      wordWrap: "break-word",
                      "&>p": {
                        margin: "0px !important",
                        padding: "0px !important",
                      },
                      "& li": { padding: "5px !important" },
                    }}
                  >
                    <TypographyDefault>
                      {Boolean(terms_conditions)
                        ? parse(terms_conditions)
                        : "-"}
                    </TypographyDefault>
                  </Stack>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Stack
                py={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#F9FAFB",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <StyledPrimaryDefaultTypography>
                  {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
                </StyledPrimaryDefaultTypography>
                <StyledSecondaryTypography>
                  {LanguageData?.Scroll_mobile ||
                    "Scroll on the mobile phone to review details"}
                </StyledSecondaryTypography>
              </Stack>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  my={6.5}
                  sx={{
                    width: "375px",
                    height: "750px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                    overflowY: "scroll",
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                    <BackArrowMobileIcon />
                    <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                      {title}
                    </Typography>
                    <StyledPrimaryDefaultTypography mt={2}>
                      {location}
                    </StyledPrimaryDefaultTypography>
                  </Stack>
                  <img
                    src={image[0]?.src}
                    width="100%"
                    height="211px"
                    style={{ objectFit: "cover" }}
                  />
                  <Box padding="24px 16px 24px 16px">
                    {/* <ImageCourosel images={FacilityImages} /> */}
                    {/* <img src="https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=600" width="100%" /> */}
                    {datetimerangedata?.end && datetimerangedata?.start ? (
                      <SecondaryTypography>
                        {datetimerangedata?.start} - {datetimerangedata?.end}{" "}
                      </SecondaryTypography>
                    ) : (
                      "-"
                    )}
                    {/* <StyledSecondaryDefaultTypography>
                      {time?.start} - {time?.end}
                    </StyledSecondaryDefaultTypography> */}
                  </Box>
                  <Box height="10px" backgroundColor="#F9FAFB">
                    {" "}
                  </Box>
                  <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                    <Stack gap="4px">
                      <SecondaryTypography>
                        {" "}
                        {LanguageData?.Details || "Details"}
                      </SecondaryTypography>
                      <StyledSecondaryTypography>
                        {Boolean(description) ? parse(description) : "-"}{" "}
                      </StyledSecondaryTypography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </CustomTabPanel>
          </Box>
        )}
      </Box>

      <Modal open={ConfirmchangeStatus}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Confirm_Inactive ||
                  "Confirm Inactive Immediately"}
              </PrimaryTypography>
              <Close onClick={() => setConfirmChangeStatus(false)} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <StyledSecondaryDefaultTypography
                sx={{ color: "#1F2A37 !important" }}
              >
                {LanguageData?.Confirm_Inactive_Des ||
                  "Please confirm if you wish to inactive the events immediately."}
              </StyledSecondaryDefaultTypography>
              <StyledSecondaryDefaultTypography
                sx={{ mt: 3, color: "#1F2A37 !important" }}
              >
                {" "}
                {Marketing?.Active_To_Inactive ||
                  "The status will change from 'Active' to 'Inactive'."}
              </StyledSecondaryDefaultTypography>
              <Box sx={{ mt: 3 }} display="flex" alignItems="center">
                {status?.id == 1 ? (
                  <>
                    {getChip("active")}
                    <Box display="flex" alignItems="center">
                      <div
                        style={{
                          marginLeft: "6px",
                          borderBottom: "1px solid black",
                          width: "50px",
                          height: "1px",
                        }}
                      ></div>
                      <span style={{ marginRight: "6px" }}>{">"}</span>
                    </Box>
                    {getChip("inactive")}
                  </>
                ) : (
                  <>
                    {getChip("inactive")}
                    <Box display="flex" alignItems="center">
                      <div
                        style={{
                          marginLeft: "6px",
                          borderBottom: "1px solid black",
                          width: "50px",
                          height: "1px",
                        }}
                      ></div>
                      <span style={{ marginRight: "6px" }}>{">"}</span>
                    </Box>
                    {getChip("active")}
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setConfirmChangeStatus(false)}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  changeStatus();
                  SetPostLoader(true);
                }}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={DuplicateModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Duplicate_As_Draft || "Duplicate as draft"}
              </PrimaryTypography>
              <Close onClick={handleCloseDupModal} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <StyledSecondaryDefaultTypography
                sx={{ color: "#1F2A37 !important" }}
              >
                Please confirm if you wish to duplicate as draft for “{title}“
                event. Once you confirm, the event will be saved in a draft
                screen.
              </StyledSecondaryDefaultTypography>
              <StyledTypography mt={4}>
                {LanguageData?.Event_title || "Event Title"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: duplicateErr ? "#912018" : "#111927",
                  },
                  backgroundColor: duplicateErr ? "#FEE4E2" : " #F9FAFB",
                }}
                name="title"
                placeholder="Enter title"
                value={DuplicateName}
                onChange={(e) => {
                  setDuplicateName(e.target.value);
                  setduplicateErr(false);
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              {duplicateErr && (
                <ErrorTypography>
                  This event already exist in draft.
                </ErrorTypography>
              )}
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={handleCloseDupModal}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => handleDuplicateConfirm()}
                disabled={DuplicateName == "" ? true : false}
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {LanguageData?.Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
