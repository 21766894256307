import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../Icons/MoreInfo.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SystemDetailsModal from "../../../CommonComponents/SystemDetailsModal";
import {
  updatePropertyDetailsCompletedSteps,
  setNestedStep,
  setActiveStep,
  setPropertySliceToNull,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { changeUserData } from "../../../Utils";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const PropertyInformationForm = ({
  urManagementdata,
  urOperationdata,
  urGroundstaffdata,
  setDisplayScreen,
  commonmodal,
}) => {
  const dispatch = useDispatch();
  const [postLoader, SetPostLoader] = useState(false);
  const { propertyId, propertyCompletedStep } = useSelector(
    (state) => state.PropertyDetails
  );

  const handlecontinue = () => {
    SetPostLoader(true);
    if (changeUserData(4)) {
      dispatch(
        updatePropertyDetailsCompletedSteps({ propertyId, completed_step: 4 })
      );
    } else if (!changeUserData(4)) {
      dispatch(setActiveStep(4));
      dispatch(setPropertySliceToNull());
      dispatch(setNestedStep(0));
    }
  };
  useEffect(() => {
    if (propertyCompletedStep != null) {
      if (propertyCompletedStep.success === true) {
        console.log(changeUserData(4, "change"));
        changeUserData(4, "change");
        dispatch(setActiveStep(4));
        dispatch(setPropertySliceToNull());
        dispatch(setNestedStep(0));
      }
    }
  }, [propertyCompletedStep]);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box bgcolor="#F3F4F6">
        <BackArrow
          onClick={() => {
            setDisplayScreen("addUserAndRole");
            dispatch(setNestedStep(0));
          }}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={4}>
          <PrimaryTypography mb="4px">Users</PrimaryTypography>
          <StyledSecondaryTypography>
            Add, edit and delete staff roles and permissions.
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="30px">
          <Grid xs={12}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0 ",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Management{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>{" "}
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {urManagementdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.management} />
            </Stack>
            <Box
              component="div"
              padding={1}
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                {urManagementdata?.map((itm, index) => {
                  const { title, service } = itm;
                  return (
                    <Accordion
                      sx={{
                        background: "none",
                        border: "none",
                        borderBottom:
                          urManagementdata?.length !== index + 1 &&
                          "1px solid #F3F4F6",
                        "::before": { height: "0px" },
                      }}
                    >
                      <AccordionSummary
                        height="55px"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <SecondaryTypography>{title}</SecondaryTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container xs={12} gap={3}>
                          <Grid xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              {itm?.service[0].rights.map((rts) => {
                                return (
                                  <Grid xs={6}>
                                    <StyledSecondaryTypography>
                                      {rts.name}
                                    </StyledSecondaryTypography>
                                  </Grid>
                                );
                              })}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Stack direction="row">
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[0].enabled === true &&
                                    sitm.rights[0].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[1].enabled === true &&
                                    sitm.rights[1].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0 ",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Operation{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {urOperationdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.operations} />
            </Stack>
            <Box
              component="div"
              padding={1}
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                {urOperationdata?.map((itm, index) => {
                  const { title, info } = itm;
                  return (
                    <Accordion
                      sx={{
                        background: "none",
                        border: "none",
                        borderBottom:
                          urOperationdata?.length !== index + 1 &&
                          "1px solid #F3F4F6",
                        "::before": { height: "0px" },
                      }}
                    >
                      <AccordionSummary
                        height="55px"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <SecondaryTypography>{title}</SecondaryTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container xs={12} gap={3}>
                          <Grid xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              {itm?.service[0].rights.map((rts) => {
                                return (
                                  <Grid xs={6}>
                                    <StyledSecondaryTypography>
                                      {rts.name}
                                    </StyledSecondaryTypography>
                                  </Grid>
                                );
                              })}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Stack direction="row">
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[0].enabled === true &&
                                    sitm.rights[0].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[1].enabled === true &&
                                    sitm.rights[1].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Stack
              sx={{
                borderRadius: "8px 8px 0 0 ",
                borderBottom: "1px solid #E5E7EB",
              }}
              direction="row"
              bgcolor="#FCFCFD"
              alignItems="center"
              padding="16px"
            >
              <Box flexGrow={1}>
                <Stack direction="row" alignItems="center">
                  <PrimaryTypography>
                    Ground Staff{" "}
                    <span style={{ color: "#9DA4AE", paddingLeft: "8px" }}>
                      {" "}
                      |
                    </span>
                  </PrimaryTypography>
                  <StyledSecondaryTypography ml="15px">
                    {urGroundstaffdata?.length} Roles
                  </StyledSecondaryTypography>
                </Stack>
              </Box>
              <SystemDetailsModal commonmodal={commonmodal.groundstaff} />
            </Stack>
            <Box
              component="div"
              padding={1}
              sx={{
                backgroundColor: "#FFFFFF !important",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                {urGroundstaffdata?.map((itm, index) => {
                  const { title, info } = itm;
                  return (
                    <Accordion
                      sx={{
                        background: "none",
                        border: "none",
                        borderBottom:
                          urGroundstaffdata?.length !== index + 1 &&
                          "1px solid #F3F4F6",
                        "::before": { height: "0px" },
                      }}
                    >
                      <AccordionSummary
                        height="55px"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <SecondaryTypography>{title}</SecondaryTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container xs={12} gap={3}>
                          <Grid xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              {itm?.service[0]?.rights?.map((rts) => {
                                return (
                                  <Grid xs={6}>
                                    <StyledSecondaryTypography>
                                      {rts.name}
                                    </StyledSecondaryTypography>
                                  </Grid>
                                );
                              })}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Stack direction="row">
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[0].enabled === true &&
                                    sitm.rights[0].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                          <Stack direction="column" width="50%">
                            {itm?.service.map((sitm, sindex) => {
                              return (
                                <>
                                  {sitm.rights[1].enabled === true &&
                                    sitm.rights[1].editable === true && (
                                      <Stack mt={1} gap="2px">
                                        <TypographyDefault>
                                          {sitm.service_name}
                                        </TypographyDefault>
                                      </Stack>
                                    )}
                                </>
                              );
                            })}
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              bgcolor="#FFFFFF"
              borderRadius="8px"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 16px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RefreshIcon style={{ marginRight: "5px" }} />
                <StyledSecondaryTypography>
                  Last Updated at {moment(new Date()).format("HH:mm A")}
                </StyledSecondaryTypography>
              </Box>
              <Button
                onClick={handlecontinue}
                sx={{
                  backgroundColor: "#E7EFF3",
                  "&:hover": {
                    backgroundColor: "#CFDFE6",
                  },
                }}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PropertyInformationForm;
