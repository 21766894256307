import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as LobbyText } from "../../../Icons/Lobbytext.svg";
import { ReactComponent as CameraIcon } from "../../../Icons/Camera.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteRedIcon.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/DownloadIcon.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { setNestedStep } from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import moment from "moment";

const CoverAndLogo = ({ setDisplayScreen, image, setImage }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [errorText, setErrorText] = useState({ cover: "", logo: "" });
  const coverRef = useRef(null);
  const handleCoverPhoto = (e) => {
    setImage({
      ...image,
      cover: {
        ...e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      },
    });
    setErrorText({ cover: "", logo: "" });
  };

  const handleLogoUpload = (e) => {
    setImage({
      ...image,
      logo: {
        ...e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      },
    });
    setErrorText({ cover: "", logo: "" });
  };

  const handleRemoveImage = () => {
    if (type === "cover") {
      setImage({
        ...image,
        cover: "",
      });
    } else if (type === "logo") {
      setImage({
        ...image,
        logo: "",
      });
    }
  };

  const validate = () => {
    let isError = [];
    for (let key in image) {
      if (image[key] === "") {
        setErrorText((prev) => {
          return {
            ...prev,
            [key]: `Add ${key} Image`,
          };
        });
        isError.push(true);
      }
    }
    return isError.includes(true);
  };

  const handleContinue = () => {
    let isError = validate();
    if (!isError) {
      setDisplayScreen("review");
      dispatch(setNestedStep(2));
    }
  };
  return (
    <>
      <BackArrow
        onClick={() => {
          setDisplayScreen("generalDetails");
          dispatch(setNestedStep(0));
        }}
        style={{ marginBottom: "30px", cursor: "pointer" }}
      />
      <PrimaryTypography mb={0.5}>General</PrimaryTypography>
      <StyledSecondaryTypography mb="30px">
        Information related to property development and building.
      </StyledSecondaryTypography>
      <Box borderRadius="8px" sx={{ backgroundColor: "#FFFFFF" }}>
        <Box
          sx={{
            p: 2,
            borderRadius: "8px 8px 0px 0px",
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <SecondaryTypography>Logo & Cover Image</SecondaryTypography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              background:
                image.cover === "" ? "#F3F4F6" : `url(${image.cover.src})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Box sx={{ position: "absolute", right: 20, top: 20 }}>
              {image.cover === "" && (
                <Button
                  component="label"
                  startIcon={<CameraIcon />}
                  sx={{
                    backgroundColor: "#E5E7EB",
                    "&:hover": { backgroundColor: "#E5E7EB" },
                    color: "#111927",
                  }}
                  variant="contained"
                >
                  Add cover photo
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleCoverPhoto(e)}
                    style={{ display: "none" }}
                  />
                </Button>
              )}
              {image.cover != "" && (
                <Button
                  onClick={() => coverRef.current.click()}
                  startIcon={<CameraIcon />}
                  sx={{
                    backgroundColor: "#E5E7EB",
                    "&:hover": { backgroundColor: "#E5E7EB" },
                    color: "#111927",
                  }}
                  variant="contained"
                >
                  Edit cover photo
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                width: "380px",
                borderRadius: "8px",
                p: 4,
                my: 15,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  height: "85px",
                  borderRadius: "8px ",
                  width: "85px",
                  padding: "10px ",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage:
                    image.logo === "" ? "" : `url(${image.logo.src})`,
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                {image.logo === "" && (
                  <LobbyText style={{ marginLeft: "-16px" }} />
                )}
                <Box
                  component="label"
                  sx={{
                    position: "absolute",
                    bottom: "-10px",
                    right: "-10px",
                    backgroundColor: "#E5E7EB",
                    padding: "7px 7px 4px 7px",
                    borderRadius: "100px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoUpload}
                    style={{ display: "none" }}
                  />
                  {/* <IconButton sx={{ backgroundColor: '#E5E7EB' }}> */}
                  <CameraIcon />
                  {/* </IconButton> */}
                </Box>
              </Box>
              <PrimaryTypography sx={{ mt: 2 }}>
                Log in to your account
              </PrimaryTypography>
              <StyledSecondaryTypography sx={{ mt: 1 }}>
                The LOBY portal is for Juristics & Condo Admin access only.
              </StyledSecondaryTypography>
              <TextField
                disabled
                sx={{ my: 2 }}
                placeholder="Email Address"
                inputProps={{ maxLength: 125 }}
                InputProps={{ disableUnderline: true }}
              />
              <Button variant="contained" disabled>
                Login
              </Button>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
              >
                <StyledSecondaryTypography textAlign={"center"}>
                  {" "}
                  Remember for 30 days
                </StyledSecondaryTypography>
                <StyledTypography>Forgot password?</StyledTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <ErrorTypography textAlign={"center"}>
            {errorText.cover}
          </ErrorTypography>
          <ErrorTypography textAlign={"center"}>
            {errorText.logo}
          </ErrorTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            mb: 5,
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshIcon style={{ marginRight: "5px" }} />
            <StyledSecondaryTypography>
              {" "}
              Last Updated at{" "}
              {moment.utc(image?.updateDate).local().format("HH:mm A")}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={handleContinue}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
      <input
        type="file"
        onChange={handleCoverPhoto}
        ref={coverRef}
        hidden
      ></input>
    </>
  );
};

export default CoverAndLogo;
