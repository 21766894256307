import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import MUIRichTextEditor from "mui-rte";
import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import parse from "html-react-parser";
import PhoneInput from "react-phone-input-2";
import ImageCourosel from "../../../../CommonComponents/ImageCourosel";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as CheckboxIcon } from "../../../../Icons/Checkbox.svg";
import { ReactComponent as FacilityStarsIcon } from "../../../../Icons/FacilityStarsIcon.svg";
import { ReactComponent as SelectTimeIcon } from "../../../../Icons/SelectTimeIcon.svg";
import { ReactComponent as DeleteSBIcon } from "../../../../Icons/DeleteSBIcon.svg";
import { ReactComponent as JPGIcon } from "../../../../Icons/Jpg.svg";
import { ReactComponent as DeleteIcon } from "../../../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as SideArrow } from "../../../../Icons/SideDropDownArrow.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../../Icons/ContactDetails.svg";
import { ReactComponent as BorderAlertCloseIcon } from "../../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DatePickerIcon } from "../../../../Icons/Datepicker.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../../Icons/BackArrowMobileIcon.svg";
import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { styled } from "@mui/material/styles";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  styledTyphographyDefault,
  StyledSecondaryServiceTypography,
  StyledSecondaryDefaultTypography,
  StyledPrimaryDefaultTypography,
  SecondaryTypographyDefault,
  SecondaryTypography,
  StyledSwitch,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Stack,
  Box,
  Button,
  Tooltip,
  tooltipClasses,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tab,
  Tabs,
  FormGroup,
  Typography,
  IconButton,
  Divider,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  Checkbox,
} from "@mui/material";

import AlertModal, {
  PostLoaderModal,
} from "../../../../CommonComponents/modal";
import { showToast } from "../../../../CommonComponents/Toaster";
import axiosInstance from "../../../../Redux/AxiosInstance";

const endAdornmentStyles = {
  position: "absolute",
  right: 0,
  top: "-20px",
  fontSize: "13px !important",
  "&>*": { fontSize: "13px !important", fontWeight: "400", color: "#9DA4AE" },
};

let leaveModalDatas = {
  label: "Are you sure you want to leave this section?",
  descriptionOne:
    "You have unfinished content to be filled. Filled information on this page will be lost if you leave the section.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Leave",
  },
};

export default function EditDocumentation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];
  const [questionData, setQuestionData] = useState({
    id: "",
    question: "",
    answer: "",
  });
  const [Questions, setQuestions] = useState([questionData]);
  const [errText, setErrText] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [leavemodal, setLeavemodal] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [deleteFaq, setDeleteFaq] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);

  let deleteModalDatas = {
    label: "Confirm to delete question permanently",
    descriptionOne: `Please confirm that you wish to delete question ${
      deleteId + 1
    }. Upon deletion, the question ${
      deleteId + 1
    } will disappear from both dashboard and mobile app.`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Confirm",
    },
  };

  useEffect(() => {
    SetPostLoader(true);
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}faq/property/${propertyId}`)
      .then(async (res) => {
        const filteredData = await res.data.data?.faq.map(
          ({ id, question, answer }) => ({ id, question, answer })
        );
        setQuestions(filteredData);
        SetPostLoader(false);
      })
      .catch((err) => {
        console.error(err);
        SetPostLoader(true);
      });
  }, [trigger]);

  const handleQuestion = (e, id) => {
    const { name, value } = e.target;
    setQuestions((prev) => {
      const data = Questions.map((itm, index) => {
        if (index == id) {
          return { ...itm, [name]: value };
        }
        return itm;
      });
      return data;
    });
    setErrText((prev) => {
      const data = errText?.map((itm, index) => {
        if (index == id) {
          return { ...itm, [name]: false };
        }
        return itm;
      });
      return data;
    });
  };

  const AddQuestion = () => {
    setQuestions((prev) => [...prev, { question: "", answer: "" }]);
  };

  const deleteQuestion = () => {
    SetPostLoader(true);
    const data = Questions.filter((itm, index) => index != deleteId);
    setQuestions(data);

    if (deleteFaq) {
      axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}faq/property/${propertyId}/record/${deleteFaq}`
        )
        .then((res) => {
          if (res) {
            setDeleteFaq(null);
            setTrigger(!trigger);
            showToast(res?.data?.toast, "delete");
            SetPostLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    closeModal();
  };

  useEffect(() => {
    if (Questions.length == 0) {
      navigate("/documentations");
    }
  }, [Questions]);

  const valid = () => {
    let isValid = true;
    setErrText((prev) => {
      const data = Questions.map((itm) => {
        const errs = { ...itm };
        for (const key in itm) {
          if (itm[key] == "") {
            Object.assign(errs, { [key]: true });
            isValid = false;
          } else if (itm[key] != "") {
            Object.assign(errs, { [key]: false });
            isValid = true;
          }
        }
        return errs;
      });
      return data;
    });
    return isValid;
  };

  const Continue = (nxtval) => {
    if (valid()) {
      if (nxtval == "Review Information") {
        setaddblocks({ Details: false, Review_Information: true });
      } else if (nxtval == "Details") {
        setaddblocks({ Details: true, Review_Information: false });
      }
    }
  };

  const closeModal = () => {
    setLeavemodal(false);
    setdeletemodal(false);
    setDeleteId(null);
  };

  const Details = () => {
    return (
      <>
        {Questions.map((itm, index) => {
          return (
            <Box
              backgroundColor="#FFFFFF"
              mb={4}
              sx={{ borderRadius: "8px", border: "1px solid #E5E7EB" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                padding="16px"
                borderBottom="1px solid #E5E7EB"
              >
                <SecondaryTypography>
                  {LanguageData?.Question || "Question"} {index + 1}
                </SecondaryTypography>
                <DeleteSBIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDeleteId(index);
                    setdeletemodal(true);
                    setDeleteFaq(itm?.id);
                  }}
                />
              </Stack>
              <Box sx={{ padding: "24px 10px 24px 10px" }}>
                <Box
                  sx={{
                    padding: "24px 16px 24px 16px",
                    borderRadius: "8px",
                    border: "1px solid #E5E7EB",
                  }}
                >
                  <SecondaryTypographyDefault>
                    {LanguageData?.Question || "Question"}
                  </SecondaryTypographyDefault>
                  <TextField
                    placeholder="Enter a question."
                    InputProps={{ disableUnderline: true }}
                    value={itm?.question}
                    name="question"
                    onChange={(e) => {
                      handleQuestion(e, index);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        color: errText[index]?.question ? "#912018" : "#111927",
                      },
                      backgroundColor: errText[index]?.question
                        ? "#FEE4E2"
                        : "",
                    }}
                  />

                  <SecondaryTypographyDefault mt={3}>
                    {LanguageData?.Answer || "Answer"}
                  </SecondaryTypographyDefault>
                  <TextField
                    placeholder="Enter answer to frequently asked question."
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment sx={endAdornmentStyles}>
                          {itm?.answer.length}/1000
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 1000 }}
                    value={itm?.answer}
                    name="answer"
                    onChange={(e) => {
                      handleQuestion(e, index);
                    }}
                    sx={{
                      minHeight: "88px",
                      "& .MuiInputBase-root": {
                        color: errText[index]?.answer ? "#912018" : "#111927",
                      },
                      backgroundColor: errText[index]?.answer ? "#FEE4E2" : "",
                    }}
                    multiline
                  />
                </Box>
                {Questions.length - 1 == index && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    mt={4}
                    width="100%"
                  >
                    <Button
                      onClick={() => {
                        AddQuestion();
                      }}
                      sx={{
                        padding: "8px 14px 8px 14px",
                        height: "41px",
                        color: "#0E5E84",
                        border: "1px solid #E5E7EB",
                      }}
                    >
                      {LanguageData?.Add_More_FAQs || "Add More FAQs"}{" "}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}

        <Box
          bgcolor="#FFFFFF"
          mt={5}
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => Continue("Review Information")}
                sx={{
                  padding: "8px 14px 8px 14px",
                  height: "41px",
                  color: "#0E5E84",
                  backgroundColor: "#E7EFF3",
                  "&:hover": {
                    backgroundColor: "#CFDFE6",
                  },
                }}
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    const handleEditPost = () => {
      axiosInstance
        .post(`${process.env.REACT_APP_API_URL}faq/property/${propertyId}`, {
          faq: Questions,
        })
        .then((res) => {
          if (res.data?.success) {
            SetPostLoader(false);
            showToast(res?.data?.toast);
            navigate("/documentations");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    return (
      <>
        <Box
          backgroundColor="#FFFFFF"
          sx={{ borderRadius: "8px", border: "1px solid #E5E7EB" }}
        >
          {Questions.map((itm, index) => {
            return (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  px={2}
                  borderBottom="1px solid #E5E7EB"
                  sx={{ borderTop: index !== 0 ? "1px solid #E5E7EB" : "" }}
                  height="53px"
                >
                  <SecondaryTypography>
                    {LanguageData?.Question || "Question"} {index + 1}
                  </SecondaryTypography>
                  <DeleteSBIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDeleteId(index);
                      setdeletemodal(true);
                    }}
                  />
                </Stack>
                <Grid container padding="12px 16px 20px 16px">
                  <Grid xs={12} sm={5.8}>
                    <StyledSecondaryServiceTypography>
                      {LanguageData?.Question || "Question"}
                    </StyledSecondaryServiceTypography>
                    <TypographyDefault>{itm?.question}</TypographyDefault>
                  </Grid>
                  <Grid xs={12} sm={5.8}>
                    <StyledSecondaryServiceTypography>
                      {LanguageData?.Answer || "Answer"}
                    </StyledSecondaryServiceTypography>
                    <TypographyDefault>{itm?.answer}</TypographyDefault>
                  </Grid>
                </Grid>
                {Questions.length - 1 == index && (
                  <Stack
                    direction="row"
                    px={2}
                    justifyContent="space-between"
                    alignItems="center"
                    height="81px"
                    borderTop="1px solid #E5E7EB"
                    borderRadius="0 0 8px 8px "
                  >
                    <Stack direction="row">
                      <RefreshIcon style={{ marginRight: "5px" }} />
                      <StyledSecondaryTypography>
                        {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                        {moment(new Date()).format("HH:mm A")}
                      </StyledSecondaryTypography>
                    </Stack>
                    <Button
                      sx={{
                        padding: "8px 14px 8px 14px",
                        height: "41px",
                        color: "#0E5E84",
                        backgroundColor: "#E7EFF3",
                        "&:hover": {
                          backgroundColor: "#CFDFE6",
                        },
                      }}
                      onClick={() => {
                        handleEditPost();
                        SetPostLoader(true);
                      }}
                    >
                      {LanguageData?.Common_Confirm || "Confirm"}
                    </Button>
                  </Stack>
                )}{" "}
              </>
            );
          })}
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {leavemodal && (
        <AlertModal
          modalDatas={leaveModalDatas}
          confirmFunction={() => navigate("/documentations")}
          closeFunction={closeModal}
        />
      )}
      {deletemodal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteQuestion}
          closeFunction={closeModal}
        />
      )}

      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            style={{ cursor: "pointer" }}
            onClick={() => {
              setLeavemodal(true);
            }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={0.5}>
              {LanguageData?.Frequently_Asked_Questions ||
                "Frequently Asked Questions"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.edit_doc ||
                "Manage, add, edit or delete frequently asked questions. Once updated, this will be visible to all users on the LOBY system."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%" mb={5}>
          {blocks.map((itm) => {
            return (
              <Grid xs={5.91} onClick={() => Continue(itm.tle, "")}>
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Details && Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
}
