import React, { useEffect, useState } from 'react'
import { Dialog, Box, Button, CircularProgress } from '@mui/material';
import { PrimaryTypography } from '../Theme';
import tower from "../Icons/nonetwork.png"

const NetworkModal = () => {

    const [isOnline, setIsOnline] = useState(true)
    const [loading, setLoading] = useState(false)


    function changeStatus() {
        setIsOnline(navigator.onLine);
    }

    function checkOnlineStatus() {
        window.addEventListener('online', () => {
            window.location.reload()
            changeStatus()
            console.log('online');
        });
        window.addEventListener('offline', () => {
            changeStatus()
            console.log('offine');

        })
    };



    useEffect(() => {
        setIsOnline(navigator.onLine);
        checkOnlineStatus()
    }, [])

    const loadingFun = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }



    return (
        <>
            {!isOnline &&
                <Dialog open={true}>
                    <Box sx={{ minWidth: '400px', minHeight: '200px', padding: '10px 30px 30px 35px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ maxWidth: '100px', maxHeight: '100px' }}>
                            <img src={tower} alt="" width='100%' height='100%' />
                        </Box>
                        <PrimaryTypography mt={2} sx={{ fontSize: '20px !important' }}>Check your connection and <Box component="span" sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#7373a8', fontSize: '18px', fontWeight: 400, '&:hover': { color: '#2727ae' } }} onClick={() => { loadingFun() }}>{loading ? <CircularProgress sx={{ color: '#7373a8', ml: 3 }} size={22} /> : 'Try again'}</Box></PrimaryTypography>
                        {/* <Button onClick={() => { checkOnlineStatus() }} sx={{ marginTop: 3 }} variant="contained">Retry</Button> */}
                    </Box>
                </Dialog>
            }
        </>
    )
}

export default NetworkModal
