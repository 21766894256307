import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from '../Icons/BackArrow.svg';
import { ReactComponent as AlertIcon } from "../Icons/ErrorAlert.svg";
import { StyledErrorTypography, StyledPrimaryTypography, StyledSecondaryTypography } from "../Theme";
import { Button, InputAdornment, TextField, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../Redux/Feature/Login/loginSlice";

const ForgotPassword = ({ email, setEmail, setDisplayScreen }) => {
    const dispatch = useDispatch()
    const { loginStatus, verifyEmailStatus } = useSelector((state) => state.Login)
    const [errorText, setErrorText] = useState('')
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [loadingBtn, setLoadingBtn] = useState(false)

    useEffect(() => {
        if (verifyEmailStatus !== null) {
            setLoadingBtn(false)
            if (verifyEmailStatus.success === true) {
                setDisplayScreen('otp')
            } else {
                setErrorText(verifyEmailStatus.error)
            }
        }
    }, [verifyEmailStatus])

    const handleEmail = (e) => {
        let { value } = e.target
        setEmail(value)
        setErrorText('')
    }
    const handleSingUp = () => {
        let isError = validateEmail()
        if (!isError) {
            setLoadingBtn(true)
            dispatch(verifyEmail({ email }))
        }
    }

    const validateEmail = () => {
        let isError = false;
        if (email === '') {
            setErrorText('Error! No input detected.')
            isError = true
        } else if (emailRegex.test(email) === false) {
            setErrorText('Enter valid email.')
            isError = true
        }
        return isError
    }
    return (
        <>
            <BackArrow onClick={() => setDisplayScreen('signup')} />
            <StyledPrimaryTypography sx={{ mt: 2 }}>Recover Password</StyledPrimaryTypography>
            <StyledSecondaryTypography>Please enter your email address below.</StyledSecondaryTypography>
            <TextField value={email} onChange={handleEmail}
                InputProps={{ disableUnderline: true, endAdornment: <InputAdornment position="end">{errorText !== '' && <AlertIcon />}</InputAdornment> }}
                sx={{ mt: 3, mb: 2, "& .MuiInputBase-root": { color: errorText === '' ? '#111927' : '#912018' }, backgroundColor: errorText === '' ? "#F9FAFB" : "#FEE4E2" }}
            />
            {errorText != '' && <StyledErrorTypography>{errorText}</StyledErrorTypography>}
            <Button disabled={loadingBtn} onClick={handleSingUp} fullWidth variant="contained">{loadingBtn ? <CircularProgress size={25} /> : 'Set up now'}</Button>
        </>
    )
}

export default ForgotPassword;