import React, { useEffect, useState } from "react";
import GeneralDetails from "./generalDetails";
import CoverAndLogo from "./coverAndLogo";
import ReviewGeneralDetails from "./reviewGeneralDetailis";
import {
  getGeneralDetailsByProperty,
  setPropertyId,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../../CommonComponents/modal";
import { logoutModalData } from "../../../Data";
import { savePropertyDetailsAsDraftToNull } from "../../../Redux/Feature/Login/loginSlice";

const GeneralDetailsHandler = () => {
  let initialState = {
    generalDetails: false,
    coverAndLogo: false,
    review: false,
  };
  const dispatch = useDispatch();
  let { generalDetailsList, propertyId } = useSelector(
    (state) => state.PropertyDetails
  );
  const { savePropertyDetailsAsDraftStatus, loginStatus } = useSelector(
    (state) => state.Login
  );
  const [displayScreen, setDisplayScreen] = useState({
    generalDetails: true,
    coverAndLogo: false,
    review: false,
  });
  const [generalDetails, setGeneralDetails] = useState({
    title: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    email: "",
    contact: "",
    phone: "",
    country_code: "",
    details: "",
  });
  const [image, setImage] = useState({ cover: "", logo: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(getGeneralDetailsByProperty(propertyId));
  }, [propertyId]);


  useEffect(() => {
    if (loginStatus != null) {
      if (loginStatus.success) {
        if (
          loginStatus.data.user_details.user_role == 2 &&
          loginStatus.data.property_status.status === "draft"
        ) {
          localStorage.setItem(
            "accessToken",
            loginStatus.data.token.access_token
          );
          dispatch(setPropertyId(loginStatus.data.property_status.id));
          setGeneralDetails({
            ...generalDetails,
            email: loginStatus.data.property_status.email,
            title: loginStatus.data.property_status.title,
            address: loginStatus.data.property_status.address,
          });
        }
      }
    }
  });

  useEffect(() => {
    if (generalDetailsList.success) {
      setLoader(false);
      let data = generalDetailsList.data.property;
      setGeneralDetails({
        ...generalDetails,
        title: data.title,
        address: data.address,
        country: data.country,
        city: data.city,
        zipcode: data.zipcode,
        email: data.email,
        contact: data.country_code + data.phone,
        phone: data.phone,
        country_code: data.country_code,
        details: data.description,
        updateDate: data.updated_at,
      });
      setImage({
        ...image,
        cover: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.cover_image}`,
        },
        logo: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.property_logo}`,
        },
        updateDate: data.updated_at,
      });
    } else {
      setLoader(true);
    }
  }, [generalDetailsList]);

  const handleDisplayScreen = (value) => {
    setDisplayScreen({
      ...initialState,
      [value]: true,
    });
  };
  return (
    <>
      {savePropertyDetailsAsDraftStatus && (
        <AlertModal
          closeFunction={() => dispatch(savePropertyDetailsAsDraftToNull())}
          modalDatas={logoutModalData}
        />
      )}
      {displayScreen.generalDetails && (
        <GeneralDetails
          generalDetails={generalDetails}
          setGeneralDetails={setGeneralDetails}
          setDisplayScreen={handleDisplayScreen}
          isLoading={loader}
        />
      )}
      {displayScreen.coverAndLogo && (
        <CoverAndLogo
          image={image}
          setImage={setImage}
          setDisplayScreen={handleDisplayScreen}
        />
      )}
      {displayScreen.review && (
        <ReviewGeneralDetails
          generalDetails={generalDetails}
          image={image}
          setDisplayScreen={handleDisplayScreen}
        />
      )}
    </>
  );
};

export default GeneralDetailsHandler;
