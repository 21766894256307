import React, { lazy, useEffect, useState } from "react";
import {
  StyledPrimaryTypography,
  TypographyDefault,
  StyledSecondaryServiceTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
} from "../../Theme";
import { Box, Grid, Stack, Skeleton } from "@mui/material";
import { getChip } from "../../Utils";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as JPGIcon } from "../../Icons/Jpg.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import ParcelStepper from "./ParcelStepper";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import { showToast } from "../../CommonComponents/Toaster";

const ParcelReview = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const { id } = useParams();
  const [parcelData, SetParcelData] = useState([]);
  const [reportTrack, setReportTrack] = useState({});
  const [StatusData, setStatusData] = useState([]);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageloading, setpageloading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/parcel_collection/${id}`
      )
      .then((res) => {
        SetParcelData(res.data.data?.parcel_collection);
        setStatusData(res.data.data?.parcel_collection?.report_track);
        let data = res.data.data?.parcel_collection.report_track.find(
          (value) => value.status_details.id === 1
        );
        if (data) {
          setReportTrack(data);
        }
        setpageloading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleDownloadImage = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}download_file`,
        {
          file_path: parcelData?.images?.path,
        },
        {
          responseType: "blob",
        }
      );

      saveAs(response.data, parcelData.images.name);
    } catch (error) {
      showToast("Download Failed", "error");
      console.error("Error downloading image:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BackArrow
        onClick={() => navigate("/parcel-collection")}
        style={{ cursor: "pointer" }}
      />
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
        {pageloading ? (
          <Skeleton width={200} height={30} />
        ) : (
          <>
            <StyledPrimaryTypography style={{ fontSize: "18px" }}>
              {LanguageData?.Parcel || "Parcel"} {parcelData?.uuid}
            </StyledPrimaryTypography>
            <TypographyDefault style={{ marginLeft: "10px" }}>
              {" "}
              {getChip(parcelData.status?.name)}
            </TypographyDefault>
          </>
        )}
      </Box>
      <StyledSecondaryServiceTypography>
        {pageloading ? (
          <Skeleton width={200} />
        ) : (
          " Last Updated:" +
          moment
            .utc(parcelData?.updated_at)
            .local()
            .format("DD MMM YYYY, HH:mm")
        )}
      </StyledSecondaryServiceTypography>

      <Grid container style={{ marginTop: "30px" }}>
        {pageloading ? (
          <Skeleton variant="rounded" height={400} width="65.5%" />
        ) : (
          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "8px",
              border: "1px solid #E5E7EB",
            }}
          >
            {parcelData.status?.id === 1 && (
              <>
                <Box
                  sx={{
                    padding: "16px",
                    borderRadius: "8px 8px 0 0",
                    border: "1px solid",
                    borderColor: "#E5E7EB",
                    backgroundColor: "#FCFCFD",
                  }}
                >
                  <SecondaryTypography>
                    {LanguageData?.Collecter_Details || "Collector Details"}{" "}
                  </SecondaryTypography>
                </Box>
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Collected_By || "Collected By"}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {reportTrack.personal_details?.full_name}
                    </TypographyDefault>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {" "}
                      {LanguageData?.Unit || "Unit"}{" "}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {parcelData.unitDetails?.unit_no}
                    </TypographyDefault>
                  </Grid>
                </Grid>
                <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                  <Grid sx={{ p: 2 }} container rowSpacing={3}>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Mobile || "Mobile"}
                      </StyledSecondaryTypography>

                      <TypographyDefault>
                        {reportTrack.personal_details?.country_code +
                          reportTrack.personal_details?.phone}
                      </TypographyDefault>
                    </Grid>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Email_Address || "Email Address"}
                      </StyledSecondaryTypography>

                      <TypographyDefault>
                        {reportTrack.user_details?.email}
                      </TypographyDefault>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "12px 16px 20px 16px",
                    borderTop: "1px solid #F3F4F6",
                  }}
                >
                  <StyledSecondaryTypography>
                    {LanguageData?.Collected_On || "Collected On"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {moment
                      .utc(parcelData?.collectedBy?.collected_date)
                      .local()
                      .format("DD MMM YYYY • HH:mm")}
                  </TypographyDefault>
                </Grid>
              </>
            )}
            <>
              <Box
                sx={{
                  padding: "16px",
                  borderRadius:
                    parcelData.status?.id === 1 ? "initial" : "8px 8px 0 0",
                  border: "1px solid",
                  borderColor: "#E5E7EB",
                  backgroundColor: "#FCFCFD",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Parcel_Details || "Parcel Details"}{" "}
                </SecondaryTypography>
              </Box>
              <Grid sx={{ p: 2 }} container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Parcel_Owner || "Parcel Owner"}
                  </StyledSecondaryTypography>

                  <TypographyDefault>
                    {parcelData.parcelOwner?.name || "--"}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {" "}
                    {LanguageData?.Unit || "Unit"}{" "}
                  </StyledSecondaryTypography>

                  <TypographyDefault>
                    {parcelData.unitDetails?.unit_no || "--"}
                  </TypographyDefault>
                </Grid>
              </Grid>
              <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Parcel_Number || "Parcel Number"}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {parcelData?.uuid || "--"}
                    </TypographyDefault>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Delivery_Service || "Delivery Service"}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {parcelData?.delivery_service || "--"}
                    </TypographyDefault>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Added_to_System || "Added to System"}{" "}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {" "}
                      {parcelData?.created_at
                        ? moment
                            .utc(parcelData?.created_at)
                            .local()
                            .format("DD MMM YYYY • HH:mm")
                        : "--"}
                    </TypographyDefault>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Received_by || "Received by"}
                    </StyledSecondaryTypography>

                    <TypographyDefault>
                      {parcelData?.receivedBy?.name} •{" "}
                      {parcelData.roleDetails?.title || "--"}{" "}
                    </TypographyDefault>
                  </Grid>
                </Grid>
              </Box>
            </>
            <Box
              sx={{
                padding: "16px",
                border: "1px solid",
                borderColor: "#E5E7EB",
                backgroundColor: "#FCFCFD",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Parcel_Image || "Parcel Image"}
              </SecondaryTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #EAECF0",
                borderRadius: "6px",
                p: 2,
                m: 1.5,
              }}
            >
              <Stack direction="row">
                <JPGIcon style={{ marginRight: "12px" }} />

                <Stack direction="column">
                  <StyledSecondaryTypography>
                    {parcelData.images?.name}
                  </StyledSecondaryTypography>
                  <StyledSecondaryTypography>
                    {`${
                      parcelData.images?.file_size
                        ? (parcelData.images.file_size / (1024 * 1024)).toFixed(
                            2
                          )
                        : "0.00"
                    }MB`}
                  </StyledSecondaryTypography>
                </Stack>
              </Stack>
              {loading ? (
                <Box sx={{ width: "40px" }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <DownloadIcon
                  onClick={handleDownloadImage}
                  cursor={"pointer"}
                  width={40}
                />
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={0.5} />
        {pageloading ? (
          <Skeleton variant="rounded" height={400} width="30%" />
        ) : (
          <Grid item xs={3.5}>
            <ParcelStepper parcelStatus={StatusData} fullData={parcelData} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ParcelReview;
