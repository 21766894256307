import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as EditIcon } from "../../../../Icons/Edit.svg";
import { ReactComponent as LobbyText } from "../../../../Icons/Lobbytext.svg";
import { ReactComponent as CameraIcon } from "../../../../Icons/Camera.svg";
import { ReactComponent as DeleteIcon } from "../../../../Icons/DeleteRedIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../Icons/DownloadIcon.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledErrorTypography,
  StyledSecondaryTypography,
  TypographyDefault,
  StyledTypography,
} from "../../../../Theme";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Divider,
  Menu,
  TextField,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDetails,
  getGeneralDetailsByProperty,
  setTabValue,
  editGeneralDetails,
  updatePropertyDetailsCompletedSteps,
  setPropertyId,
  setActiveStep,
  setPropertySliceToNull,
  SetgeneralDetailsList,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import axiosInstance from "../../../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../../../CommonComponents/modal";
import { showToast } from "../../../../CommonComponents/Toaster";

const EditGeneralDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coverRef = useRef(null);
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];

  let {
    addGeneralDetailsStatus,
    TabValue,
    generalDetailsList,
    editGeneralDetailsStatus,
    propertyId,
    propertyCompletedStep,
  } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [generalDetails, setGeneralDetails] = useState({
    title: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    email: "",
    contact: "",
    phone: "",
    country_code: "",
    details: "",
    updated: null,
  });
  const [image, setImage] = useState({ cover: "", logo: "" });

  const [errorText, setErrorText] = useState({
    title: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    email: "",
    contact: "",
    phone: "",
    country_code: "",
    details: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [type, setType] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    if (generalDetailsList?.length == 0) {
      dispatch(getGeneralDetailsByProperty(propertyId));
    }
  }, [propertyId]);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      if (selectedCountryObject?.city) {
        setCityList(selectedCountryObject?.city);
      }
    }
  }, [selectedCountry, CountryList]);

  useEffect(() => {
    if (generalDetailsList.success) {
      let data = generalDetailsList.data.property;
      setGeneralDetails({
        ...generalDetails,
        title: data.title,
        address: data.address,
        country: data.country,
        city: data.city,
        zipcode: data.zipcode,
        email: data.email,
        contact: data.country_code + data.phone,
        phone: data.phone,
        country_code: data.country_code,
        details: data.description,
        updated: data.updated_at,
      });
      setSelectedCountry(data.country);
      setImage({
        ...image,
        cover: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.cover_image}`,
        },
        logo: {
          src: `${process.env.REACT_APP_FILE_PATH}${data.property_logo}`,
        },
      });
    }
  }, [generalDetailsList]);

  useEffect(() => {
    if (editGeneralDetailsStatus != null)
      if (editGeneralDetailsStatus.success) {
        // showToast(editGeneralDetailsStatus.toast);
        SetPostLoader(false);
        dispatch(SetgeneralDetailsList(editGeneralDetailsStatus));
        navigate("/settings");
        dispatch(setTabValue(0));
      }
  }, [editGeneralDetailsStatus]);

  const handleDetails = (e) => {
    let { name, value } = e.target;
    if (name === "country" && value) {
      setCityList([]);
      const selectedCountry = value;
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      setCityList(selectedCountryObject?.city);
      setGeneralDetails((prevData) => ({
        ...prevData,
        city: "",
      }));
    }
    setGeneralDetails({
      ...generalDetails,
      [name]: value,
    });
    setErrorText({
      ...errorText,
      [name]: "",
    });
  };

  const handleMobileInput = (value, country) => {
    setGeneralDetails({
      ...generalDetails,
      contact: value,
      country_code: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    setErrorText({
      ...errorText,
      phone: "",
    });
  };

  //image

  const handleCoverPhoto = (e) => {
    setImage({
      ...image,
      cover: {
        ...e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      },
    });
    setErrorText({ cover: "", logo: "" });
    setAnchorEl(null);
  };

  const handleLogoUpload = (e) => {
    setImage({
      ...image,
      logo: {
        ...e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      },
    });
    setErrorText({ cover: "", logo: "" });
  };

  const handleRemoveImage = () => {
    if (type === "cover") {
      setImage({
        ...image,
        cover: "",
      });
    } else if (type === "logo") {
      setImage({
        ...image,
        logo: "",
      });
    }

    setAnchorEl(null);
  };

  const validate = () => {
    let isError = [];
    for (let key in generalDetails) {
      if (key === "email" && generalDetails[key] != "") {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(generalDetails[key]) === false) {
          setErrorText((prevState) => {
            return {
              ...prevState,
              [key]: "Enter valid email",
            };
          });
          isError.push(true);
        }
      } else if (key === "phone" && generalDetails[key].length < 6) {
        setErrorText((prevState) => {
          return {
            ...prevState,
            [key]: "Error! The phone must be at least 6 characters.",
          };
        });
        isError.push(true);
      } else if (key === "zipcode" && generalDetails[key].length < 5) {
        setErrorText((prevState) => {
          return {
            ...prevState,
            [key]: "Error! Postal Code must be above 5 numbers",
          };
        });
        isError.push(true);
      } else {
        if (generalDetails[key] === "") {
          setErrorText((prevState) => {
            return {
              ...prevState,
              [key]: "Error! No Inputs detected",
            };
          });
          isError.push(true);
        }
      }
    }

    for (let key in image) {
      if (image[key] === "") {
        setErrorText((prev) => {
          return {
            ...prev,
            [key]: `Add ${key} Image`,
          };
        });
        isError.push(true);
      }
    }
    return isError.includes(true);
  };

  const Continue = (nxtval) => {
    let isError = validate();
    if (!isError) {
      if (nxtval == "Review Information") {
        setaddblocks({ Details: false, Review_Information: true });
      } else if (nxtval == "Details") {
        setaddblocks({ Details: true, Review_Information: false });
      }
    }
  };

  const handleContinue = () => {
    let data = new FormData();
    data.append("title", generalDetails.title);
    data.append("email", generalDetails.email);
    data.append("phone", generalDetails.phone);
    data.append("country_code", generalDetails.country_code);
    data.append("address", generalDetails.address);
    data.append("country", generalDetails.country);
    data.append("city", generalDetails.city);
    data.append("zipcode", generalDetails.zipcode);
    data.append("description", generalDetails.details);
    {
      Boolean(image.logo.file) && data.append("property_logo", image.logo.file);
    }
    {
      Boolean(image.cover.file) && data.append("cover_image", image.cover.file);
    }

    SetPostLoader(true);
    dispatch(editGeneralDetails({ datas: data, propertyId }));

    // if(propertyId==null){
    //    dispatch(addGeneralDetails(data))
    // }
    // else{

    // }
  };

  const Details = () => {
    return (
      <>
        <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px" }}>
          <Box
            sx={{
              p: 2,
              mt: 2,
              borderRadius: "8px 8px 0 0 ",
              borderBottom: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>
              {LanguageData?.General_Property_Details ||
                "General Property Details"}{" "}
            </SecondaryTypography>
          </Box>
          <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <StyledTypography>
                {" "}
                {LanguageData?.Property_Title || "Property Title"}{" "}
              </StyledTypography>
              <TextField
                value={generalDetails.title}
                onChange={(e) => handleDetails(e)}
                name="title"
                placeholder="Add Property Title"
                sx={{ height: "46px" }}
                InputProps={{ disableUnderline: true }}
              />
              <ErrorTypography>{errorText.title}</ErrorTypography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <StyledTypography>
                  {LanguageData?.Property_Address || "Property Address"}{" "}
                </StyledTypography>
                <StyledSecondaryTypography>
                  {generalDetails.address.length}/1000
                </StyledSecondaryTypography>
              </Box>
              <TextField
                value={generalDetails.address}
                onChange={(e) => handleDetails(e)}
                inputProps={{ maxLength: 1000 }}
                name="address"
                placeholder="Enter The Full Property Address Here."
                sx={{ height: "92px" }}
                InputProps={{ disableUnderline: true }}
              />
              <ErrorTypography>{errorText.address}</ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTypography>
                {LanguageData?.Country || "Country"}
              </StyledTypography>
              <Select
                value={generalDetails.country}
                onChange={(e) => handleDetails(e)}
                name="country"
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>{errorText.country}</ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTypography>
                {" "}
                {LanguageData?.City || "City"}
              </StyledTypography>
              <Select
                value={generalDetails.city}
                onChange={(e) => handleDetails(e)}
                name="city"
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>{errorText.city}</ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTypography>
                {LanguageData?.Postal_Code || "Postal Code"}{" "}
              </StyledTypography>
              <TextField
                type="number"
                value={generalDetails.zipcode}
                onChange={(e) => handleDetails(e)}
                name="zipcode"
                placeholder="Postal Code"
                sx={{ height: "46px" }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 6 }}
              />
              <ErrorTypography>{errorText.zipcode}</ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>
                {LanguageData?.Corporate_Email_Address ||
                  "Corporate Email Address"}{" "}
              </StyledTypography>
              <TextField
                value={generalDetails.email}
                onChange={(e) => handleDetails(e)}
                name="email"
                placeholder="Enter The Main Contact Email Address For The Condo."
                sx={{ height: "46px" }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
              />
              <ErrorTypography>{errorText.email}</ErrorTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography sx={{ mb: 1 }}>
                {LanguageData?.Main_Contact_Number || "Main Contact Number"}
              </StyledTypography>
              <PhoneInput
                value={generalDetails.contact}
                onChange={(value, country, e, formatValue) =>
                  handleMobileInput(value, country)
                }
                inputProps={{ name: "contact" }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: "#F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                }}
                countryCodeEditable={false}
                placeholder="Add Contact Number"
              />
              <ErrorTypography>{errorText.phone}</ErrorTypography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <StyledTypography>
                  {" "}
                  {LanguageData?.Details_property ||
                    "Details of the property"}{" "}
                </StyledTypography>
                <StyledSecondaryTypography>
                  {generalDetails.details.length}/1000
                </StyledSecondaryTypography>
              </Box>
              <TextField
                value={generalDetails.details}
                onChange={(e) => handleDetails(e)}
                name="details"
                inputProps={{ maxLength: 1000 }}
                placeholder="Enter The Description Of The Property Here."
                sx={{ height: "92px" }}
                InputProps={{ disableUnderline: true }}
              />
              <ErrorTypography>{errorText.details}</ErrorTypography>
            </Grid>
          </Grid>
          <Box
            sx={{
              p: 2,
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>
              {LanguageData?.Property_Logo_Image ||
                "Property Logo & Cover Image"}
            </SecondaryTypography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                background:
                  image.cover === "" ? "#F3F4F6" : `url(${image.cover.src})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Box sx={{ position: "absolute", right: 20, top: 20 }}>
                {image.cover === "" && (
                  <Button
                    component="label"
                    startIcon={<CameraIcon />}
                    sx={{
                      backgroundColor: "#E5E7EB",
                      "&:hover": { backgroundColor: "#E5E7EB" },
                      color: "#111927",
                    }}
                    variant="contained"
                  >
                    {LanguageData?.Add_cover_photo || "Add cover photo"}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleCoverPhoto(e)}
                      style={{ display: "none" }}
                    />
                  </Button>
                )}
                {image.cover != "" && (
                  <Button
                    onClick={() => coverRef.current.click()}
                    startIcon={<CameraIcon />}
                    sx={{
                      backgroundColor: "#E5E7EB",
                      "&:hover": { backgroundColor: "#E5E7EB" },
                      color: "#111927",
                    }}
                    variant="contained"
                  >
                    {LanguageData?.Edit_cover_photo || "Edit cover photo"}
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  width: "380px",
                  borderRadius: "8px",
                  p: 4,
                  my: 15,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    height: "85px",
                    borderRadius: "8px ",
                    width: "85px",
                    padding: "10px ",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage:
                      image.logo === "" ? "" : `url(${image.logo.src})`,
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                  }}
                >
                  {/* {image.logo === '' &&
                                        <LobbyText style={{ marginLeft: '-16px' }} />
                                    } */}
                  <Box
                    component="label"
                    sx={{
                      position: "absolute",
                      bottom: "-10px",
                      right: "-10px",
                      backgroundColor: "#E5E7EB",
                      padding: "7px 7px 4px 7px",
                      borderRadius: "100px",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleLogoUpload}
                      style={{ display: "none" }}
                    />
                    {/* <IconButton sx={{ backgroundColor: '#E5E7EB' }}> */}
                    <CameraIcon />
                    {/* </IconButton> */}
                  </Box>
                </Box>
                <PrimaryTypography sx={{ mt: 3 }}>
                  {LanguageData?.Log_account || "Log in to your account"}
                </PrimaryTypography>
                <StyledSecondaryTypography sx={{ mt: 1 }}>
                  {LanguageData?.Log_account_des ||
                    "The LOBY portal is for Juristics & Condo Admin access only."}
                </StyledSecondaryTypography>
                <TextField
                  disabled
                  sx={{ my: 2 }}
                  placeholder="Email Address"
                  InputProps={{ disableUnderline: true }}
                />
                <Button variant="contained" disabled>
                  {LanguageData?.Login || "Login"}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 2,
                  }}
                >
                  <StyledSecondaryTypography textAlign={"center"}>
                    {" "}
                    {LanguageData?.Remember || "Remember for 30 days"}
                  </StyledSecondaryTypography>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.forget || "Forgot password?"}{" "}
                  </StyledTypography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <ErrorTypography textAlign={"center"}>
              {errorText.cover}
            </ErrorTypography>
            <ErrorTypography textAlign={"center"}>
              {errorText.logo}
            </ErrorTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              mb: 5,
              borderTop: "1px solid #F3F4F6",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(generalDetails.updated).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Box>
            <Button
              onClick={() => Continue("Review Information")}
              sx={{
                backgroundColor: "#E7EFF3",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
            >
              {LanguageData?.Common_Continue || "Continue"}
            </Button>
          </Box>
        </Box>
        <input
          type="file"
          onChange={handleCoverPhoto}
          ref={coverRef}
          accept="image/*"
          hidden
        />
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "0 0 8px 8px" }}>
          <Box
            sx={{
              p: 2,
              borderRadius: "8px 8px 0 0 ",
              borderBottom: "1px solid #E5E7EB",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SecondaryTypography>
              {" "}
              {LanguageData?.General_Property_Details ||
                "General Property Details"}{" "}
            </SecondaryTypography>
          </Box>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Property_Title || "Property Title"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.title}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Property_Address || "Property Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.address}</TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Country || "Country"}{" "}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.country}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.City || "City"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.city}</TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Postal_Code || "Postal Code"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.zipcode}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Corporate_Email_Address ||
                  "Corporate Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.email}</TypographyDefault>
            </Grid>
            <Grid item xs={12} sm={6} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Main_Contact_Number || "Main Contact Number"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {generalDetails?.country_code} {generalDetails?.phone}
              </TypographyDefault>
            </Grid>
            <Divider />
            <Grid item xs={12} my={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Details_property || "Details of the property"}
              </StyledSecondaryTypography>
              <TypographyDefault>{generalDetails?.details}</TypographyDefault>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 2,
              p: 2,
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SecondaryTypography>
              {LanguageData?.Property_Logo_Image ||
                "Property Logo & Cover Image"}
            </SecondaryTypography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${image.cover.src})`,
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  width: "380px",
                  borderRadius: "8px",
                  p: 4,
                  my: 15,
                }}
              >
                <Box
                  sx={{
                    height: "85px",
                    borderRadius: "8px ",
                    width: "85px",
                    padding: "10px ",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                    backgroundImage: `url(${image.logo.src})`,
                  }}
                ></Box>
                <PrimaryTypography sx={{ mt: 2 }}>
                  {LanguageData?.Log_account || "Log in to your account"}
                </PrimaryTypography>
                <StyledSecondaryTypography sx={{ mt: 1 }}>
                  {LanguageData?.Log_account_des ||
                    "The LOBY portal is for Juristics & Condo Admin access only."}
                </StyledSecondaryTypography>
                <TextField
                  disabled
                  sx={{ my: 2 }}
                  placeholder="Email Address"
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 125 }}
                />
                <Button variant="contained" disabled>
                  {LanguageData?.Login || "Login"}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 2,
                  }}
                >
                  <StyledSecondaryTypography textAlign={"center"}>
                    {" "}
                    {LanguageData?.Remember || "Remember for 30 days"}
                  </StyledSecondaryTypography>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.forget || "Forgot password?"}{" "}
                  </StyledTypography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              mb: 5,
              borderTop: "1px solid #F3F4F6",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(generalDetails.updated).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Box>
            <Button
              onClick={() => handleContinue()}
              sx={{
                backgroundColor: "#E7EFF3",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
            >
              {LanguageData?.Common_Continue || "Continue"}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/settings");
            }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={0.5}>
              {LanguageData?.General || "General"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.general ||
                "Edit the general details of the condo like condo name, address and description."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%" mb={5}>
          {blocks.map((itm) => {
            return (
              <Grid xs={5.91} onClick={() => Continue(itm.tle, "")}>
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Details && Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
      {/* <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem>
          <UploadIcon />
          <TypographyDefault component="label" sx={{ ml: 1 }}>
            {LanguageData?.Upload_Photo || "Upload Photo"}
            <input
              type="file"
              onChange={type === "cover" ? handleCoverPhoto : handleLogoUpload}
              // style={{ display: "none" }}
            ></input>
          </TypographyDefault>
        </MenuItem>
        <MenuItem onClick={handleRemoveImage}>
          <DeleteIcon />
          <Typography sx={{ color: "#B42318", fontSize: "12px" }}>
            {LanguageData?.Remove || "Remove"}
          </Typography>
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default EditGeneralDetails;
