import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypographyDefault,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../Theme";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  TextField,
  Checkbox,
  Menu,
  MenuItem,
  Modal,
  Grid,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { ReactComponent as SelectTimeIcon } from "../Icons/SelectTimeIcon.svg";
import { ReactComponent as DatePickerIcon } from "../Icons/Datepicker.svg";
import axiosInstance from "../Redux/AxiosInstance";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .makeStyles-root-61": {
      backgroundColor: "grey",
    },
    position: "relative",
    backgroundColor: "white ",
    "& .MuiDateRangeCalendar-root ": { color: "red !important" },
    // "& .css-968uz8-MuiStack-root-MuiMultiInputDateRangeField-root": {
    //   border: "2px solid red"
    // },
    "&.MuiDateRangeCalendar-root > div ": {
      height: "640px !important",
    },
    "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
      color: "white !important",
      zIndex: "-100000 !important",
    },
    // "& .css-jef1b6-MuiDateRangeCalendar-container": { color: "red !important" },
  },
}));

export default function CustomDateTimeRange({
  datetimerangedata,
  setdatetimerangedata,
  time,
  setTime,
  errDateRange,
  seterrDateRange,
  isStatus,
  setIsStatus,
}) {
  const classes = useStyles();
  const { LanguageData } = useSelector((state) => state.Language);

  const [TimeSlotAnchorEl, setTimeSlotAnchorEl] = useState(null);
  const timeSlotMenuOpen = Boolean(TimeSlotAnchorEl);

  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [timeSlot, setTimeSlot] = useState([
    "06:00",
    "06:30",
    "07:00",
    "07:30",
  ]);
  const [err, seterr] = useState({ date: "", start: "", end: "" });
  const [anchorstartTime, setanchorstartTime] = useState(false);

  useEffect(() => {
    const getTimeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=slot_time`
        );
        const TimeData = response.data.data.drop_down;
        setTimeSlot(TimeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getTimeData();
  }, []);

  const handledateTimeRange = (e, key) => {
    if (key == "date") {
      setdatetimerangedata(e);
      seterr((prev) => ({ ...prev, date: false }));
    } else {
      setTime((prev) => ({ ...prev, [key]: e }));
      seterr((prev) => ({ ...prev, [key]: false }));
      setTimeSlotAnchorEl(null);
    }
  };

  const gettime = (id) => {
    const data = timeSlot.find((itm) => itm == id);
    return data?.time;
  };

  const changeScheduleStatusvalid = () => {
    let isValid = false;

    if (time?.start == "") {
      seterr((prev) => ({ ...prev, start: true }));
      isValid = true;
    }
    if (time?.end == "") {
      seterr((prev) => ({ ...prev, end: true }));
      isValid = true;
    }
    if (datetimerangedata[0] == null) {
      seterr((prev) => ({ ...prev, date: true }));
      isValid = true;
    }
    return isValid;
  };

  const applyDateRange = () => {
    if (!changeScheduleStatusvalid()) {
      setIsStatus(true);
      setopenDateRangePicker(false);
      seterrDateRange((prev) => ({ ...prev, duration: false }));
    }
  };
  const canelDateRange = () => {
    setTime({ start: "", end: "" });
    seterr({ date: "", start: "", end: "" });
    setdatetimerangedata([null, null]);
    setopenDateRangePicker(false);
    setTimeSlotAnchorEl(null);
    setIsStatus(false);
  };

  return (
    <>
      <Stack
        onClick={() => setopenDateRangePicker(true)}
        sx={{
          height: "47px",
          padding: "2px 12px 2px 12px",
          borderRadius: "8px",
          backgroundColor: errDateRange ? "#FEE4E2" : "#F9FAFB",
        }}
        direction="row"
        height="40px"
        justifyContent="space-between"
        alignItems="center"
      >
        {isStatus ? (
          <StyledSecondaryDefaultTypography>
            {moment(datetimerangedata[0]?.$d).format("D MMM YYYY")},{" "}
            {time?.start} -{" "}
            {moment(datetimerangedata[1]?.$d).format("D MMM YYYY")}, {time?.end}{" "}
          </StyledSecondaryDefaultTypography>
        ) : (
          <StyledSecondaryDefaultTypography
            sx={{
              color: errDateRange ? "#912018 !important" : "#9DA4AE !important",
            }}
          >
            {LanguageData?.Date_Time_Range_placeholder ||
              "Select date & time range"}
          </StyledSecondaryDefaultTypography>
        )}
        <DatePickerIcon stroke={errDateRange ? "#912018" : ""} />
      </Stack>

      <Box
        sx={{ backgroundColor: "#F9FAFB", position: "absolute", top: "10px" }}
      >
        <DateRangePicker
          classes={useStyles()}
          sx={{
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              display: "none !important",
            },
            "& .css-8wfwvd-MuiTypography-root-MuiMultiInputDateRangeField-separator":
              {
                display: "none",
              },
            "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
              width: "fit-Content",
            },
          }}
          value={datetimerangedata}
          localeText={{ start: "", end: "" }}
          open={openDateRangePicker}
          onChange={(e) => handledateTimeRange(e, "date")}
          disablePast
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: "1301",
            // left: "5%",
            transform: "translate3d(5%, 10px, 0px)",
            top: "400px",
            // width: '600px',
            width: "117%",
            backgroundColor: "white",
            // height: "234px",
            display: openDateRangePicker ? "" : "none",
            transition: "opacity 2s ease-in",
            transition: "opacity 2s ease-out",
            opacity: openDateRangePicker ? 1 : 0,
            webkitanimation: "fadeIn 2.5s",
            animation: "fadeIn 2.5s",
            // display: openDateRangePicker ? '' : 'none'
          }}
        >
          <Grid container gap={3}>
            <Grid xs={5.5}>
              <Stack direction="column">
                <SecondaryTypographyDefault>
                  {LanguageData?.Start_Date || "Start Date"}
                </SecondaryTypographyDefault>
                <TextField
                  placeholder={
                    LanguageData?.Start_date_Placeholder || "Select start date"
                  }
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: err?.date ? "#912018" : "#111927",
                    },
                    backgroundColor: err?.date ? "#FEE4E2" : " #F9FAFB",
                  }}
                  value={
                    Boolean(datetimerangedata[0])
                      ? moment(datetimerangedata[0]?.$d).format("D MMM YYYY")
                      : ""
                  }
                  fullWidth
                />
                <SecondaryTypographyDefault mt={2}>
                  {LanguageData?.End_date || "End Date"}
                </SecondaryTypographyDefault>
                <TextField
                  placeholder={
                    LanguageData?.End_date_Placeholder || "Select end date"
                  }
                  value={
                    Boolean(datetimerangedata[1])
                      ? moment(datetimerangedata[1]?.$d).format("D MMM YYYY")
                      : ""
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      color: err?.date ? "#912018" : "#111927",
                    },
                    backgroundColor: err?.date ? "#FEE4E2" : " #F9FAFB",
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                />
              </Stack>
            </Grid>

            <Grid xs={5.5} sx={{ visibility: anchorstartTime ? "hidden" : "" }}>
              <Stack direction="column">
                <SecondaryTypographyDefault>
                  {LanguageData?.Start_Time || "Start Time"}
                </SecondaryTypographyDefault>
                <Stack
                  mt={1}
                  mb={2}
                  onClick={(e) => setTimeSlotAnchorEl("start")}
                  sx={{
                    backgroundColor: err?.start ? "#FEE4E2" : " #F9FAFB",
                    padding: "0 10px 0 10px ",
                    height: "45px",
                    minWidth: "140px",
                    borderRadius: "8px",
                  }}
                  direction="row"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {Boolean(time?.start) ? (
                    <StyledSecondaryDefaultTypography>
                      {" "}
                      {time?.start}
                    </StyledSecondaryDefaultTypography>
                  ) : (
                    <StyledSecondaryDefaultTypography
                      sx={{
                        color: Boolean(time?.start)
                          ? ""
                          : err?.start
                          ? "#912018 !important"
                          : "#9DA4AE !important",
                      }}
                    >
                      {LanguageData?.Select_start_time || " Select start time"}
                    </StyledSecondaryDefaultTypography>
                  )}
                  <SelectTimeIcon />
                </Stack>
                <SecondaryTypographyDefault>
                  {LanguageData?.End_time || "End Time"}
                </SecondaryTypographyDefault>
                <Stack
                  mt={1}
                  mb={2}
                  onClick={(e) => setTimeSlotAnchorEl("end")}
                  sx={{
                    backgroundColor: err?.end ? "#FEE4E2" : " #F9FAFB",
                    padding: "0 10px 0 10px ",
                    height: "45px",
                    minWidth: "140px",
                    borderRadius: "8px",
                  }}
                  direction="row"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {Boolean(time?.end) ? (
                    <StyledSecondaryDefaultTypography>
                      {" "}
                      {time?.end}
                    </StyledSecondaryDefaultTypography>
                  ) : (
                    <StyledSecondaryDefaultTypography
                      sx={{
                        color: Boolean(time?.end)
                          ? ""
                          : err?.end
                          ? "#912018 !important"
                          : "#9DA4AE !important",
                      }}
                    >
                      {LanguageData?.Select_end_time || "Select end time"}
                    </StyledSecondaryDefaultTypography>
                  )}
                  <SelectTimeIcon />
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="end">
                <Stack direction="row" mt={5} columnGap={2}>
                  <Button onClick={() => canelDateRange()} variant="outlined">
                    {LanguageData?.Cancel || "Cancel"}
                  </Button>
                  <Button onClick={() => applyDateRange()} variant="contained">
                    {LanguageData?.Common_Apply || "Apply"}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Menu
        open={timeSlotMenuOpen}
        sx={{ position: "absolute", transform: "translate3d(65%, -2%, 0px)" }}
        onClose={() => setTimeSlotAnchorEl(null)}
      >
        {timeSlot?.map((itm) => (
          <MenuItem>
            <Typography
              onClick={(e) => handledateTimeRange(itm?.time, TimeSlotAnchorEl)}
            >
              {itm?.time}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
