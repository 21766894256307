import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
  ResidentData: {},
  VisitorData: {},
  StaffData: {},
  FaultReportData: {},
  ServiceRequestData: {},
  ParcelCollectionData: {},
  FacilityData: {},
  OverviewStatus: null,
};

export const getOverviewDatas = createAsyncThunk(
  "getOverviewDatas",
  async (data, { rejectWithValue }) => {
    const { propertyId, yearSorting } = data;
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard_overview?year=${yearSorting}`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const OverViewDetailsReducer = createSlice({
  name: "OverViewDetails",
  initialState,
  reducers: {
    setOverViewDetailSliceToNull: (state) => {
      state.ResidentData = {};
      state.VisitorData = {};
      state.StaffData = {};
      state.ServiceRequestData = {};
      state.FaultReportData = {};
      state.ParcelCollectionData = {};
      state.FacilityData = {};
      state.OverviewStatus = false;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getOverviewDatas.pending, (state) => {
      state.OverviewStatus = false;
    });
    builders.addCase(getOverviewDatas.fulfilled, (state, action) => {
      state.ResidentData = action.payload.data.data.overview.resident;
      state.VisitorData = action.payload.data.data.overview.visitor;
      state.StaffData = action.payload.data.data.overview.staff;
      state.ServiceRequestData =
        action.payload.data.data.overview.service_request;
      state.FaultReportData = action.payload.data.data.overview.fault_report;
      state.ParcelCollectionData =
        action.payload.data.data.overview.parcel_collection;
      state.FacilityData = action.payload.data.data.overview.facility_booking;
      state.OverviewStatus = false;
    });
    builders.addCase(getOverviewDatas.rejected, (state, action) => {
      state.ResidentData = {};
      state.VisitorData = {};
      state.StaffData = {};
      state.ServiceRequestData = {};
      state.FaultReportData = {};
      state.ParcelCollectionData = {};
      state.FacilityData = {};
      state.OverviewStatus = true;
    });
  },
});

export const { setOverViewDetailSliceToNull } = OverViewDetailsReducer.actions;
export default OverViewDetailsReducer.reducer;
