import {
  Box,
  Button,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StyledPrimaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
} from "../Theme";
import SignUp from "./signup";
import Otp from "./otp";
import SuccessPage from "./successPage";
import ForgotPassword from "./forgotPassword";
import CreatePassword from "./createPassword";
import Login from "./login";
import { getToken } from "firebase/messaging";
import firebase, { auth, messaging } from "../firebase";
import { userData } from "../Utils";
import { useSelector } from "react-redux";
import Toaster from "../CommonComponents/Toaster";

const LandingPage = () => {
  console.log("old");
  const navigate = useNavigate();
  const location = useLocation();
  let initialState = {
    signup: false,
    createPassword: false,
    otp: false,
    recoverPassword: false,
    successPage: false,
    login: false,
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayScreen, setDisplayScreen] = useState(initialState);
  const { userDetails } = useSelector((state) => state.Login);

  const displayScreenHandler = (key) => {
    setDisplayScreen({
      ...initialState,
      [key]: true,
    });
  };

  async function requestPermission() {
    // if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.register("../../public/firebase-messaging-sw");
    // }
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BEcXS_AgaFakKnyow06ZjTswGIwd3YExIYkPBSVH9kjwZ_FSncmeZVEgv_XtlY1s2vHheLW9QC2yk4tSvQ1iGj0",
        });
        if (token) {
          localStorage.setItem("Notify_token", JSON.stringify(token));
        } else {
          console.log("Failed to generate the Notify token.");
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  useEffect(() => {
    localStorage.getItem("accessToken") != null &&
      (userDetails?.user_role == 2 && userData("status") === "draft"
        ? navigate("/propertyDetailsSetup")
        : navigate(
            `${location.pathname == "/" ? "overview" : location.pathname}`
          ));

    requestPermission();
  }, []);

  console.log("kjgfchvbj");

  return (
    <>
      <Toaster />

      {localStorage.getItem("accessToken") == null && (
        <>
          <Box
            sx={{
              height: "92%",
              width: "100%",
              backgroundColor: "#0E4965",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: { xs: "50%", sm: "25%" },
                borderRadius: "8px",
                padding: "40px",
                boxShadow:
                  "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              {displayScreen.signup ? (
                <SignUp
                  email={email}
                  setEmail={setEmail}
                  setDisplayScreen={displayScreenHandler}
                />
              ) : displayScreen.createPassword ? (
                <CreatePassword
                  email={email}
                  setPasswords={setPassword}
                  setDisplayScreen={displayScreenHandler}
                />
              ) : displayScreen.otp ? (
                <Otp email={email} setDisplayScreen={displayScreenHandler} />
              ) : displayScreen.recoverPassword ? (
                <ForgotPassword
                  setEmail={setEmail}
                  email={email}
                  setDisplayScreen={displayScreenHandler}
                />
              ) : displayScreen.successPage ? (
                <SuccessPage
                  email={email}
                  password={password}
                  setDisplayScreen={displayScreenHandler}
                />
              ) : displayScreen.login ? (
                <Login setDisplayScreen={displayScreenHandler} />
              ) : (
                <>
                  {/* <StyledPrimaryTypography>Sign up </StyledPrimaryTypography>  
                            <StyledSecondaryTypography>The LOBY sign up portal is for authorised condo administrators.</StyledSecondaryTypography>
                            <TextField value={email} onChange={(e)=>setEmail(e.target.value)}  InputProps={{disableUnderline: true}} sx={{mt:3,mb:2}}  />  
                            <Button onClick={()=>displayScreenHandler('signup')} fullWidth variant="contained" sx={{my:2}}>Sign Up</Button>
                            <StyledTypography sx={{cursor:'pointer'}} onClick={()=>displayScreenHandler('recoverPassword')}  textAlign="center">Forgot password?</StyledTypography> */}
                  <Button
                    onClick={() => displayScreenHandler("signup")}
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    Sign Up
                  </Button>
                  <Button
                    onClick={() => displayScreenHandler("login")}
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    Login
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              height: "8%",
              width: "100%",
              backgroundColor: "#0E4965",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: "20px", color: "#FFFFFF" }}>
              loby
              <span style={{ color: "#D2D6DB", fontSize: "14px" , marginLeft: "6px", }}>
                Building Management System{" "}
              </span>
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default LandingPage;
