import { Box, Button, Modal, IconButton, Typography, Stack, Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { PrimaryTypography, TypographyDefault } from "../Theme";
import { ReactComponent as Close } from "../Icons/BorderAlertClose.svg";
import { ReactComponent as SnackbarClose } from "../Icons/AlertClose.svg";
import { ReactComponent as SnackbarSuccess } from "../Icons/SnackbarSuccess.svg";
import { Snackbar, SnackbarContent } from '@mui/material';
import { styledTyphographyDefault } from '../Theme';
import { ReactComponent as AlertTosterClose } from "../Icons/AlertTosterClose.svg";
import { ReactComponent as ContactBookToasterIcon } from "../Icons/ContactBookToaster.svg"
import { circularProgressClasses, } from '@mui/material/CircularProgress';


const AlertModal = ({ modalDatas, confirmFunction, closeFunction, isDraft }) => {
    let { label, descriptionOne, descriptionTwo, buttonOne, buttonTwo } = modalDatas
    return (
        <>
            <Modal open={true}>
                <Box sx={{ position: 'absolute', border: 'none', width: '100%', height: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ backgroundColor: '#FFFFFF', width: '40%', borderRadius: '8px', }}>
                        <Box sx={{ p: 2, borderBottom: '2px solid #F3F4F6', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <PrimaryTypography>{label}</PrimaryTypography>
                            <Close style={{cursor:'pointer'}} onClick={() => closeFunction()} />
                        </Box>
                        <Box sx={{ px: 2, py: 3, borderBottom: '2px solid #F3F4F6' }}>
                            <TypographyDefault>{descriptionOne}</TypographyDefault>
                            {descriptionTwo != '' && <TypographyDefault sx={{ mt: 3 }}>{descriptionTwo}</TypographyDefault>}
                        </Box>
                        <Box sx={{ p: 2, display: 'flex', justifyContent: isDraft ? 'end' : 'space-between' }}>
                            <Button onClick={() => closeFunction()} sx={{ background: buttonOne.backgroundColor, color: buttonOne.color, marginRight: isDraft ? '20px' : 'initial' }} variant={buttonOne.variant}>{buttonOne.text}</Button>
                            <Button onClick={() => confirmFunction()} sx={{ background: buttonTwo.backgroundColor, color: buttonTwo.color, "&:hover": { background: buttonTwo.backgroundColor } }} variant={buttonTwo.variant}>{buttonTwo.text}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AlertModal;



export const SnackbarSucces = ({ data }) => {
    return (
        <>
            <Box sx={{ position: 'absolute', bottom: '5%', display: 'flex', justifyContent: 'center', width: "80%" }}>
                <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px', borderColor: '#E5E7EB #E5E7EB #16A763 #E5E7EB', borderStyle: 'solid', borderWidth: '1px 1px 3px 1px', p: 1.5, boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20)' }}>
                    <SnackbarSuccess style={{ marginRight: '10px' }} />
                    <Stack direction={"column"}>
                        <Typography sx={{ color: '#384250', fontSize: '14px', fontWeight: 500 }}>{data.main}</Typography>
                        <Typography sx={{ color: '#384250', fontSize: '10px', fontWeight: 400 }}>{data.sub}</Typography>
                    </Stack>
                    <SnackbarClose style={{ marginLeft: '10px' }} />
                </Box>
            </Box>
        </>
    )
}



export const Toaster = ({ Toaster, setToaster }) => {


    const handleClose = () => {
        setToaster({ message: "", delStatus: true })
    }



    return (
        <>
            <Snackbar
                open={Boolean(Toaster.message)}
                onClose={() => handleClose()}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <SnackbarContent style={{
                    backgroundColor: 'white',
                    paddingTop: '0',
                    paddingBottom: '0',
                    color: '#384250',
                    fontWeight: '500',
                    borderRadius: '8px',
                    height: "55px"

                }}
                    message={(
                        <Stack direction='row' alignItems='center'>
                            {!(Toaster.delStatus) && <IconButton
                                key="success"
                                aria-label="success"
                                color="inherit"
                            >
                                <ContactBookToasterIcon />
                            </IconButton>}
                            <styledTyphographyDefault >
                                {Toaster.message}
                            </styledTyphographyDefault>
                        </Stack>
                    )}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => { handleClose() }}
                        >
                            <AlertTosterClose />
                        </IconButton>,

                    ]}

                />
            </Snackbar>
        </>
    )
}


export const PostLoaderModal = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
            open={true}
        // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}



export const PropertySetupLoaderModal = ({ open }) => {

    function FCircularProgress(props) {
        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
                    }}
                    size={80}
                    thickness={3}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? 'green' : '#308fe8'),
                        animationDuration: '2000ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={80}
                    thickness={3}
                    {...props}
                />
            </Box>
        );
    }

    return (
        <Modal open={open}>
            <Box sx={{
                position: 'absolute', border: 'none', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: "flex", alignItems: 'center', justifyContent: 'center'
            }}>
                <Box sx={{ backgroundColor: '#FFFFFF', width: { xs: '100%', lg: "80%" }, borderRadius: '8px', }}>
                    <Box sx={{ p: 4, borderBottom: '2px solid #F3F4F6', borderRadius: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Stack direction='row' alignItems='center' justifyContent='center'>
                            <Stack direction='column' alignItems='center'>
                                <FCircularProgress />

                                <TypographyDefault sx={{ fontSize: '32px !important', fontWeight: '600 !important', marginTop: '30px' }}>Creating Dashboard</TypographyDefault>
                                <TypographyDefault mt={2} textAlign='center'>Please give us a moment while we are creating and personalising the LOBY system for your condo. </TypographyDefault>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}